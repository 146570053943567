<template>
  <div class="main-container">
    <div class="left-box">
      <dd-card title="组织机构树" height="100%">
        <div class="container-left-tree" style = "height:700px;overflow:auto;">
          <el-tree :data="TreeList" accordion :props="defaultProps">
            <div
              class="custom-tree-node"
              slot-scope="{ node, data }"
              style="width: 100%"
            >
              <div @click="handleNodeClick(data)">{{ node.label }}</div>
            </div>
          </el-tree>
        </div>
      </dd-card>
    </div>
    <div class="right-box">
      <div class="serach-container">
        <dd-card title="要素列表" spacer>
          <dd-search-form
            :form-config="formList"
            @search-click="handleSearchEvent"
            labelWidth="100px"
            ref="ddSearchForm"
          ></dd-search-form>
        </dd-card>
      </div>
      <dd-card title="签到列表" :height="tableHeight">
        <dd-table
          :columns="columnsList"
          :data="tableData"
          border
          ref="dTableRef"
          :isSort="true"
          :topBtn="false"
          :isSelection="false"
        >
          <template #table-pagination>
            <dd-pagination
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="40%"
      height="60%"
      :show="infoPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
    >
      <info-pop
        v-if="infoPopupVisible"
        slot="main"
        :pointInfo="pointInfo"
      ></info-pop>
    </dd-popup>
  </div>
</template>
<script>
import { getApi, postApi } from "@/api/request";
import infoPop from "./components/InfoPop.vue";
import { getNowDate } from "@/utils/date.js";
export default {
  name: "detail",
  $dynamicRoute: {
    name: "签到详情",
    path: "/checkingIn/detail",
  },
  components: {
    infoPop,
  },
  data() {
    return {
      tableHeight: null,
      infoPopupVisible: false,
      poptitleName: "签到位置",
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      formData: {},
      tableData: [],
      formList: [
        {
          label: "签到类型",
          placeholderText: "请选择签到类型",
          value: "signType",
          change: this.handleChange,
          inputType: "select",
          labelWidth: "10px",
          defaultValue: "",
          optionsList: [],
          optionsListConfig: {
            code: "code",
            name: "name",
          },
        },
        {
          label: "签到状态",
          placeholderText: "签到状态",
          value: "instatus",
          change: this.handleChange,
          inputType: "select",
          defaultValue: "",
          labelWidth: "10px",
          optionsList: [],
          optionsListConfig: {
            code: "code",
            name: "name",
          },
        },

        {
          label: "签到时间",
          placeholderText: "请选择查询日期",
          value: "date",
          inputType: "date",
          format: "yyyy-MM-dd",
          dateType: "daterange",
          defaultValue: "",
        },
      ],
      columnsList: [
        {
          title: "日期",
          key: "checkdate",
          align: "center",
          overflow: true,
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        {
          title: "人员类型",
          key: "empType",
          align: "center",
        },
        {
          title: "签到时间",
          key: "checkindate",
          align: "center",
        },
        {
          title: "签到人",
          key: "userFullName",
          align: "center",
        },
        {
          title: "签到状态",
          key: "instatusName",
          align: "center",
        },
        {
          title: "签到位置",
          align: "center",
          width: "120px",
          event: [
            {
              show: "true",
              label: "查看详情",
              icon: "el-icon-info",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
          ],
        },
        {
          title: "签到类型",
          key: "name",
          align: "center",
        },
        {
          title: "项目编码",
          key: "prjCode",
          align: "center",
        },
        {
          title: "项目名称",
          key: "prjName",
          align: "center",
        },
        {
          title: "审核类型",
          key: "editReason",
          align: "center",
        },
        {
          title: "送审说明",
          key: "remark",
          align: "center",
          width: "150px",
        },
      ],
      total: 0,
      current: 1,
      size: 10,
      pointInfo: "",
      deptId: "",
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(1);
    });
    this.getDeptTree(); //组织机构树
    this.getTypeList(); //签到类型
    this.getStatusList(); //签到状态
    this.getElmentList();
  },
  methods: {
    getTypeList() {
      let params = {
        typeCode: "attend_type",
      };
      postApi(`/hr/hrdict/list?typeCode=${params.typeCode}`).then((res) => {
        this.formList.forEach((item) => {
          let { data } = res;
          if (item.value == "signType") {
            item.optionsList = data.data;
          }
        });
      });
    },
    //考勤状态列表数据
    getStatusList() {
      let params = {
        typeCode: "type-qdzt",
      };
      postApi(`/hr/hrdict/list?typeCode=${params.typeCode}`, params).then(
        (res) => {
          this.formList.forEach((item) => {
            let { data } = res;
            if (item.value == "instatus") {
              item.optionsList = data.data;
            }
          });
        }
      );
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getElmentList();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.formData.deptId = this.deptId;
      if (this.deptId == "") {
        this.$message.warning("请先选择组织机构或部门");
      } else {
        this.getElmentList();
      }
    },
    // 获取组织机构数据
    getDeptTree() {
      getApi(`/admin/dept/user-tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.TreeList = data.data;
        }
      });
    },
    handleNodeClick(data) {
      this.deptId = data.id;
      this.getElmentList();
    },
    getElmentList() {
      let { current, size } = this;
      let params = {
        current,
        size,
        signType: this.formData.signType,
        startTimeStr: this.formData.date ? this.formData.date[0] : "",
        endTimeStr: this.formData.date ? this.formData.date[1] : "",
        instatus: this.formData.instatus,
        deptId: this.deptId,
      };
      getApi(`/hr/attend/monthinfo/page`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleClose() {
      this.pointInfo = "";
      this.infoPopupVisible = false;
    },
    handleInfo(row) {
      this.pointInfo = row.inlocation;
      this.infoPopupVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  position: relative;
  display: flex;
  .left-box {
    width: 20%;
    border-right: 5px solid #e7eaec;
  }
  .right-box {
    width: 80%;
  }
}
</style>
