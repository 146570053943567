<template>
  <div class="container">
    <dd-card title="生效时间" spacer
      ><dd-form
        :form-config="formBasicList"
        :flex="2"
        labelWidth="140px"
        ref="effectTimeRef"
        formRef="effectTimeRef"
      >
      </dd-form>
    </dd-card>
    <dd-card title="外勤范围列表" :height="tableHeight">
      <dd-table
        ref="baseTable_1"
        :columns="tableColumns"
        @select-change="handleSelectionChange"
        :handleCheckDisable="handleCheckDisable"
        :data="tableData"
        border
        :isSelection="true"
        :isSelectable="true"
      >
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <dd-popup
      width="80%"
      height="90%"
      inPopup
      showBtn
      :saveBtn="false"
      cancelBtn
      :show="addPopupVisible"
      title-name="查看范围"
      @popup-close="handleClose0"
      @popup-save="handleSave('addAreaPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :type="type"
        :id="id"
      ></add-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
import addPop from "../../Region/components/AddPop.vue";
export default {
  props: ["pageProp"],
  name: "addSignRange",
  components: { addPop },
  data() {
    return {
      addPopupVisible: false,
      loading: false,
      formBasicList: [
        {
          label: "生效开始时间",
          value: "effectStartTime",
          placeholderText: "请选择生效开始时间",
          inputType: "dateStr",
          dateType: "date",
          filterable: true,
          format: "yyyy-MM-dd",
          defaultValue: "",
          rules: [
            { required: true, message: "请选择生效开始时间", trigger: "blur" },
          ],
        },
        {
          label: "生效结束时间",
          value: "effectEndTime",
          placeholderText: "请选择生效结束时间",
          inputType: "dateStr",
          dateType: "date",
          filterable: true,
          defaultValue: "2099-01-01",
          format: "yyyy-MM-dd",
          rules: [
            { required: true, message: "请选择生效结束时间", trigger: "blur" },
          ],
        },
      ],
      tableColumns: [
        {
          title: "区域名称",
          key: "areaname",
          align: "center",
          overflow: true,
        },
        {
          title: "一级部门",
          key: "fenDeptName",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "操作",
          width: 300,
          align: "center",
          event: [
            {
              show: "true",
              label: "查看详情",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
          ],
        },
      ],

      tableHeight: null,
      tableData: [],
      selectData: [],
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
  },
  methods: {
    handleClose0() {
      this.addPopupVisible = false;
    },
    handleInfo(row) {
      this.id = row.id;
      this.type = "view";
      this.addPopupVisible = true;
    },
    handleSelectionChange(e) {
      this.selectData = e;
    },
    getTableData() {
      let { current, size } = this;
      let params = {
        current,
        size,
      };
      getApi(`/hr/attend/baseinfo/page`, params).then((res) => {
        if (res.data.code == 0) {
          //   if (this.pageProp.tableData && this.pageProp.tableData.length > 0) {
          //     this.pageProp.tableData.forEach((it) => {
          //       res.data.data.records.forEach((item) => {
          //         if (it.areaId == item.id) {
          //           item.disabledCheck = 1;
          //         }
          //       });
          //     });
          //   }
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getTableData();
    },
    handleCheckDisable(row) {
      if (row.disabledCheck == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>