<template>
  <div class="container">
    <dd-table
      ref="baseTable_1"
      :columns="tableColumns"
      :data="tableData"
      @select-change="handleSelectionChange"
      border
      :isSelection="true"
      :loading="loading"
    >
      <template #table-btn>
        <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
          >添加付款单
        </dd-button>
        <dd-button type="primary" icon="minus" size="medium" @click="handleDelete"
          >移除
        </dd-button>
      </template>
    </dd-table>
    <dd-popup
      v-if="showPayment"
      width="100%"
      height="100%"
      inPopup
      showBtn
      :saveBtn="saveBtn"
      :submitBtn="false"
      :show="showPayment"
      title-name="添加付款单"
      @popup-close="handleClose"
    >
      <addPaymentPopup
        ref="addPayment"
        :pageProp="pageProp"
        @addPayment="addPayment"
        slot="main"
      ></addPaymentPopup>
    </dd-popup>
  </div>
</template>
 
<script>
import { getApi } from "@/api/request";
import addPaymentPopup from "./addPaymentPopup.vue";
export default {
  props: ["pageProp"],
  name: "addPaymentFir",
  components: { addPaymentPopup },
  data() {
    return {
      saveBtn: false,
      showPayment: false,
      tableColumns: [
        {
          title: "单据编号",
          key: "paymentfnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "单据名称",
          key: "paymentfname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同编码",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "供应商",
          key: "fparta",
          align: "center",
          overflow: true,
        },
        {
          title: "本期实付金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "不含税金额",
          key: "fnotaxdataamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "税额",
          key: "ftax",
          align: "center",
          overflow: true,
        },
      ],
      tableData: [],
      selectTableSource: [],
      loading: false,
    };
  },
  created() {},
  mounted() {
    this.getPageData();
  },
  methods: {
    getPageData() {
      getApi(`/contract/execute/query/payment?id=${this.pageProp.id}`).then(
        (res) => {
          if (res.data.code == 0) {
            let { data } = res;
            this.tableData = data.data;
          }
        }
      );
    },
    handleSelectionChange(e) {
      this.selectTableSource = e;
    },
    handleDelete() {
      if (this.selectTableSource.length == 0) {
        this.$message.warning("请先至少勾选一条数据");
        return false;
      }

      this.selectTableSource.forEach((item) => {
        const index = this.tableData.findIndex((row) => row.id === item.id);
        if (index > -1) {
          this.tableData.splice(index, 1);
        }
      });
      // 清空已选择的项
      this.selectTableSource = [];
      this.$refs.baseTable_1.$refs.baseTable.clearSelection();
    },
    handleAdd() {
      this.pageProp.tableData = this.tableData;
      this.showPayment = true;
    },
    handleClose() {
      this.showPayment = false;
    },
    addPayment(obj) {
      this.tableData.push(obj);
    },
  },
};
</script>

<style lang="less" scoped>
</style>