<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="工作包列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        border
        :isSelection="false"
        show-page
      >
        <template #table-btn>
          <dd-button
            type="warning"
            size="medium"
            icon="back"
            @click="handleBackPage"
            >返回</dd-button
          >
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >新增</dd-button
          >
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-work-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></add-work-pop>
    </dd-popup>
    <!-- 编辑计量与绩效计算页面弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="performancePopVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handlePerformanceSave('formulaForm', 'baseForm')"
    >
      <performance-compute
        v-if="performancePopVisible"
        slot="main"
        ref="performancePop"
        :rowData="rowData"
      ></performance-compute>
    </dd-popup>
    <!-- 历史绩效版本页面弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      :showBtn="false"
      :show="historyVersionPopVisible"
      title-name="历史绩效"
      @popup-close="handleClose"
    >
      <historyVersionPop
        v-if="historyVersionPopVisible"
        slot="main"
        ref="historyVersionPop"
        :rowData="rowData"
      ></historyVersionPop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addWorkPop from "./components/AddWorkPop.vue";
// 编辑计量与绩效计算
import performanceCompute from "@/views/Project/ProjectBaseManagement/components/performanceCompute";
// 历史绩效版本
import historyVersionPop from "./components/HistoryVersionPop.vue";
export default {
  props: {
    rowId: null,
  },
  components: {
    addWorkPop,
    performanceCompute,
    historyVersionPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      formData: {},
      formList: [
        // {
        //   label: "工作包编码",
        //   value: "nodeCode",
        //   inputType: "text",
        //   placeholderText: "请输入工作包编码",
        // },
        {
          label: "工作包名称",
          value: "nodeName",
          inputType: "text",
          placeholderText: "请输入工作包名称",
        },
      ],
      tableColumns: [
        {
          title: "区域节点",
          key: "areaCodeName",
          align: "center",
        },
        {
          title: "业务类型",
          key: "businessName",
          align: "center",
        },
        // {
        //   title: "工作包编码",
        //   key: "nodeCode",
        //   align: "center",
        // },
        {
          title: "工作包名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "工作包类型",
          key: "nodeTypeName",
          align: "center",
        },
        // {
        //   title: "启用标志",
        //   key: "validateCode",
        //   align: "center",
        // },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 400,
          event: [
            {
              label: "编辑计量与绩效计算",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditorPerformance(row);
              },
              type: "primary",
            },
            {
              label: "查看历史绩效版本",
              icon: "el-icon-view",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "warning",
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      dictType: [],
      paramType: [],
      statusType: [],
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
      performancePopVisible: false,
      // 历史绩效版本
      historyVersionPopVisible: false,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    handleBackPage() {
      this.$emit("pageBack");
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {},
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
        accountingId: this.rowId,
      };
      getApi(`/project/work/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.performancePopVisible = false;
      this.historyVersionPopVisible = false;
    },
    // 编辑计量与绩效计算
    handleRowEditorPerformance(row) {
      this.performancePopVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑计量与绩效计算__" + row.nodeTypeName;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.historyVersionPopVisible = true;
      this.rowData = row;
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/project/work/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let workList = this.$refs.addPop.multipleSelection;
      console.log(workList);
      if (workList && workList.length == 0) {
        this.$message.warning("请选择工作包");
        return;
      }
      let nodeIds = [];
      workList.forEach((item) => {
        nodeIds.push(item.id);
      });
      let params = {
        accountingId: this.rowId,
        nodeIds,
      };
      postApi(`/project/work/saveWork`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("保存成功");
          this.handleClose();
          this.getTableData();
        }
      });
    },
    // 编辑计量与绩效计算保存事件
    handlePerformanceSave(formName, formNameBase) {
      let flag = false;
      this.$refs.performancePop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.performancePop.$refs[formName].formData;
      params.nodeId = this.rowData.id;
      params.details = this.$refs.performancePop.tableDataSources;
      if (flag) {
        postApi(`/project/work/detail/release`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose(); 
            this.getTableData();
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
