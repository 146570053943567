<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :labelWidth="'170px'"
        :flex="3"
        ref="assignFormSales"
        formRef="assignFormSales"
      ></dd-form>
    </dd-card>

    <dd-card title="销售预算固定项">
          <dd-table
            :columns="tableColumns"
            :data="tableData"
            :topBtn="false"
            :is-selection="false"
            :loading="loading"
            border
          >
          </dd-table>
    </dd-card>
    <!-- <dd-card title="审批流程" v-if="addEdit.showStep">
      <el-steps :active="activeIndex" align-center :finish-status="activeIndex == activityList.length ? 'success' : 'finish'">
        <el-step
          v-for="(val,idx) in activityList" :key="idx"
          :title="val.activityName"
        >
        <div slot="description" class="description">
          <div v-for="(item,index) in val.description" :key="index" class="list">
            <div class="flex_c">
              {{item.time}}
              <div class="name">{{item.userName}} {{item.message}}</div>
            </div>
            <div>意见 {{item.fullmessage}}</div>
          </div>
        </div>
        </el-step>
      </el-steps>
    </dd-card> -->
    <dd-card title="审批流程" v-if="addEdit.showStep">
      <dd-table
        ref="expenseDetail"
        :topBtn="false"
        :isSelection="false"
        :isSort="true"
        :columns="tableColumnsStep"
        :data="tableDataSources"
        border
      ></dd-table>
    </dd-card>

    <dd-card title="">
      <dd-form
        :form-config="PersonList"
        :labelWidth="'170px'"
        :flex="3"
        ref="personFormSales"
        formRef="personFormSales"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
import { getNowDate } from "@/utils/date";
import qs from "qs";
import { mapState, mapActions } from "vuex";
import { disable } from "ol/rotationconstraint";

export default {
  name: "add",
  props: {
    type: "",
    addEdit: {},
  },
  // props: ["type", "id", "state"],
  computed: {
    // ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      activityList: [],
      description: [],
      tableDataSources: [],
      tableColumnsStep: [
        {
          title: "节点名称",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "time",
          align: "center",
        },
        {
          title: "节点操作人",
          key: "userName",
          align: "center",
        },
        {
          title: "审批意见",
          key: "fullmessage",
          align: "center",
        },
      ],
      activeIndex: 1,
      budgetTotalAmount: 0,
      loading: false,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
      }, // 请求头信息
      tableData: [{
        jobContent: '合同费用'
      }], // 销售预算固定项列表数据
      tableColumns: [ // 销售预算固定项表头
        {
          title: "工作内容",
          key: "jobContent",
          align: "center",
        },
        {
          title: "材料办公费",
          key: "materialsOfficeFee",
          align: "center",
          input: [
            {
              method: (value, row, index) => {
                this.changeAmount(value, row, index);
              },
              isEdit: () => {
                let state = false;
                if (this.addEdit.type == "detail") {
                  state = true;
                }
                return state;
              },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, '');
                if (result.charAt(0) === '.') {
                  result = result.slice(1);
                }
                const parts = result.split('.');
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join('.');
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                return result;
              },
            }
          ]
        },
        {
          title: "外协劳务费",
          key: "outsourcedLaborFee",
          align: "center",
          input: [
            {
              method: (value, row, index) => {
                this.changeAmount(value, row, index);
              },
              isEdit: () => {
                let state = false;
                if (this.addEdit.type == "detail") {
                  state = true;
                }
                return state;
              },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, '');
                if (result.charAt(0) === '.') {
                  result = result.slice(1);
                }
                const parts = result.split('.');
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join('.');
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                return result;
              },
            }
          ]
        },
        {
          title: "小计",
          key: "subtotal",
          align: "center",
        },
      ],
      formList: [ // 基本信息配置项
      {
          label: "生产任务书名称",
          value: "assignName",
          inputType: "select",
          optionsList: [],
          disabled: this.addEdit.type == "detail",
          filterable: "filterable",
          placeholderText: "请选择生产任务书名称",
          optionsListConfig: {
            name: "assignName",
            code: "assignId",
          },
          rules: [
              {
                required: true,
                message: "请选择生产任务书名称",
                trigger: "blur",
              },
            ],
          change: this.changeAssignName,
        },
        {
          label: "生产任务书编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "销售预算总额",
          value: "budgetTotalAmount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "销售合同名称",
          value: "contractName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "销售合同编号",
          value: "contractCode",
          inputType: "text",
          disabled: true,
        },
        {
          label: "暂定合同额",
          value: "assignAmount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "版本",
          value: "version",
          inputType: "text",
          disabled: true,
        },
        {
          label: "状态",
          value: "statusName",
          inputType: "text",
          disabled: true,
        },
      ],
      PersonList: [ // 底部编制人信息 表单
      {
          label: "编制人",
          value: "applierName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "编制时间",
          value: "applyDate",
          inputType: "date",
          format: "yyyy-MM-dd",
          dateType: "date",
          defaultValue: '',
          disabled: true,
        },
      ],
    };
  },
  mounted() {
    this.getAssignList();
    if(this.addEdit.pageType = 'workout') {
      if (this.addEdit.type == 'add') {
        this.$nextTick(() => {
          this.personListDefalutData();
        })
      }
      if (this.addEdit.type == 'detail' || this.addEdit.type == 'edit') {
        this.getDetailData(this.addEdit.id)
      }
    }
  },
  methods: {
    getStepList(jbxxData) { // 获取审批流程
      getApi(`/activiti/process/viewPage/${jbxxData.processInstanceId}`).then(res => {
        let { data } = res;
        if(res.data.code == 0) {
          this.activityList = data.data;
          this.activityList.forEach((item,index) => {
            if(item.isCurrentActiviti == 1 || item.isHistoricActiviti == 1) {
              this.activeIndex = index+1
            }
          })
          if (jbxxData.taskNodes) {
                  this.activityList.forEach((item) => {
                    item.description = [];
                    jbxxData.taskNodes.forEach((val) => {
                      if (item.activityName == val.taskName) {
                            item.description.push(val)
                      }
                    })
                  })
                }
        }
      })
    },
    getDetailData(id) { // 获取详情数据
      getApi(`/finance/budget/establishment/${id}`).then(res => {
          if(res.data.code == 0) {
            let {data} = res;
            this.$refs.personFormSales.formData = {
              applierName: data.data.applierName,
              applyDate: data.data.applyDate,
              applierId: data.data.applierId,
            }
            let { finBudgetEstablishmentXsGds, ...jbxxData} = data.data
            this.$refs.assignFormSales.formData =  jbxxData
            this.tableData = finBudgetEstablishmentXsGds
            if(this.addEdit.showStep) {
              // this.getStepList(jbxxData);
              this.tableDataSources = data.data.taskNodes


            }
          }
        })
    },
    personListDefalutData() { // 编制人add状态 默认值
      this.$refs.personFormSales.formData = {
            applierName: sessionStorage.getItem("fullName"),
            applyDate: this.$moment().format('YYYY-MM-DD'),
            applierId: sessionStorage.getItem("userId"),
          }
    },
    changeAmount(value, row, index) {
      if(row.materialsOfficeFee || row.outsourcedLaborFee) {
        const subtotal = (Number(row.materialsOfficeFee || 0) + Number(row.outsourcedLaborFee || 0)).toFixed(2);
        this.$set(row, 'subtotal', subtotal)
        this.budgetTotalAmount = subtotal;
        this.$set(this.$refs.assignFormSales.formData, 'budgetTotalAmount', subtotal)
      }
    },
    changeAssignName(e) { // 任务书change事件 并回填表单内容
      if(e) {
        this.formList[0].optionsList.forEach(item => {
          if(item.assignId == e) {
            this.$refs.assignFormSales.formData = {...item}
          }
        })
      }
      else {
        this.$refs.assignFormSales.formData = {}
      }
      this.$set(this.$refs.assignFormSales.formData, 'budgetTotalAmount', this.budgetTotalAmount)
    },
    getAssignList() { // 获取任务书列表
      getApi(`/project/assign/budget/assign/list/xs/gr`).then(res => {
        if(res && res.data.code == 0) {
          let { data } =  res;
          let options = {
            value: "assignName",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options)
        }
      })
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.buttonBox{
  margin: 4px 0;
}
/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
  width: 16.67% !important;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
</style>
