<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          :labelWidth="'190px'"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>

      <dd-card title="外勤范围" height="100%">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          border
          :isSelection="false"
          show-page
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >新增</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <!-- 新增/修改弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      showBtn
      :saveBtn="this.type == 'add' || this.type == 'update' ? true : false"
      cancelBtn
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addAreaPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :type="type"
        :id="id"
      ></add-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi, delApi, putApi } from "@/api/request";
import addPop from "./components/AddPop.vue";
export default {
  name: "region",
  $dynamicRoute: {
    name: "外勤范围管理",
    path: "/checkingIn/baseManage/region",
  },
  components: {
    addPop,
  },
  data() {
    return {
      id: "",
      formList: [
        {
          label: "区域名称",
          value: "areaname",
          inputType: "text",
          placeholderText: "请输入区域名称进行搜索",
        },
        {
          label: "一级部门",
          value: "fenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择一级部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
      ],
      tableColumns: [
        {
          title: "区域名称",
          key: "areaname",
          align: "center",
        },
        {
          title: "一级部门",
          key: "fenDeptName",
          align: "center",
        },
        {
          title: "操作",
          width: 300,
          align: "center",
          event: [
            {
              show: "true",
              label: "查看详情",
              icon: "el-icon-info",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleEditor(row);
              },
              type: "warning",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleDel(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
      addPopupVisible: false,
      poptitleName: "",
      type: "",
      searchData: {}
    };
  },
  mounted() {
    this.getTableData();
    this.getFenDept();
  },
  methods: {
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getFenDept() {
      getApi(`/admin/sysfendept/getList`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "fenDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    handleSearchEvent(e) {
      this.current = 1;
      this.searchData = e;
      this.getTableData();
    },
    // 获取表格列表数据
    getTableData() {
      let { searchData, current, size } = this;
      let params = {
        ...searchData,
        current,
        size,
      };
      getApi(`/hr/attend/baseinfo/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增按钮事件
    handleAdd() {
      this.type = "add";
      this.addPopupVisible = true;
      this.poptitleName = "新增外勤范围";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.addPopupVisible = false;
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            if (this.type == "add") {
              let params = {
                lngAndLatStr: this.$refs.addPop.lngAndLatStr,
                ...this.$refs.addPop.$refs[formName].formData,
              };
              postApi(`/hr/attend/baseinfo`, params).then((res) => {
                let { code } = res.data;
                if (code == 0) {
                  this.addPopupVisible = false;
                  this.getTableData();
                  this.$message.success("新增成功");
                }
              });
            } else if (this.type == "update") {
              let params = {
                lngAndLatStr: this.$refs.addPop.lngAndLatStr,
                ...this.$refs.addPop.$refs[formName].formData,
                id: this.id,
              };
              putApi(`/hr/attend/baseinfo`, params).then((res) => {
                console.log(res);
                let { code } = res.data;
                if (code == 0) {
                  this.addPopupVisible = false;
                  this.getTableData();
                  this.$message.success("编辑成功");
                }
              });
            }
          }
        }
      );
    },
    //查看详情
    handleInfo(row) {
      this.id = row.id;
      this.type = "view";
      this.addPopupVisible = true;
      this.poptitleName = "查看详情";
    },
    //编辑
    handleEditor(row) {
      this.id = row.id;
      this.type = "update";
      this.addPopupVisible = true;
      this.poptitleName = "编辑";
    },
    //删除
    handleDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/hr/attend/baseinfo/${row.id}`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("删除成功");
          }
          this.getTableData();
        });
      });
    },
  },
};
</script>

<style></style>
