<template>
  <div class="main-container">
    <dd-form
      :form-config="formBasicList"
      labelWidth="150px"
      ref="addPopForm"
      formRef="addPopForm"
    >
    </dd-form>
  </div>
</template>
  
  <script>
import { getApi, postApi } from "@/api/request";

export default {
  name: "addEditContract",
  props: ["pageProp"],
  data() {
    return {
      formBasicList: [
        {
          label: "合同编号",
          value: "fnumber",
          inputType: "text",
          placeholderText: "请输入合同编号",
          rules: [
            {
              required: true,
              message: "请输入合同编号",
              trigger: "blur",
            },
          ],
        },
        {
          label: "合同名称",
          value: "fname",
          inputType: "text",
          placeholderText: "请输入合同名称",
          rules: [
            {
              required: true,
              message: "请输入合同名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "合同类型",
          value: "procurementType",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "code",

          },
          rules: [
            {
              required: true,
              message: "请选择合同类型",
              trigger: "blur",
            },
          ],
          placeholderText:
              "请选择合同类型进行搜索",
        },
        {
          label: "变更后总金额",
          value: "ftotalamount",
          inputType: "text",
          placeholderText: "请输入变更后总金额",
          rules: [
            {
              required: true,
              message: "请输入变更后总金额",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "甲方",
          value: "fpartaid",
          inputType: "text",
          placeholderText: "请选择合同甲方",
          disabled: true,
          defaultValue: "西安大地测绘股份有限公司",
          rules: [
            {
              required: true,
              message: "请选择合同甲方",
              trigger: "blur",
            },
          ],
        },
        {
          label: "乙方",
          value: "fpartbid",
          inputType: "select",
          filterable: true,
          optionsList: [],
          placeholderText: "请选择合同乙方",
          optionsListConfig: {
            name: "purName",
            code: "purId",
          },
          rules: [
            {
              required: true,
              message: "请选择合同乙方",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {
    this.getPartbList();
  },
  methods: {
    getDataType() {
      // 采购合同类型
      postApi(`/contract/dicttype/list/all`, { code: "PC-CCONTRACTTYPE-STATUS" }).then(res => {
        if (res.data.code == 0) {
          this.formBasicList.forEach(item => {
            if (item.value == 'procurementType') {
              item.optionsList = res.data.data
            }
          })
        }
      })
    },
    getPartbList() {
      getApi(`/project/purinfo/list`).then((res) => {
        if (res.data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "fpartbid") {
              item.optionsList = res.data.data;
            }
          });
          if (this.pageProp.pageType == "edit") {
            this.formBasicList.forEach((item) => {
              item.defaultValue = this.pageProp.data[item.value];
              if (item.value == "fpartaid") {
                item.defaultValue = this.pageProp.data.fpartaName;
              }
            });
          }
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
</style>