<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          :labelWidth="'190px'"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>

      <dd-card title="个人考勤列表" :height="tableHeight">
        <dd-table
          :columns="columnsList"
          :data="tableData"
          border
          :isSelection="true"
          show-page
          :loading="loading"
          @select-change="handleSelectionChange"
          ref="dTableRef"
        >
          <template #table-btn-right>
            <el-button
              icon="el-icon-s-operation"
              circle
              type="info"
              size="small"
              @click="drawerVisible = true"
              title="列筛选"
            ></el-button>
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handlePageChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>

      <dd-filter-drawer
        v-if="drawerVisible"
        width="30%"
        title-name="列筛选"
        :show="drawerVisible"
        :tableColumns="tableColumns"
        :columns="columnsList"
        @popup-close="drawerVisible = false"
        @handleColumnsChange="handleColumnsChange"
      >
      </dd-filter-drawer>

      <!-- 查看详情 -->
      <dd-popup
        v-if="detailVisible"
        width="60%"
        height="70%"
        showBtn
        :saveBtn="false"
        :submitBtn="false"
        :show="detailVisible"
        title-name="查看详情"
        @popup-close="handleClose"
      >
        <detailPop
          ref="detailVisible"
          :pageProp="pageProp"
          slot="main"
        ></detailPop>
      </dd-popup>
    </div>
  </div>
</template>
      <script>
import { getApi, postApi } from "@/api/request";
import detailPop from "./components/detailPop.vue";
export default {
  name: "personalAttend",
  $dynamicRoute: {
    name: "个人考勤",
    path: "/checkingIn/personalAttend/index",
  },
  components: {
    detailPop,
  },
  data() {
    return {
      drawerVisible: false,
      detailVisible: false,
      loading: false,
      tableHeight: null,
      formData: {},
      tableData: [],
      formList: [
        {
          label: "所属考勤月",
          value: "month",
          inputType: "date",
          dateType: "month",
          format: "yyyy-MM",
          placeholderText: "请选择所属考勤月进行搜索",
        },
      ],
      columnsList: [
        {
          title: "所属考勤月",
          key: "month",
          align: "center",
          overflow: true,
        },
        {
          title: "工作日天数",
          key: "workDay",
          align: "center",
        },
        {
          title: "已正常打卡天数",
          key: "normalDay",
          align: "center",
        },
        {
          title: "操作",
          width: 200,
          align: "center",
          event: [
            {
              show: "true",
              label: "查看详情",
              method: (row) => {
                this.handleDetail(row);
              },
              type: "primary",
            },
          ],
        },
      ],
      total: 0,
      current: 1,
      size: 10,
      selectTable: [],
      tableColumns: [],
      pageProp: {},
    };
  },
  mounted() {
    this.tableColumns = [...this.columnsList];
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(1);
    });
    this.getElmentList();
  },
  methods: {
    handleAdd() {
      this.dialogVisible = true;
    },
    handleDetail(row) {
      this.detailVisible = true;
      this.pageProp = row;
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handlePageChange(size, number) {
      this.size = size;
      this.current = number;
      this.getElmentList();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getElmentList();
    },
    getElmentList() {
      let { formData } = this;
      let param = {
        ...formData,
      };
      postApi(
        `/hr/attendsignsum/personal/page/${this.current}/${this.size}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.selectTable = e;
    },
    handleClose() {
      this.detailVisible = false;
    },
  },
};
</script>
      
    <style lang="less" scoped>
</style>
      