<template>
  <div class="main-container">
    <div class="buttonBtn" v-if="titleName == '详情'">
      <dd-button style="position: absolute; right: 15px; top: 5px" type="primary" size="medium" icon="view" 
        @click="handleDetail">
          修改记录
        </dd-button>
    </div>
    <el-tabs v-model="defaultTabs" type="card" sticky="true">
      <el-tab-pane label="基本信息" name="jbxx" style="height: 500px;overflow: hidden;overflow-y:auto">
        <dd-form
          :form-config="formBasicList"
          :flex="3"
          labelWidth="220px"
          ref="addPopForm"
        >
        </dd-form>
        <dd-form :form-config="formBasicList1" labelWidth="165px" ref="addPopForm1">
        </dd-form>
      </el-tab-pane>
      <el-tab-pane label="客户相关信息" name="khxgxx" style="height: 500px;overflow: hidden;overflow-y:auto">
        <dd-form
          :form-config="formBasicListKh"
          :flex="3"
          labelWidth="220px"
          ref="addPopFormKh"
        >
        </dd-form>
      </el-tab-pane> 
      <el-tab-pane label="项目关联信息" name="xmglxx" style="height: 500px;overflow: hidden;overflow-y:auto">
        <dd-form
          :form-config="formBasicListXm"
          :flex="3"
          labelWidth="240px"
          ref="addPopFormXm"
        >
        </dd-form>
      </el-tab-pane>
      <!-- 记录信息 -->
      <el-tab-pane label="记录信息" name="jlxx" title="记录信息">
        <dd-form
          :form-config="formBasicListJL"
          :flex="2"
          labelWidth="230px"
          ref="addPopFormJL"
        >
        </dd-form>
      </el-tab-pane>
      <!-- 管理信息 -->
      <el-tab-pane label="管理信息" name="glxx" title="管理信息">
        <dd-form
          :form-config="formBasicListGL"
          :flex="2"
          labelWidth="220px"
          ref="addPopFormGL"
        >
        </dd-form>
      </el-tab-pane>
    </el-tabs>
    <!-- 修改记录 -->
    <dd-popup
      width="90%"
      height="90%"
      inPopup
      :show="detailPopupVisible"
      title-name="修改记录"
      @popup-close="detailPopupVisible = false"
      >
      <EditRecordPop
        v-if="detailPopupVisible"
        slot="main"
        :assignId="rowData.id"
      ></EditRecordPop>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
import { getNowDate, getNowYear } from "@/utils/date";
import EditRecordPop from "./EditRecordPop.vue";
export default {
  name: "add-pop",
  props: {
    rowData: [],
    titleName: "",
  },
  components: {
    EditRecordPop,
  },
  data() {
    return {
      defaultTabs: "jbxx",
      formBasicList: [
        {
          label: "单据编号",
          value: "assignCode",
          inputType: "text",
          placeholderText: "审批通过后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "保存后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书年份",
          value: "assignYear",
          inputType: "text",
          defaultValue: getNowYear(),
          disabled: true,
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入任务书名称",
              trigger: "blur",
            },
          ],
        },
        
        {
          label: "预计合同金额(元)",
          value: "estimatedAmount",
          inputType: "text",
          placeholderText: "请输入预计合同金额",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入预计合同金额",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            }
          ],
        },
        {
          label: "所在区域",
          value: "regionAreaId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择所在区域",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择所在区域",
              trigger: "blur",
            },
          ],
        },
        {
          label: "项目生产需求级别",
          value: "assignLevel",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择项目生产需求级别",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择项目生产需求级别",
              trigger: "blur",
            },
          ],
        },
        {
          label: "任务工期",
          value: "taskDuration",
          placeholderText: "任务工期",
          inputType: "date",
          dateType: "daterange",
          defaultValue: getNowDate(),
          format: "yyyy-MM-dd",
          rules: [
            {
              type: "array",
              required: true,
              message: "请选择任务工期",
              trigger: "blur",
            },
          ],
        },
        // --------------添加开始------------
        {
          label: "任务下发分管部门",
          value: "downFenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务下发分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择任务下发分管部门",
              trigger: "blur",
            },
          ],
          change: this.getDownFenDeptId,
        },
        {
          label: "任务下发部门",
          value: "downDeptId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          filterable: true,
          placeholderText: "请选择任务下发部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择任务下发部门",
              trigger: "blur",
            },
          ],
          change: this.getDownDeptId,
        },
        {
          label: "客户经理",
          value: "cmmmanagerId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: false,
          defaultValue: "",
          placeholderText: "请选择客户经理",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          rules: [
            {
              required: true,
              message: "请选择客户经理",
              trigger: "blur",
            },
          ],
        },
        {
          label: "任务生产分管部门",
          value: "receiveFenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务生产分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择任务生产分管部门",
              trigger: "blur",
            },
          ],
          change: this.getReceiveFenDeptId,
        },
        {
          label: "任务生产部门",
          value: "receiveDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          defaultValue:'',
          placeholderText: "请选择任务生产部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择任务生产部门",
              trigger: "blur",
            },
          ],
          change:this.modeReceiveDeptId
        },
        {
          label: "项目经理",
          value: "leaderId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择项目经理",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
        },
        {
          label: "业务类型",
          value: "businessTypeId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择业务类型",
          rules: [
            {
              required: false,
              message: "请选择业务类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "生产控制模式",
          value: "produceType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择生产控制模式",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: false,
              message: "请选择生产控制模式",
              trigger: "blur",
            },
          ],
        },
      ],
      formBasicList1: [
        {
          label: "任务概况",
          value: "assignOverview",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入任务概况",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          rules: [
            {
              required: true,
              message: "请输入任务概况",
              trigger: "blur",
            },
          ],
        },
        {
          label: "工作内容及工作量",
          value: "contentWorkload",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入工作内容及工作量",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          rules: [
            {
              required: true,
              message: "请输入工作内容及工作量",
              trigger: "blur",
            },
          ],
        },
        {
          label: "执行标准及质量要求",
          value: "qualityRequire",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入执行标准及质量要求",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          rules: [
            {
              required: true,
              message: "请输入执行标准及质量要求",
              trigger: "blur",
            },
          ],
        },
        {
          label: "应提交的资料",
          value: "resultList",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入应提交的资料",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          rules: [
            {
              required: true,
              message: "请输入应提交的资料",
              trigger: "blur",
            },
          ],
        },
        {
          label: "特殊情况及注意事项",
          value: "specialPrecautions",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入特殊情况及注意事项",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
        },
      ],
      // 客户相关信息
      formBasicListKh:[
        {
          label: "客户名称",
          value: "cmmId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户名称",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择客户名称",
              trigger: "blur",
            },
          ],
          // remote: true,
          remoteMethod: (query) => {
            this.getCrmList(query);
          },
          change: this.getCrmPerson,
        },
        {
          label: "客户联系人",
          value: "crmContactId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户联系人",
          optionsListConfig: {
            code: "id",
            name: "name",
          },
          rules: [
            {
              required: true,
              message: "请选择客户联系人",
              trigger: "blur",
            },
          ],
          change:this.selectContactPhone
        },
        {
          label: "个人联系方式",
          disabled: true,
          value: "contactPhone",
          defaultValue:'',
          inputType: "text",
          rules: [
            {
              required: false,
              message: "请输入个人联系方式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "工作联系方式",
          value: "crmContactDept",
          inputType: "text",
          disabled: false,
          defaultValue: "",
          
        },
      ],
      contactPhoneList:[],
      // 项目关联信息
      formBasicListXm:[
        {
          label: "任务书类型",
          value: "assignType",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务书类型",
          optionsListConfig: {
            code: "code",
            name: "name",
          },
          change: this.assignTypeSelect,
        },
        {
          label: "项目名称及编号",
          value: "prjId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          // disabled: true,
          placeholderText: "请选择",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
          // defaultValue: this.prjId,
          defaultValue: '',
          change: this.getContractList,
        },
        {
          label: "合同名称及编号",
          value: "contractId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {
          label: "老任务书名称",
          value: "relateAssignId",
          inputType: "select",
          show: false,
          optionsList: [],
          filterable: true,
          remote: true,
          remoteMethod: (query) => {
            this.getAssignRelateList(query);
          },
          placeholderText: "请选择",
          change: this.assignRelateList,
          optionsListConfig: {
            name: "assignName",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择合同名称及编号",
              trigger: "blur",
            },
          ],
        },
        {
          label: "老任务书编码",
          value: "relateAssignCode",
          inputType: "text",
          disabled: true,
          show: false,
        },
        {
          label: "老任务书已重新生产次数",
          value: "serialNumberO",
          disabled: true,
          show: false,
          inputType: "text",
        },
      ],
      // 记录信息
      formBasicListJL:[
        {
          label: "任务书创建人",
          value: "applier",
          inputType: "text",
          placeholderText: "任务书创建人",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "创建时间",
          value: "createTime",
          inputType: "text",
          placeholderText: "创建时间",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "最后修改人",
          value: "updateByName",
          inputType: "text",
          placeholderText: "最后修改人",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "最后修改时间",
          value: "updateTime",
          inputType: "text",
          placeholderText: "最后修改时间",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "客户经理",
          value: "cmmmanagerName",
          inputType: "text",
          placeholderText: "客户经理",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "客户经理下发任务时间",
          value: "cmmmanagerApproveTime",
          inputType: "text",
          placeholderText: "客户经理下发任务时间",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "下发部门负责人",
          value: "cmmmanagerLeader",
          inputType: "text",
          placeholderText: "下发部门负责人",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "下发部门负责人确认时间",
          value: "cmmmanagerLeaderApproveTime",
          inputType: "text",
          placeholderText: "下发部门负责人确认时间",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "接收部门主管领导",
          value: "receivingAgent",
          inputType: "text",
          placeholderText: "接收部门主管领导",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "接收部门分管领导确认时间",
          value: "receivingAgentApproveTime",
          inputType: "text",
          placeholderText: "接收部门分管领导确认时间",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "接收部门负责人",
          value: "companyConfirmator",
          inputType: "text",
          placeholderText: "接收部门负责人",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "接收部门负责人确认时间",
          value: "companyConfirmatorApproveTime",
          inputType: "text",
          placeholderText: "接收部门负责人确认时间",
          disabled: true,
          defaultValue: "",
        },
      ],
      // 管理信息
      formBasicListGL:[
        {
          label: "现下发负责部门",
          value: "nowDownDeptName",
          inputType: "text",
          placeholderText: "现下发负责部门",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "现客户经理",
          value: "nowCmmmanagerName",
          inputType: "text",
          placeholderText: "现客户经理",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "现下发分管部门",
          value: "nowDownFenDept",
          inputType: "text",
          placeholderText: "现下发分管部门",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "现下发分管领导",
          value: "nowDownFenDeptLeader",
          inputType: "text",
          placeholderText: "现下发分管领导",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "现生产负责部门",
          value: "nowReceiveDeptName",
          inputType: "text",
          placeholderText: "现生产负责部门",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "现项目经理",
          value: "nowLeaderName",
          inputType: "text",
          placeholderText: "现项目经理",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "现生产分管部门",
          value: "nowReceiveFenDept",
          inputType: "text",
          placeholderText: "现生产分管部门",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "现生产分管领导",
          value: "nowReceiveFenDeptLeader",
          inputType: "text",
          placeholderText: "现生产分管领导",
          disabled: true,
          defaultValue: "",
        },
      ],
      detailData: null,
      // 客户信息
      basicCrmContactList: [],
      detailPopupVisible: false,
    };
  },
  created() {},
  mounted() {
    if (this.titleName != "详情") {
      this.formBasicList.forEach((item, index) => {
        if (item.value == "contactPhone") {
          this.formBasicList.splice(index, 1);
        }
      });
    }
    if (this.rowData != null) {
      this.getDataDetail(this.rowData.id);
    }
    else {
      this.getAssignRelateList('')
    }
    this.getDataType();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型 - 项目名称及编号
    getDataType() {
      // 获取项目列表数据
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "prjId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicListXm, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "receiveDeptId",
            optionsList: data.data,
          };
          let options1 = {
            value: "downDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
          this.setOPtionsList(this.formBasicList, options1);
        }
      });
      // 区域列表
      getApi(`/admin/syscompanyregion/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "regionAreaId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 生产控制模式
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-SCKZMS" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 项目生产需求级别
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJB" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "assignLevel",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 项目关联信息 - 任务书类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ_ASSIGN_TYPE" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "assignType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicListXm, options);
          }
        }
      );
      this.getCrmList("", "init");
      this.getInit();
    },
    // 获取客户列表
    getCrmList(name, type) {
      let params = {
        name: name,
      };
      postApi(`/project/customerinfo/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicListKh.forEach((item) => {
            if (item.value == "cmmId") {
              item.optionsList = data.data;
              // if (type != "init") {
              //   item.defaultValue = null;
              // }
            }
            else {
              // item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
            }
          });
        }
      });
    },
    // 默认加载
    getInit(){
      this.getFenDeptList();
      this.getBusinessType();
      if(this.titleName == '新增'){
        this.getDetaultDownFenDeptId();
        
      }
    },
    getDetaultDownFenDeptId(){
      postApi(`/admin/dept/getfenbydeptid`, {deptId:sessionStorage.getItem("depId")}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "downFenDeptId") {
              item.defaultValue = data.data.id;
            }
            else{
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
          })
        }
      });
      // 联动客户经理
      this.getClientJlList(sessionStorage.getItem("depId"), "init")
      this.formBasicList.forEach((item) => {
        if (item.value == "downDeptId") {
          item.defaultValue = sessionStorage.getItem("depId")
        }
        // 联动默认客户经理
        if(item.value == "cmmmanagerId"){
          // item.defaultValue = sessionStorage.getItem("username")
          item.defaultValue = sessionStorage.getItem("userId")
        }
      })
    },
    // 获取分管部门（任务下发分管部门、任务生产分管部门）
    getFenDeptList(){
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "downFenDeptId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formBasicList, options);
          let options1 = {
            value: "receiveFenDeptId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formBasicList, options1);
        }
      });
    },
    // 根据 任务下发分管部门 获取 任务下发分管部门 联动
    getDownFenDeptId(fenDeptId){
      this.formBasicList.forEach((item) => {
        if(item.value == 'downDeptId'){
          item.defaultValue = '';
        }
        else if(item.value == 'cmmmanagerId'){
          item.defaultValue = '';
        }else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value]
        }
      })
      getApi(`/admin/dept/getbydepid/${fenDeptId}`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
              if (item.value == "downFenDeptId") {
                item.defaultValue = fenDeptId;
              }else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              }

              if(item.value == 'downDeptId'){
                item.optionsList = data.data;
              }
          })
        }
      })
    },
    // 根据 任务下发部门 获取 客户经理 联动
    getDownDeptId(deptId){
      postApi(`/admin/user/getuserlist/bydeptid`, { deptId: deptId }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "downDeptId") {
              item.defaultValue = deptId;
            }

            if (item.value == "cmmmanagerId") {
              item.optionsList = data.data;
            }

            if (item.value == "leaderId") {
              item.optionsList = data.data;
            }
          })
        }
      });
    },
    // 根据 任务生产分管部门 获取 任务生产部门 联动
    getReceiveFenDeptId(fenDeptId){
      this.formBasicList.forEach((item) => {
        if(item.value == 'receiveDeptId'){
          item.defaultValue = '';
        }else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value]
        }
      })
      getApi(`/admin/dept/getbydepid/${fenDeptId}`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if(item.value == 'receiveDeptId'){
              item.optionsList = data.data;
            }
            if (item.value == "receiveFenDeptId") {
              item.defaultValue = fenDeptId;
            }else{
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            
          })
        }
      })
    },
    // 任务生产部门添加值
    modeReceiveDeptId(id){
      this.formBasicList.forEach((item) => {
        if(item.value == 'receiveDeptId'){
          item.defaultValue = id;
        }else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value]
        }
      })
    },
    // 获取客户联系人
    getCrmPerson(id, type) {
      let params = {
        crmId: id,
      };
      postApi(`/project/customercontactdetail/list`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.contactPhoneList = data.data;
          this.formBasicListKh.forEach((item) => {
            if (item.value == "crmContactId") {
              item.optionsList = data.data;
              // if (type != "init") {
              //   item.defaultValue = null;
              // }
            }
            else{
              item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
            }
          });
        }
      });
    },
    // 选择联系人
    selectContactPhone(id) {
      this.formBasicListKh.forEach((item) => {
        if (item.value == 'contactPhone' || item.value == 'crmContactDept') {
          item.defaultValue = '';
        } else {
          item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
        }
      })
      this.contactPhoneList.forEach(item => {
        if (item.id == id) {
          this.$refs.addPopFormKh.formData.contactPhone = item.personalContact
          this.$refs.addPopFormKh.formData.crmContactDept = item.workContact
        }
      })
      // this.contactPhoneList.forEach(obj => {
      //   if(obj.id == id){
      //     this.formBasicListKh.forEach((item) => {
      //       if (item.value == "crmContactId") {
      //         item.defaultValue = obj.id;
      //       }
      //       if (item.value == "contactPhone") {
      //         item.defaultValue = obj.phone;
      //       }
      //     })
      //   }
      // })
    },
    //生产负责人列表
    getPersonList(deptId, type) {
      this.getClientJlList(deptId, type);
    },
    // 获取客户经理列表数据
    getClientJlList(deptId, type) {
      postApi(`/admin/user/getuserlist/bydeptid`, { deptId: deptId }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "leaderId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
            if (item.value == "cmmmanagerId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    // 业务类型
    getBusinessType(){
      getApi(`/project/prjareasubassginbussitype/tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "businessTypeId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    // 获取合同列表数据
    getContractList(id, type) {
      let params = {
        prjId: id,
      };
      getApi(`/project/contractinfo/getcontractinfolistbyprjid`, params).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.formBasicListXm.forEach((item) => {
              if (item.value == "prjId") {
                item.defaultValue = id;
              } else {
                item.defaultValue = this.$refs.addPopFormXm.formData[item.value];
              }
              if (item.value == "contractId") {
                item.optionsList = data.data;
                if (type != "init") {
                  item.defaultValue = null;
                }
              }
            });
          }
        }
      );
    },
    getAssignRelateList(name) {
      // serialNumberO relateAssignCode
      postApi(`/project/assign/pc/relate`, {assignName: name}).then(res => {
        if(res.data.code == 0) {
          this.formBasicListXm.forEach(item => {
            if(item.value == 'relateAssignId') {
              item.optionsList = res.data.data;
              this.relateList = res.data.data;
            }
          })
        }
      })
     },
     assignRelateList(id) {
      if(id) {
        this.relateList.forEach(item => {
          if(item.id == id) {
            this.$refs.addPopFormXm.formData.relateAssignCode = item.assignCode;
            this.$refs.addPopFormXm.formData.serialNumberO = item.serialNumberO;
          }
        })
      }
     },
     assignTypeSelect(code) {
      let selType = {};
      // this.assignTypeList.forEach((item) => {
      //   if (item.code == code) {
      //     selType = item;
      //   }
      // });
      this.formBasicListXm.forEach((item) => {
        if (item.value == "assignType") {
          item.defaultValue = code;
        }
        // 立项任务书
        if (code == "PRJ-ASSIGN-TYPE-LX") {
          if (item.value == "prjId" || item.value == "contractId") {
            item.defaultValue = "";
          } else {
            item.defaultValue = this.$refs.addPopFormXm.formData[item.value];
          }
          if (item.value == "prjId") {
            item.disabled = true;
            // item.rules[0].required = false;
          }
          if (item.value == "contractId") {
            item.disabled = true;
          }
          if(item.value == 'serialNumberO' || item.value == 'relateAssignCode' || item.value == 'relateAssignId') {
            item.show = false;
          }
        } else if(code == 'PRJ-ASSIGN-TYPE-CXSC'){
          if(item.value == 'serialNumberO' || item.value == 'relateAssignCode' || item.value == 'relateAssignId') {
            item.show = true;
          }
          if (item.value == "prjId") {
            item.disabled = true;
            // item.rules[0].required = false;
          }
          if (item.value == "contractId") {
            item.disabled = true;
          }
        }
        else {
          item.defaultValue = this.$refs.addPopFormXm.formData[item.value];
          if (item.value == "prjId") {
            item.disabled = false;
            // item.rules[0].required = true;
          }
          if (item.value == "contractId") {
            item.disabled = false;
          }
          if(item.value == 'serialNumberO' || item.value == 'relateAssignCode' || item.value == 'relateAssignId') {
            item.show = false;
          }
        }
      });
    },
    // 编辑
    getDataDetail(id) {
      getApi(`/project/assign/${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.detailData = data.data;
          // if(data.data.downFenDeptId && data.data.downFenDeptId != ''){
          //   this.getFenDeptSelect(data.data.downFenDeptId,1)
          // }
          // if(data.data.receiveFenDeptId && data.data.receiveFenDeptId != ''){
          //   this.getFenDeptSelect(data.data.receiveFenDeptId,2)
          // }
          // this.getInit();
          this.assignTypeSelect(data.data.assignType);
          this.getClientJlList(data.data.downDeptId, "init");
          this.getPersonList(data.data.receiveDeptId, "init");
          this.getContractList(data.data.prjId, "init");
          this.getCrmList(data.data.cmmName, "init");
          this.getCrmPerson(data.data.cmmId, "init");
          this.getAssignRelateList(data.data.relateAssign)
          this.basicCrmContactList = data.data.basicCrmContactList;
          this.formBasicList.forEach((item, index) => {
            if (item.value == "taskDuration") {
              item.defaultValue = [data.data.startTime, data.data.endTime];
            } else {
              item.defaultValue = data.data[item.value];
            }
            if (this.titleName == "详情") {
              item.disabled = true;
              item.rules = null;
            }
          });
          this.formBasicList1.forEach((item) => {
            item.defaultValue = data.data[item.value];
            if (this.titleName == "详情") {
              item.disabled = true;
              item.rules = null;
            }
          });
          this.formBasicListKh.forEach(item => {
            item.defaultValue = data.data[item.value];
            if (this.titleName == "详情") {
              item.disabled = true;
              item.rules = null;
            }
          });
          this.formBasicListXm.forEach(item => {
            item.defaultValue = data.data[item.value];
            if (this.titleName == "详情") {
              item.disabled = true;
              item.rules = null;
            }
          });
          this.formBasicListJL.forEach(item => {
            item.defaultValue = data.data[item.value];
            if (this.titleName == "详情") {
              item.disabled = true;
              item.rules = null;
            }
          });
          this.formBasicListGL.forEach(item => {
            item.defaultValue = data.data[item.value];
            if (this.titleName == "详情") {
              item.disabled = true;
              item.rules = null;
            }
          });
        }
      });
    },
    // 查看修改记录
    handleDetail() {
      this.detailPopupVisible = true;
    },
  },
  // mounted() {
  //   if (this.rowData != null) {
  //     this.getDataDetail(this.rowData.id);
  //   }
  //   this.getDataType();
  // },
  // methods: {
  //   //条件查询表单--下拉选择数据填充（optionsList）
  //   setOPtionsList(formList, options) {
  //     formList.forEach((item) => {
  //       if (options) {
  //         if (item.value == options.value) {
  //           item.optionsList = options.optionsList;
  //         }
  //       }
  //     });
  //   },
  //   // 获取数据字典类型
  //   getDataType() {
  //     // 获取项目列表数据
  //     getApi(`/project/prj/list/all`).then((res) => {
  //       let { data } = res;
  //       if (data.code == 0) {
  //         let options = {
  //           value: "prjId",
  //           optionsList: data.data,
  //         };
  //         this.setOPtionsList(this.formBasicList, options);
  //       }
  //     });
  //     //获取部门数据
  //     postApi(`/admin/dept/getdeptlistbyorgid`, {
  //       orgId: sessionStorage.getItem("orgId"),
  //     }).then((res) => {
  //       let { data } = res;
  //       if (data.code == 0) {
  //         let options = {
  //           value: "receiveDeptId",
  //           optionsList: data.data,
  //         };
  //         let options1 = {
  //           value: "downDeptId",
  //           optionsList: data.data,
  //         };
  //         this.setOPtionsList(this.formBasicList, options);
  //         this.setOPtionsList(this.formBasicList, options1);
  //       }
  //     });
  //     // 区域列表
  //     getApi(`/admin/syscompanyregion/list`).then((res) => {
  //       let { data } = res;
  //       if (data && data.code == 0) {
  //         let options = {
  //           value: "regionAreaId",
  //           optionsList: data.data,
  //         };
  //         this.setOPtionsList(this.formBasicList, options);
  //       }
  //     });
  //     // 生产控制模式
  //     postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-SCKZMS" }).then(
  //       (res) => {
  //         let { data } = res;
  //         if (data && data.code == 0) {
  //           let options = {
  //             value: "produceType",
  //             optionsList: data.data,
  //           };
  //           this.setOPtionsList(this.formBasicList, options);
  //         }
  //       }
  //     );
  //     // 项目生产需求级别
  //     postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJB" }).then(
  //       (res) => {
  //         let { data } = res;
  //         if (data && data.code == 0) {
  //           let options = {
  //             value: "assignLevel",
  //             optionsList: data.data,
  //           };
  //           this.setOPtionsList(this.formBasicList, options);
  //         }
  //       }
  //     );
  //     this.getCrmList();
  //   },
  //   // 获取客户列表
  //   getCrmList(name) {
  //     let params = {
  //       name: name || "",
  //     };
  //     postApi(`/project/basiccrm/list`, params).then((res) => {
  //       let { data } = res;
  //       if (data.code == 0) {
  //         let options = {
  //           value: "cmmId",
  //           optionsList: data.data,
  //         };
  //         this.setOPtionsList(this.formBasicList, options);
  //       }
  //     });
  //   },
  //   //生产负责人列表
  //   getPersonList(deptId, type) {
  //     postApi(`/admin/user/getuserlist/bydeptid`, {
  //       deptId: deptId,
  //     }).then((res) => {
  //       let { data } = res;
  //       if (data.code == 0) {
  //         this.formBasicList.forEach((item) => {
  //           if (item.value == "receiveDeptId") {
  //             item.defaultValue = deptId;
  //           } else {
  //             item.defaultValue = this.$refs.addPopForm.formData[item.value];
  //           }
  //           if (item.value == "leaderId") {
  //             item.optionsList = data.data;
  //             if (type != "init") {
  //               item.defaultValue = null;
  //             }
  //           }
  //         });
  //       }
  //     });
  //   },
  //   // 获取客户经理列表数据
  //   getClientJlList(deptId, type) {
  //     postApi(`/admin/user/getuserlist/bydeptid`, {
  //       deptId: deptId,
  //     }).then((res) => {
  //       let { data } = res;
  //       if (data.code == 0) {
  //         this.formBasicList.forEach((item) => {
  //           if (item.value == "downDeptId") {
  //             item.defaultValue = deptId;
  //           } else {
  //             item.defaultValue = this.$refs.addPopForm.formData[item.value];
  //           }
  //           if (item.value == "cmmmanagerId") {
  //             item.optionsList = data.data;
  //             if (type != "init") {
  //               item.defaultValue = null;
  //             }
  //           }
  //         });
  //       }
  //     });
  //   },
  //   // 获取合同列表数据
  //   getContractList(id, type) {
  //     let params = {
  //       prjId: id,
  //     };
  //     getApi(`/project/contractinfo/getcontractinfolistbyprjid`, params).then(
  //       (res) => {
  //         let { data } = res;
  //         if (data && data.code == 0) {
  //           this.formBasicList.forEach((item) => {
  //             if (item.value == "prjId") {
  //               item.defaultValue = id;
  //             } else {
  //               item.defaultValue = this.$refs.addPopForm.formData[item.value];
  //             }
  //             if (item.value == "contractId") {
  //               item.optionsList = data.data;
  //               if (type != "init") {
  //                 item.defaultValue = null;
  //               }
  //             }
  //           });
  //         }
  //       }
  //     );
  //   },
  //   getDataDetail(id) {
  //     getApi(`/project/assign/${id}`).then((res) => {
  //       let { data } = res;
  //       if (data && data.code == 0) {
  //         this.detailData = data.data;
  //         this.getClientJlList(data.data.downDeptId, "init");
  //         this.getPersonList(data.data.receiveDeptId, "init");
  //         this.getContractList(data.data.prjId, "init");
  //         this.getCrmList(data.data.cmmName);
  //         this.basicCrmContactList = data.data.basicCrmContactList;
  //         this.formBasicList.forEach((item) => {
  //           if (item.value == "taskDuration") {
  //             item.defaultValue = [data.data.startTime, data.data.endTime];
  //           } else {
  //             item.defaultValue = data.data[item.value];
  //           }
  //           if (this.titleName == "详情") {
  //             item.disabled = true;
  //           }
  //         });
  //         this.formBasicList1.forEach((item) => {
  //           item.defaultValue = data.data[item.value];
  //           if (this.titleName == "详情") {
  //             item.disabled = true;
  //           }
  //         });
  //       }
  //     });
  //   },
  //   // 查看修改记录
  //   handleDetail() {
  //     this.detailPopupVisible = true;
  //   },
  // },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/.el-tabs__item {
  width:20% !important;
}
// /deep/.el-tabs__item{
//   color:red !important;
// }
.buttonBtn{
  height: 50px;
  line-height: 50px;
}
</style>
