<template>
  <div class="container">
    <dd-table
      ref="baseTable_1"
      :columns="tableColumns"
      isSort
      :summary-method="getSummaries"
      :data="tableData"
      show-summary
      border
      :isSelection="false"
      :loading="loading"
      max-height="560"
    >
      <template #table-btn>
        <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
          >添加或移除付款单
        </dd-button>
      </template>
    </dd-table>

    <!--添加付款单  -->
    <dd-popup
      v-if="addPaymentVisible"
      width="90%"
      height="90%"
      inPopup
      showBtn
      :cancelBtn="false"
      :saveBtn="saveBtn"
      :submitBtn="false"
      :show="addPaymentVisible"
      title-name="添加或移除付款单"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        size="medium"
        type="warning"
        @click="handleReturn"
        >返回</dd-button
      >
      <dd-button
        slot="button"
        size="medium"
        type="primary"
        @click="handleSubmit"
        >确定</dd-button
      >
      <addPaymentFir
        ref="addPaymentFir"
        :pageProp="pageProp"
        slot="main"
      ></addPaymentFir>
    </dd-popup>
  </div>
</template>
  
  <script>
import { getApi, postApi } from "@/api/request";
import addPaymentFir from "./addPaymentFir.vue";
export default {
  components: {
    addPaymentFir,
  },
  name: "paymentPopup",
  props: ["pageProp"],
  data() {
    return {
      addPaymentVisible: false,
      loading: false,
      saveBtn: false,
      tableColumns: [
        {
          title: "单据编号",
          key: "paymentfnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "单据名称",
          key: "paymentfname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同编码",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "供应商",
          key: "fparta",
          align: "center",
          overflow: true,
        },
        {
          title: "本期实付金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "不含税金额",
          key: "fnotaxdataamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "税额",
          key: "ftax",
          align: "center",
          overflow: true,
        },
        {
          title: "付款期间",
          key: "fperiod",
          align: "center",
          overflow: true,
        },
        {
          title: "执行日期",
          key: "fbizdate",
          align: "center",
          overflow: true,
        },
        {
          title: "工程项目",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "制单人",
          key: "documenter",
          align: "center",
          overflow: true,
        },
      ],
      tableData: [],
    };
  },
  created() {
    this.getPageData();
  },
  mounted() {},
  methods: {
    handleClose() {
      this.addPaymentVisible = false;
    },
    handleReturn() {
      this.addPaymentVisible = false;
    },
    handleAdd() {
      this.addPaymentVisible = true;
    },
    handleSubmit() {
      const param = {
        id: this.pageProp.id,
        voList: this.$refs.addPaymentFir.tableData.map((item) => {
          return { id: item.id };
        }),
      };
      postApi(
        `/contract/execute/addexe/payment`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败，请稍后重试");
        }
        this.getPageData();
      });
      this.addPaymentVisible = false;
    },
    getPageData() {
      getApi(`/contract/execute/query/payment?id=${this.pageProp.id}`).then(
        (res) => {
          if (res.data.code == 0) {
            let { data } = res;
            this.tableData = data.data;
          }
        }
      );
    },
    getSummaries(param) {
      // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "foriginalamount" ||
          column.property == "fnotaxdataamount" ||
          column.property == "ftax"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .table_cell {
  .backgroundColor {
    background-color: #f5d7c8 !important;
  }
}
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
</style>