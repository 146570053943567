import { render, staticRenderFns } from "./allocation.vue?vue&type=template&id=8759d7a8&scoped=true"
import script from "./allocation.vue?vue&type=script&lang=js"
export * from "./allocation.vue?vue&type=script&lang=js"
import style0 from "./allocation.vue?vue&type=style&index=0&id=8759d7a8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8759d7a8",
  null
  
)

export default component.exports