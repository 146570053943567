<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          :labelWidth="'190px'"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>

      <dd-card title="考勤汇总列表" :height="tableHeight">
        <dd-table
          :columns="columnsList"
          :data="tableData"
          border
          :isSelection="true"
          show-page
          :loading="loading"
          @select-change="handleSelectionChange"
          ref="dTableRef"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >新建考勤汇总单
            </dd-button>
          </template>
          <template #table-btn-right>
            <el-button
              icon="el-icon-s-operation"
              circle
              type="info"
              size="small"
              @click="drawerVisible = true"
              title="列筛选"
            ></el-button>
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handlePageChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>

      <dd-filter-drawer
        v-if="drawerVisible"
        width="30%"
        title-name="列筛选"
        :show="drawerVisible"
        :tableColumns="tableColumns"
        :columns="columnsList"
        @popup-close="drawerVisible = false"
        @handleColumnsChange="handleColumnsChange"
      >
      </dd-filter-drawer>

      <!-- 新建考勤汇总单 -->
      <dd-popup
        v-if="dialogVisible"
        width="30%"
        height="30%"
        showBtn
        :cancelBtn="true"
        :saveBtn="true"
        :submitBtn="false"
        :show="dialogVisible"
        title-name="新建考勤汇总单"
        @popup-close="handleClose"
        @popup-save="handleSave"
      >
        <addPop slot="main" ref="addPop"></addPop>
      </dd-popup>

      <!-- 查看详情 -->
      <dd-popup
        v-if="detailVisible"
        width="90%"
        height="90%"
        showBtn
        :saveBtn="false"
        :submitBtn="false"
        :show="detailVisible"
        title-name="查看详情"
        @popup-close="handleClose"
      >
        <sumDetail
          ref="detailVisible"
          :pageProp="pageProp"
          slot="main"
        ></sumDetail>
      </dd-popup>
    </div>
  </div>
</template>
    <script>
import { getApi, postApi, delApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
import addPop from "./components/addPop.vue";
import sumDetail from "./components/sumDetail.vue";
export default {
  name: "companySum",
  $dynamicRoute: {
    name: "考勤汇总分析",
    path: "/checkingIn/companySum/index",
  },
  components: {
    addPop,
    sumDetail,
  },
  data() {
    return {
      detailVisible: false,
      dialogVisible: false,
      drawerVisible: false,
      loading: false,
      tableHeight: null,
      formData: {},
      tableData: [],
      formList: [
        {
          label: "单据编号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单据编号进行搜索",
        },
        {
          label: "单据名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入单据名称进行搜索",
        },
      ],
      columnsList: [
        {
          title: "单据编号",
          key: "code",
          align: "center",
          overflow: true,
        },
        {
          title: "单据名称",
          key: "name",
          align: "center",
        },
        {
          title: "所属考勤月",
          key: "month",
          align: "center",
        },
        {
          title: "本考勤月法定工作日",
          key: "workNum",
          align: "center",
        },
        {
          title: "操作",
          width: 200,
          align: "center",
          event: [
            {
              show: "true",
              label: "查看详情",
              method: (row) => {
                this.handleDetail(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "导出",
              method: (row) => {
                this.handleExport(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "删除",
              method: (row) => {
                this.handleDelete(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      total: 0,
      current: 1,
      size: 10,
      selectTable: [],
      tableColumns: [],
      pageProp: {},
    };
  },
  mounted() {
    this.tableColumns = [...this.columnsList];
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(1);
    });
    this.getElmentList();
  },
  methods: {
    handleDelete(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/hr/attendsignsum/${row.id}`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("删除成功");
          }
          this.getElmentList();
        });
      });
    },
    handleSave() {
      let flag = false;
      this.$refs.addPop.$refs.addPopForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return false;
      }
      let param = {
        month: this.$refs.addPop.$refs.addPopForm.formData.month,
      };
      postApi(`/hr/attendsignsum`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败");
        }
        this.dialogVisible = false;
        this.getElmentList();
      });
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleDetail(row) {
      this.detailVisible = true;
      this.pageProp = row;
    },
    handleExport(row) {
      this.$message({
        message: "下载中...",
        type: "success",
      });
      let param = {
        id: row.id,
      };
      loadApi(`/hr/attendsignsum/export`, param).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handlePageChange(size, number) {
      this.size = size;
      this.current = number;
      this.getElmentList();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getElmentList();
    },
    getElmentList() {
      let { formData } = this;
      let param = {
        ...formData,
        current: this.current,
        size: this.size,
      };
      getApi(`/hr/attendsignsum/page`, param).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.selectTable = e;
    },
    handleClose() {
      this.dialogVisible = false;
      this.detailVisible = false;
    },
  },
};
</script>
    
  <style lang="less" scoped>
</style>
    