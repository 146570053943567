<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          :labelWidth="'190px'"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>

      <dd-card title="外勤人员" height="100%">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          border
          :isSelection="false"
          show-page
        >
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <!-- 新增/修改弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      showBtn
      :saveBtn="false"
      cancelBtn
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addAreaPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :userId="userId"
      ></add-pop>
    </dd-popup>
  </div>
</template>
  
  <script>
import { postApi } from "@/api/request";
import addPop from "./components/AddPop.vue";
export default {
  name: "region",
  $dynamicRoute: {
    name: "按人员配置范围",
    path: "/checkingIn/baseManage/userscope",
  },
  components: {
    addPop,
  },
  data() {
    return {
      userId: "",
      formList: [
        {
          label: "外勤人员名称",
          value: "fullName",
          inputType: "text",
          placeholderText: "请输入外勤人员名称进行搜索",
        },
        {
          label: "外勤人员工号",
          value: "username",
          inputType: "text",
          placeholderText: "请输入外勤人员工号进行搜索",
        },
      ],
      tableColumns: [
        {
          title: "外勤人员工号",
          key: "username",
          align: "center",
        },
        {
          title: "外勤人员名称",
          key: "fullName",
          align: "center",
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        {
          title: "打卡范围分配状态",
          key: "rangeStatus",
          align: "center",
        },
        {
          title: "操作",
          width: 300,
          align: "center",
          event: [
            {
              show: "true",
              label: "打卡范围管理",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
          ],
        },
      ],
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
      addPopupVisible: false,
      poptitleName: "",
      searchData: {},
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    handleSearchEvent(e) {
      this.current = 1;
      this.searchData = e;
      this.getTableData();
    },
    // 获取表格列表数据
    getTableData() {
      let params = {
        ...this.searchData,
      };
      postApi(
        `/hr/attend/checkrelation/range/page/${this.current}/${this.size}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增弹框关闭事件
    handleClose() {
      this.addPopupVisible = false;
    },
    //查看详情
    handleInfo(row) {
      this.userId = row.userId;
      this.addPopupVisible = true;
      this.poptitleName = "打卡范围管理";
    },
  },
};
</script>
  
  <style></style>
  