<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="工作量汇总管理" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            icon="add"
            size="medium"
            @click="handleAddEdit(0)"
            >新建工作量汇总单</dd-button
          >
          <dd-button
            icon="minus"
            type="warning"
            size="medium"
            autoConfirm
            @click="handleAddEdit(1)"
            >工作量公示确认</dd-button
          >
          <dd-button
            v-if="$store.state.btnAuthorityList.includes('bt:return:workloadsum')"
            icon="back"
            type="danger"
            size="medium"
            autoConfirm
            @click="handleBackSum()"
            >退回工作量汇总</dd-button
          >
          <dd-button
            icon="export"
            type="success"
            size="medium"
            autoConfirm
            @click="handleFileExport"
            >批量导出</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 新增/修改/详情-->
    <dd-popup
      v-if="dialogVisible"
      width="90%"
      height="90%"
      :showBtn="false"
      :title-name="popTitle"
      :show="dialogVisible"
      :inPopup="true"
      @popup-close="handleClose"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        :type="type"
        :rowData="rowData"
        :isEdit="isEdit"
        ref="addPop"
        :id="id"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <!-- 公示确认-->
    <dd-popup
      v-if="publicityPopDialogVisible"
      width="30%"
      height="60%"
      showBtn
      :saveBtn="false"
      submitBtn
      :title-name="popTitle"
      :show="publicityPopDialogVisible"
      @popup-close="handleClose"
      @popup-submit="handleSubmit"
    >
      <publicityPop slot="main" ref="publicityPop" :id="id"></publicityPop>
    </dd-popup>
    <!-- 公示确认详情-->
    <dd-popup
      v-if="publicityDetailPopDialogVisible"
      width="70%"
      height="80%"
      :showBtn="false"
      :title-name="popTitle"
      :show="publicityDetailPopDialogVisible"
      :inPopup="true"
      @popup-close="handleClose"
    >
      <publicityDetailPop slot="main" :id="id"></publicityDetailPop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="handleClose"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import AddUpdateDetailInfoPop from "./components/AddUpdateDetailInfoPop";
import publicityPop from "./components/publicityPop/index.vue";
import publicityDetailPop from "./components/publicityPop/components/detailPop.vue";
import { postApi, getApi, loadApi, delApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index.js";
import { formatDateTime } from "@/utils/date";
export default {
  name: "workloadCollect",
  $dynamicRoute: {
    name: "工作量汇总管理",
    path: "/performance/workload/collect/index",
  },
  components: { AddUpdateDetailInfoPop, publicityPop, publicityDetailPop },
  data() {
    return {
      tableHeight: null,
      showBtn: true,
      isEdit:false,
      type: "",
      id: "",
      popTitle: "",
      drawerVisible: false,
      publicityPopDialogVisible: false,
      publicityDetailPopDialogVisible: false,
      processInstanceId: null,
      loading: false,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      rowData: {},
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "单据名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入单据名称",
          title: true,
        },
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          defaultValue: '',
          placeholderText: "请选择部门",
          rules: [
            {
              required: true,
              message: "请选择部门",
              trigger: "blur"
            }
          ],
        },
        // {
        //   label: "绩效核算组",
        //   value: "accountingGroupId",
        //   inputType: "select",
        //   optionsList: [],
        //   filterable: true,
        //   placeholderText: "请选择或搜索状态",
        //   optionsListConfig: {
        //     name: "name",
        //     code: "id",
        //   },
        // },
        {
          label: "状态",
          value: "status",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "所属年月",
          value: "applyDate",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },{},{},{},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title:"单据编码",
          key: "code",
          width: 200,
          align: "center",
        },
        {
          title: "单据名称",
          key: "name",
          align: "center",
          width: 340,
          overflow: true,
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
          width: 180,
          overflow: true,
        },
        // {
        //   title: "绩效核算组",
        //   key: "accountingGroupName",
        //   width: 180,
        //   align: "center",
        // },
        {
          title: "所属年月",
          key: "reportMonth",
          align: "center",
          width: 180,
        },
        {
          title: "开始时间点",
          key: "startTime",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "截止时间点",
          key: "deadLine",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "统计工作量类型",
          key: "standardWorkpackageType",
          align: "center",
          overflow: true,
          width:'120',
        },
        {
          title: "状态",
          key: "statusName",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: "280px",
          event: [
            {
              label: "编辑",
              icon: "el-icon-edit",
              state: "default",
              type:"primary",
              method: (row) => {
                this.handleAddEdit(3, row);
              },
              rules: (row) => {
                let show = false;
                if (row.status == "PRJ-ASSIGN-WORKLOAD-SUM-DQR" || row.status == "PRJ-ASSIGN-WORKLOAD-SUM-YTH") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看详情",
              icon: "el-icon-edit",
              state: "default",
              type: "info",
              method: (row) => {
                this.handleAddEdit(4, row);
              },
              rules: (row) => {
                let show = false;
                if (row.status != "PRJ-ASSIGN-WORKLOAD-SUM-DQR") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看公示情况",
              icon: "el-icon-view",
              state: "default",
              type: "info",
              rules: (row) => {
                let show = false;
                if (row.status == "PRJ-ASSIGN-WORKLOAD-SUM-YQR" 
                  || row.status == "PRJ-ASSIGN-WORKLOAD-SUM-QRZ"
                  || row.status == 'PRJ-ASSIGN-WORKLOAD-SUM-YHSJX'
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
              method: (row) => {
                this.handleAddEdit(2, row);
              },
            },
            {
              label: "删除",
              state: "default",
              type: "danger",
              icon: "el-icon-delete",
              rules: (row) => {
                let show = false;
                if (row.status == "PRJ-ASSIGN-WORKLOAD-SUM-DQR") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
              method: (row) => {
                this.handleDelete(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getBaseInfo();
    // 获取状态
    this.getStateList();
    this.getAccountingList(sessionStorage.getItem("depId"));
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  // computed: {
  //   ...mapState(["btnAuthorityList"]), //权限标识数组
  // },
  methods: {
    handleBackSum() {
      let { multipleSelection } = this;
      if (multipleSelection.length == 0 || multipleSelection.length > 1) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      if(multipleSelection[0].status == 'PRJ-ASSIGN-WORKLOAD-SUM-QRZ' || multipleSelection[0].status == 'PRJ-ASSIGN-WORKLOAD-SUM-YQR') {
          getApi(`/project/workloadsum/return?id=${multipleSelection[0].id}`).then(res => {
          if(res.data.code == 0) {
            let data = { res };
            this.$message.success('操作成功');
          }
          else {
            this.$message.warning('操作失败，请稍后重试');
          }
          this.$refs.dTableRef.$refs.baseTable.clearSelection();
          this.multipleSelection = [];
          this.getApplyList();
        })
      }
      else {
        this.$message.warning("只能退回状态为已确认，确认中的绩效核算");
      }
    },
    getBaseInfo() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_ASSIGN_WORKLOAD_SUM_STATE",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "status") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //核算组列表
    getAccountingList(deptId) {
      getApi(`/project/accounting/list`, { deptId }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "accountingGroupId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 列表请求
    getApplyList() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
      };
      postApi(`/project/workloadsum/page/${current}/${size}`, params).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            this.tableDataSources = data.data.records;
            this.total = data.data.total;
          }
        }
      );
    },

    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    //导出
    handleFileExport() {
      let ids = [];
      let { multipleSelection } = this;
      if (multipleSelection.length == 0 ) {
        this.$message.warning("请选择一条数据进行导出");
        return;
      }
      this.multipleSelection.length &&
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let params = {
        formData: {
          ids:ids,
        },
      };
      loadApi(`/project/workloadsum/export`, params).then((res) => {
        let { data } = res;
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
        this.multipleSelection = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },

    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getApplyList();
    },
    // 新增弹框关闭事件
    handleClose() {
      this.current = 1;
      this.getApplyList();
      this.dialogVisible = false;
      this.drawerVisible = false;
      this.publicityPopDialogVisible = false;
      this.publicityDetailPopDialogVisible = false;
    },
    handleAddEdit(type, row) {
      // type 0: 新建；1：公示确认；2：查看公示情况；3：编辑 4查看详情
      if (type == 0) {
        this.id = null;
        this.type = "add";
        this.isEdit = true;
        this.popTitle = "新增工作量汇总单";
        this.dialogVisible = true;
        this.showBtn = true;
      } else if (type == 1) {
        let { multipleSelection } = this;
        if (multipleSelection.length == 0 || multipleSelection.length > 1) {
          this.$message.warning("请选择一条数据进行公示确认!");
          return;
        } else if (multipleSelection[0].status == "PRJ-ASSIGN-WORKLOAD-SUM-YQR") {  // 已确认
          this.$message.warning("该条数据已确认!");
          return;
        }else if(multipleSelection[0].status == "PRJ-ASSIGN-WORKLOAD-SUM-DQR" || multipleSelection[0].status == "PRJ-ASSIGN-WORKLOAD-SUM-YTH"){ // 待确认
          this.id = multipleSelection[0].id;
          this.popTitle = "工作量公示确认";
          this.publicityPopDialogVisible = true;
        }else if(multipleSelection[0].status == "PRJ-ASSIGN-WORKLOAD-SUM-YHSJX"){ // 已核算绩效
          this.$message.warning("该条数据已核算绩效!");
          return;  
        }
        else if(multipleSelection[0].status == "PRJ-ASSIGN-WORKLOAD-SUM-QRZ"){  // 确认中
          this.$message.warning("该条数据在确认中!");
          return; 
        }
      } else if (type == 2) {
        this.id = row.id;
        this.popTitle = "公示情况";
        this.publicityDetailPopDialogVisible = true;
        this.showBtn = false;
      } else if(type == 3) {
        this.id = row.id;
        this.type = "detail";
        this.popTitle = "编辑";
        this.dialogVisible = true;
        this.rowData = row;
        this.isEdit = true;
        this.showBtn = false;
      }else{
        this.id = row.id;
        this.type = "detail";
        this.popTitle = "查看详情";
        this.dialogVisible = true;
        this.rowData = row;
        this.isEdit = false;
        this.showBtn = false;
      }
    },
    handleDelete(row) {
      this.$confirm("是否确定删除？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          delApi(`/project/workloadsum/${row.id}`)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getApplyList();
    },
    // //校验输入框的值
    handleInputReg(val) {
      let value = val.applyWorkload;
      let regex = /^\d+(\.\d{1,2})?$/; // 匹配整数或最多两位小数的数字
      if (!regex.test(value)) {
        val.applyWorkload = "";
        this.$message.warning("请输入正确的格式!");
        return;
      }
    },
    // 确认事件
    handleSubmit() {
      let { radio, checkList, deadLine } = this.$refs.publicityPop;
      // if (!radio) {
      //   this.$message.warning("请选择公示类型");
      //   return;
      // } else 
      if(checkList.length == 0 && checkList[0] == undefined){
        this.$message.warning("请选择公示类型");
        return;
      }else if (!deadLine) {
        this.$message.warning("请选择公示与确认截止时间");
        return;
      }
      // let list = [radio, ...checkList];
      let list = [...checkList];
      postApi(
        `/project/workloadsum/confirm?id=${
          this.id
        }&type=${list.toString()}&deadLine=${formatDateTime(deadLine)}`,
        {}
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("提交成功");
          this.$refs.dTableRef.$refs.baseTable.clearSelection();
          this.multipleSelection = [];
          this.handleClose();
          this.getApplyList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
