<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          :labelWidth="'190px'"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>

      <dd-card title="考勤列表" :height="tableHeight">
        <dd-table
          :columns="columnsList"
          :data="tableData"
          border
          :isSelection="true"
          show-page
          :loading="loading"
          @select-change="handleSelectionChange"
          ref="dTableRef"
        >
          <template #table-btn-right>
            <el-button
              icon="el-icon-s-operation"
              circle
              type="info"
              size="small"
              @click="drawerVisible = true"
              title="列筛选"
            ></el-button>
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handlePageChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>

      <dd-filter-drawer
        v-if="drawerVisible"
        width="30%"
        title-name="列筛选"
        :show="drawerVisible"
        :tableColumns="tableColumns"
        :columns="columnsList"
        @popup-close="drawerVisible = false"
        @handleColumnsChange="handleColumnsChange"
      >
      </dd-filter-drawer>
    </div>
    <!-- 详情 -->
    <dd-popup
      width="40%"
      height="60%"
      :show="infoPopupVisible"
      title-name="签到地点"
      @popup-close="handleClose"
    >
      <info-pop
        v-if="infoPopupVisible"
        slot="main"
        :pointInfo="pointInfo"
      ></info-pop>
    </dd-popup>
  </div>
</template>
  <script>
import { getApi, postApi } from "@/api/request";
import infoPop from "./components/InfoPop.vue";
export default {
  name: "attendQuery",
  $dynamicRoute: {
    name: "考勤查询",
    path: "/checkingIn/attendQuery/index",
  },
  components: {
    infoPop,
  },
  data() {
    return {
      infoPopupVisible: false,
      drawerVisible: false,
      loading: false,
      tableHeight: null,
      formData: {
        startTimeStr: this.getlastMonth(),
      },
      tableData: [],
      formList: [
        {
          label: "签到人工号",
          value: "username",
          inputType: "text",
          placeholderText: "请输入签到人工号进行搜索",
        },
        {
          label: "签到人姓名",
          value: "userFullName",
          inputType: "text",
          placeholderText: "请输入签到人姓名进行搜索",
        },
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择部门进行搜索",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
        {
          label: "所属考勤月",
          value: "attendMonth",
          inputType: "date",
          dateType: "month",
          format: "yyyy-MM",
          placeholderText: "请选择所属考勤月进行搜索",
        },
        {
          label: "开始时间",
          value: "startTimeStr",
          inputType: "date",
          placeholderText: "请选择开始时间进行搜索",
          defaultValue: this.getlastMonth(),
        },
        {
          label: "结束时间",
          value: "endTimeStr",
          inputType: "date",
          placeholderText: "请选择结束时间进行搜索",
          defaultValue: null,
        },
      ],
      columnsList: [
        {
          title: "签到人工号",
          key: "username",
          align: "center",
          overflow: true,
        },
        {
          title: "签到人",
          key: "userFullName",
          align: "center",
        },
        {
          title: "签到时间",
          key: "signTime",
          align: "center",
        },
        {
          title: "签到节点",
          key: "signTimeNode",
          align: "center",
        },
        {
          title: "签到项目名称",
          key: "prjName",
          align: "center",
        },
        {
          title: "签到类型",
          key: "signTypeName",
          align: "center",
        },
        {
          title: "签到状态",
          key: "statusName",
          align: "center",
        },
        {
          title: "签到地点",
          key: "signAddress",
          align: "center",
        },
        {
          title: "签到位置",
          align: "center",
          event: [
            {
              show: "true",
              label: "查看详情",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
          ],
        },
        {
          title: "所属考勤月",
          key: "attendMonth",
          align: "center",
        },
        {
          title: "所属部门",
          key: "deptName",
          align: "center",
          width: "150px",
        },
        {
          title: "审核状态",
          key: "verifyStatusName",
          align: "center",
          width: "150px",
        },
        {
          title: "审核人",
          key: "auditName",
          align: "center",
          width: "150px",
        },
        {
          title: "审核日期",
          key: "auditDate",
          align: "center",
          width: "150px",
        },
      ],
      total: 0,
      current: 1,
      size: 10,
      pointInfo: "",
      deptId: "",
      selectTable: [],
      tableColumns: [],
    };
  },
  mounted() {
    this.tableColumns = [...this.columnsList];
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(1);
    });
    this.getDeptList();
    this.getElmentList();
  },
  methods: {
    handleInfo(row) {
      this.pointInfo = row.inlocation;
      this.infoPopupVisible = true;
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    getlastMonth() {
      // 获取当前日期
      const currentDate = new Date();

      // 创建一个新的日期对象，设定为上个月
      const lastMonthDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );

      // 获取上个月的年和月
      const lastMonthYear = lastMonthDate.getFullYear();
      const lastMonthMonth = lastMonthDate.getMonth() + 1; // 月份从 0 开始，所以加 1
      return (
        lastMonthYear +
        "-" +
        lastMonthMonth.toString().padStart(2, "0") +
        "-" +
        "01"
      );
    },
    getDeptList() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handlePageChange(size, number) {
      this.size = size;
      this.current = number;
      this.getElmentList();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getElmentList();
    },
    getElmentList() {
      let { formData } = this;
      let param = { ...formData };
      postApi(
        `/hr/attend/monthinfo/page2/${this.current}/${this.size}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.selectTable = e;
    },
    handleClose() {
      this.pointInfo = "";
      this.infoPopupVisible = false;
    },
  },
};
</script>
  
<style lang="less" scoped>
</style>
  