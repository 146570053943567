<template>
  <div class="main-container">
    <div class="serach-container">
      <dd-card title="条件查询" spacer>
        <div class="search-wrap">
          <dd-search-form
            :form-config="formList"
            @search-click="handleSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </div>
      </dd-card>
    </div>
    <dd-card title="生效时间" spacer
      ><dd-form
        :form-config="formBasicList"
        :flex="2"
        labelWidth="120px"
        ref="effectTimeRef"
        formRef="effectTimeRef"
      >
      </dd-form>
    </dd-card>
    <dd-card title="人员列表" cardStyle="height: 720px; overflow: auto;">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        max-height="500"
        border
        ref="dTableRef"
        @select-change="handleUserSelect"
        isSelection
        show-page
        :loading="loading"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
export default {
  name: "add-user-pop",
  props: { areaId: "" },
  data() {
    return {
      total: 0,
      loading: false,
      current: 1,
      size: 10,
      formData: {},
      formBasicList: [
        {
          label: "生效开始时间",
          value: "effectStartTime",
          placeholderText: "请选择生效开始时间",
          inputType: "dateStr",
          dateType: "date",
          filterable: true,
          format: "yyyy-MM-dd",
          defaultValue: "",
          rules: [
            { required: true, message: "请选择生效开始时间", trigger: "blur" },
          ],
        },
        {
          label: "生效结束时间",
          value: "effectEndTime",
          placeholderText: "请选择生效结束时间",
          inputType: "dateStr",
          dateType: "date",
          filterable: true,
          defaultValue: "2099-01-01",
          format: "yyyy-MM-dd",
          rules: [
            { required: true, message: "请选择生效结束时间", trigger: "blur" },
          ],
        },
      ],
      formList: [
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          filterable: "filterable",
          placeholderText: "请选择部门",
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
        {
          label: "用户名称",
          value: "fullName",
          inputType: "text",
          filterable: "filterable",
          placeholderText: "请输入员工姓名",
        },
        {
          label: "工号",
          value: "username",
          inputType: "text",
          filterable: "filterable",
          placeholderText: "请输入员工工号",
        },
      ],
      columnsList: [
        {
          title: "姓名",
          key: "fullName",
          align: "center",
          overflow: true,
        },
        {
          title: "工号",
          key: "username",
          align: "center",
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        {
          title: "电话",
          key: "phone",
          align: "center",
        },
        {
          title: "岗位",
          key: "postName",
          align: "center",
        },
      ],
      tableData: [],
      selection: [],
    };
  },
  mounted() {
    this.getUserList();
    this.getDept(); //组织机构树
  },
  methods: {
    //查询按钮
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getUserList();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getUserList();
    },
    getUserList() {
      let { current, size, formData } = this;
      let params = {
        current,
        size,
        ...formData,
      };
      postApi(`/admin/user/uservos/data`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取组织机构树数据
    getDept() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },

    handleUserSelect(selection, row) {
      this.selection = selection;
      if (selection.length == 0) return;
      this.$refs.dTableRef.$refs.baseTable.setCurrentRow(row);
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  /deep/ .search-form-input {
    width: 25% !important;
  }
}
</style>