<template>
  <div class="main-container">
    <div class="left-box">
      <div class="serach-container">
        <dd-card title="外勤区域查询条件" spacer>
          <div class="search-wrap">
            <dd-search-form
              :form-config="areaFormList"
              @search-click="handleAreaSearchEvent"
              labelWidth="100px"
            ></dd-search-form>
          </div>
        </dd-card>
      </div>
      <dd-card title="外勤区域列表" :height="tableHeight">
        <dd-table
          :columns="areaColumnsList"
          :data="areaTableData"
          border
          ref="areaTableRef"
          @select-change="handleAreaSelect"
          @row-click="handleRowAreaSelect"
          :row-class-name="getRowClass"
          :isSelection="false"
          :stripe="false"
          show-page
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAddArea"
              >新增</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleAreaCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <div class="right-box">
      <div class="serach-container">
        <dd-card title="用户外勤区域查询" spacer>
          <div class="search-wrap">
            <dd-search-form
              labelWidth="100px"
              :form-config="userFormList"
              @search-click="handleUserSearchEvent"
            ></dd-search-form>
          </div>
        </dd-card>
      </div>
      <dd-card title="用户列表" :height="tableHeight">
        <dd-table
          :columns="userColumnsList"
          :data="userTableData"
          border
          ref="userTableRef"
          @select-change="handleUserSelect"
          :isSelection="true"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAddUser"
              >新增</dd-button
            >
            <dd-button
              type="danger"
              size="medium"
              icon="delete"
              @click="handleDelUser"
              >删除</dd-button
            >
          </template>

          <template #table-pagination>
            <dd-pagination
              :total="userTotal"
              :page-size="userSize"
              :page-number="userCurrent"
              @page-change="handleUserCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <!-- 新增/修改弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      showBtn
      :saveBtn="this.type == 'add' || this.type == 'update' ? true : false"
      cancelBtn
      :show="addAreaPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleAreaClose"
      @popup-save="handleAreaSave('addAreaPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addAreaPopupVisible"
        slot="main"
        ref="addPop"
        :type="type"
        :id="id"
      ></add-pop>
    </dd-popup>

    <!-- 用户新增弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addUserPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleUserClose"
      @popup-save="handleUserSave()"
    >
      <add-user-pop
        v-if="addUserPopupVisible"
        slot="main"
        ref="addUserPop"
        :areaId="areaId"
      ></add-user-pop>
    </dd-popup>

    <!-- 失效范围 -->
    <dd-popup
      v-if="lostSignVisible"
      width="35%"
      height="50%"
      inPopup
      showBtn
      :saveBtn="true"
      :submitBtn="false"
      :show="lostSignVisible"
      title-name="失效范围"
      @popup-save="handlelose()"
      @popup-close="handleClose2"
    >
      <div slot="main">
        <dd-card title="">
          <dd-form
            :form-config="formList_form"
            :flex="1"
            :labelWidth="'150px'"
            ref="EditForm"
            formRef="EditForm"
          ></dd-form>
        </dd-card>
      </div>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
import addPop from "../Region/components/AddPop.vue";
import addUserPop from "./components/AddUserPop.vue";
export default {
  $dynamicRoute: {
    name: "按范围配置人员",
    path: "/checkingIn/baseManage/regionUser",
  },
  components: { addPop, addUserPop },
  data() {
    return {
      tableHeight: null,
      addAreaPopupVisible: false,
      addUserPopupVisible: false,
      type: "",
      id: "",
      poptitleName: "",
      total: 1,
      size: 10,
      current: 1,
      userCurrent: 1,
      userSize: 10,
      userTotal: 1,
      areaId: "",
      formData: {},
      formList_form: [
        {
          label: "姓名",
          value: "userFullName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "工号",
          value: "username",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "部门",
          value: "deptName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "生效开始时间",
          value: "effectStartTime",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "生效结束时间",
          value: "effectEndTime",
          inputType: "dateStr",
          disabled: false,
          dateType: "date",
          filterable: true,
          format: "yyyy-MM-dd",
          defaultValue: "",
          rules: [
            { required: true, message: "请选择生效结束时间", trigger: "blur" },
          ],
        },
      ],
      areaFormList: [
        {
          label: "区域名称",
          placeholderText: "请输入区域名称",
          value: "areaname",
          change: this.handleChange,
          inputType: "text",
          labelWidth: "20px",
          defaultValue: "",
        },
      ],
      areaColumnsList: [
        {
          title: "区域名称",
          key: "areaname",
          align: "center",
        },
        {
          title: "一级部门",
          key: "fenDeptName",
          align: "center",
        },
        {
          title: "操作",
          width: 300,
          align: "center",
          event: [
            {
              show: "true",
              label: "查看详情",
              icon: "el-icon-info",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleEditor(row);
              },
              type: "warning",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleDelArea(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      areaTableData: [],
      userFormList: [
        {
          label: "员工姓名",
          placeholderText: "请输入员工姓名",
          value: "userFullName",
          change: this.handleChange,
          inputType: "text",
          labelWidth: "10px",
          defaultValue: "",
        },
        {
          label: "工号",
          placeholderText: "请输入员工工号",
          value: "username",
          inputType: "text",
          labelWidth: "10px",
          defaultValue: "",
        },
      ],
      userColumnsList: [
        {
          title: "姓名",
          key: "userFullName",
          align: "center",
        },
        {
          title: "工号",
          key: "username",
          align: "center",
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        {
          title: "状态",
          key: "effectStatus",
          align: "center",
        },
        {
          title: "生效开始时间",
          key: "effectStartTime",
          align: "center",
        },
        {
          title: "生效结束时间",
          key: "effectEndTime",
          align: "center",
        },
        {
          title: "操作",
          width: 100,
          align: "center",
          event: [
            {
              show: "true",
              label: "失效范围",
              method: (row) => {
                this.handleRemove(row);
              },
              type: "success",
              // rules: (row) => {
              //   let show = false;
              //   if (row.effectStatus == "生效中") {
              //     show = true;
              //   } else {
              //     show = false;
              //   }
              //   return show;
              // },
            },
          ],
        },
      ],
      userTableData: [],
      selection: [],
      userIds: "",
      rowData: {},
      lostSignVisible: false,
      selectedRow: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getAreaTableData();
  },
  methods: {
    handlelose() {
      let flag = false;
      this.$refs.EditForm.$refs.EditForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return false;
      }
      if (
        Date.parse(this.$refs.EditForm.formData.effectEndTime) <
        Date.parse(this.$refs.EditForm.formData.effectStartTime)
      ) {
        this.$message.warning("生效结束时间不能小于生效开始时间!");
        return;
      }
      let param = {
        id: this.rowData.id,
        effectEndTime: this.$refs.EditForm.formData.effectEndTime + " 23:59:59",
      };
      postApi(`/hr/attend/checkrelation/lose/range`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败");
        }
        this.lostSignVisible = false;
        this.getUserTableData();
      });
    },
    handleRemove(row) {
      this.rowData = row;
      this.lostSignVisible = true;
      this.$nextTick(() => {
        this.formList_form[0].defaultValue = row.userFullName;
        this.formList_form[1].defaultValue = row.username;
        this.formList_form[2].defaultValue = row.deptName;
        this.formList_form[3].defaultValue = row.effectStartTime
          ? row.effectStartTime.split(" ")[0]
          : null;
        this.formList_form[4].defaultValue = row.effectEndTime
          ? row.effectEndTime.split(" ")[0]
          : null;
      });
    },
    getAreaTableData() {
      let { current, size, formData } = this;
      let params = {
        current,
        size,
        ...formData,
      };
      getApi(`/hr/attend/baseinfo/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.areaTableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    //条件查询
    handleAreaSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getAreaTableData();
    },
    //单选
    handleAreaSelect(row) {
      // console.log("selection",selection);
      // this.areaId = row.id;
      // this.$refs.userTableRef.$refs.baseTable.clearSelection();
      // this.$refs.areaTableRef.$refs.baseTable.clearSelection();
      // if (selection.length == 0) return;
      // this.$refs.areaTableRef.$refs.baseTable.toggleRowSelection(row, true);
      // this.getUserTableData();
      // this.$refs.areaTableRef.$refs.baseTable.setCurrentRow(row);
    },
    //点击行单选
    handleRowAreaSelect(row) {
      this.selectedRow = row;
      this.areaId = row.id;
      this.$refs.userTableRef.$refs.baseTable.clearSelection();
      this.$refs.areaTableRef.$refs.baseTable.clearSelection();
      this.$refs.areaTableRef.$refs.baseTable.toggleRowSelection(row, true);
      this.getUserTableData();
      this.$refs.areaTableRef.$refs.baseTable.setCurrentRow(row);
    },
    getRowClass({ row }) {
      return this.selectedRow === row ? "success-row" : "";
    },
    //区域新增
    handleAddArea() {
      this.type = "add";
      this.addAreaPopupVisible = true;
      this.poptitleName = "新增外勤范围";
    },
    // 新增确认事件
    handleAreaSave(formName, formNameBase) {
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            if (this.type == "add") {
              let params = {
                lngAndLatStr: this.$refs.addPop.lngAndLatStr,
                ...this.$refs.addPop.$refs[formName].formData,
              };
              postApi(`/hr/attend/baseinfo`, params).then((res) => {
                let { code } = res.data;
                if (code == 0) {
                  this.addAreaPopupVisible = false;
                  this.current = 1;
                  this.getAreaTableData();
                  this.$message.success("新增成功");
                }
              });
            } else if (this.type == "update") {
              let params = {
                lngAndLatStr: this.$refs.addPop.lngAndLatStr,
                ...this.$refs.addPop.$refs[formName].formData,
                id: this.id,
              };
              putApi(`/hr/attend/baseinfo`, params).then((res) => {
                let { code } = res.data;
                if (code == 0) {
                  this.addAreaPopupVisible = false;
                  this.current = 1;
                  this.getAreaTableData();
                  this.$message.success("编辑成功");
                }
              });
            }
          }
        }
      );
    },
    //查看详情
    handleInfo(row) {
      this.id = row.id;
      this.type = "view";
      this.addAreaPopupVisible = true;
      this.poptitleName = "查看详情";
    },
    //编辑
    handleEditor(row) {
      this.id = row.id;
      this.type = "update";
      this.addAreaPopupVisible = true;
      this.poptitleName = "编辑";
    },
    //删除
    handleDelArea(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/hr/attend/baseinfo/${row.id}`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("删除成功");
          }
          this.getAreaTableData();
        });
      });
    },
    // 新增弹框关闭事件
    handleAreaClose() {
      this.addAreaPopupVisible = false;
    },
    //分页
    handleAreaCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getAreaTableData();
    },

    /*
    用户考勤区域
     */
    getUserTableData() {
      let { formData } = this;
      let params = {
        current: this.userCurrent,
        size: this.userSize,
        areaId: this.areaId,
        ...formData,
      };
      getApi(`/hr/attend/checkrelation/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.userTableData = data.data.records;
          this.userTotal = data.data.total;
        }
      });
    },
    //条件查询
    handleUserSearchEvent(obj) {
      if (this.areaId == "") {
        this.$message.warning("请先选择考勤区域");
      } else {
        this.formData = obj;
        this.current = 1;
        this.getUserTableData();
      }
    },
    //多选
    handleUserSelect(selection, row) {
      this.selection = selection;
      if (selection.length == 0) return;
      this.$refs.userTableRef.$refs.baseTable.setCurrentRow(row);
      console.log(this.selection);
    },
    //用户新增
    handleAddUser() {
      if (this.areaId == "") {
        this.$message.warning("请先选择考勤区域");
      } else {
        this.addUserPopupVisible = true;
        this.poptitleName = "新增用户";
      }
    },
    // 新增确认事件
    handleUserSave() {
      let flag = false;
      this.$refs.addUserPop.$refs.effectTimeRef.$refs.effectTimeRef.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }
      if (this.$refs.addUserPop.selection.length == 0) {
        this.$message.warning("请选择外勤人员！");
        return;
      }

      let param = {
        effectStartTime:
          this.$refs.addUserPop.$refs.effectTimeRef.formData.effectStartTime +
          " 00:00:00",
        effectEndTime:
          this.$refs.addUserPop.$refs.effectTimeRef.formData.effectEndTime +
          " 23:59:59",
        areaId: this.areaId,
        voList: this.$refs.addUserPop.selection,
      };
      console.log("param", param);
      postApi(`/hr/attend/checkrelation/add/user`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("添加成功");
        } else {
          this.$message.warning("添加失败");
        }
        this.addUserPopupVisible = false;
        this.getUserTableData();
      });
    },
    //弹窗关闭
    handleUserClose() {
      this.addUserPopupVisible = false;
    },
    handleClose2() {
      this.lostSignVisible = false;
    },
    //删除用户
    handleDelUser() {
      if (this.areaId == "") {
        this.$message.warning("请先选择考勤区域");
      } else {
        if (this.selection.length > 0) {
          this.$confirm(`确认删除所选择的用户吗？`, "提示", {
            confirmButtonClass: "btnsearch",
            confirmButtonText: "确认",
            cancelButtonClass: "btnreset",
            cancelButtonText: "取消",
            showClose: true,
            type: "warning",
          }).then(() => {
            this.selection.forEach((item) => {
              this.userIds = this.userIds + item.userId + ",";
            });
            this.userIds = this.userIds.substring(
              0,
              this.userIds.lastIndexOf(",")
            );
            delApi(
              `/hr/attend/checkrelation?areaId=` +
                this.areaId +
                `&userIds=` +
                this.userIds
            ).then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message.success("删除成功");
              }
              this.$refs.userTableRef.$refs.baseTable.clearSelection();
              this.selection = [];
              this.userIds = "";
              this.getUserTableData();
            });
          });
        } else {
          this.$message.warning("请先选择用户");
        }
      }
    },
    //分页
    handleUserCurrentChange(size, number) {
      this.userCurrent = number;
      this.userSize = size;
      this.getUserTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  display: flex;
  justify-content: space-between;
  .left-box {
    width: 48%;
    border-right: 10px solid #e7eaec;
    /deep/ .search-form-input {
      width: 50% !important;
    }
  }
  .right-box {
    width: 52%;
    /deep/ .search-form-input {
      width: 33.3% !important;
    }
  }
}
:deep(.el-table__header .el-checkbox__inner) {
  display: none !important;
}
/deep/ .main-container {
  display: block !important;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-table {
  .success-row {
    background-color: #d4edda !important; /* 轻绿色背景，表示成功 */
  }
}
</style>
