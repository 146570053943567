<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>
        </div>
        <dd-card title="客户列表" :height="tableHeight">
            <dd-table :columns="columnsList" :data="tableData" border :isSelection="false" :topBtn="true"
                show-page :loading="loading">
                <template #table-btn-right>
                    <el-button
                        icon="el-icon-s-operation"
                        circle
                        type="info"
                        size="small"
                        @click="drawerVisible = true"
                        title="列筛选"
                    ></el-button>
                    </template>
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handleCurrentChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>
        <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
            :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
            @handleColumnsChange="handleColumnsChange">
        </dd-filter-drawer>

        <dd-popup
            v-if="editVisible"
            width="90%"
            height="90%"
            showBtn
            :saveBtn="false"
            :submitBtn="false"
            :show="editVisible"
            title-name="编辑"
            @popup-save="handleSave()"
            @popup-close="handleClose"
            >
            <edit-Pop
                :crmId="crmId"
                ref="editPop"
                slot="main"
            ></edit-Pop>
            </dd-popup>
    </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
import editPop from './editPop.vue'
export default {
    components: { editPop },
    name: "customerManagement",
    $dynamicRoute: {
        name: "客户管理",
        path: "/customerManagement/index",
        meta: {
            keepAlive: true,
        },
    },
    data() {
        return {
            saveBtn: true,
            columnsList: [],
            editVisible: false,
            tableHeight: null,
            drawerVisible: false,
            loading: false,
            formData: {},
            formList: [
                {
                    label: "客户名称",
                    value: "name",
                    inputType: "text",
                    placeholderText: "请输入客户名称",
                },
                {}, {}
            ],
            tableColumns: [
                {
                    title: "编码",
                    key: "code",
                    align: "center",
                    width: 150,
                },
                {
                    title: "名称",
                    key: "name",
                    align: "center",
                    width: 270,
                },
                {
                    title: "状态名称",
                    key: "stateName",
                    align: "center",
                },
                {
                    title: "税务登记号",
                    key: "txRegisterNo",
                    align: "center",
                },
                {
                    title: "版本信息",
                    key: "version",
                    align: "center",
                },
                {
                    title: "法人代表",
                    key: "artificialPerson",
                    align: "center",
                },
                {
                    title: "地址",
                    key: "address",
                    align: "center",
                    width: 230,
                },
                {
                    title: "操作",
                    align: "center",
                    width: 160,
                    event: [
                        {
                            label: "管理客户联系人",
                            state: "default",
                            type: "primary",
                            method: (row) => {
                                this.handleEdit(row);
                            },
                            //   rules: (row) => {
                            //     let show = false;
                            //     if (row.status == "CW-YSBZ-ZT-BZZ" || row.status == "CW-YSBZ-ZT-SPTH" || row.status == 'CW-YSBZ-ZT-XDBZZ') {
                            //       show = true;
                            //     } else {
                            //       show = false;
                            //     }
                            //     return show;
                            //   },
                        },
                    ],
                },
            ],
            crmId: null,
            tableData: [],
            current: 1,
            size: 20,
            total: null,
        };
    },
    created() {
        this.columnsList = [...this.tableColumns];
    },
    mounted() {
        // 自动计算table高度
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
        this.getDataType();
        this.getTableData();
    },
    methods: {
        handleColumnsChange(val) {
            let list = new Set();
            this.tableColumns.map((item) => {
                val.map((itm) => {
                    if (item.event || item.key == itm.key) {
                        list.add(item);
                    }
                });
            });
            this.columnsList = [...list];
        },
        //条件查询表单--下拉选择数据填充（optionsList）
        setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },
        // 获取数据字典类型
        getDataType() {
            postApi(`/project/prj/dict/list/all`, { typeCode: "khlb" }).then(
                (res) => {
                    let { data } = res;
                    if (data && data.code == 0) {
                        let options = {
                            value: "typeId",
                            optionsList: data.data,
                        };
                        this.setOPtionsList(this.formList, options);
                    }
                }
            );
            postApi(`/project/prj/dict/list/all`, { typeCode: "khzt" }).then(
                (res) => {
                    let { data } = res;
                    if (data && data.code == 0) {
                        let options = {
                            value: "stateId",
                            optionsList: data.data,
                        };
                        this.setOPtionsList(this.formList, options);
                    }
                }
            );
            postApi(`/project/prj/dict/list/all`, { typeCode: "khhy" }).then(
                (res) => {
                    let { data } = res;
                    if (data && data.code == 0) {
                        let options = {
                            value: "industryId",
                            optionsList: data.data,
                        };
                        this.setOPtionsList(this.formList, options);
                    }
                }
            );
            postApi(`/project/prj/dict/list/all`, { typeCode: "khly" }).then(
                (res) => {
                    let { data } = res;
                    if (data && data.code == 0) {
                        let options = {
                            value: "sourceId",
                            optionsList: data.data,
                        };
                        this.setOPtionsList(this.formList, options);
                    }
                }
            );
        },
        // 获取表格列表数据
        getTableData() {
            let { formData, current, size } = this;
            let params = {
              ...formData,
              current,
              size
            };
            // this.loading = true;
            getApi(`/project/customerinfo/page`, params).then((res) => {
              let { data } = res;
              if (data && data.code == 0) {
                this.loading = false;
                this.tableData = data.data.records;
                this.total = data.data.total;
              }
            });
        },
        // 表格行内编辑事件
        handleEdit(row) {
            this.crmId = row.id
            this.editVisible = true;
        },
        // 格式化类型
        getState(key, row) {
            let label = "";
            this[key].forEach((item) => {
                if (item.value == row) {
                    label = item.label;
                }
            });
            return label;
        },
        /* 搜索栏 */
        handleSearchEvent(obj) {
            this.formData = obj;
            this.current = 1;
            this.getTableData();
        },
        /* 分页页码回调 */
        handleCurrentChange(size, number) {
            this.current = number;
            this.size = size;
            this.getTableData();
        },
        handleSave() {

        },
        handleClose() {
            this.editVisible = false;
        }
    },
};
</script>

<style lang="less" scoped></style>