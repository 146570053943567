<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="预算申请列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <!-- <el-button
              icon="el-icon-download"
              circle
              type="info"
              @click="handleFileExport"
              size="small"
              title="下载"
          ></el-button> -->
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 预算明细弹窗 -->
    <dd-popup
      v-if="dialogDetailVisible"
      width="60%"
      height="80%"
      :title-name="applyDetailTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <applyDetail
        slot="main"
        :applyId="applyId"
        :projectId="projectId"
        :amountType="amountType"
      ></applyDetail>
    </dd-popup>
    <dd-popup
      v-if="dialogApplyBasicInfoVisible"
      width="60%"
      height="80%"
      title-name="预算详情"
      :show="dialogApplyBasicInfoVisible"
      @popup-close="dialogApplyBasicInfoVisible = false"
    >
      <applyBasicInfo slot="main" :rowData="rowData"></applyBasicInfo>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import applyDetail from "./components/applyDetail";
import applyBasicInfo from "./components/applyBasicInfo";
import { postApi, getApi, loadApi } from "@/api/request";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import qs from "qs";
export default {
  name: "budgetApply",
  $dynamicRoute: {
    name: "预算申请",
    path: "/finance/budget/apply",
    meta: {
      keepAlive: true,
    },
  },
  components: { applyDetail, applyBasicInfo },
  data() {
    return {
      tableHeight: null,
      loading: false,
      id: null,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      addEdit: {
        id: null,
        type: "",
      }, //子组件传值
      dialogAddEditVisible: false,
      dialogDetailVisible: false,
      dialogApplyBasicInfoVisible: false,
      drawerVisible: false,
      addEditTitle: "",
      applyDetailTitle: "",
      projectId: "",
      type: "addOredit",
      applyId: null,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "编号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入编号",
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编码",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "单据状态",
          value: "state",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {
          label: "申请人",
          value: "applier",
          inputType: "text",
          placeholderText: "请输入申请人姓名",
        },
        {
          label: "部门",
          value: "applyDep",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          placeholderText: "请选择或搜索申请人部门",
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "编号",
          key: "code",
          align: "center",
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
        },
        {
          title: "项目编码",
          key: "projectCode",
          align: "center",
        },
        {
          title: "总金额(元)",
          key: "totalAmount",
          align: "center",
          event: [
            {
              href: (row) => {
                return row.totalAmount;
              },
              type: "primary",
              method: (row) => {
                this.handleDetail(row);
              },
            },
          ],
        },
        {
          title: "单据状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "节点",
          key: "taskFlag",
          align: "center",
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "申请日期",
          key: "applyDate",
          align: "center",
        },
        {
          title: "部门",
          key: "applyDep",
          align: "center",
        },
        {
          title: "详情",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
    this.getApplyList();
    setTimeout(() => {
      this.getConditionData();
    }, 500);
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //条件数据查询
    getConditionData() {
      //获取项目信息
      getApi(`/project/prj/get/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      // 获取单据状态
      postApi(
        `/finance/dict/list`,
        qs.stringify({ typeCode: "xmyssqdzt" })
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data = data.data.filter((item) => {
            return item.code !== "shtg";
          });

          this.formList.forEach((item) => {
            if (item.value == "state") {
              item.optionsList = data.data;
            }
          });
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.depList = res.data.data;
        let options = {
          value: "applyDep",
          optionsList: this.depList,
        };
        this.setOPtionsList(this.formList, options);
      });
    },
    // 预算申请列表请求
    getApplyList() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      postApi(`/finance/apply/audit/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.totalAmount = formatNumber(item.totalAmount);
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
          // this.loading = false;
        }
      });
    },
    // 预算详情
    handleDetailInfo(row) {
      this.rowData = row;
      this.dialogApplyBasicInfoVisible = true;
    },
    // 总金额（预算明细)
    handleDetail(val) {
      this.applyDetailTitle = "预算明细";
      this.applyId = val.id;
      this.projectId = val.projectId;
      this.dialogDetailVisible = true;
      this.amountType = "totalAmount";
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });

      this.formData["curOrgId"] = sessionStorage.getItem("curOrgId");

      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },

    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getApplyList();
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "totalAmount") {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (!isNaN(+item)) {
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} pageSize 每页显示条数
     * @param {*} pageNumber 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getApplyList();
    },
  },
};
</script>

<style lang="less" scoped></style>
