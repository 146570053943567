<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-03-21 16:39:15
 * @LastEditors: hechen
 * @LastEditTime: 2023-03-23 17:42:48
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-finance-ui\src\components\BaseButton\index.vue
 * @Descripttion: 系统按钮组件
    <dd-button type="primary" icon="search">搜索</dd-button>
    <dd-button type="primary" icon="add">增加</dd-button>
    <dd-button type="danger" icon="delete">删除</dd-button>
    <dd-button type="warning" icon="refresh">重置</dd-button>
    <dd-button type="primary" icon="update">修改</dd-button>
    <dd-button type="danger" icon="import">导入</dd-button>
    <dd-button type="warning" icon="export">导出</dd-button>
-->
<template>
  <el-button
    v-bind="$attrs"
    :icon="getIcon"
    :disabled="loadingStatus"
    @click.prevent="handleClick"
  >
    <slot />
  </el-button>
</template>

<script>
export default {
  name: "dd-button",
  props: {
    icon: {
      type: String,
      default: "",
    },
    autoLoading: {
      type: Boolean,
      default: false,
    },
    autoConfirm: {
      type: Boolean,
      default: false,
    },
    confirmConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loadingStatus: false,
    };
  },
  computed: {
    getIcon() {
      const iconMap = {
        add: "el-icon-plus",
        delete: "el-icon-delete",
        update: "el-icon-edit",
        search: "el-icon-search",
        import: "el-icon-upload2",
        export: "el-icon-download",
        refresh: "el-icon-refresh",
        close: "el-icon-close",
        back: "el-icon-back",
        view: "el-icon-view",
        "arrow-left": "el-icon-arrow-left",
        arrow: "el-icon-arrow-left",
        "delete-solid": "el-icon-delete-solid",
        deletesolid: "el-icon-delete-solid",
        minus: "el-icon-minus"
      };
      return iconMap[this.icon] || ""; // 默认返回空字符串
    },
  },
  watch: {
    autoConfirm(n, o) {
      if (n) {
        this.confirmClick();
      }
    },
  },
  mounted() {
    this.$on("click", () => {
      this.loadingStatus = false;
    });
  },
  methods: {
    handleClick() {
      if (this.autoLoading) {
        this.loadingStatus = true;
        setTimeout(() => {
          this.$emit("click");
        }, 300);
      } else {
        this.$emit("click");
      }
    },
    confirmClick() {
      const {
        message = "此操作将永久删除该数据, 是否继续?",
        title = "提示",
        confirmButtonText = "确定",
        cancelButtonText = "取消",
        type = "warning",
      } = this.confirmConfig;
      this.$confirm(message, title, {
        confirmButtonText,
        cancelButtonText,
        type,
      })
        .then(() => {
          this.$emit("confirm");
        })
        .catch(() => {
          this.$emit("cancel");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--primary {
  background: @primaryBtn;
  border-color: @primaryBtn;
}

.el-button--warning {
  background: @warningBtn;
  border-color: @warningBtn;
}

.el-button--danger {
  background: @dangerBtn;
  border-color: @dangerBtn;
}

.el-button {
  transition: none;
}

.el-button:hover {
  opacity: 0.8;
}
</style>
