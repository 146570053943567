<template>
  <div class="container">
      <dd-card title="基本信息">
        <dd-form :form-config="formList" :flex="3" ref="budgetBasicForm" formRef="budgetBasicForm"></dd-form>
      </dd-card>
      <dd-card title="预算编制">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane v-for="(item,idx) in tabArr" :key="idx" :label="item.name" :name="item.name">
              <dd-table
                :columns="tableTypeColumns"
                :data="item.children"
                :topBtn="false"
                :is-selection="false"
                :loading="loading"
                border
                default-expand-all
                :tree-props="{
                  children: 'children',
                  hasChildren: 'hasChildren',
                }"
              >
              </dd-table>
            </el-tab-pane>
          </el-tabs>
      </dd-card>
      <dd-card title="附件查看">
        <div v-if="fileList.length" class="ibox-content">
          <div v-for="(item, index) in fileList" :key="index" >
            <el-link :underline="false" :href="item.url">{{item.name}}</el-link>
          </div>
        </div>
        <div v-else class="ibox-content" style="height: 20px; overflow: auto">
          <div>暂无附件</div>
        </div>
      </dd-card>
      <dd-card title="审批流程">
        <dd-table
          ref="expenseDetail"
          :topBtn="false"
          :isSelection="false"
          :isSort="true"
          :columns="tableColumns"
          :data="tableDataSources"
          border
        ></dd-table>
      </dd-card>
  </div>
</template>

<script>
import {getApi, postApi} from "@/api/request";
import { mapState, mapActions } from "vuex";

export default {
  name: "add",
  props: {
    rowData: null,
    type: "",
    revisePrj: {},
  },
  computed: {
    // ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      loading: false,
      activeName: "生产类",
      fileList: [],
      formList: [
      {
          label: "编号",
          value: "code",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "总金额(元)",
          value: "totalAmount",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "项目编码",
          value: "projectCode",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "项目名称",
          value: "projectName",
          inputType: "text",
          type: "textarea",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请人",
          value: "applyUser",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "修订人",
          value: "reviser",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "部门",
          value: "applyDep",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "状态",
          value: "stateName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "制单日期",
          value: "makeDate",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "制单人",
          value: "maker",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "节点",
          value: "taskFlag",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "版本",
          value: "versionCode",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "修订日期",
          value: "reviseDate",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
      ],
      tableTypeColumns: [
        {
          title: "费用类型编号",
          key: "code",
          align: "center",
          width: 180
        },
        {
          title: "费用类型名称",
          key: "name",
          align: "center",
          width: 180
        },
        {
          title: "预算金额",
          key: "amount",
          align: "center",
          width: 180,
        },
        {
          title: "使用金额(元)",
          key: "realUseAmount",
          align: "center",
          width: 180,
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
        },
      ],
      //审批流程表头
      tableColumns: [
        {
          title: "节点名称",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "time",
          align: "center",
        },
        {
          title: "节点操作人",
          key: "userName",
          align: "center",
        },
        {
          title: "审批意见",
          key: "fullmessage",
          align: "center",
        },
      ],
      //审批流程数据
      tableDataSources: [],
      tabArr: [],
    };
  },
  mounted() {
    this.getPrjBudgetApplyInfo();
  },
  methods: {
    handleClick(tab, event) {
    },
    //编辑页面信息回显
    getPrjBudgetApplyInfo() {
      let params = {id: this.rowData.id};
      postApi(`/finance/apply/front/detail`,params).then((res) => {
      // getApi(`/finance/apply/front/${this.rowData.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach(item => {
            item.defaultValue = data.data[item.value]
          })
          this.fileList = data.data.prjBudgetApplyAttachFileList;
          if(data.data.taskNodes) {
            this.tableDataSources = data.data.taskNodes;
          }

          let arr = [];
          data.data.oneTypeVOList.forEach((item) => {
            let obj = {
                name: item.name,
                children: [item]
            }
            arr.push(obj);
          })
          this.tabArr = arr;
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
  /deep/.el-tabs__header {
    margin: 0;
  }
  /deep/ .el-tabs__item {
    font-weight: 600;
    border-bottom: none;
  }
  /deep/.el-tabs--card>.el-tabs__header {
    border-bottom: none;
  }
  /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background-color: #576289;
    border-bottom: none;
  }
  /deep/.el-tabs__item.is-active {
    color: @systemColor !important;
  }
  /deep/.el-tabs__item:hover {
    color: #000 !important;
  }
  /deep/.el-tabs__item.is-active:focus {
    color: @systemColor !important;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
  }
  /deep/.el-textarea.is-disabled .el-textarea__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
    resize: none;
  }
  /deep/ .el-input__suffix {
    display: none;
  }
</style>