<template>
    <div class="container">
        <dd-card title="">
            <dd-form
                :form-config="formList"
                :labelWidth="'170px'"
                :flex="3"
                ref="assignForm_12"
                formRef="assignForm_12"
            ></dd-form>
        </dd-card>
        <dd-card title="自建合同列表">
            <dd-table :columns="tableColumns" :data="tableData" border :isSelection="true" show-page :loading="loading">
            </dd-table>
        </dd-card>
        <dd-popup v-if="detailVisible" width="90%" height="90%" class="contractDetailPopup" showBtn inPopup :saveBtn="false" :submitBtn="false"
            :show="detailVisible" title-name="查看详情" @popup-close="handleClose">
            <contractDetail ref="detailVisible" :pageProp="pageProp" slot="main"></contractDetail>
        </dd-popup>
    </div>
</template>

<script>
import contractDetail from './contractDetailZj.vue'
    export default {
        name: 'contractAssociation',
        components: { contractDetail },
        props: ['detailData'],
        data() {
            return {
                loading: false,
                detailVisible: false,
                formList: [
                    {
                        label: "关联类型",
                        value: "correlationTypeName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同变更后总金额",
                        value: "ftotalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "关联金额",
                        value: "selfAllotmentAmount",
                        inputType: "text",
                        disabled: true,
                    },
                ],
                tableColumns: [
                    {
                        title: "自建合同编号",
                        key: "code",
                        align: "center",
                        overflow: true,
                        width: 180,
                    },
                    {
                        title: "自建合同名称",
                        key: "name",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "合同乙方",
                        key: "partyBCompany",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "项目名称",
                        key: "prjName",
                        align: "center",
                        overflow: true,
                        
                    },{
                        title: "关联金额",
                        key: "allotmentAmount",
                        align: "center",
                        overflow: true,
                        
                    },
                    // {
                    //     title: "操作",
                    //     align: "center",
                    //     overflow: true,
                    //     event: [
                    //     {
                    //         state: "default",
                    //         label: "查看详情",
                    //         method: (row) => {
                    //             this.handleDetail(row);
                    //         },
                    //     },
                    //     ]
                    // },
                ],
                pageProp: {},
                tableData: [],
            }
        },
        watch: {
            detailData() {
                this.initPageData()
            },
        },
        created() {

        },
        mounted() {

        },
        methods: {
            initPageData() {
                this.$refs.assignForm_12.formData = {
                    correlationTypeName: this.detailData.correlationTypeName,
                    ftotalamount: this.detailData.ftotalamount,
                    selfAllotmentAmount: this.detailData.selfAllotmentAmount,
                }
                this.tableData = this.detailData.contractSelfPurchaseRelationVOList || []
            },
            handleClose() {
                this.detailVisible = false;
            },
            handleDetail(row) {
                this.pageProp.pageType = 'detail';
                this.pageProp.id = row.id;
                this.detailVisible = true;
                console.log('row', row)
            }
        }
    }
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}

/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}

// /deep/ .contractDetailPopup .tabs_card .el-tabs__item {
//     width: 28% !important;
//   }
</style>