<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :flex="3"
        ref="budgetBasicForm"
        formRef="budgetBasicForm"
      ></dd-form>
    </dd-card>
    <dd-card title="预算编制">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, idx) in tabArr"
          :key="idx"
          :label="item.name"
          :name="item.name"
        >
          <dd-table
            :columns="tableColumns"
            :data="item.children"
            :topBtn="false"
            :is-selection="false"
            :loading="loading"
            border
            default-expand-all
            :tree-props="{
              children: 'children',
              hasChildren: 'hasChildren',
            }"
          >
          </dd-table>
        </el-tab-pane>
      </el-tabs>
    </dd-card>
    <dd-card title="上传附件">
      <el-upload
        :auto-upload="true"
        class="upload-demo"
        ref="upload"
        action="/admin/sys-file/upload"
        :headers="headers"
        :on-remove="handleRemove"
        :on-success="beforeSuccess"
        multiple
        :limit="10"
        :on-exceed="handleExceed"
        :before-upload="beforeAvatarUpload"
        :file-list="fileList"
      >
        <dd-button size="small" type="primary" icon="import">
          选择文件
        </dd-button>
        <dd-button
          size="small"
          type="primary"
          icon="export"
          @click.stop.native="downloadModel"
        >
          模板下载
        </dd-button>
        <div slot="tip" class="el-upload__tip">请下载模板后填写上传</div>
        <div slot="tip" class="el-upload__tip" ref="filesValid"></div>
      </el-upload>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
import { getNowDate } from "@/utils/date";
import qs from "qs";
import { mapState, mapActions } from "vuex";

export default {
  name: "add",
  props: {
    addEdit: {},
  },
  computed: {
    // ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      loading: false,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
      }, // 请求头信息
      limit: 10, //文件上次个数限制
      ruleForm: {},
      activeName: "生产类",
      applyId: "",
      value: "",
      proTableData: [],
      manTableData: [],
      saleTableData: [],
      devTableData: [],
      tableData: [],
      formList: [
        {
          label: "申请人",
          value: "applyUser",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.getStore({ name: "fullName" }),
        },
        {
          label: "部门",
          value: "applyDep",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.getStore({ name: "depName" }),
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "date",
          disabled: true,
          defaultValue: getNowDate(),
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectName",
          },
          filterable: true,
          disabled: true,
          defaultValue: "",
          placeholderText: "请选择或搜索项目",
          rules: [{ required: true, message: "请选择项目", trigger: "blur" }],
          change: this.judgeIsCurPrjManager,
        },
        {
          label: "项目编码",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: true,
          defaultValue: "",
          placeholderText: "请选择或搜索编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
          change: this.judgeIsCurPrjManager,
          rules: [
            {
              required: true,
              message: "请选择项目编码",
              trigger: "blur",
            },
          ],
        },
        {
          label: "制单人",
          value: "maker",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.getStore({ name: "fullName" }),
        },
        {
          label: "制单日期",
          value: "makeDate",
          inputType: "date",
          dateType: "date",
          disabled: true,
          defaultValue: getNowDate(),
        },
      ],
      tableColumns: [
        {
          title: "费用类型编号",
          key: "code",
          align: "center",
          width: 180,
        },
        {
          title: "费用类型名称",
          key: "name",
          align: "center",
          width: 180,
        },
        {
          title: "预算金额",
          key: "amount",
          align: "center",
          width: 180,
          input: [
            {
              placeholderText: "请输入预算金额",
              method: (value, row) => {
                this.changeAmount(value, row);
              },
              isEdit: (value) => {
                let state = false;
                if (
                  value.code == "01" ||
                  value.code == "02" ||
                  value.code == "03" ||
                  value.code == "04"
                ) {
                  state = true;
                }
                return state;
              },
              rules: (value) => {
                return value.replace(/[^\d.]/g, "");
              },
            },
          ],
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
          input: [
            {
              isEdit: (value) => {
                let state = false;
                if (
                  value.code == "01" ||
                  value.code == "02" ||
                  value.code == "03" ||
                  value.code == "04"
                ) {
                  state = true;
                }
                return state;
              },
            },
          ],
        },
      ],
      projectIdBak: "",
      flag: false,
      fileList: [],
      fileListParam: [],
      tabArr: [],
      saleTypeList: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.getProjectList(); //初始化项目
    }, 500);

    this.getSubList();
    this.getPrjBudgetApplyInfo();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取项目列表
    getProjectList() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    //获取费用类型
    getSubList() {
      this.loading = true;
      getApi(`/finance/expense/subList`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.loading = false;
          let arr = [];
          for (let key in data.data) {
            let obj = {
              name: key,
              children: data.data[key],
            };
            arr.push(obj);
          }
          arr.forEach((item, idx) => {
            if (item.name == "销售类") {
              this.saleTypeList = item;
              arr.splice(idx, 1);
            }
          });
          this.tabArr = arr;
        }
      });
    },
    //编辑页面信息回显
    getPrjBudgetApplyInfo() {
      let params = {id: this.addEdit.id};
      postApi(`/finance/apply/front/detail`,params).then((res) => {
      // getApi(`/finance/apply/front/${this.addEdit.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
          // this.fileList = data.data.prjBudgetApplyAttachFileList;
          // this.fileListParam = data.data.prjBudgetApplyAttachFileList;

          let arr = [];
          data.data.oneTypeVOList.forEach((item) => {
            let obj = {
              name: item.name,
              children: [item],
            };
            arr.push(obj);
          });
          this.tabArr = arr;
          this.judgeIsCurPrjManager(data.data.projectId);
        }
      });
    },
    // 更新费用
    changeAmount(val, row) {
      let typeCode = row.code.split(".")[0];
      this.tabArr.forEach((item) => {
        if (item.children[0].code == typeCode) {
          let chiledrenamount = item.children[0].children;
          let amount = 0;
          for (let i = 0; i < chiledrenamount.length; i++) {
            amount += Number(chiledrenamount[i].amount);
          }
          item.children[0].amount = amount;
        }
      });
    },
    handleClick(tab, event) {},
    // 判断是否可编制销售类
    judgeIsCurPrjManager(projectId, list) {
      if (!projectId) {
        this.tabArr.forEach((item, idx) => {
          if (!data && item.name == "销售类") {
            this.tabArr.splice(idx, 1);
          }
        });
        return;
      }
      let params = {
        projectId: projectId,
      };
      postApi(
        `/finance/apply/front/isCurPrjCmmManager`,
        qs.stringify(params)
      ).then((res) => {
        let { data } = res;
        if (data) {
          let index = this.tabArr.findIndex((item) => item.name === "销售类");
          if (index == -1) {
            this.tabArr.splice(1, 0, this.saleTypeList);
          } else {
            this.tabArr.forEach((item) => {
              if (item.name == "销售类") {
                item.children.forEach((val) => {
                  val.amount = 0;
                  val.children.forEach((val1) => {
                    val1.amount = 0;
                  });
                });
              }
            });
          }
        } else {
          this.tabArr.forEach((item, idx) => {
            if (item.name == "销售类") {
              this.tabArr.splice(idx, 1);
            }
          });
        }
        this.activeName = "生产类";
      });
    },
    //成功上传文件
    beforeSuccess(res, file) {
      if (res.code == 0) {
        this.fileListParam = this.fileListParam.concat(res.data);
      }
    },
    //只能上传图片类型
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (isJPG) {
        this.$message.error("上传附件只能是 xlsx/xls格式!");
      }
      return !isJPG;
    },
    //删除文件
    handleRemove(file, fileList) {
      this.fileListParam.map((item, index) => {
        if (item.fileName == file.fileName) {
          this.fileListParam.splice(index, 1);
        }
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    //模板下载
    downloadModel() {
      loadApi(`/finance/apply/front/export/template/1`)
        .then((res) => {
          let { data } = res;
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
        })
        .catch((err) => {
          this.$message.error(err.response.headers.msg);
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}
</style>
