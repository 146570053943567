<template>
  <div class="main-container">
    <dd-form
      :form-config="formBasicList"
      ref="editPopForm"
      formRef="editPopForm"
      :labelWidth="'200px'"
      :flex="1"
    >
    </dd-form>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
export default {
  name: "edit-pop",
  props: ["editProp"],
  data() {
    return {
      formBasicList: [
        {
          label: "付款计划申请单",
          value: "name",
          inputType: "text",
          placeholderText: "请选择付款计划申请单",
          disabled: true,
        },
        {
          label: "执行周期开始日期",
          value: "startTime",
          placeholderText: "执行周期开始日期",
          inputType: "dateStr",
          dateType: "date",
          defaultValue: "",
          disabled: true,
          format: "yyyy-MM-dd",
          rules: [
            {
              required: true,
              message: "请选择执行周期开始日期",
              trigger: "blur",
            },
          ],
        },
        {
          label: "执行周期结束日期",
          value: "endTime",
          change: this.handleChange,
          placeholderText: "执行周期结束日期",
          inputType: "dateStr",
          dateType: "date",
          defaultValue: "",
          format: "yyyy-MM-dd",
          rules: [
            {
              required: true,
              message: "请选择执行周期结束日期",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.formBasicList[0].defaultValue = this.editProp.name;
    this.formBasicList[1].defaultValue = this.editProp.startTime + " 00:00:00";
    this.formBasicList[2].defaultValue = this.editProp.endTime + " 23:59:59";
  },
  methods: {
    handleChange(e, type) {
      let start = new Date(this.$refs.editPopForm.formData.startTime).getTime();
      let end = new Date(this.$refs.editPopForm.formData.endTime).getTime();
      if (end < start) {
        this.$message.warning("执行周期结束日期需大于执行周期开始日期！");
        this.$refs.editPopForm.formData.endTime = "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>
