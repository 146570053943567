<template>
    <div class="container">
        <el-tabs v-model="activeName" type="card" class="tabs_card3333" @tab-click="handleClick">
            <el-tab-pane label="合同信息" name="合同信息">
                <contractInformation :pageProp="pageProp"></contractInformation>
            </el-tab-pane>
            <el-tab-pane label="合同修改记录" name="合同修改记录">
                <dd-table :columns="tableColumns" :data="tableData" border isSort :isSelection="false" :loading="loading">
                </dd-table>
            </el-tab-pane>
            <el-tab-pane label="关联金蝶合同列表" name="关联金蝶合同列表">
                <dd-table :columns="tableColumns_kingDee" :data="tableData_kingDee" show-summary  border :isSelection="false" :loading="loading" max-height="500">
                    <template #table-btn>
                        <dd-button type="primary" icon="export" size="medium" @click="handlekingExport"
                            >导出
                        </dd-button>
                    </template>
                </dd-table>
            </el-tab-pane>
            <el-tab-pane label="合同结算信息" name="合同结算信息">
                <dd-table ref="baseTable_1" :columns="tableColumns_settlement" :data="tableData_settlement" show-summary :summary-method="getSettleSummaries" border :isSelection="false" :loading="loading" max-height="500">
                    <template #table-btn>
                        <dd-button type="primary" icon="export" size="medium" @click="handlesettleExport"
                            >导出
                        </dd-button>
                    </template>
                </dd-table>
            </el-tab-pane>
            <el-tab-pane label="合同付款信息" name="合同付款信息">
                <dd-table :columns="tableColumns_payment" :data="tableData_payment" show-summary :summary-method="getPaymentSummaries" border :isSelection="false" :loading="loading" max-height="500">
                    <template #table-btn>
                        <dd-button type="primary" icon="export" size="medium" @click="handlepaymentExport"
                            >导出
                        </dd-button>
                    </template>
                </dd-table>
            </el-tab-pane>
            <el-tab-pane label="开票信息" name="开票信息">
                <dd-table :columns="tableColumns_invoicing" :data="tableData_invoicing" show-summary :summary-method="getInvoicingSummaries" border :isSelection="false" :loading="loading" max-height="500">
                    <template #table-btn>
                        <dd-button type="primary" icon="export" size="medium" @click="handleinvoiceExport"
                            >导出
                        </dd-button>
                    </template>
                </dd-table>
            </el-tab-pane>
        </el-tabs>

        <dd-popup v-if="detailVisible" width="90%" height="90%" showBtn :saveBtn="false" inPopup :submitBtn="false"
            :show="detailVisible" title-name="查看详情" @popup-close="handleClose">
            <pageDetail ref="detailVisible" :pageProp="pageProp" slot="main"></pageDetail>
        </dd-popup>
    </div>
</template>

<script>
import { getApi,loadApi } from '@/api/request';
import contractInformation from './contractInformation.vue';
import pageDetail from '../../procurementContract/components/pageDetail.vue';
import { downloadBinaryFile } from '@/utils';
export default {
    name: 'contractDetail',
    props: ['pageProp'],
    components: { contractInformation, pageDetail },
    data() {
        return {
            activeName: '合同信息',
            detailVisible: false,
            pageProp: {},
            loading: false,
            tableColumns: [
                {
                    title: "修改原因",
                    key: "modifierReason",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "修改者",
                    key: "modifierName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "修改日期",
                    key: "modifyDate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购合同变更后总金额",
                    key: "procurementFinallyAmount",
                    align: "center",
                    overflow: true,
                },
            ],
            tableColumns_kingDee: [
                {
                    title: "关联金蝶合同编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联金蝶合同编号",
                    key: "fname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同乙方",
                    key: "fpartbname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "金蝶合同总合同额",
                    key: "ftotalamount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "操作",
                    align: "center",
                    overflow: true,
                    width: 100,
                    event: [
                        {
                            state: "default",
                            label: "查看详情",
                            width: 100,
                            method: (row) => {
                                this.handleRowDetail(row);
                            },
                        },
                    ],
                },
            ],
            tableColumns_settlement: [
                {
                    title: "合同编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "结算单据编号",
                    key: "settlefnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "结算单据名称",
                    key: "settlefname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "业务日期",
                    key: "fbizdate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "期间",
                    key: "fperiod",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "结算总金额",
                    key: "foriginalamount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "甲方",
                    key: "fparta",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "乙方",
                    key: "fpartb",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单人",
                    key: "documenter",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单日期",
                    key: "fcreatetime",
                    align: "center",
                    overflow: true,
                },
                // {
                //     title: "说明",
                //     key: "fdescription",
                //     align: "center",
                //     overflow: true,
                // },
                {
                    title: "关联结算金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                },
            ],
            tableColumns_payment: [
                {
                    title: "单据编号",
                    key: "paymentfnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "单据名称",
                    key: "paymentfname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "供应商",
                    key: "fparta",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "支付类型",
                    key: "payType",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "本期实付金额",
                    key: "foriginalamount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "不含税金额",
                    key: "fnotaxdataamount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "税额",
                    key: "ftax",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "付款期间",
                    key: "fperiod",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "付款日期",
                    key: "fauditdate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "工程项目",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单人",
                    key: "documenter",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联付款金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
            ],
            tableColumns_invoicing: [
                {
                    title: "合同编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "发票代码",
                    key: "invoiceCode",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "发票号码",
                    key: "invoiceNum",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "开票日期",
                    key: "invoiceDate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "金额",
                    key: "totalAmount",
                    align: "center",
                    overflow: true,
                },
                // {
                //     title: "税率",
                //     key: "code",
                //     align: "center",
                //     overflow: true,
                // },
                // {
                //     title: "税额",
                //     key: "code",
                //     align: "center",
                //     overflow: true,
                // },
                {
                    title: "价税合计金额",
                    key: "amountInFiguers",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "发票类型",
                    key: "invoiceType",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "有效发票金额",
                    key: "validInvoiceAmount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "开票供应商名称",
                    key: "sellerName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单人",
                    key: "noteDrawer",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单日期",
                    key: "applyDate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "附件",
                    align: "center",
                    overflow: true,
                    width: 140,
                    event: [
                        {
                            type: "primary",
                            method: (row) =>{
                                this.handleView(row);
                            },
                            href: (row) =>{
                                return(
                                    "发票预览"
                                );
                            }
                        }
                    ]
                },
                // {
                //     title: "附件",
                //     align: "center",
                //     overflow: true,
                //     image: true,
                //     AttachFiles: "file",
                // },
                {
                    title: "关联发票金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                },
            ],
            tableData_invoicing: [],
            tableData_payment: [],
            tableData_settlement: [],
            tableData_kingDee: [],
            tableData: [],
        }
    },
    created() {
        this.getPageData();
    },
    mounted() {

    },
    methods: {
        handlekingExport(){
            loadApi(
                `/contract/selfestablished/procurement/relation/export?id=${this.pageProp.id}&type=detail`
            ).then((res) => {
                let { data } = res;
                downloadBinaryFile(
                data,
                decodeURIComponent(
                    res.headers["content-disposition"].split(";")[1].split("=")[1]
                )
                );
                this.$message.success("导出成功");
            });
        },
        handlesettleExport(){
            loadApi(
                `/contract/selfestablished/procurement/relation/settle/export?id=${this.pageProp.id}&type=detail`
            ).then((res) => {
                let { data } = res;
                downloadBinaryFile(
                data,
                decodeURIComponent(
                    res.headers["content-disposition"].split(";")[1].split("=")[1]
                )
                );
                this.$message.success("导出成功");
            });
        },
        handlepaymentExport(){
            loadApi(
                `/contract/selfestablished/procurement/relation/payment/export?id=${this.pageProp.id}&type=detail`
            ).then((res) => {
                let { data } = res;
                downloadBinaryFile(
                data,
                decodeURIComponent(
                    res.headers["content-disposition"].split(";")[1].split("=")[1]
                )
                );
                this.$message.success("导出成功");
            });
        },
        handleinvoiceExport(){
            loadApi(
                `/contract/selfestablished/procurement/relation/invoice/export?id=${this.pageProp.id}&type=detail`
            ).then((res) => {
                let { data } = res;
                downloadBinaryFile(
                data,
                decodeURIComponent(
                    res.headers["content-disposition"].split(";")[1].split("=")[1]
                )
                );
                this.$message.success("导出成功");
            });
        },
        handleClose() {
            this.detailVisible = false;
        },
        handleClick(e) {},
        handleRowDetail(row) {
            this.detailVisible = true;
            this.pageProp.id = row.purchaseContractId;
        },
        handleView(row){            
        getApi(`/admin/sys-file/online/ssh/${row.file[0].fileName}`).then((res) => {
            let { data } = res;
            if (data && data.code == 0) {
            let url = data.data;
            window.open(url);
            }
        });
    },
        async getPageData() {
            let typeArr = ['change', 'purchase', 'settle', 'payment', 'invoice']
            for(let item of typeArr) {
                let res = await getApi(`/contract/selfestablished/procurement/self/purchase`, {id: this.pageProp.id, type: item});
                if(res.data.code == 0) {
                    let { data } = res
                    // console.log(item, data.data)
                    if(item == 'change') {
                        this.tableData = data.data
                    }else if(item == 'purchase') {
                        this.tableData_kingDee = data.data;
                    }else if(item == 'settle') {
                        this.tableData_settlement = data.data;
                    }else if(item == 'payment') {
                        this.tableData_payment = data.data;
                    }else {
                        data.data.forEach(item => {
                            item.file = [item.file]
                        })
                        this.tableData_invoicing = data.data;
                    }
                }
                
            }
        },
        getSettleSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = "合计";
                return;
                } else if (column.property == "allotmentAmount" || column.property == "foriginalamount"
                ) {
                //如果是经费（正常的加减法）
                const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    var sum = 0;
                    if (!isNaN(value)) {
                        sum = Number(Number(prev) + Number(curr)).toFixed(2);
                        return sum;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += " ";
                }
                }
            });
            return sums
        },
        getPaymentSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = "合计";
                return;
                } else if (column.property == "allotmentAmount" || column.property == "foriginalamount" 
                || column.property == "fnotaxdataamount" || column.property == "ftax"
                ) {
                //如果是经费（正常的加减法）
                const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    var sum = 0;
                    if (!isNaN(value)) {
                        sum = Number(Number(prev) + Number(curr)).toFixed(2);
                        return sum;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += " ";
                }
                }
            });
            return sums
        },
        getInvoicingSummaries(param){
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = "合计";
                return;
                } else if (column.property == "allotmentAmount" || column.property == "totalAmount" 
                || column.property == "amountInFiguers" || column.property == "validInvoiceAmount"
                ) {
                //如果是经费（正常的加减法）
                const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    var sum = 0;
                    if (!isNaN(value)) {
                        sum = Number(Number(prev) + Number(curr)).toFixed(2);
                        return sum;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += " ";
                }
                }
            });
            return sums
        }
    }
}
</script>

<style lang="less" scoped>
  .tabs_card3333{
    /deep/ .el-tabs__item {
    width: 17% !important;
  }
  }
</style>