<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        :labelWidth="'170px'"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="详细预算列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        ref="dTableRef"
        :loading="loading"
        show-page
        :isSelectable="false"
        @select-change="handleSelectionChange"
      >
      <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-btn>
          <!-- <dd-button type="info" size="medium" @click="viewDetails()"
            >查看详情</dd-button
          > -->
          <dd-button
              v-if="$store.state.btnAuthorityList.includes('finance:costdisassemble:export')"
            type="primary"
            size="medium"
            icon="export"
            @click="exportDetail()"
            >导出</dd-button
          >
          <dd-button
              v-if="$store.state.btnAuthorityList.includes('finance:costdisassemble:batchexport')"
            type="warning"
            size="medium"
            icon="export"
            @click="handleExportBatch()"
            >批量导出</dd-button
          >
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 详细预算表详情 -->
    <dd-popup
      v-if="dialogAddEditProVisible"
      width="90%"
      height="90%"
      showBtn
      :saveBtn="false"
      :submitBtn="false"
      :show="dialogAddEditProVisible"
      :title-name="addEditTitle"
      @popup-submit="handleSubmit('budgetBasicForm')"
      @popup-save="handleSubmit(0)"
      @popup-close="dialogAddEditProVisible = false"
    >
      <DetailedBudgetDetails
        ref="viewsDetails"
        slot="main"
        :propDetail="propDetail"
        :pageType="propDetail.pageType"
      ></DetailedBudgetDetails>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { postApi, loadApi, getApi } from "@/api/request";
import DetailedBudgetDetails from "./components/DetailedBudgetDetails.vue"; // 详情
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import {mapState} from "vuex";
export default {
  name: "details",
  $dynamicRoute: {
    name: "详细预算表",
    path: "/finance/budget/details",
    meta: {
      keepAlive: true,
    },
  },
  components: { DetailedBudgetDetails },
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  data() {
    return {
      searchObj: {},
      drawerVisible: false,
      tableHeight: null,
      amountType: "",
      dialogAddEditProVisible: false,
      dialogAddEditSalesVisible: false,
      addEditTitle: '详细预算表详情',
      loading: false,
      propDetail: {
        id: null,
        type: '',
        pageType: 'details'
      },
      tableData: [],
      formList: [
        {
          label: "单据编码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单据编码",
        },
        {
          label: "任务书名称及编码",
          value: "assignName",
          inputType: "text",
          defaultValue: "",
          placeholderText: "请输入任务书名称或编码",
        },
        {
          label: "状态",
          value: "status",
          inputType: "select",
          filterable: "filterable",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择状态",
        },
        {
          label: "修订状态",
          value: "revisionStatus",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择或搜索修订状态",
        },{},{},{}
      ],
      selectTable: [],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "单据编码",
          key: "code",
          align: "center",
          width: 200,
        },
        {
          title: "任务书编码",
          key: "assignNum",
          align: "left",
          overflow: true,
          width: 180,
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "left",
          width: 400,
        },
        {
          title: "状态",
          key: "statusName",
          align: "center",
          width: 180,
        },
        {
          title: "最新版本完成日期",
          key: "latestVersionDate",
          align: "center",
        },
        {
          title: "销售预算版本",
          key: "xsBudgetVersion",
          align: "center",
        },
        {
          title: "生产预算版本",
          key: "scBudgetVersion",
          align: "center",
        },
        {
          title: "修订状态",
          key: "revisionStatusName",
          align: "center",
        },
        {
          title: "是否生成可调整预算表",
          key: "whetherStatusName",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 120,
          fixed: "right",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetail(row);
              },
            },
          ],
        },
      ],
      columnsList: [],
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
    };
  },
  created() {
    this.getRevisionStatus(); // 获取修订状态List
    this.getStatusList(); // 获取状态list
    this.getTableList(); // 获取列表list
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    handleSearchEvent(e) { // 顶部查询
      this.searchObj = e;
      this.getTableList(e);
    },
    viewDetails() { // 查看详情
      if(this.selectTable.length != 1) {
        this.$message.warning('请选择一条数据');
        return false;
      }

      this.propDetail.type = 'detail';
      this.propDetail.id = this.selectTable[0].id;

      this.dialogAddEditProVisible = true;
    },
    handleSelectionChange(e) {
      this.selectTable = e;
    },
    exportDetail() { // 导出
      if(this.selectTable.length != 1) {
        this.$message.warning('请选择一条数据');
        return false;
      }

      loadApi(`/finance/finbudget/costdisassemble/export`, {id: this.selectTable[0].id}).then(res => {
        let { data } = res;
          this.$refs.dTableRef.$refs.baseTable.clearSelection();
          this.multipleSelection = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
          this.$message.success('导出成功')
      })
      this.dialogAddEditSalesVisible = true;
    },
    handleExportBatch() {
      if (this.selectTable.length < 1) {
        this.$message.warning('请至少选择一条数据');
        return false;
      }
      loadApi(`/finance/finbudget/costdisassemble/batch/export`, { ids: this.selectTable.map((item) => {return item.id}) }).then(res => {
        let { data } = res;
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
        this.selectTable = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success('导出成功')
      })
    },
    handleDetail(row) { // 查看详情
      this.propDetail.type = 'detail';
      this.propDetail.id = row.id;
      this.dialogAddEditProVisible = true;
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    getTableList(obj) { // 获取列表状态
      postApi(`/finance/budget/establishment/complete/list/${this.current}/${this.size}`, obj ? obj : {}).then(res => {
        let { data } = res;
        if(data.code == 0) {
          this.total = data.data.total;
          this.tableData = data.data.records;
        }
        else {
          this.tableData = []
        }
      })
    },
    getRevisionStatus() { // 获取修订状态下拉数据
      const params = {
        typeCode: "CW-XXYS-XDZT"
      };
      postApi('/finance/dict/list/all', params).then(res => {
        let { data } = res;
        if(data.code == 0) {
          let options = {
            value: "revisionStatus",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options)
        }
      })
    },
    getStatusList() { // 获取预算编制状态
      const params = {
        typeCode: "CW-XXYS-ZT"
      }
      postApi('/finance/dict/list/all', params).then(res => {
        if(res.data.code == 0) {
          let { data } =  res;
          let options = {
            value: "status",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options)
        }
      })
    },
    handleCurrentChange(size, number) { // 分页切换
      this.current = number;
      this.size = size;
      this.getTableList(this.searchObj);
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
