<template>
  <div class="container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="用车汇总列表">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        :topBtn="false"
        max-height="405"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <!-- <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template> -->
      </dd-table>
      <!-- <el-form :model="ruleForm" ref="ruleForm" class="form_wrap">
        <div
          class="group-height group-center"
        style="position: fixed; bottom: 15px; right: 35px"
        >
          <div>
            <dd-button slot="button" size="medium" @click="cancel"
              >取消
            </dd-button>
            <dd-button
              slot="button"
              type="primary"
              size="medium"
              @click="handlePopupSave(0)"
              >确认
            </dd-button>
          </div>
        </div>
      </el-form> -->
    </dd-card>
    <dd-popup
      v-if="dialogDetailVisible"
      width="90%"
      height="90%"
      title-name="详情"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
      :inPopup="true"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        :type="type"
        :id="id"
        @diaChange="diaChange"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <!-- 单图片预览 -->
    <el-image-viewer
      v-if="isspaImgePreview"
      :urlList="[spaImgePreviewUrl]"
      :on-close="closeViewer"
    ></el-image-viewer>
  </div>
</template>
  
<script>
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import AddUpdateDetailInfoPop from "../../Apply/components/AddUpdateDetailInfoPop";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import qs from "qs";
import { postApi, getApi, loadApi } from "@/api/request";
export default {
  components: { AddUpdateDetailInfoPop, ElImageViewer },
  props: ['collectDepId'],
  data() {
    return {
      dialogDetailVisible: false,
      processInstanceId: null,
      loading: false,
      ruleForm: {},
      id: "",
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      formList: [
        {
          label: "项目名称",
          value: "proName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
          title: true,
          change: this.handleChange,
          clear: this.handleClear,
        },
        {
          label: "项目编码",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
          title: true,
          change: this.handleChange,
          clear: this.handleClear,
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
          format: "yyyy-MM-dd",
        },
      ],
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置

      // 表格头部配置数据
      tableColumns: [
        {
          title: "用车类型",
          key: "carSubsidyTypeName",
          align: "center",
          width: 80,
        },
        {
          title: "填报人",
          key: "applierName",
          align: "center",
        },
        {
          title: "收款人",
          key: "payeeName",
          align: "center",
        },
        {
          title: "申请时间",
          key: "applyTime",
          align: "center",
          width: 100,
        },
        {
          title: "用车事宜",
          key: "vehicleMatter",
          align: "left",
          width: 100,
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "left",
          width: 150,
          overflow: true,
        },
        {
          title: "项目编码",
          key: "projectCode",
          align: "left",
          width: 120,
          overflow: true,
        },
        {
          title: "起始日期",
          key: "vehicleStartDate",
          align: "center",
          width: 100,
        },
        {
          title: "终止日期",
          key: "vehicleEndDate",
          align: "center",
          width: 100,
        },
        {
          title: "起始公里",
          key: "startKilometer",
          align: "center",
          href: [
            {
              method: (row) => {
                this.handleImgPreview(row, "startKilometer");
              },
            },
          ],
        },
        {
          title: "结束公里",
          key: "endKilometer",
          align: "center",
          href: [
            {
              method: (row) => {
                this.handleImgPreview(row, "endKilometer");
              },
            },
          ],
        },

        {
          title: "核定公里",
          key: "approvedKilometer",
          align: "center",
        },
        {
          title: "标准",
          key: "standardParameter",
          align: "center",
          width: 50,
        },
        {
          title: "用车核定金额(元)",
          key: "approvedAmount",
          align: "center",
        },
        {
          title: "过路费金额(元)",
          key: "tollAmount",
          align: "center",
        },
        {
          title: "过路费核定金额(元)",
          key: "tollVerifyAmount",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
      isspaImgePreview: false,
      spaImgePreviewUrl: "",
    };
  },
  created() {
    this.getApplyList();
  },
  mounted() {
    //获取项目信息
    this.getProject();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    diaChange(e) {
      this.dialogAddEditVisible = e;
      this.dialogApplyBasicInfoVisible = e;
      this.dialogDetailVisible = e;
      console.log(e);
      this.id = null;
      this.state = null;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.getApplyList();
    },
    // 预算详情
    handleDetailInfo(row) {
      this.id = row.id;
      this.type = "detail";
      this.dialogDetailVisible = true;
    },
    // 列表请求
    getApplyList() {
      this.loading = true;
      if(this.collectDepId) {
        this.formData.expenseBearDepId = this.collectDepId;
      }
      postApi(`/hr/applysheet/page/addApply`, this.formData).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          console.log(data);
          data.data.forEach((item) => {
            item.standardParameter = formatNumber(item.standardParameter);
            item.approvedAmount = formatNumber(item.approvedAmount);
            item.tollAmount = formatNumber(item.tollAmount);
            item.tollVerifyAmount = formatNumber(item.tollVerifyAmount);
          });
          this.tableDataSources = data.data;
        }
      });
    },
    //单图片预览
    handleImgPreview(row, type) {
      console.log(row, type);
      if (type == "startKilometer") {
        this.spaImgePreviewUrl = row.hrVehicleApplySheetFileList[0].url;
      } else {
        this.spaImgePreviewUrl = row.hrVehicleApplySheetFileList[1].url;
      }
      this.isspaImgePreview = true;
    },
    closeViewer() {
      this.isspaImgePreview = false;
    },
    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    //监听搜索下拉
    handleChange(e, type) {
      if (type == "proName") {
        this.$refs.ddSearchForm.formData.projectId = e;
      } else if (type == "projectId") {
        this.$refs.ddSearchForm.formData.proName = e;
      }
      console.log(this.$refs);
    },
    //监听下拉清除
    handleClear() {
      if (type == "proName" || type == "projectId") {
        this.$refs.ddSearchForm.formData.proName = "";
        this.$refs.ddSearchForm.formData.projectId = "";
      }
    },
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getApplyList();
    },
    handleAddEdit(type) {
      // type 0: 新建；1：编辑
      if (type == 0) {
        this.addEdit.id = null;
        this.addEditTitle = "添加项目预算单";
        this.addEdit.type = "add";
        this.dialogAddEditVisible = true;
      } else {
        if (this.multipleSelection.length !== 1) {
          this.$message.warning("请选择一条数据！");
          return;
        }
        // dtj: 项目预算状态 待提交 状态编码
        if (
          this.multipleSelection[0].state !== "dtj" &&
          this.multipleSelection[0].state !== "yth-2"
        ) {
          this.$message.warning("只能选择待提交或已退回状态数据编辑！");
          return;
        }
        this.addEdit.id = this.multipleSelection[0].id;
        this.addEdit.type = "edit";
        this.addEditTitle = "编辑项目预算单";
        this.dialogAddEditVisible = true;
      }
    },
    handleDelete() {
      var idArr = [];
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.multipleSelection[i].state != "dtj" &&
          this.multipleSelection[i].state != "yth-2"
        ) {
          this.$message.warning("只能选择待提交或已退回状态数据删除！");
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      var id = { ids: idArr.join() };
      this.$confirm("是否确定删除预算申请？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/finance/apply/deleteApply`, id)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
                this.$refs.dTableRef.$refs.baseTable.clearSelection();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 格式化表格单据状态
    getState(cellValue) {
      if (cellValue == "dtj") {
        return "待提交";
      } else if (cellValue == "shz") {
        return "审核中";
      } else if (cellValue == "shtg") {
        return "审核通过";
      } else if (cellValue == "shbtg") {
        return "审核不通过";
      }
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "approvedKilometer" ||
          column.property == "approvedAmount"||
          column.property == "tollAmount" ||
          column.property == "tollVerifyAmount"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (index == sums.length - 1) {
          console.log(sums[index]);
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} pageSize 每页显示条数
     * @param {*} pageNumber 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getApplyList();
    },
    feedbackMultipleSelection(list) {
      let _this = this;
      setTimeout(() => {
        let arr = list;
        if (arr.length > 0) {
          this.$nextTick(() => {
            console.log("arr", arr);
            console.log("this.tableDataSources", this.tableDataSources);
            arr.map((row) => {
              _this.$refs.dTableRef.$refs.baseTable.toggleRowSelection(
                this.tableDataSources.find((item) => {
                  return row.id == item.id;
                }),
                true
              );
            });
          });
        }
      }, 500);
    },
    // 弹框保存回调事件
    handlePopupSave() {
      console.log(1, this.multipleSelection);
      this.$emit("handleApplyList", this.multipleSelection);
      // this.$refs.addEditPop.submitOrSave();
    },
    //弹框取消回调事件
    cancel() {
      this.$emit("closeApplyPop", false);
    },
    // 弹框提交回调事件
    handlePopupSubmit() {},
    // 弹框重置回调事件

    handlePopupRest() {},
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "proName" || item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
  },
};
</script>
  
<style lang='less' scoped>
.container {
  width: 100%;
  position: relative;
  .form_wrap {
    /* width: 100%; */
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 50px;
    background: #fff;
  }
}
</style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>