<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        labelWidth="180px"
      ></dd-search-form>
    </dd-card>
    <dd-card title="预算比对列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        :isSelection="false"
        border
        ref="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <el-button
            icon="el-icon-download"
            circle
            type="info"
            @click="handleFileExport"
            size="small"
            title="下载"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogDetailVisible"
      width="60%"
      :title-name="detailCompareTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <applyDetail slot="main" :projectId="projectId"></applyDetail>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import applyDetail from "./components/applyDetail";
import { postApi, getApi, loadApi } from "@/api/request";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils";

export default {
  name: "budgetComparion",
  $dynamicRoute: {
    name: "预算比对列表",
    path: "/finance/budget/comparion",
    meta: {
      keepAlive: true,
    },
  },
  components: { applyDetail },
  data() {
    return {
      tableHeight: null,
      loading: false,
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      // 接收表单返回数据
      formData: {},
      dialogDetailVisible: false,
      detailCompareTitle: "",
      projectId: "",
      drawerVisible: false,
      multipleSelection: [],
      // 条件查询表单配置配置
      formList: [
        {
          label: "实时化单据单号",
          value: "sshCode",
          inputType: "text",
          placeholderText: "请输入单据编号",
        },
        {
          label: "金蝶单据单号",
          value: "kindeeCode",
          inputType: "text",
          placeholderText: "请输入单据编号",
        },
        {
          label: "实时化项目名称",
          value: "sshProjectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "实时化项目编码",
          value: "sshProjectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "金蝶项目名称",
          value: "kindeeProjectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "金蝶项目编码",
          value: "kindeeProjectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "实时化单据编号",
          key: "sshCode",
          align: "center",
          event: [
            {
              type: "primary",
              href: (row) => {
                return row.sshCode;
              },
              method: (row) => {
                this.handleDetail(row);
              },
            },
          ],
        },
        {
          title: "金蝶单据编号",
          key: "kindeeCode",
          align: "center",
        },
        {
          title: "实时化项目名称",
          key: "sshProjectName",
          align: "center",
        },
        {
          title: "金蝶项目名称",
          key: "kindeeProjectName",
          align: "center",
        },
        {
          title: "实时化项目编码",
          key: "sshProjectCode",
          align: "center",
        },
        {
          title: "金蝶项目编码",
          key: "kindeeProjectCode",
          align: "center",
        },
        {
          title: "实时化金额(元)",
          key: "sshTotalAmount",
          align: "center",
        },
        {
          title: "金蝶金额(元)",
          key: "kindeeTotalAmount",
          align: "center",
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    setTimeout(() => {
      //获取项目信息
      this.getProject();
    }, 500);
    this.getDataList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    // 总金额（预算明细)
    handleDetail(val) {
      this.detailCompareTitle = "预算明细";
      this.projectId = val.sshProjectId;
      this.dialogDetailVisible = true;
    },
    getDataList() {
      this.formData["curOrgId"] = sessionStorage.getItem("orgId");

      postApi(
        `/finance/budget/compare/list/${this.pageNumber}/${this.pageSize}`,
        this.formData
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.sshTotalAmount = formatNumber(item.sshTotalAmount);
            item.kindeeTotalAmount = formatNumber(item.kindeeTotalAmount);
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleSearchEvent(obj) {
      this.formData.sshCode = obj.sshCode;
      this.formData.sshProjectId = obj.sshProjectId;
      this.formData.kindeeCode = obj.kindeeCode;
      this.formData.kindeeProjectId = obj.kindeeProjectId;
      this.pageNumber = 1;
      this.getDataList();
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "sshTotalAmount" ||
          column.property == "kindeeTotalAmount"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (!isNaN(+item)) {
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getDataList();
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/get/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.options = {
            value: "sshProjectId",
            optionsList: data.data,
          };
          this.projectCodeOptions = {
            value: "kindeeProjectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, this.options);
          this.setOPtionsList(this.formList, this.projectCodeOptions);
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleFileExport() {
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });

      this.formData["curOrgId"] = sessionStorage.getItem("orgId");

      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };

      let params = {
        formData,
        ids,
      };

      loadApi(`/finance/budget/compare/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
