<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-03-22 15:03:56
 * @LastEditors: hechen
 * @LastEditTime: 2023-09-04 19:09:50
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\components\BaseTable\index.vue
 * @Descripttion: 系统基础表格组件
-->
<template>
  <div class="base-table">
    <div v-show="topBtn" class="table-btn-group">
      <div class="btn-left">
        <slot name="table-btn"></slot>
      </div>
      <div class="btn-right">
        <slot name="table-btn-right"></slot>
      </div>
    </div>
    <div class="table-box">
      <el-table
        v-bind="$attrs"
        :row-key="getRowKey"
        :header-cell-class-name="headerCellClassName"
        :cell-class-name="cellClassName"
        :cell-style="cellStyle"
        @select="handleSelect"
        @select-all="selectAll"
        @selection-change="handleSelectionChange"
        @row-click="handleRowClick"
        @cell-click="handleCellClick"
        @header-click="handleHeaderClick"
        ref="baseTable"
        v-loading="loading"
        :stripe="stripe"
      >
        <el-table-column
          v-if="isSort"
          type="index"
          label="序号"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          v-if="isSelection"
          type="selection"
          :resizable="false"
          :selectable="selectable"
          width="45"
          align="center"
          reserve-selection
        >
        </el-table-column>
        <el-table-column
          :resizable="true"
          v-for="item in columns"
          :key="item.key"
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          :align="item.align"
          :fixed="item.fixed ? item.fixed : false"
          :show-overflow-tooltip="item.overflow || true"
        >
          <!-- 多级表头 -->
          <template v-if="item.children">  <!-- 2024年5月17日 将包裹二级表头的 div 改为template 原因：循环二级表头的时候使用div 包裹上级 会造成 二级表头顺序渲染错乱问题  -->
            <el-table-column
              :resizable="false"
              v-for="val in item.children"
              :key="val.key"
              :prop="val.key"
              :label="val.title"
              :width="val.width"
              :align="val.align"
              :show-overflow-tooltip="val.overflow || true"
            >
              <template slot-scope="scope">
                <!-- 自定义输入框 -->
                <div v-if="val.input" class="select_box_edit">
                  <div v-if="isEdit">
                    <div v-if="val.inputTooltip">
                        <el-tooltip placement="top">
                              <div slot="content">
                                {{ scope.row[val.key] }}
                              </div>
                          <el-input
                            v-for="(item, idx) in val.input"
                            :key="idx"
                            :disabled="item.isEdit ? item.isEdit(scope.row) : false"
                            v-model="scope.row[val.key]"
                            @change="
                              item.method
                                ? item.method($event, scope.row, scope.$index)
                                : null
                            "
                          :style="{'--styleInput': getColor(scope.row, val.key)}"

                            @input="
                              item.rules
                                ? (scope.row[val.key] = item.rules(
                                    scope.row[val.key]
                                  ))
                                : null
                            "
                            :placeholder="item.placeholderText"
                          ></el-input>
                        </el-tooltip>
                    </div>
                    <div v-else>
                      <el-input
                            v-for="(item, idx) in val.input"
                            :key="idx"
                            :disabled="item.isEdit ? item.isEdit(scope.row) : false"
                            v-model="scope.row[val.key]"
                            @change="
                              item.method
                                ? item.method($event, scope.row, scope.$index)
                                : null
                            "
                          :style="{'--styleInput': getColor(scope.row, val.key)}"

                            @input="
                              item.rules
                                ? (scope.row[val.key] = item.rules(
                                    scope.row[val.key]
                                  ))
                                : null
                            "
                            :placeholder="item.placeholderText"
                          ></el-input>
                    </div>
                  </div>
                  <span v-else :class="item.overflow ? 'ellipsis' : ''">
                    {{
                      scope.row[val.key] || scope.row[val.key] == "0"
                        ? scope.row[val.key]
                        : "-"
                    }}
                  </span>
                </div>
                <div v-else-if="val.formatter">
                  <el-tag
                    v-if="val.formatter(scope.row[val.key])"
                    :type="item.type"
                    >{{ val.formatter(scope.row[val.key]) }}</el-tag
                  >
                  <div v-else>-</div>
                </div>
                <div
                  v-else-if="val.styleEvent"
                  :class="val.overflow ? 'ellipsis' : ''"
                  :style="val.styleEvent ? val.styleEvent.method(scope.row) : ''"
                >
                  {{ scope.row[val.key] || scope.row[val.key] == 0 ? scope.row[val.key] : "-" }}
                </div>
                <span v-else :class="val.overflow ? 'ellipsis' : ''">
                  {{
                    scope.row[val.key] || scope.row[val.key] == "0"
                      ? scope.row[val.key]
                      : "-"
                  }}
                </span>
              </template>
            </el-table-column>
          </template>

          <template slot-scope="scope">
            <!-- 自定义标签事件 -->
            <div
              v-if="item.event"
              class="handle-table-btnlist"
              :style="{
                'justify-content': item.align == 'center' ? 'center' : 'start',
              }"
            >
              <div v-for="(val, idx) in item.event" :key="idx">
                <el-link
                  v-if="val.rules && val.rules(scope.row)"
                  :underline="false"
                  @click.native.stop="val.method(scope.row)"
                  :type="val.type"
                  :icon="val.icon"
                >
                  {{ val.label || val.href(scope.row) }}
                </el-link>
                <el-link
                  v-else-if="!val.rules"
                  :underline="false"
                  @click.native.stop="val.method(scope.row, scope)"
                  :type="val.type"
                  :icon="val.icon"
                >
                  {{ val.label || val.href(scope.row, val.type) }}
                </el-link>
              </div>
            </div>
            <div
              v-else-if="item.styleEvent"
              :class="item.overflow ? 'ellipsis' : ''"
              :style="item.styleEvent ? item.styleEvent.method(scope.row) : ''"
            >
              {{ scope.row[item.key] ? scope.row[item.key] : "-" }}
            </div>
            <!-- 自定义单标签 -->
            <div v-else-if="item.formatter">
              <el-tag
                v-if="item.formatter(scope.row[item.key])"
                :type="item.type"
                >{{ item.formatter(scope.row[item.key]) }}</el-tag
              >
              <div v-else>-</div>
            </div>
            <!-- 自定义标签集合 -->
            <div v-else-if="item.formatterArr">
              <div
                v-if="item.formatterArr(scope.row[item.key]).length > 0"
                class="handle-table-btnlist"
              >
                <el-tag
                  :type="item.type"
                  :closable="item.close ? item.close : false"
                  v-for="(val, idx) in item.formatterArr(scope.row[item.key])"
                  :key="idx"
                >
                  {{ val }}
                </el-tag>
              </div>
              <div v-else>-</div>
            </div>
            <!-- 自定义图标标签 -->
            <div v-else-if="item.formatterIcon">
              <span
                v-if="scope.row[item.key]"
                :class="['iconfont', `${scope.row[item.key]}`]"
              ></span>
              <div v-else>-</div>
            </div>
            <!-- 自定义输入框 -->
            <div v-else-if="item.input">
              <div v-if="isEdit">
                <div v-if="item.tooltip">
                  <el-tooltip placement="top">
                    <div slot="content">
                      <table>
                        <tr>
                          <td style="padding-right:6px;">已分配父节点预估标准工天</td>
                          <td style="padding-left:6px;">剩余父节点预估标准工天</td>
                        </tr>
                        <tr>
                          <td style="text-align:center;">{{scope.row['assignedStandardWorkday']}}</td>
                          <td style="text-align:center;">{{scope.row['remainStandardWorkday']}}</td>
                        </tr>
                      </table>
                    </div>
                    <el-input
                      v-for="(val, idx) in item.input"
                      :key="idx"
                      :disabled="val.isEdit ? val.isEdit(scope.row) : false"
                      v-model="scope.row[item.key]"

                      @change="
                        val.method
                          ? val.method($event, scope.row, scope.$index)
                          : null
                      "
                      @input="
                        val.rules
                          ? (scope.row[item.key] = val.rules(scope.row[item.key]))
                          : null
                      "
                      :placeholder="val.placeholderText"
                    ></el-input>
                  </el-tooltip>
                </div>
                <div v-else>
                  <el-input
                    v-for="(val, idx) in item.input"
                    :key="idx"
                    :disabled="val.isEdit ? val.isEdit(scope.row) : false"
                    v-model="scope.row[item.key]"
                    @change="
                      val.method
                        ? val.method($event, scope.row, scope.$index)
                        : null
                    "
                    @input="
                      val.rules
                        ? (scope.row[item.key] = val.rules(scope.row[item.key]))
                        : null
                    "
                    :placeholder="val.placeholderText"
                  ></el-input>
                </div>
              </div>
                    
                    <span v-else :class="item.overflow ? 'ellipsis' : ''">
                {{
                  scope.row[item.key] || scope.row[item.key] == "0"
                    ? scope.row[item.key]
                    : "-"
                }}
              </span>
            </div>
            <!-- 自定义下拉选择 -->
            <div v-else-if="item.selectInput">
              <div v-if="isEdit && !item.isCustom">
                <el-select
                  v-model="scope.row[item.key]"
                  :placeholder="item.selectInput.placeholderText"
                  :filterable="item.filterable"
                  :disabled="
                    item.selectInput.isEdit
                      ? item.selectInput.isEdit(scope.row)
                      : false
                  "
                  :multiple="
                    item.selectInput.multiple
                      ? item.selectInput.multiple
                      : false
                  "
                  @change="
                    item.change
                      ? item.change(scope.row[item.key], scope.row)
                      : ''
                  "
                  @visible-change="
                    item.selectInput.blur
                      ? item.selectInput.blur($event, scope.row)
                      : ''
                  "
                  @remove-tag="
                    item.selectInput.remove
                      ? item.selectInput.remove($event, scope.row)
                      : ''
                  "
                >
                  <el-option
                    v-for="val in (typeof item.selectInput.options == 'function' ? item.selectInput.options(scope.row) : item.selectInput.options)"
                    :key="val.value"
                    :label="val.label"
                    :value="val.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <div v-else-if="isEdit && item.isCustom?.options1 && scope.$index == 0" class="select_box_edit">
                <el-select
                  v-model="scope.row[item.key]"
                  :placeholder="item.selectInput.placeholderText"
                  :style="{'--styleInput': getColor(scope.row, item.key)}"
                  :disabled="
                    item.selectInput.isEdit
                      ? item.selectInput.isEdit(scope.row)
                      : false
                  "
                  :multiple="
                    item.selectInput.multiple
                      ? item.selectInput.multiple
                      : false
                  "
                  @change="
                    item.change
                      ? item.change(scope.row[item.key], scope.row)
                      : ''
                  "
                  @visible-change="
                    item.selectInput.blur
                      ? item.selectInput.blur($event, scope.row)
                      : ''
                  "
                  @remove-tag="
                    item.selectInput.remove
                      ? item.selectInput.remove($event, scope.row)
                      : ''
                  "
                >
                  <el-option
                    v-for="val in item.isCustom?.options1(scope.row)"
                    :key="val.value"
                    :label="val.label"
                    :value="val.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-else-if="isEdit && item.isCustom?.options2 && scope.$index == 1" class="select_box_edit">
                <el-select
                  v-model="scope.row[item.key]"
                  :placeholder="item.selectInput.placeholderText"
                  :style="{'--styleInput': getColor(scope.row, item.key)}"
                  :disabled="
                    item.selectInput.isEdit
                      ? item.selectInput.isEdit(scope.row)
                      : false
                  "
                  :multiple="
                    item.selectInput.multiple
                      ? item.selectInput.multiple
                      : false
                  "
                  @change="
                    item.change
                      ? item.change(scope.row[item.key], scope.row)
                      : ''
                  "
                  @visible-change="
                    item.selectInput.blur
                      ? item.selectInput.blur($event, scope.row)
                      : ''
                  "
                  @remove-tag="
                    item.selectInput.remove
                      ? item.selectInput.remove($event, scope.row)
                      : ''
                  "
                >
                  <el-option
                    v-for="val in item.isCustom?.options2(scope.row)"
                    :key="val.value"
                    :label="val.label"
                    :value="val.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <span v-else :class="item.overflow ? 'ellipsis' : ''">
                {{
                  scope.row[item.key] || scope.row[item.key] == "0"
                    ? scope.row[item.key]
                    : "-"
                }}
              </span>
            </div>
            <!-- 自定义日期选择 -->
            <div v-else-if="item.date">
              <div v-if="isEdit">
                <el-date-picker
                  v-model="scope.row[item.key]"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  @change="
                    item.change
                      ? item.change(scope.row[item.key], scope.row)
                      : ''
                  "
                >
                </el-date-picker>
              </div>
              <span v-else :class="item.overflow ? 'ellipsis' : ''">
                {{
                  scope.row[item.key] || scope.row[item.key] == "0"
                    ? scope.row[item.key]
                    : "-"
                }}
              </span>
            </div>
            <!-- 自定义Switch 开关 -->
            <div v-else-if="item.switch">
              <div v-if="isEdit">
                <el-switch
                  v-model="scope.row[item.key]"
                  @change="item.change ? item.change(item.key, scope.row) : ''"
                >
                </el-switch>
              </div>
            </div>
            <div v-else-if="item.inputTypeArray">
              <div v-if="isEdit">
                <div v-for="(val, idx) in item.inputTypeArray" :key="idx">
                  <el-input
                    v-if="val.inputType == 'text' && val.showEvent(scope.row)"
                    :disabled="val.isEdit ? val.isEdit(scope.row) : false"
                    v-model="scope.row[item.key]"
                    @change="
                      val.method
                        ? val.method($event, scope.row, scope.$index)
                        : null
                    "
                    @input="
                      val.rules
                        ? (scope.row[item.key] = val.rules(scope.row[item.key]))
                        : null
                    "
                    :placeholder="val.placeholderText"
                  ></el-input>
                  <el-select
                    v-if="val.inputType == 'select' && val.showEvent(scope.row)"
                    v-model="scope.row[item.key]"
                    :placeholder="val.placeholderText"
                    @change="val.change ? val.change(scope.row[item.key]) : ''"
                  >
                    <el-option
                      v-for="data in val.options"
                      :key="data.value"
                      :label="data.label"
                      :value="data.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <span v-else :class="item.overflow ? 'ellipsis' : ''">
                {{
                  scope.row[item.key] || scope.row[item.key] == "0"
                    ? scope.row[item.key]
                    : "-"
                }}
              </span>
            </div>
            <!-- 自定义图片 -->
            <div v-else-if="item.image">
              <el-image
                v-if="scope.row[item.AttachFiles]"
                style="width: 45px; height: 25px"
                :src="scope.row[item.AttachFiles][0].url"
                :preview-src-list="
                  handlePreviewSrcList(scope.row[item.AttachFiles])
                "
              ></el-image>
              <span v-else>-</span>
            </div>
            <span v-else :class="item.overflow ? 'ellipsis' : ''">
              <div v-if="item.tooltip" >
                  <el-tooltip placement="top">
                    <div slot="content">
                      <table>
                        <tr>
                          <td style="padding-right:6px;">已分配父节点预估标准工天</td>
                          <td style="padding-left:6px;">剩余父节点预估标准工天</td>
                        </tr>
                        <tr>
                          <td style="text-align:center;">{{scope.row['assignedStandardWorkday']}}</td>
                          <td style="text-align:center;">{{scope.row['remainStandardWorkday']}}</td>
                        </tr>
                      </table>
                    </div>
                    <div>
                      {{
                    scope.row[item.key] || scope.row[item.key] == "0"
                      ? scope.row[item.key]
                      : "-"
                    }}
                    </div>
                  </el-tooltip>
                  
                </div>
                <span v-else>
                  {{
                    scope.row[item.key] || scope.row[item.key] == "0"
                      ? scope.row[item.key]
                      : "-"
                  }}
                </span>
              
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div class="table-pagination"> -->
    <slot name="table-pagination"></slot>
    <!-- </div> -->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "dd-table",
  props: {
    columns: {
      type: Array,
      default: [],
    },
    topBtn: {
      type: Boolean,
      default: true,
    },
    isSelection: {
      type: Boolean,
      default: true,
    },
    isSort: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    handleCheckDisable: {
      type: Function,
      default: () => {},
    },
    SetheaderCellClassName: {
      type:Function,
      default: () => {}
    },
    SetCellClassName: {
      type: Function,
      default: () => {}
    },
    SetcellStyle: {
      type:Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    stripe: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      value: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getTableHeight();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.baseTable.doLayout();
      this.getTableHeight();
    });
  },
  methods: {
    getColor(row, key) {
      // scope.row.meritCheck || salaryCheck || outsourcedCheck colorVal
      let colorVal = '#333333';
      if(key == 'outsourcedLaborUnivalence' && row.outsourcedCheck) {
        colorVal = 'red';
      }
      if(key == 'meritPayUnivalence' && row.meritCheck) {
        colorVal = 'red';
      }
      if(key == 'salaryInsuranceUnivalence' && row.salaryCheck) {
        colorVal = 'red';
      }
      if(key === 'quotaPercentage' && row.quotaCheck) {
        colorVal = 'red'
      }
      return colorVal
    },
    getRowKey(row) {
      return row.id || row.costTypeId;
    },
    clearSelect() {
      this.$nextTick(() => {
        if(this.isSelection) {
          this.$refs['baseTable'].clearSelection();
        }
      })
    },
    getTableHeight() {
      let pageHeight =
        $(".el-pagination").height() && $(".el-pagination").height() !== 0
          ? $(".el-pagination").height() + 10
          : 0;
      let btnHeight =
        $(".table-btn-group").height() !== 0
          ? $(".table-btn-group").height() + 10
          : 0;
      let boxHeight = pageHeight + btnHeight;
      $(".table-box").css({ height: "calc(100% - " + boxHeight + "px" });
      setTimeout(() => {
        let footHeight = $(".el-table__footer-wrapper").height() || 0;
        let headerHeight = $(".el-table__header-wrapper").height();
        let height = headerHeight + footHeight;
        $(".el-table__body-wrapper").css({
          height: "calc(100% - " + height + "px",
        });
        $(".el-table__fixed-body-wrapper").css({
          height: "calc(100% - " + (height + 8) + "px",
          top: headerHeight + "px",
        });
      }, 300);
    },
    handleSelectionChange(val) {
      this.$emit("select-change", val);
    },
    handleRowClick(val) {
      this.$emit("row-click", val);
    },
    handleCellClick(val) {
      this.$emit("cell-click", val);
    },
    handleHeaderClick(val) {
      this.$emit('header-click', val);
    },
    selectAll(val) {
      this.$emit("select-all", val);
    },
    handleSelect(selection, row) {
      this.$emit("select", selection, row);
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      return this.SetCellClassName({row, column, rowIndex, columnIndex})
    },
    headerCellClassName({row, column, rowIndex, columnIndex}) {
      return this.SetheaderCellClassName({row, column, rowIndex, columnIndex})
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      return this.SetcellStyle({row, column, rowIndex, columnIndex})
    },
    selectable(row, index) {
      if (!this.isSelectable) {
        return true;
      } else {
        return this.handleCheckDisable(row, index);
      }
    },
    //转换预览图片数组格式
    handlePreviewSrcList(arr) {
      let list = [];
      arr.map((item) => {
        list.push(item.url);
      });
      return list;
    },
  },
};
</script>

<style lang="less" scoped>
.base-table {
  width: 100%;
  height: 100%;
  /deep/ .el-table {
    overflow: visible !important;
    height: 100%;
  }
  /deep/ .el-table .el-table__cell {
    padding: 8px 0;
  }
  /deep/ .el-table__body {
    width: 100%;
    // 使表格兼容safari，不错位
    table-layout: fixed !important;
  }
  /deep/ .el-table__body-wrapper {
    overflow-y: auto !important;
  }
  /deep/ .el-table__header {
    // 使表格兼容safari，不错位,表头宽度较小
    width: 100%;
    table-layout: fixed !important;
  }
  // 关键css代码
  /deep/ .el-table__header-wrapper .gutter {
    width: 8px !important;
    display: inline-block !important;
    border: none !important;
  }
  // /deep/ .el-table__fixed-right {
  //   right: 8px !important;
  // }
  /deep/ .el-table__fixed-right-patch {
    width: 8px !important;
    background-color: @tableHeaderBg;
  }
  /deep/ .el-table__header-wrapper {
    background-color: @tableHeaderBg !important;
  }
  /deep/ .el-table .has-gutter .el-table__cell {
    background-color: @tableHeaderBg;
    color: @defaultFontColor;
  }
  /deep/ .el-table__fixed-footer-wrapper .el-table__cell {
    background-color: @tableHeaderBg !important;
    color: @defaultFontColor !important;
  }
  
  /deep/.el-table__fixed-right {
    padding-top: 12px;
    background-color: #fff;
  }
  /deep/ .el-table th.el-table__cell {
    background-color: @tableHeaderBg !important;
    color: @defaultFontColor;
  }



  /deep/ .el-table--border .el-table-column--selection .cell {
    padding: 0;
  }

  /deep/ .el-pagination {
    margin-top: 10px;
    padding: 0 5px;
  }

  .handle-table-btnlist {
    display: flex;
    flex-wrap: wrap;
    /deep/ .el-tag {
      margin: 1px;
    }
    .el-link {
      margin: 0 5px;
    }
  }

  .table-btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .btn-right {
      display: flex;
      align-items: center;
    }
  }

  .handle-btn {
    margin: 0 5px;
  }

  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  /deep/ .select_box_edit .el-tooltip .el-input .el-input__inner {
    color: var(--styleInput) !important
  }
}


</style>
<style scoped lang="css">

</style>
