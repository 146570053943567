import { render, staticRenderFns } from "./associationPop.vue?vue&type=template&id=1a212e28&scoped=true"
import script from "./associationPop.vue?vue&type=script&lang=js"
export * from "./associationPop.vue?vue&type=script&lang=js"
import style0 from "./associationPop.vue?vue&type=style&index=0&id=1a212e28&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a212e28",
  null
  
)

export default component.exports