<template>
  <div class="container">
    <div class="table_box">
      <dd-table
        ref="baseTable_1"
        :columns="tableColumns"
        :data="tableData"
        isSort
        :isSelection="false"
        border
        :loading="loading"
        max-height="560"
      >
        <template #table-btn>
          <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
            >添加打卡范围
          </dd-button>
        </template>
      </dd-table>
    </div>

    <!-- 添加打卡范围 -->
    <dd-popup
      v-if="addSignRangeVisible"
      width="90%"
      height="90%"
      showBtn
      inPopup
      :saveBtn="saveBtn"
      :submitBtn="false"
      :show="addSignRangeVisible"
      title-name="添加打卡范围"
      @popup-close="handleClose0"
    >
      <dd-button
        slot="button"
        size="medium"
        type="primary"
        @click="handleSubmit"
        >提交</dd-button
      >
      <addSignRange
        :pageProp="pageProp"
        ref="addSignRange"
        slot="main"
      ></addSignRange>
    </dd-popup>

    <!-- 查看范围 -->
    <dd-popup
      width="80%"
      height="90%"
      inPopup
      showBtn
      :saveBtn="false"
      cancelBtn
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addAreaPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :type="type"
        :id="id"
      ></add-pop>
    </dd-popup>

    <!-- 失效范围 -->
    <dd-popup
      v-if="lostSignVisible"
      width="35%"
      height="50%"
      inPopup
      showBtn
      :saveBtn="true"
      :submitBtn="false"
      :show="lostSignVisible"
      title-name="失效范围"
      @popup-save="handlelose()"
      @popup-close="handleClose1"
    >
      <div slot="main">
        <dd-card title="">
          <dd-form
            :form-config="formList_form"
            :flex="1"
            :labelWidth="'150px'"
            ref="EditForm"
            formRef="EditForm"
          ></dd-form>
        </dd-card>
      </div>
    </dd-popup>
  </div>
</template>
  
  <script>
import { getApi, delApi, postApi } from "@/api/request";
import addPop from "../../Region/components/AddPop.vue";
import addSignRange from "./addSignRange.vue";
export default {
  name: "AddPop",
  props: {
    userId: "",
  },
  components: { addPop, addSignRange },
  data() {
    return {
      lostSignVisible: false,
      addPopupVisible: false,
      poptitleName: "",
      addSignRangeVisible: false,
      loading: false,
      saveBtn: false,
      tableColumns: [
        {
          title: "范围名称",
          key: "areaname",
          align: "center",
          overflow: true,
        },
        {
          title: "状态",
          key: "effectStatus",
          align: "center",
          overflow: true,
        },
        {
          title: "生效开始时间",
          key: "effectStartTime",
          align: "center",
          overflow: true,
        },
        {
          title: "生效结束时间",
          key: "effectEndTime",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          width: 300,
          align: "center",
          event: [
            {
              show: "true",
              label: "查看范围",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
            {
              show: "true",
              label: "失效范围",
              method: (row) => {
                this.handleRemove(row);
              },
              type: "success",
                rules: (row) => {
                  let show = false;
                  if (row.effectStatus == "生效中") {
                    show = true;
                  } else {
                    show = false;
                  }
                  return show;
                },
            },
            {
              show: "true",
              label: "删除范围",
              method: (row) => {
                this.handleDelete(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      formList_form: [
        {
          label: "范围名称",
          value: "areaname",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "状态",
          value: "effectStatus",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "生效开始时间",
          value: "effectStartTime",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "生效结束时间",
          value: "effectEndTime",
          inputType: "dateStr",
          disabled: false,
          dateType: "date",
          filterable: true,
          format: "yyyy-MM-dd",
          defaultValue: "",
          rules: [
            { required: true, message: "请选择生效结束时间", trigger: "blur" },
          ],
        },
      ],
      tableData: [{}],
      pageProp: {},
      rowData: {},
    };
  },
  created() {
    this.getTableList();
  },
  mounted() {},
  methods: {
    handlelose() {
      let flag = false;
      this.$refs.EditForm.$refs.EditForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return false;
      }
      if (
        Date.parse(this.$refs.EditForm.formData.effectEndTime) <
        Date.parse(this.$refs.EditForm.formData.effectStartTime)
      ) {
        this.$message.warning("生效结束时间不能小于生效开始时间!");
        return;
      }
      let param = {
        id: this.rowData.id,
        effectEndTime: this.$refs.EditForm.formData.effectEndTime + " 23:59:59",
      };
      postApi(`/hr/attend/checkrelation/lose/range`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败");
        }
        this.lostSignVisible = false;
        this.getTableList();
      });
    },
    handleDelete(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        postApi(`/hr/attend/checkrelation/delete/range?id=${row.id}`).then(
          (res) => {
            if (res.data.code == 0) {
              this.$message.success("删除成功");
            } else {
              this.$message.warning("删除失败，请稍后重试");
            }
            this.getTableList();
          }
        );
      });
    },
    handleSubmit() {
      let flag = false;
      this.$refs.addSignRange.$refs.effectTimeRef.$refs.effectTimeRef.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }

      if (this.$refs.addSignRange.selectData.length == 0) {
        this.$message.warning("请选择外勤范围！");
        return;
      }
      let param = {
        effectStartTime:
          this.$refs.addSignRange.$refs.effectTimeRef.formData.effectStartTime +
          " 00:00:00",
        effectEndTime:
          this.$refs.addSignRange.$refs.effectTimeRef.formData.effectEndTime +
          " 23:59:59",
        userId: this.userId,
        voList: this.$refs.addSignRange.selectData,
      };
      postApi(`/hr/attend/checkrelation/add/range`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("添加成功");
        } else {
          this.$message.warning("添加失败");
        }
        this.addSignRangeVisible = false;
        this.getTableList();
      });
    },
    handleInfo(row) {
      this.id = row.areaId;
      this.type = "view";
      this.addPopupVisible = true;
      this.poptitleName = "查看范围";
    },
    handleRemove(row) {
      this.rowData = row;
      this.lostSignVisible = true;
      this.$nextTick(() => {
        this.formList_form[0].defaultValue = row.areaname;
        this.formList_form[1].defaultValue = row.effectStatus;
        this.formList_form[2].defaultValue = row.effectStartTime
          ? row.effectStartTime.split(" ")[0]
          : null;
        this.formList_form[3].defaultValue = row.effectEndTime
          ? row.effectEndTime.split(" ")[0]
          : null;
      });
    },
    handleAdd() {
      //   this.pageProp.tableData ? (this.pageProp.tableData = []) : null;
      //   if (this.tableData.length > 0) {
      //     this.pageProp.tableData = this.tableData;
      //   }
      this.addSignRangeVisible = true;
    },
    getTableList() {
      let param = {
        userId: this.userId,
      };
      postApi(`/hr/attend/checkrelation/query/range`, param).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data || [];
        }
      });
    },
    handleClose() {
      this.addPopupVisible = false;
    },
    handleClose0() {
      this.addSignRangeVisible = false;
    },
    handleClose1() {
      this.lostSignVisible = false;
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
</style>