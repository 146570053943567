<template>
  <div class="container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="记账列表">
      <dd-table
          ref="expenseApplyTable"
          tableRef="expenseApplyTable"
          :columns="columnsList"
          :data="tableDataSources"
          height="450"
          border
          show-page
          :isSelection="false"
          show-summary
          :summary-method="getSummaries"
      >
        <template #table-pagination>
          <dd-pagination
              :total="total"
              :page-size="pageSize"
              :page-number="pageNumber"
              @page-change="handlePageChange"
              align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";

export default {
  props: ["projectId"],
  computed: {},
  data() {
    return {
      activeName: "first",
      tableData: [],
      proTableData: [],
      manTableData: [],
      saleTableData: [],
      devTableData: [],
      expandRowKeys: [],
      //判断当前用户角色，是否为当前项目经理角色
      isCurPrjCmmManager: false,
      // 当前页码
      pageNumber: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      pageSize: 10,
      tableDataSources: [],
      formList: [
        {
          label: "单号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单号",
        },
        {
          label: "报账人",
          value: "createUserName",
          inputType: "text",
          placeholderText: "请输入报账人姓名",
        },
        {
          label: "承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          placeholderText: "请选择或搜索费用承担部门",
        },
        {
          label: "报账日期",
          value: "createDateArr",
          inputType: "date",
          dateType: "daterange",
        },
        {},
        {},
        {}
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "报账单号",
          key: "code",
          align: "center",
          width: 200,
        },
        {
          title: "报账人",
          key: "createUserName",
          align: "center",
        },
        {
          title: "金额(元)",
          key: "amount",
          align: "center",
          width: 200,
        },
        {
          title: "核定金额(元)",
          key: "reviewAmount",
          align: "center",
          width: 200,
        },
        {
          title: "单据状态",
          key: "stateName",
          align: "center",
          width: 100,
        },
        {
          title: "费用承担部门",
          key: "expenseBearDepName",
          align: "center",
          overflow: true,
        },
        {
          title: "报账日期",
          key: "createDateStr",
          align: "center",
        },
      ],
      columnsList: [],
    }
  },
  created() {},
  mounted() {
    this.columnsList = [...this.tableColumns];
    this.getConditionData();
    this.getDataList();
  },
  methods: {
    //条件数据查询
    getConditionData() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.depList = res.data.data;
        this.options = {
          value: "expenseBearDepId",
          optionsList: this.depList,
        };
        this.setOPtionsList(this.formList, this.options);
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleSearchEvent(obj) {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.formData = obj;
      this.getDataList(obj);
    },
    cancel() {
      this.$emit("diaChange", false);
    },
    getDataList(formdata) {
      let currentPage = this.pageNumber;
      let pageSize = this.pageSize;
      // formdata.assignId = this.assignId;

      postApi(
          `/finance/claims/audit/reimbursed/${currentPage}/${pageSize}`,
          {...formdata, "assignId": this.projectId}
      ).then((res) => {
        let {data} = res.data;
        this.total = data.total;

        if (data.records) {
          data.records.forEach((item) => {
            item.amount = parseFloat(item.amount).toFixed(2);
            item.reviewAmount = parseFloat(item.reviewAmount).toFixed(2);
          });
          this.tableDataSources = data.records;
        } else {
          this.tableDataSources = [];
        }
      });
    },
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getDataList();
    },
    getSummaries(param){
      const { columns, data } = param;
      const sums = [param];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index === 2 || index === 3) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          } else {
            sums[index] = " ";
          }
        } else {
          sums[index] = " ";
          return;
        }
      });

      sums[2] = parseFloat(sums[2]).toFixed(2);
      sums[3] = parseFloat(sums[3]).toFixed(2);

      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item .search-form-input {
  width:33%;
}

</style>
