<!--
 * @version: V1.0.0
 * @Author: hebojiang
 * @Date: 2023-04-20 11:13:12
 * @LastEditors: hechen
 * @LastEditTime: 2023-10-18 17:10:15
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\views\Finance\ExpenseApply\index.vue
 * @Descripttion: 
-->
<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="日记账列表" :height="tableHeight">
      <dd-table
        ref="expenseApplyTable"
        tableRef="expenseApplyTable"
        :columns="columnsList"
        :data="tableDataSources"
        :loading="loading"
        border
        show-page
        show-summary
        :summary-method="getSummaries"
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
            >新增
          </dd-button>
          <dd-button
            type="warning"
            icon="update"
            size="medium"
            @click="handleEdit"
            >编辑
          </dd-button>
          <dd-button
            type="danger"
            size="medium"
            icon="delete"
            :autoConfirm="autoConfirm"
            @click="handleDelete"
            @confirm="handleConfirmDelete"
            >删除
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <!-- <el-button
            icon="el-icon-download"
            circle
            type="info"
            @click="handleFileExport"
            size="small"
            title="下载"
          ></el-button> -->
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增/编辑页面弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      showBtn
      submitBtn
      restBtn
      :show="diaexpenseApply"
      title-name="费用申请单"
      @popup-close="handleCancel"
      @popup-save="handleSubmit(0, 'expenseBasicForm', 'expenseDetailForm')"
      @popup-submit="handleSubmit(1, 'expenseBasicForm', 'expenseDetailForm')"
      @popup-rest="handleRest()"
    >
      <add-pop
        v-if="diaexpenseApply"
        slot="main"
        ref="addPop"
        @taskBookIdNum="taskBookIdNum"
        :projectTree="projectTree"
        :expenseApply="expenseApply"
        :depList="depList"
        :expenseType="expenseType"
      ></add-pop>
    </dd-popup>
    <!-- 详情页面弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      :showBtn="false"
      :show="diaexpenseDetail"
      title-name="申请单详情"
      @popup-close="handleCancel"
    >
      <detail-pop
        v-if="diaexpenseDetail"
        slot="main"
        ref="detailPop"
        :expenseApply="expenseApply"
        :expenseType="expenseType"
        :billState="billState"
      ></detail-pop>
    </dd-popup>
    <!-- 通过项目查询日记账明细页面弹窗 -->
    <dd-popup
      width="90%"
      showBtn
      :defaultBtn="false"
      :show="detailByProjectPop"
      :title-name="detailByProjectTitle"
      @popup-close="handleCancel"
    >
      <apply-detail
        v-if="detailByProjectPop"
        slot="main"
        ref="detailPop"
        :projectId="detailProjectId"
        :amountType="amountType"
      ></apply-detail>
    </dd-popup>
    <el-popover
      v-if="popovervisible"
      placement="top-start"
      width="200"
      v-model="popovervisible"
    >
      <div>
        <h5 style="text-align: center">
          {{ this.popoverConfirm.type }}
        </h5>
        <h5 style="text-align: center">{{ this.popoverConfirm.tip }}</h5>
      </div>
      <div style="margin: 15px 0px; display: flex; justify-content: center">
        <el-button class="btn-search" @click="handleContinue"
          >{{ this.popoverConfirm.define }}
        </el-button>
        <el-button class="btn-reset" @click="handlePopoverCancel"
          >取消
        </el-button>
      </div>
    </el-popover>
    <el-popover
      v-if="savePopoverVisible"
      placement="top-start"
      width="200"
      v-model="savePopoverVisible"
    >
      <div>
        <h5 style="text-align: center">
          {{ this.savePopoverConfirm.type }}
        </h5>
        <h5 style="text-align: center">{{ this.savePopoverConfirm.tip }}</h5>
      </div>
      <div style="margin: 15px 0px; display: flex; justify-content: center">
        <el-button class="btn-search" @click="handleSaveContinue"
          >{{ this.savePopoverConfirm.define }}
        </el-button>
        <el-button class="btn-reset" @click="handleSavePopoverCancel"
          >取消
        </el-button>
      </div>
    </el-popover>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import addPop from "./components/addEditPop.vue";
import { postApi, getApi, delApi, loadApi } from "@/api/request";
import detailPop from "./components/expenseDetail.vue";
import detailProject from "./components/detailByProject.vue";
import applyDetail from "../ProjectBudget/BudgetApply/components/applyDetail";
import { mapState, mapActions } from "vuex";
import qs from "qs";
import { getNowDate } from "@/utils/date";
import { downloadBinaryFile } from "@/utils/index";

export default {
  name: "dayBookApply",
  $dynamicRoute: {
    name: "日记账申请",
    path: "/finance/dayBook/apply",
    // meta: {
    //   keepAlive: true,
    // },
  },
  components: {
    addPop,
    detailPop,
    detailProject,
    applyDetail,
  },
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  data() {
    return {
      taskBookObj: {},
      tableHeight: null,
      loading: true,
      //删除确认显隐
      autoConfirm: false,
      //弹窗加载条件
      diaexpenseApply: false,
      //子传父值（判断新增或者编辑或者详情，type;id）
      expenseApply: {},
      //表单数据
      formData: {},
      //费用类型（一、二、三级）
      expenseType: [],
      tableDataSources: [],
      //表选择数据
      multipleSelection: [],
      //部门数据
      depList: [],
      //通过项目查询所有日记账明细弹窗
      detailByProjectPop: false,
      detailByProjectTitle: "",
      detailProjectId: "",
      amountType: "",
      // 条件查询表单配置配置
      formList: [
        {
          label: "单号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单号",
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编码",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "费用类型",
          value: "expenseTypeCode",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "code",
            label: "name",
            checkStrictly: true,
          },
          placeholderText: "请选择费用类型",
        },
        {
          label: "承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          placeholderText: "请选择或搜索费用承担部门",
        },
        {
          label: "单据状态",
          value: "state",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
        },
        {},{},{}
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "单号",
          key: "code",
          align: "center",
          overflow: true,
          width: 180,
        },
        {
          title: '任务书编码',
          key: 'assignNum',
          align: "left",
          overflow: true,
          width: 180,
          href: false,
        },
        {
          title: '任务书名称',
          key: 'assignName',
          align: "left",
          overflow: true,
          width: 230,
          href: false,
        },
        {
          title: "项目编码",
          key: "prjCode",
          align: "left",
          overflow: true,
          width: 130,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "left",
          overflow: true,
          href: false,
          width: 230,
        },
        {
          title: "单据状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "金额（元）",
          key: "amount",
          align: "center",
          width: 100,
        },
        {
          title: "核定金额（元）",
          key: "reviewAmount",
          align: "center",
          width: 120,
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
          width: 100,
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
          overflow: true,
          width: 100,
        },
        {
          title: "二级费用类型",
          key: "twoLevelType",
          align: "center",
          width: 110,
          overflow: true,
        },
        {
          title: "业务类别",
          key: "firstExpense",
          align: "center",
          width: 100,
          overflow: true,
        },
        {
          title: "节点",
          key: "nodeName",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: 100,
          event: [
            {
              state: "default",
              icon: "el-icon-view",
              label: "查看详情",
              type: "info",
              method: (row) => {
                this.getDetail(row);
              },
            },
          ],
        },
      ],
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
      //详情查看显隐
      diaexpenseDetail: false,
      //是否继续记账弹窗显隐
      popovervisible: false,
      //是否继续记账弹窗内容
      popoverConfirm: {
        type: "",
        tip: "是否继续记账？",
        define: "继续",
      },
      //是否保存记账信息弹窗显隐
      savePopoverVisible: false,
      //是否保存记账信息弹窗内容
      savePopoverConfirm: {
        type: "",
        tip: "是否保存记账信息？",
        define: "确认",
      },
      //批量删除数据id集合
      deleteid: [],
      //选择或者级联配置数据对象
      options: {},
      // 记账人部门下拉框初始化
      applierDepIdOptions: {},
      drawerVisible: false,
      //筛选列表格头部配置
      columnsList: [],
      // 项目下拉树
      projectTree: [],
      // 单据状态
      billState: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
    this.getDataList();
    setTimeout(() => {
      this.getConditionData();
    }, 500);
  },
  methods: {
    //条件数据查询
    getConditionData() {
      //获取项目信息
      getApi(`/project/prj/get/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.projectTree = data.data;
          this.setOPtionsList(this.formList, options);
        }
      });
      //获取费用类型（一、二、三级树）
      getApi(`/finance/expense/getThFinTypeAll`)
        .then((res) => {
          this.expenseType = res.data.data;
          let options = {
            value: "expenseTypeCode",
            optionsList: this.expenseType,
          };
          this.setOPtionsList(this.formList, options);
        })
        .catch(() => {
          this.$message.error("请求出错");
        });
      //获取单据状态
      postApi(`/finance/dict/list`, qs.stringify({ typeCode: "xmfysqdzt" }))
        .then((res) => {
          if (res.data.code == 0) {
            this.billState = res.data.data;
            let options = {
              value: "state",
              optionsList: res.data.data,
            };
            this.setOPtionsList(this.formList, options);
          }
        })
        .catch(() => {
          this.$message.error("请求出错");
        });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.depList = res.data.data;
        this.options = {
          value: "expenseBearDepId",
          optionsList: this.depList,
        };
        this.setOPtionsList(this.formList, this.options);
      });
    },
    //获取接口数据
    getDataList() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      postApi(`/finance/applysheet/list`, params).then((res) => {
        let { data } = res.data;
        this.total = data.total;
        if (data.records) {
          data.records.forEach((item) => {
            item.amount = parseFloat(item.amount).toFixed(2);
            item.reviewAmount = parseFloat(item.reviewAmount).toFixed(2);
            item.finExpenseApplyAttachFiles &&
              item.finExpenseApplyAttachFiles.length &&
              item.finExpenseApplyAttachFiles.forEach((itm) => {
                itm.url = `/admin/sys-file/ssh/${itm.fileName}`;
              });
            item.AttachFiles = item.finExpenseApplyAttachFiles;
          });
          this.tableDataSources = data.records;
          this.loading = false;
        } else {
          this.tableDataSources = [];
        }
      });
      // this.$nextTick(() => {
      //     if (
      //         this.btnAuthorityList.indexOf("expenseApply:detailByProject") != -1
      //     ) {
      //       this.tableColumns.forEach((item) => {
      //         if (item.key == "projectName") {
      //           item.href = [
      //             {
      //               method: (row) => {
      //                 this.handleRowHref(row);
      //               },
      //             },
      //           ];
      //         }
      //       });
      //     }
      // });
    },
    //新增/编辑界面（expenseApply.type——0：新增，1：编辑 ）保存/提交事件（type——0：保存，1提交）
    handleSubmit(type, formName, formNames) {
      let flag = false;
      let flags = false;
      this.$refs.addPop.$refs[formName].$refs[formName].validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      this.$refs.addPop.$refs[formNames].$refs[formNames].validate((valids) => {
        if (valids) {
          flags = true;
        }
      });

      if (!this.$refs.addPop.fileListParam.length) {
        this.$refs.addPop.$refs.filesValid.innerText = "请先上传文件";
        this.$refs.addPop.$refs.filesValid.style.color = "#F56C6C";
        return;
      } else {
        this.$refs.addPop.$refs.filesValid.innerText = "";
      }

      if (flag && flags) {
        let params = {};
        let formBasicData = this.$refs.addPop.$refs[formName].formData;
        let formDetailData = this.$refs.addPop.$refs[formNames].formData;
        params = Object.assign(params, formBasicData, formDetailData);
        if (this.expenseApply.type == "0") {
          params.applier = sessionStorage.getItem("fullName");
          params.applierId = sessionStorage.getItem("userId");
          params.deptId = sessionStorage.getItem("depId");
        } else {
          params.id = this.expenseApply.id;
          params.deptId = this.$refs.addPop.editDataSources.deptId;
          params.applierId = this.$refs.addPop.editDataSources.applierId;
        }
        if (
          Array.isArray(params.expenseTypeId) &&
          params.expenseTypeId.length > 0
        ) {
          params.expenseTypeId =
            params.expenseTypeId[params.expenseTypeId.length - 1];
        }
        params.finExpenseApplyAttachFiles = this.$refs.addPop.fileListParam;
        params.expenseBearOrgId = sessionStorage.getItem("orgId");
        params.assignNum = this.taskBookObj.assignNum
        params.assignId = this.taskBookObj.assignId

        postApi(`/finance/applysheet/save/${type}`, params)
          .then((res) => {
            let { data } = res;
            if (data.code === 0) {
              // 预算不足时，提示是否保存数据
              if (
                data.data &&
                "string" == typeof data.data &&
                data.data.startsWith("-1___")
              ) {
                var idArr = [];
                idArr.push(data.data.split("___")[1]);
                this.deleteid = { ids: idArr };
                this.savePopoverVisible = true;

                this.savePopoverConfirm = {
                  type: "提示",
                  tip: "预算不足，是否保存记账信息",
                  define: "确认",
                };
              } else {
                this.$message.success(data.msg);

                if (this.expenseApply.type == "0") {
                  this.popovervisible = true;
                  if (type === 0) {
                    this.popoverConfirm = {
                      type: "保存成功!",
                      tip: "是否继续记账？",
                      define: "继续",
                    };
                  } else {
                    this.popoverConfirm = {
                      type: "提交成功!",
                      tip: "是否继续记账？",
                      define: "继续",
                    };
                  }
                } else {
                  this.handleCancel();
                  this.getDataList();
                }
              }
            }
          })
          .catch((error) => {
            // this.$message.error(error.response.data.msg);
            this.$message({
              message: error.response.data.msg,
              type: "error",
              duration: 6000,
            });
          });
      }
    },
    taskBookIdNum(e) {
      this.taskBookObj = e;
    },
    //新增/编辑页面重置
    handleRest() {
      let basicFormData = {
        applier: sessionStorage.getItem("fullName"),
        applyTime: getNowDate(),
        deptName: sessionStorage.getItem("depName"),
        projectId: "",
        expenseBearDepId: sessionStorage.getItem("depId"),
        expenseBearOrg: sessionStorage.getItem("orgName"),
      };
      let detailFormData = {
        expenseTypeId: "",
        residueAmount: "",
        amount: "",
        subjectMatter: "",
      };
      this.$refs.addPop.$refs["expenseBasicForm"].formData = basicFormData;
      this.$refs.addPop.$refs["expenseDetailForm"].formData = detailFormData;
      this.$refs.addPop.fileList = [];
      this.$refs.addPop.fileListParam = [];
    },
    //新增、编辑弹窗界面关闭
    handleCancel() {
      this.diaexpenseApply = false;
      this.diaexpenseDetail = false;
      this.detailByProjectPop = false;
      this.multipleSelection = [];
      this.$refs.expenseApplyTable.$refs.baseTable.clearSelection();
    },

    //日记账列表查询事件
    handleSearchEvent(obj) {
      this.current = 1;
      this.size = 10;
      if (obj.expenseTypeCode && obj.expenseTypeCode.length > 0) {
        obj.expenseTypeCode = obj.expenseTypeCode.slice(-1)[0];
      }
      this.formData = obj;
      this.getDataList();
    },
    getCloseState(val) {
      if (val == "0") {
        return "否";
      } else {
        return "是";
      }
    },
    //数值合并
    getSummaries(param) { // 自定义项表格合计方法
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = "合计";
                return;
                } else if (column.property == "amount" || column.property == "reviewAmount"
                ) {
                //如果是经费（正常的加减法）
                const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    var sum = 0;
                    if (!isNaN(value)) {
                        sum = Number(Number(prev) + Number(curr)).toFixed(2);
                        return sum;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += " ";
                }
                }
            });
            return sums
            },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleAdd() {
      this.diaexpenseApply = true;
      this.expenseApply = {
        type: "0",
      };
    },
    handleEdit() {
      if (this.multipleSelection.length != 1) {
        this.$message.warning("请选择一条数据！");
        return;
      }
      if (
        this.multipleSelection[0].state != "dtj-1" &&
        this.multipleSelection[0].state != "yth-1"
      ) {
        this.$message.warning("只能选择暂存或已退回状态数据编辑！");
        return;
      }
      // this.id = this.multipleSelection[0].id;
      this.diaexpenseApply = true;
      this.expenseApply = {
        type: "1",
        id: this.multipleSelection[0].id,
      };
    },
    handleDelete() {
      var idArr = [];
      this.autoConfirm = false;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请至少选择一条数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[i].state != "dtj-1") {
          this.$message.warning("只能选择暂存状态数据删除！");
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      this.deleteid = { ids: idArr };
      // if (this.deleteid.ids && this.deleteid.ids.length > 0) {
      this.autoConfirm = true;
      // }
      this.multipleSelection = [];
    },
    handleConfirmDelete(noPromptFlag) {
      postApi(`/finance/applysheet/delete`, this.deleteid)
        .then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$refs.expenseApplyTable.$refs.baseTable.clearSelection();

            if (!noPromptFlag) {
              this.$message.success("删除成功!");
            }
          }
          this.getDataList();
          this.autoConfirm = false;
        })
        .catch((error) => {
          this.$message.error(error.response.msg);
        });
    },
    //继续记账
    handleContinue() {
      let obj = {
        expenseTypeId: "",
        amount: "",
        subjectMatter: "",
      };
      this.popovervisible = false;
      this.$refs.addPop.$refs["expenseDetailForm"].formData = obj;
      this.$refs.addPop.fileList = [];
      this.$refs.addPop.fileListParam = [];
      this.expenseApply.id = undefined;
    },
    //继续记账取消
    handlePopoverCancel() {
      this.popovervisible = false;
      this.handleCancel();
      this.getDataList();
    },
    //继续记账
    handleSaveContinue() {
      this.savePopoverVisible = false;
      this.popovervisible = true;

      this.popoverConfirm = {
        type: "保存成功!",
        tip: "是否继续记账？",
        define: "继续",
      };
    },
    //继续记账取消
    handleSavePopoverCancel() {
      this.handleConfirmDelete(true);
      this.savePopoverVisible = false;
      this.handleCancel();
      this.getDataList();
    },
    // 日记账列表分页切换事件
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getDataList();
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        current: 1,
        size: this.size,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/expenseApply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    //通过项目查询日记账明细
    handleRowHref(row) {
      this.detailProjectId = row.projectId;
      this.detailByProjectTitle = "预算明细";
      this.detailByProjectPop = true;
      this.amountType = "totalAmount";
    },

    //查看详情事件
    getDetail(row) {
      this.diaexpenseDetail = true;
      this.expenseApply = {
        id: row.id,
      };
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    buildFiles(params, fileList) {
      let finExpenseApplyAttachFiles = [];

      fileList.forEach((item) => {
        if (item.id) {
          finExpenseApplyAttachFiles.push(item);
        } else {
          if (item[0] && item[0].bucketName) {
            item.forEach((item2) => {
              let itemTemp = {};
              itemTemp.name = item2.name;
              itemTemp.fileName = item2.fileName;
              itemTemp.url = item2.url;

              finExpenseApplyAttachFiles.push(itemTemp);
            });
          }
        }

        params.finExpenseApplyAttachFiles = finExpenseApplyAttachFiles;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-popover {
  left: 40%;
  top: 40%;
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 9999;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
}
</style>
