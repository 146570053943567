<template>
  <div class="container">
    <dd-card title="条件搜索" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <div class="table_box">
      <dd-table
        ref="baseTable_1"
        :columns="tableColumns"
        :data="tableData"
        @select-change="handleSelectionChange"
        :handleCheckDisable="handleCheckDisable"
        border
        :isSelection="false"
        :isSelectable="true"
      >
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </div>
  </div>
</template>
  
  <script>
import { postApi } from "@/api/request";
export default {
  name: "addPaymentPopup",
  props: ["pageProp"],
  data() {
    return {
      tableData: [],
      tableColumns: [
        {
          title: "单据编号",
          key: "paymentfnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "单据名称",
          key: "paymentfname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同编码",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "供应商",
          key: "fparta",
          align: "center",
          overflow: true,
        },
        {
          title: "本期实付金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "不含税金额",
          key: "fnotaxdataamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "税额",
          key: "ftax",
          align: "center",
          overflow: true,
        },
        {
          title: "付款期间",
          key: "fperiod",
          align: "center",
          overflow: true,
        },
        {
          title: "执行日期",
          key: "fbizdate",
          align: "center",
          overflow: true,
        },
        {
          title: "工程项目",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "制单人",
          key: "documenter",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 100,
          event: [
            {
              state: "default",
              label: "确认",
              type: "warning",
              width: 100,
              method: (row) => {
                this.handleAdd(row);
              },
              rules: (row) => {
                let show = true;
                if (row.disabledCheck == 1) {
                  show = false;
                } else {
                  show = true;
                }
                return show;
              },
            },
          ],
        },
      ],
      formList: [
        {
          label: "单据编号",
          value: "paymentfnumber",
          inputType: "text",
          placeholderText: "请输入单据编号进行搜索",
        },
        {
          label: "单据名称",
          value: "paymentfname",
          inputType: "text",
          placeholderText: "请输入单据名称进行搜索",
        },
        {
          label: "合同编码",
          value: "fnumber",
          inputType: "text",
          placeholderText: "请输入合同编码进行搜索",
        },
        {
          label: "合同名称",
          value: "fname",
          inputType: "text",
          placeholderText: "请输入合同名称进行搜索",
        },
        {},
        {},
        {},
      ],
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      selection_payment: [],
      // 每页的数据条数
      size: 10,
      searchData: {},
      tableHeight: null,
    };
  },
  created() {
    this.getPaymentList();
  },
  mounted() {},
  methods: {
    getPaymentList() {
      let params = { ...this.searchData };
      postApi(
        `/contract/execute/add/payment/${this.current}/${this.size}`,
        params
      ).then((res) => {
        if (res.data.code == 0) {
          if (this.pageProp.tableData && this.pageProp.tableData.length > 0) {
            this.pageProp.tableData.forEach((it) => {
              res.data.data.records.forEach((item) => {
                if (it.id == item.id) {
                  item.disabledCheck = 1;
                }
              });
            });
          }
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleAdd(row) {
      this.$emit("addPayment", row);
      this.getPaymentList();
    },
    handleSelectionChange(e) {
      this.selection_payment = e;
    },
    handleSearchEvent(e) {
      this.current = 1;
      this.searchData = e;
      this.getPaymentList();
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getPaymentList();
    },
    handleCheckDisable(row) {
      if (row.disabledCheck == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.table_box {
  margin-top: 20px;
}

/deep/ .el-table__fixed-body-wrapper {
  height: auto !important;
}
</style>