<template>
  <div>
    <dd-card title="基本信息" spacer>
      <dd-form
        :form-config="formList"
        ref="baseFoem"
        labelWidth="130px"
        :flex="3"
        :formRef="formBasicRef"
      ></dd-form>
    </dd-card>
    <dd-card title="费用明细">
      <dd-table
        max-height="360"
        ref="expenseApplyTable"
        :columns="tableColumns"
        :data="tableDataSources"
        :loading="loading"
        border
        show-page
        show-summary
        :summary-method="getSummaries"
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            size="medium"
            icon="add"
            @click="handleExpenseApply"
            >选择</dd-button
          >
          <dd-button
            type="danger"
            size="medium"
            icon="delete"
            @click="handleDelete"
            >移除</dd-button
          >
        </template>
      </dd-table>
    </dd-card>

    <dd-popup
      width="80%"
      height="80%"
      inPopup
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="expenseApplyVisible"
      title-name="费用申请单"
      @popup-close="expenseApplyVisible = false"
    >
      <dd-button slot="button" size="medium" @click="popupClose"
        >取消</dd-button
      >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleApplySubmit"
        >确认</dd-button
      >

      <expense-apply
        v-if="expenseApplyVisible"
        slot="main"
        ref="expenseApply"
        :selectDataObj="selectDataObj"
        :filterSelection="filterSelection"
      ></expense-apply>
    </dd-popup>
  </div>
</template>

<script>
import { getNowDate } from "@/utils/date";
import expenseApply from "./ExpenseApplyPop.vue";
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  components: {
    expenseApply,
  },
  props: {
    rowEditId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      depList: [],
      projectList: [],
      loading: false,
      formList: [
        {
          label: "报账日期",
          value: "applyDate",
          inputType: "text",
          disabled: true,
          defaultValue: getNowDate(),
          placeholderText: "请选择报账日期",
        },
        {
          label: "报账人",
          value: "applier",
          inputType: "text",
          defaultValue: this.getStore({name:"fullName"}),
          placeholderText: "请输入报账人",
          disabled: true,
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
          change: this.changeProject,
          rules: [
            {
              required: true,
              message: "请选择项目",
              trigger: "blur",
            },
          ],
        },
        {
          label: "项目编码",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索编码",
          optionsListConfig: {
            name: "prjCode",
            code: "projectId",
          },
          change: this.changeProject,
          rules: [
            {
              required: true,
              message: "请选择项目编码",
              trigger: "blur",
            },
          ],
        }
      ],
      tableColumns: [
        {
          title: "申请单号",
          key: "code",
          align: "center",
          width: 200,
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
          overflow: true,
        },
        {
          title: "任务书编码",
          key: "assignNum",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
          width: 300,
        },
        {
          title: "记账日期",
          key: "applyTime",
          align: "center",
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
        },
        {
          title: "金额(元)",
          key: "amount",
          align: "center",
        },
        {
          title: "核定金额(元)",
          key: "reviewAmount",
          align: "center",
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "费用承担部门",
          key: "expenseBearDeptName",
          align: "center",
        },
        {
          title: "操作",
          key: "file",
          align: "center",
          width: 80,
          event: [
            {
              label: "移除",
              method: (row) => {
                this.handleRemove(row);
              },
            },
          ],
        },
      ],
      tableDataSources: [],
      //费用申请单显隐
      expenseApplyVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      //是否打开删除确认
      autoConfirm: false,
      //默认选择状态
      filterSelection: [],
      formBasicRef: "formBasic",
      checked: false,
      options: {},
      // 所选申请单集合
      multipleSelectionProject: [],
      // 费用类别承担部门集合
      typeSelectData: [],
      selectDataObj: {}
    };
  },
  mounted() {
    this.getDataType();
    if(this.rowEditId) {
      this.getDetail();
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取下拉选择数据
    getDataType() {
      // 获取项目列表数据
      postApi(`/finance/claims/unaudit/list/1/100`).then((res) => {
        let {data} = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data.records,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 项目选择事件
    changeProject(projectId) {
      this.selectDataObj.projectId = projectId;
      let expenseType = {
        label: "费用类别",
        value: "expenseTypeCode",
        inputType: "select",
        optionsList: [],
        defaultValue: "",
        placeholderText: "请选择费用类别",
        optionsListConfig: {
          name: "name",
          code: "expenseTypeCode",
        },
        change: this.changeExpenseType,
        rules: [
          {
            required: true,
            message: "请选择费用类别",
            trigger: "blur",
          },
        ],
      }
      this.formList.forEach((item,index) => {
        if(item.value == "expenseTypeCode") {
          this.formList.splice(index,1)
        }
      })
      postApi(`/finance/claims/get/type`, {
					projectId
				}).then(res => {
					if (res && res.data && res.data.code == 0) {
            expenseType.optionsList = res.data.data;
            this.typeSelectData = res.data.data;
            this.formList.push(expenseType);
            this.formList.forEach((item) => {
              if(item.value == 'projectId') {
                item.defaultValue = projectId
              }
            })
					}
				})
    },
    // 业务类别选择事件
    changeExpenseType(val) {
      console.log('val', val)
      this.selectDataObj.expenseTypeCode = val;
      let expenseBearDep = {
          label: "费用承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          placeholderText: "请选择费用承担部门",
          optionsListConfig: {
            name: "expenseBearDeptName",
            code: "expenseBearDepId",
          },
          change: this.changeExpenseBearDep,
          rules: [
            {
              required: true,
              message: "请选择费用承担部门",
              trigger: "blur",
            },
          ],
        }
      this.formList.forEach((item,index) => {
        if(item.value == "expenseBearDepId") {
          this.formList.splice(index,1)
        }
      })
      this.formList.push(expenseBearDep);
      this.typeSelectData.forEach((item) => {
        if(item.expenseTypeCode == val) {
          expenseBearDep.optionsList = item.expenseDepList
        }
      })
      this.formList.forEach((item) => {
        if(item.value == 'projectId') {
          item.defaultValue = this.selectDataObj.projectId
        }
        if(item.value == 'expenseTypeCode') {
          item.defaultValue = val
        }
      })
    },
    // 费用承担部门选择事件
    changeExpenseBearDep(val) {
      this.selectDataObj.expenseBearDepId = val;
    },
    // 编辑回显详情查询
    getDetail() {
      getApi(`/finance/claims/update/${this.rowEditId}`)
      .then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let obj = {
            applier: data.data.user,
            applyDate: data.data.createDate,
            code: data.data.code,
            expenseTypeCode: data.data.expenseTypeCode,
            expenseBearDepId: data.data.expenseBearDepId,
            projectId: data.data.projectId,
            prjCode: data.data.prjCode,
            projectName: data.data.projectName
          };
          this.$refs.baseFoem.formData = obj;
          this.tableDataSources = data.data.detail;
          this.getJbxxDetails(data.data)
        }
      })
      .catch((error) => {
        this.$message.error(error.response.data.msg);
      });
    },
    getJbxxDetails(data) {
      this.selectDataObj.projectId = data.projectId;
      this.selectDataObj.expenseTypeCode = data.expenseTypeCode;
      this.selectDataObj.expenseBearDepId = data.expenseBearDepId;
      let expenseType = {
        label: "费用类别",
        value: "expenseTypeCode",
        inputType: "select",
        optionsList: [],
        defaultValue: "",
        placeholderText: "请选择费用类别",
        optionsListConfig: {
          name: "name",
          code: "expenseTypeCode",
        },
        rules: [
          {
            required: true,
            message: "请选择费用类别",
            trigger: "blur",
          },
        ],
      }
      
      this.formList.forEach((item,index) => {
        if(item.value == "expenseTypeCode") {
          this.formList.splice(index,1)
        }
      })
      postApi(`/finance/claims/get/type`, {
        projectId: data.projectId
				}).then(res => {
					if (res && res.data && res.data.code == 0) {
            expenseType.optionsList = res.data.data;
            this.typeSelectData = res.data.data;
            this.formList.push(expenseType);
            this.formList.forEach((item) => {
              if(item.value == 'projectId') {
                item.defaultValue = data.projectId
              }
            })
					}

          let val = data.expenseTypeCode
            let expenseBearDep = {
            label: "费用承担部门",
            value: "expenseBearDepId",
            inputType: "select",
            optionsList: [],
            defaultValue: "",
            placeholderText: "请选择费用承担部门",
            optionsListConfig: {
              name: "expenseBearDeptName",
              code: "expenseBearDepId",
            },
            rules: [
              {
                required: true,
                message: "请选择费用承担部门",
                trigger: "blur",
              },
            ],
          }

        this.formList.forEach((item,index) => {
          if(item.value == "expenseBearDepId") {
            this.formList.splice(index,1)
          }
        })
        this.formList.push(expenseBearDep);
        this.formList.forEach((item) => {
          if(item.value == 'expenseTypeCode') {
            item.defaultValue = val
          }

          if(item.value == 'expenseBearDepId') {
            item.defaultValue = data.expenseBearDepName
          }
          item.disabled = true
        })
				})
    },
    //选择事件---打开费用申请单列表
    handleExpenseApply() {
      if(!this.selectDataObj.projectId) {
        this.$message.warning('请选择项目')
        return
      }
      if(!this.selectDataObj.expenseTypeCode) {
        this.$message.warning('请选择业务类别')
        return
      }
      if(!this.selectDataObj.expenseBearDepId) {
        this.$message.warning('请选择费用承担部门')
        return
      }
      this.filterSelection = [];
      this.expenseApplyVisible = true;
      //默认选择数据
      this.filterSelection = this.tableDataSources;
    },
    //关闭费用申请单事件
    popupClose() {
      this.expenseApplyVisible = false;
    },
    //单行删除
    handleRemove(row) {
      // this.$confirm("是否删除此行数据？", "提示", {
      //   confirmButtonClass: "btnsearch",
      //   confirmButtonText: "确认",
      //   cancelButtonClass: "btnreset",
      //   cancelButtonText: "取消",
      //   showClose: true,
      //   type: "warning",
      // }).then(() => {
      this.filterSelection = [];
      this.getDelete(row);
      // });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //全选事件
    selectAll() {
      this.checked = !this.checked;
      this.handleSelect(this.tableDataSources, this.checked);
    },
    //全选事件选中状态
    handleSelect(data, flag) {
      data.forEach((row) => {
        this.$refs.moreTable.$refs.moreTable.toggleRowSelection(row, flag);
        if (row.children) {
          this.handleSelect(row.children);
        }
      });
    },
    //批量删除事件
    handleDelete() {
      let deleteSelection = this.multipleSelection;
      if (deleteSelection.length < 1) {
        this.$message.warning("请至少选择一条数据!");
      } else {
        // this.autoConfirm = true;
        this.handleConfirmDelete();
      }
    },
    //是否确认删除
    handleConfirmDelete() {
      this.filterSelection = [];
      //批量删除费用明细
      this.multipleSelection.map((item) => {
        this.getDelete(item);
      });
      //清除选择状态
      this.multipleSelection = [];
      this.$refs.expenseApplyTable.$refs.baseTable.clearSelection();
      // this.autoConfirm = false;
    },
    //删除费用报账单费用明细数据
    getDelete(data) {
      this.tableDataSources.map((item, index) => {
          if (item.id == data.id) {
            this.tableDataSources.splice(index, 1);
          } else {
            this.filterSelection.push(item);
          }
      });
    },
    //选择费用类型，确认事件
    handleApplySubmit() {
      this.multipleSelectionProject = this.$refs.expenseApply.multipleSelection;
      if(this.multipleSelectionProject && this.multipleSelectionProject.length == 0) {
        this.$message.warning("请至少选择一条数据进行确认操作！");
        return;
      }
      this.expenseApplyVisible = false;
      this.getTableDataList();
      // let filterSelectionIds = [];
      // let multipleSelectionIds = [];
      // let filterSelectionProject = [];
      // let multipleSelectionProject = [];
      // let projectId = [];
      // let expenseTypeSet = new Set();


      //费用报账单--费用明细默认选择数据id集合
      // this.$refs.expenseApply.filterSelection.map((item) => {
      //   filterSelectionIds.push(item.id);
      //   filterSelectionProject.push(item.projectId);

      //   expenseTypeSet.add(item.firstExpense);
      // });
      //费用申请单--当前选择数据id集合
      // this.$refs.expenseApply.multipleSelection.map((item) => {
      //   multipleSelectionIds.push(item.id);
      //   multipleSelectionProject.push(item.projectId);

      //   expenseTypeSet.add(item.firstExpense);
      // });

      // 只能选择相同业务类别的日记账进行报账
      // if(expenseTypeSet.size > 1){
      //   this.$message.warning("只能选择相同业务类别的申请单进行报销!");
      //   return;
      // }

      // 
    },
    getTableDataList() {
      // this.autoConfirm = false;
      let expenseApplyData = [];
      //判断是否选择数据---费用报账单费用明细数据为默认选择数据和费用申请单选择数据合集
      if (this.multipleSelectionProject) {
        expenseApplyData = this.multipleSelectionProject;
        if (this.filterSelection.length != 0) {
          let arr = this.filterSelection.concat(expenseApplyData);
          for (var i = 0; i < arr.length; i++) {
            for (var j = 0; j < arr.length; j++) {
              if (arr[i].id == arr[j].id && i != j) {
                //将后面重复的数删掉
                arr.splice(j, 1);
              }
            }
          }
          expenseApplyData = arr;
        }
      } else {
        expenseApplyData = this.filterSelection;
      }

      let ids = [];
      expenseApplyData.forEach((item) => {
        ids.push(item.id);
      });
      // if (ids.length < 1) {
      //   this.$message.warning("请至少选择一条数据!");
      //   this.tableDataSources = [];
      //   return;
      // }
      let idsform = {
        ids: ids,
      };
      this.loading = true;
      postApi(`/finance/claims/detail`, idsform)
        .then((res) => {
          if (res.data.code == 0 && res.data.data) {
            if (res.data.data.length > 0) {
              res.data.data.forEach((item) => {
                item.amount = parseFloat(item.amount).toFixed(2);
              });
            }
            this.tableDataSources = res.data.data;
            this.loading = false;
          }
        })
        // .catch(() => {
        //   this.$message.error("请求出错");
        // });
    },
    //数值合并
    getSummaries(param) {
      this.$nextTick(() => {
        let { expenseApplyTable } = this.$refs.expenseApplyTable.$refs;
        if (expenseApplyTable) {
          expenseApplyTable.doLayout();
        }
      });
      const { columns, data } = param;
      const sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index === 7 || index === 8) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          } else {
            sums[index] = " ";
          }
        } else {
          sums[index] = " ";
          return;
        }
      });
      sums[7] = parseFloat(sums[7]).toFixed(2);
      sums[8] = parseFloat(sums[8]).toFixed(2);
      return sums;
    },
  },
};
</script>

<style>
</style>