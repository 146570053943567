<template>
  <div class="container">
    <el-tabs
      v-model="activeName"
      type="card"
      class="tabs_card3333"
      @tab-click="handleClick"
    >
      <el-tab-pane label="汇总说明" name="汇总说明">
        <dd-card title="" spacer>
          <dd-search-form
            :form-config="formList_detail"
            :labelWidth="'190px'"
            @search-click="handleSumSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </dd-card>
        <dd-card title="">
          <dd-table
            ref="baseTable_1"
            :columns="tableColumns_sum"
            :data="tableData_sum"
            border
            :isSelection="false"
            :loading="loading"
            max-height="500"
          >
            <template #table-pagination>
              <dd-pagination
                v-show="sumtotal > 0"
                :total="sumtotal"
                :page-size="sumsize"
                :page-number="sumcurrent"
                @page-change="handleSumPageChange"
                align="right"
              >
              </dd-pagination>
            </template>
          </dd-table>
        </dd-card>
      </el-tab-pane>
      <el-tab-pane label="异常打卡" name="异常打卡">
        <dd-card title="" spacer>
          <dd-search-form
            :form-config="formList_detail"
            :labelWidth="'190px'"
            @search-click="handleExceptionSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </dd-card>
        <dd-card title="">
          <dd-table
            :columns="tableColumns_exception"
            :data="tableData_exception"
            border
            :isSelection="false"
            :loading="loading"
            max-height="500"
          >
            <template #table-pagination>
              <dd-pagination
                v-show="exceptiontotal > 0"
                :total="exceptiontotal"
                :page-size="exceptionsize"
                :page-number="exceptioncurrent"
                @page-change="handleExceptionPageChange"
                align="right"
              >
              </dd-pagination>
            </template>
          </dd-table>
        </dd-card>
      </el-tab-pane>
      <el-tab-pane label="打卡流水" name="打卡流水">
        <dd-card title="" spacer>
          <dd-search-form
            :form-config="formList_detail"
            :labelWidth="'190px'"
            @search-click="handleSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </dd-card>
        <dd-card title="">
          <dd-table
            :columns="tableColumns_detail"
            :data="tableData_detail"
            border
            :isSelection="false"
            :loading="loading"
            max-height="500"
          >
            <template #table-pagination>
              <dd-pagination
                v-show="total > 0"
                :total="total"
                :page-size="size"
                :page-number="current"
                @page-change="handlePageChange"
                align="right"
              >
              </dd-pagination>
            </template>
          </dd-table>
        </dd-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
export default {
  name: "sumDetail",
  props: ["pageProp"],
  components: {},
  data() {
    return {
      activeName: "汇总说明",
      detailVisible: false,
      loading: false,
      tableColumns_sum: [
        {
          title: "工号",
          key: "username",
          align: "center",
          overflow: true,
        },
        {
          title: "姓名",
          key: "userFullName",
          align: "center",
          overflow: true,
        },
        {
          title: "所属部门",
          key: "depName",
          align: "center",
          overflow: true,
        },
        {
          title: "应打卡天数",
          key: "workDay",
          align: "center",
          overflow: true,
        },
        {
          title: "正常打卡天数",
          key: "normalDay",
          align: "center",
          overflow: true,
        },
      ],
      tableColumns_exception: [
        {
          title: "工号",
          key: "username",
          align: "center",
          overflow: true,
        },
        {
          title: "姓名",
          key: "userFullName",
          align: "center",
          overflow: true,
        },
        {
          title: "所属部门",
          key: "depName",
          align: "center",
          overflow: true,
        },
        {
          title: "异常打卡日期",
          key: "signDate",
          align: "center",
          overflow: true,
        },
        {
          title: "上午打卡",
          key: "signMorningStatusName",
          align: "center",
          overflow: true,
        },
        {
          title: "下午打卡",
          key: "signAfternoonStatusName",
          align: "center",
          overflow: true,
        },
      ],
      tableColumns_detail: [
        {
          title: "工号",
          key: "username",
          align: "center",
          overflow: true,
        },
        {
          title: "姓名",
          key: "userFullName",
          align: "center",
          overflow: true,
        },
        {
          title: "签到时间",
          key: "signTime",
          align: "center",
          overflow: true,
        },
        {
          title: "签到节点",
          key: "signTimeNode",
          align: "center",
          overflow: true,
        },
        {
          title: "签到项目名称",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "签到类型",
          key: "signTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "签到状态",
          key: "statusName",
          align: "center",
          overflow: true,
        },
        {
          title: "所属考勤月",
          key: "attendMonth",
          align: "center",
          overflow: true,
        },
        {
          title: "所属部门",
          key: "deptName",
          align: "center",
          overflow: true,
        },
        {
          title: "审核状态",
          key: "verifyStatusName",
          align: "center",
          overflow: true,
        },
        {
          title: "审核人",
          key: "auditName",
          align: "center",
          overflow: true,
        },
        {
          title: "审核日期",
          key: "auditDate",
          align: "center",
          overflow: true,
        },
      ],
      formList_detail: [
        {
          label: "工号",
          value: "username",
          inputType: "text",
          placeholderText: "请输入工号进行搜索",
        },
        {
          label: "姓名",
          value: "userFullName",
          inputType: "text",
          placeholderText: "请输入姓名进行搜索",
        },
        {
          label: "部门",
          value: "depId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择部门进行搜索",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
      ],
      tableData_sum: [],
      tableData_exception: [],
      tableData_detail: [],
      total: 0,
      current: 1,
      size: 10,
      sumtotal: 0,
      sumcurrent: 1,
      sumsize: 10,
      exceptiontotal: 0,
      exceptioncurrent: 1,
      exceptionsize: 10,
      formData: {},
      sumformData: {},
      excetionformData: {},
    };
  },
  created() {},
  mounted() {
    this.getAttendDetail();
    this.getSumDetail();
    this.getException();
    this.getDeptList();
  },
  methods: {
    handleClick(e) {},
    getDeptList() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "depId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList_detail, options);
        }
      });
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getAttendDetail() {
      let { formData } = this;
      let param = {
        attendMonth: this.pageProp.month,
        ...formData,
      };
      postApi(
        `/hr/attend/monthinfo/page2/${this.current}/${this.size}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData_detail = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    getSumDetail() {
      let { sumformData } = this;
      let param = {
        id: this.pageProp.id,
        ...sumformData,
      };
      postApi(
        `/hr/attendsignsum/sumdetail/page/${this.sumcurrent}/${this.sumsize}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData_sum = res.data.data.records;
          this.sumtotal = res.data.data.total;
        }
      });
    },
    getException() {
      let { excetionformData } = this;
      let param = {
        id: this.pageProp.id,
        ...excetionformData,
      };
      postApi(
        `/hr/attendsignsum/exception/page/${this.exceptioncurrent}/${this.exceptionsize}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData_exception = res.data.data.records;
          this.exceptiontotal = res.data.data.total;
        }
      });
    },
    handleExceptionPageChange(size, number) {
      this.exceptioncurrent = number;
      this.exceptionsize = size;
      this.getException();
    },
    handleSumPageChange(size, number) {
      this.sumcurrent = number;
      this.sumsize = size;
      this.getSumDetail();
    },
    handlePageChange(size, number) {
      this.size = size;
      this.current = number;
      this.getAttendDetail();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getAttendDetail();
    },
    handleSumSearchEvent(obj) {
      this.sumformData = obj;
      this.sumcurrent = 1;
      this.getSumDetail();
    },
    handleExceptionSearchEvent(obj) {
      this.excetionformData = obj;
      this.exceptioncurrent = 1;
      this.getException();
    },
  },
};
</script>

<style lang="less" scoped>
.tabs_card3333 {
  /deep/ .el-tabs__item {
    width: 33% !important;
  }
}
</style>