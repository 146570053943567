<template>
  <div class="container">
    <div class="table_box">
      <dd-table
        ref="baseTable_1"
        :columns="tableColumns"
        :data="tableData"
        :isSelection="false"
        border
        :loading="loading"
        max-height="560"
      >
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </div>
  </div>
</template>
    
    <script>
import { getApi, postApi } from "@/api/request";
export default {
  name: "detailPop",
  props: ["pageProp"],
  data() {
    return {
      loading: false,
      tableColumns: [
        {
          title: "本月考勤日",
          key: "signDate",
          align: "center",
          overflow: true,
        },
        {
          title: "上午打卡",
          key: "signMorningStatusName",
          align: "center",
          overflow: true,
        },
        {
          title: "下午打卡",
          key: "signAfternoonStatusName",
          align: "center",
          overflow: true,
        },
      ],
      tableData: [{}],
      total: 0,
      current: 1,
      size: 10,
    };
  },
  created() {
    this.getTableList();
  },
  mounted() {},
  methods: {
    getTableList() {
      let param = {
        month: this.pageProp.month,
      };
      postApi(
        `/hr/attendsignsum/personal/detailpage/${this.current}/${this.size}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handlePageChange(size, number) {
      this.size = size;
      this.current = number;
      this.getTableList();
    },
  },
};
</script>
    
    <style lang="less" scoped>
</style>