<template>
  <div class="container">
    <el-tabs
      v-model="activeName"
      type="card"
      class="tabs_card3333"
      @tab-click="handleClick"
    >
      <el-tab-pane label="合同列表" name="合同列表">
        <contractPopup :pageProp="pageProp" ref="contractRef"></contractPopup>
      </el-tab-pane>

      <el-tab-pane label="付款列表" name="付款列表">
        <paymentPopup :pageProp="pageProp"></paymentPopup>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
import contractPopup from "./contractPopup.vue";
import paymentPopup from "./paymentPopup.vue";
export default {
  name: "detailPopup",
  props: ["pageProp"],
  components: { contractPopup, paymentPopup },
  data() {
    return {
      activeName: '合同列表',
      loading: false,
      saveBtn: false,
      tableColumns: [
        {
          title: "采购年度",
          key: "cfyear",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "乙方单位",
          key: "fpartbname",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同类型",
          key: "fcontracttypename",
          align: "center",
          overflow: true,
        },
        {
          title: "采购总金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同变更后总金额",
          key: "ftotalamount",
          align: "center",
          overflow: true,
        },
        {
          title: "采购累计结算金额",
          key: "fsettlebalance",
          align: "center",
          overflow: true,
        },
        {
          title: "采购累计实付金额",
          key: "frealamount",
          align: "center",
          overflow: true,
        },
        {
          title: "本期采购计划支付",
          key: "paymentAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "本期采购计划付款节点说明",
          key: "nodeDescription",
          align: "center",
          overflow: true,
        },
        {
          title: "采购付款条件及说明",
          key: "conditionInstruction",
          align: "center",
          overflow: true,
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
          overflow: true,
        },
        {
          title: "本期付款后采购累计已付",
          key: "currentPaymentAfterPaid",
          align: "center",
          overflow: true,
        },
        {
          title: "本期付款后采购剩余未付",
          key: "currentPaymentAfterUnpaid",
          align: "center",
          overflow: true,
        },
        {
          title: "本期实际付款金额",
          key: "actualAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "关联项目编码",
          key: "prjCode",
          align: "center",
          overflow: true,
        },
        {
          title: "关联项目名称",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "关联销售合同编号",
          key: "saleContractCode",
          align: "center",
          overflow: true,
        },
        {
          title: "关联销售合同名称",
          key: "saleContractName",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同额",
          key: "saleContractAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同变更后合同额",
          key: "saleContractAfterModAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "销售已回款",
          key: "saleContractRefund",
          align: "center",
          overflow: true,
        },
      ],
      tableData: [],
      formList: [
        {
          label: "单据编码",
          defaultValue: "",
          value: "code",
          disabled: true,
          inputType: "text",
          placeholderText: "请输入单据编码",
        },
        {
          label: "单据名称",
          defaultValue: "",
          value: "name",
          inputType: "text",
          disabled: true,
          placeholderText: "请输入单据名称",
        },

        {
          label: "单据状态",
          value: "statusName",
          disabled: true,
          inputType: "text",
          defaultValue: "",
          placeholderText: "请选择单据状态",
        },
        {
          label: "年度",
          value: "year",
          dateType: "year",
          format: "yyyy",
          displayFormat: "yyyy",
          defaultValue: "",
          inputType: "otherDate",
          disabled: true,
          placeholderText: "请选择年度",
        },
        {
          label: "月份",
          value: "month",
          defaultValue: "",
          format: "MM月",
          displayFormat: "MM月",
          dateType: "month",
          disabled: true,
          inputType: "otherDate",
          placeholderText: "请选择月份",
        },
        {
          label: "本期计划付款金额",
          value: "planAmount",
          inputType: "text",
          defaultValue: "",
          disabled: true,
          placeholderText: "请输入本期计划付款金额",
        },
        {
          label: "本期实际付款金额",
          value: "actualAmount",
          inputType: "text",
          defaultValue: "",
          disabled: true,
          placeholderText: "本期实际付款金额",
        },
        {
          label: "执行周期",
          value: "executeDuration",
          inputType: "text",
          defaultValue: "",
          disabled: true,
          placeholderText: "执行周期",
        },
      ],
      formList_remark: [
        {
          label: "付款备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          show: true,
          disabled: true,
          placeholderText: "请输入付款备注",
        },
        {
          label: "审批意见",
          value: "approval",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          show: true,
          disabled: true,
          placeholderText: "请输入审批意见",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleClick(tab, e) {
      if (tab.index == 0) {
        getApi(`/contract/execute?id=${this.pageProp.id}`).then((res) => {
          if (res.data.code == 0) {
            let { data } = res;
            this.$refs.contractRef.tableData = data.data.scheduleVOS || [];
            let sum = 0;
            this.$refs.contractRef.tableData.forEach((item) => {
              sum += Number(item.actualAmount) || 0;
            });
            this.$refs.contractRef.$refs.form_addEditPopup.formData.actualAmount =
              sum.toFixed(2);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tabs_card3333 {
  /deep/ .el-tabs__item {
    width: 50% !important;
  }
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .table_cell {
  .backgroundColor {
    background-color: #f5d7c8 !important;
  }
}
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
</style>