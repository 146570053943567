<template>
  <div>
      <dd-card title="基本信息">
        <dd-form
          :form-config="formListCheck"
          :flex="3"
          ref="reportBillBasicForm"
          :formRef="formBasicRef"
        ></dd-form>
      </dd-card>
      <dd-card title="" titleStyle="height: 0px" v-if="this.popupType == 'Audit'">
        <dd-form
          :form-config="noteFormList"
          :flex="2"
          ref="reportBillNoteForm"
          :formRef="formNoteRef"
        ></dd-form>
      </dd-card>
      <dd-card title="费用合计">
        <dd-table
          v-if="this.popupType == 'Check' || this.popupType == 'Audit'"
          :isSelection="false"
          :topBtn="false"
          max-height="240"
          border
          :columns="totaltableColumns"
          :data="totaltableDataSources"
          show-summary
          :summary-method="getExpenseSummariesTotal"
        >
        </dd-table>
      </dd-card>
      <dd-card title="费用明细">
        <dd-table
          v-if="this.popupType == 'Audit'"
          :isSelection="false"
          :topBtn="false"
          border
          max-height="420"
          :columns="tableColumns"
          :data="tableDataSources"
          show-summary
          :summary-method="getExpenseSummaries"
        >
        </dd-table>
        <dd-table
          v-else
          max-height="420"
          :topBtn="false"
          border
          :isSelection="false"
          :columns="tableColumnsCheck"
          :data="tableDataSources"
          show-summary
          :summary-method="getExpenseSummaries"
        >
        </dd-table>
      </dd-card>
      <dd-card title="操作日志">
        <dd-table
          :topBtn="false"
          :columns="logTableColumns"
          :data="logTableDataSources"
          :isSelection="false"
          border
          show-page
        >
        </dd-table>
      </dd-card>
    <!-- 申请单详情弹窗 -->
    <dd-popup
      inPopup
      width="90%"
      height="90%"
      :show="diaexpenseDetail"
      title-name="申请单详情"
      @popup-close="diaexpenseDetail = false"
    >
      <detail-pop
        v-if="diaexpenseDetail"
        slot="main"
        ref="detailPop"
        :expenseApply="expenseApply"
      ></detail-pop>
    </dd-popup>

    <!-- 项目预算详情弹窗 -->
    <dd-popup
      inPopup
      v-if="showApplyList"
      width="90%"
      height="90%"
      title-name="项目预算详情"
      :show="showApplyList"
      @popup-close="showApplyList = false"
    >
      <applyDetail
        slot="main"
        :projectId="rowCheck.projectId"
        :applyId="false"
        amountType="totalAmount"
      ></applyDetail>
    </dd-popup>
  </div>
</template>

<script>
import { formatDate } from "@/utils/date";
// import applyDetail from "./applyDetail";
import { postApi, getApi } from "@/api/request";
import applyDetail from "../../ProjectBudget/BudgetApply/components/applyDetail";
import detailPop from "../../../ExpenseApply/components/expenseDetail.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "audit-pop",
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  components: {
    detailPop,
    applyDetail,
    // scopeTable,
  },
  props: {
    popupType: {
      type: String,
      default: "Audit",
    },
    rowAuditId: {
      type: String,
      default: null,
    },
    rowCheck: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //审核基本信息表单名称
      formBasicRef: "reportBillBasicForm",
      //审核备注信息表单
      formNoteRef: "reportBillNoteForm",
      //基本信息跳转预算详情页面
      showApplyList: false,
      //费用申请单跳转页面
      diaexpenseDetail: false,
      //子传父值（判断新增或者编辑或者详情，type;id）
      expenseApply: {},
      //备注表单内容
      noteFormList: [
        {
          label: "审核备注",
          value: "auditDescription",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          rows: "2",
          placeholderText: "请输入审核备注",
        },
        {
          label: "退回原因",
          value: "description",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          rows: "2",
          placeholderText: "请输入退回原因",
        },
      ],
      formListCheck: [
        {
          label: "报账单号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入报账单号",
          disabled: true,
        },
        {
          label: "报账人",
          value: "createUser",
          inputType: "text",
          placeholderText: "请输入报账人",
          disabled: true,
        },
        {
          label: "项目编码",
          value: "prjCode",
          inputType: "text",
          defaultValue: "",
          disabled: true,
        },
        {
          label: "项目名称",
          value: "projectName",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          disabled: true,
          autosize: true,
          click: (val) => {
            this.handleShowList();
          }
        },
        {
          label: "金额(元)",
          value: "amount",
          inputType: "text",
          placeholderText: "请输入金额",
          disabled: true,
        },
        {
          label: "核定金额(元)",
          value: "reviewAmount",
          inputType: "text",
          placeholderText: "请输入核定金额",
          disabled: true,
        },
        {
          label: "报账日期",
          value: "createDate",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "费用承担部门",
          value: "expenseBearDepName",
          inputType: "text",
          disabled: true,
          placeholderText: "请选择或搜索费用承担部门",
        },
      ],
      // 费用合计
      totaltableColumns: [
        {
          title: "业务类别",
          key: "oneLevelType",
          align: "center",
          width: 150,
        },
        {
          title: "二级费用类型",
          key: "twoLevelType",
          align: "center",
          width: 150,
        },
        {
          title: "二级费用类型编码",
          key: "twoLevelCode",
          align: "center",
          width: 150,
        },
        {
          title: "核定金额(元)",
          key: "sum",
          align: "center",
        },
      ],
      totaltableDataSources: [],
      // 审核费用明细
      tableColumns: [
        {
          title: "申请单号",
          key: "code",
          align: "center",
          width: 200,
          event: [
            { 
              type: "primary",
              href: (row) => {
                return row.code
              },
              method: (row) => {
                this.handleRowHref(row);
              },
            },
          ],
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
          overflow: true,
        },
        {
          title: "业务类别",
          key: "firstExpense",
          align: "center",
          overflow: true,
        },
        {
          title: "二级费用类型",
          key: "twoLevelType",
          align: "center",
          width: 110,
          overflow: true,
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "费用类型编码",
          key: "expenseTypeCode",
          align: "center",
          overflow: true,
        },
        {
          title: "事由",
          key: "subjectMatter",
          align: "center",
          overflow: true,
        },
        {
          title: "附件",
          key: "finExpenseApplyAttachFiles",
          align: "center",
          image: true,
          AttachFiles: "finExpenseApplyAttachFiles",
        },
        {
          title: "金额（元）",
          key: "amount",
          align: "center",
        },
        {
          title: "核定金额（元）",
          key: "reviewAmount",
          align: "center",
          input: [
            {
              value: "",
              placeholderText: "请输入核定金额",
              isEdit: (row) => {
                return false
              },
              method: (value, row) => {
                this.handleInput(value, row);
              },
            },
          ],
        },
        {
          title: "操作",
          key: "oper",
          align: "center",
          event: [
            {
              state: "shtg-1",
              show: "true",
              label: "反审",
              method: (row) => {
                this.handleBack(row);
              },
              type: "primary",
            }
          ]
        },
      ],
      // 费用明细
      tableColumnsCheck: [
        {
          title: "申请单号",
          key: "code",
          align: "center",
          width: 200,
          event: [
            { 
              type: "primary",
              href: (row) => {
                return row.code
              },
              method: (row) => {
                this.handleRowHref(row);
              },
            },
          ],
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
          overflow: true,
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
          overflow: true,
        },
        {
          title: "任务书编码",
          key: "assignNum",
          align: "center",
          overflow: true,
        },
        {
          title: "业务类别",
          key: "firstExpense",
          align: "center",
          overflow: true,
        },
        {
          title: "二级费用类型",
          key: "twoLevelType",
          align: "center",
          overflow: true,
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "费用类型编码",
          key: "expenseTypeCode",
          align: "center",
          overflow: true,
        },
        {
          title: "事由",
          key: "subjectMatter",
          align: "center",
          overflow: true,
        },
        {
          title: "附件",
          key: "finExpenseApplyAttachFiles",
          align: "center",
          image: true,
          AttachFiles: "finExpenseApplyAttachFiles",
        },
        {
          title: "金额(元)",
          key: "amount",
          align: "center",
        },
        {
          title: "核定金额(元)",
          key: "reviewAmount",
          align: "center",
        },
      ],
      tableDataSources: [],
      //日志操作表头
      logTableColumns: [
        {
          title: "流程",
          key: "operateTypeName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "createTime",
          align: "center",
        },
        {
          title: "操作人",
          key: "updateBy",
          align: "center",
        },
        {
          title: "原因",
          key: "description",
          align: "center",
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
        },
      ],
      //日志操作流程数据
      logTableDataSources: [],
      multipleSelection: [],
    };
  },
  mounted() {
    this.getDataSources();
  },
  methods: {
    //跳转预算详情页
    handleShowList() {
      this.showApplyList = true;
    },
    handleRowHref(row) {
      this.expenseApply = {
        id: row.id,
      };
      this.diaexpenseDetail = true;
    },
    getDataSources() {
      let id = this.rowAuditId;
      getApi(`/finance/claims/update/${id}`)
        .then((res) => {
          let { data } = res;
          if (data.code == 0) {
            // 基本信息
            let obj = {
              amount: parseFloat(data.data.amount).toFixed(2),
              createUser: data.data.createUser,
              createDate: data.data.createDate,
              code: data.data.code,
              reviewAmount: parseFloat(data.data.reviewAmount).toFixed(2),
              prjCode: data.data.prjCode,
              projectName: data.data.projectName,
              expenseBearDepName: data.data.expenseBearDepName,
            };
            this.$refs.reportBillBasicForm.formData = obj;
            // 费用明细
            if (data.data.detail.length > 0) {
              data.data.detail.forEach((item) => {
                  if (item.amount) {
                    item.amount = parseFloat(item.amount).toFixed(2);
                  }
                  if (item.reviewAmount) {
                    item.reviewAmount = parseFloat(item.reviewAmount).toFixed(2);
                  }
                  if(item.applyTime) {
                    item.applyTime = formatDate(item.applyTime)
                  }
                  if (item.finExpenseApplyAttachFiles) {
                    item.finExpenseApplyAttachFiles.forEach((u) => {
                      u.url = "/admin/sys-file/ssh/" + u.fileName;
                      item.finExpenseApplyAttachFiles.push(u.url);
                    });
                  }
              });
            }
            if(data.data.expenseTotal.length > 0) {
              data.data.expenseTotal.forEach((item) => {
                if (item.sum) {
                  item.sum = parseFloat(item.sum).toFixed(2);
                }
              })
            }
            this.tableDataSources = data.data.detail;
            this.totaltableDataSources = data.data.expenseTotal;
          }
        })
      //获取日志操作
      getApi(`/finance/claims/record/${id}`)
        .then((res) => {
          let { data } = res;
          if (data.code == 0 && data.data) {
            this.logTableDataSources = data.data;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.msg);
        });
    },
    // 修改核定金额
    handleInput(value, row) {
      let list = this.tableDataSources;
      list.forEach((item, index) => {
          if (item.id == row.id) {
            if (Number(value) > Number(this.tableDataSources[index].amount)) {
              this.tableDataSources[index].reviewAmount =
                this.tableDataSources[index].amount;
            } else {
              this.tableDataSources[index].reviewAmount = value;
            }
          }
      });
    },
    // 计算表格合计行函数
    getExpenseSummariesTotal(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计(元)";
          return;
        }
        // 初始化合计行
        sums[index] = 0;
        // 递归求和
        this.getSum(data, column.property, sums, index);

        if (index != 3) {
          sums[index] = "";
        }else {
          sums[index] = parseFloat(sums[index]).toFixed(2);
        }
        // 如果求和得到的结果是非数字,则显示--
        if (isNaN(sums[index])) {
          sums[index] = "";
        }
      });
      return sums;
    },
    getExpenseSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计(元)";
          return;
        }
        // 初始化合计行
        sums[index] = 0;
        // 递归求和
        this.getSum(data, column.property, sums, index);
        if (index != 9 && index != 8) {
          sums[index] = "";
        }else {
          sums[index] = parseFloat(sums[index]).toFixed(2);
        }
        // 如果求和得到的结果是非数字,则显示--
        if (isNaN(sums[index])) {
          sums[index] = "";
        }
      });
      return sums;
    },
    getSum(tree, idx, sums, index) {
      if (tree && tree.length > 0) {
        tree.map((item) => {
          this.getSum(item.children, idx, sums, index);
          if (item[idx]) {
            sums[index] += Number(item[idx]);
          }
        });
      }
    },
    handleBack(row){
      let idsMap = {
        expenseId: row.id,
        claimsId: this.rowCheck.id
      };
      this.$confirm("是否对已选择日记账进行反审操作，同时退回报账单？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        postApi(`/finance/claims/reverse/audit`,idsMap).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success(data.data);
            this.$emit('isShow',false);

            this.getDataSources();
          }else{
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  cursor: default;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @themeColor;
  resize: none;
  cursor: pointer;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>