<template>
  <div class="container">
    <dd-card title="条件搜索" spacer>
      <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
        ref="ddSearchForm_kingDee"></dd-search-form>
    </dd-card>
    <div class="table_box">
      <dd-table ref="baseTable_1" :columns="tableColumns" :data="tableData" @select-change="handleSelectionChange"
        :handleCheckDisable="handleCheckDisable" border :isSelectable="true" >
        <!-- <template #table-btn-right>
          <dd-button type="primary" size="medium" @click="handleViewDetails">查看详情
          </dd-button>
        </template> -->
        <template #table-pagination>
          <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
            @page-change="handlePageChange" align="right">
          </dd-pagination>
        </template>
      </dd-table>
    </div>

  </div>
</template>

<script>
import { postApi } from '@/api/request';
export default {
  name: "addKingDee",
  props: ['pageProp'],
  data() {
    return {
      tableData: [],
      tableColumns: [
        {
          title: "合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
          width: 180
        },
        {
          title: "项目编码",
          key: "prjCode",
          align: "center",
          overflow: true,
          width: 130,
        },
        {
          title: "项目名称",
          key: "prjName",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "合同状态",
          key: "fbillsatename",
          align: "center",
          overflow: true,
          width: 100,
        },
        {
          title: "合同类型",
          key: "fcontracttypename",
          align: "center",
          overflow: true,
          width: 100,
        },
        {
          title: "合同总金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "累计变更金额",
          key: "fchangeamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "变更后总金额",
          key: "ftotalamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "累计结算金额",
          key: "fsettlebalance",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "未结算金额",
          key: "unsettlebalance",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "累计实付金额",
          key: "frealamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "未付金额",
          key: "unpaidamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "累计开票金额",
          key: "fextax",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "已开票未付款金额",
          key: "invoicedamountoutstanding",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "开始日期",
          key: "fbegindate",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "结束日期",
          key: "ffinishdate",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "经办日期",
          key: "fbizdate",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "甲方",
          key: "fpartaname",
          align: "center",
          overflow: true,
          width: 100,
        },
        {
          title: "乙方",
          key: "fpartbname",
          align: "center",
          overflow: true,
          width: 100,
        },
        {
          title: "合同属性",
          key: "cfproperty",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "采购子类型",
          key: "cfproperty1",
          align: "center",
          overflow: true,
          width: 100,
        },
        {
          title: "关联类型",
          key: "associationtype",
          align: "center",
          overflow: true,
          width: 100,
        },
      ],
      formList: [
        {
          label: "合同编号",
          value: "purchaseCode",
          inputType: "text",
          placeholderText: "请输入合同编号 进行搜索",
        },
        {
          label: "合同名称",
          value: "purchaseName",
          inputType: "text",
          placeholderText: "请输入合同名称进行搜索",
        },
        {
          label: "项目编码",
          value: "prjCode",
          inputType: "text",
          placeholderText: "请输入项目编码进行搜索",
        },
        {
          label: "项目名称",
          value: "prjName",
          inputType: "text",
          placeholderText: "请输入项目名称进行搜索",
        }, {}, {}, {}
      ],
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      selection_kingDee: [],
      // 每页的数据条数
      size: 10,
      searchData: {},
      tableHeight: null,
    };
  },
  created() {

    if (this.pageProp.pageType == 'allocation') {

    }
    this.getContractList();
  },
  mounted() {
    if(this.pageProp.pageType == 'relation'){
      this.tableColumns.push(
        {
          title: "操作",
          align: "center",
          fixed: 'right',
          overflow: true,
          width: 100,
          event: [
            {
              state: "default",
              label: "确认",
              type: "warning",
              width: 100,
              method: (row) => {
                  this.handleAdd(row);
              },
              rules: (row) => {
                  let show = true;
                  if(row.disabledCheck == 1){
                    show = false
                  }else{
                    show = true
                  }
                  return show;
                },
            }
          ]
        },
      )
    }
  },
  methods: {
    getContractList() {
      let params = { ...this.searchData }
      postApi(`/contract/invoice/registration/purchase/list/${this.current}/${this.size}`, params).then(res => {
        if (res.data.code == 0) {
          if(this.pageProp.tableData && this.pageProp.tableData.length > 0) {
            this.pageProp.tableData.forEach(it => {
              res.data.data.records.forEach(item => {
                if(this.pageProp.key && this.pageProp.key == 'purchaseContractId') {
                  if (it.purchaseContractId == item.purchaseContractId) {
                    item.disabledCheck = 1;
                  }
                }
                else {
                  if (it.id == item.id) {
                    item.disabledCheck = 1;
                  }
                }
              })
            })
          }
          
          this.tableData = res.data.data.records;
          this.total = res.data.data.total
        }
      })
    },
    handleAdd(row){
      this.$emit('addKindPurchase',row);
      this.getContractList();
    },
    handleSelectionChange(e) {
      this.selection_kingDee = e;
    },
    handleSearchEvent(e) {
      this.current = 1;
      this.searchData = e;
      this.getContractList();
    },
    handleViewDetails(e) { },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getContractList();
    },
    handleCheckDisable(row) {
      if (row.disabledCheck == 1) {
        return false;
      }
      else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table_box {
  margin-top: 20px;
}

/deep/ .el-table__fixed-body-wrapper {
      height: auto !important;
    }
</style>