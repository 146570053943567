<template>
  <div class="main-container">
    <dd-card title="" spacer>
      <dd-table
        :columns="columnsList"
        @header-click="headerClick"
        :SetheaderCellClassName="SetheaderCellClassName"
        :topBtn="false"
        :isSelection="false"
        :isSort="false"
        :data="tableData"
        border
        ref="dTableRef"
      >
      </dd-table>
    </dd-card>
    <dd-popup
          v-if="dialogEditDetailVisible"
          width="90%"
          height="90%"
          inPopup
          showBtn
          :saveBtn="false"
          :submitBtn="false"
          :show="dialogEditDetailVisible"
          :title-name="EditDetailTitle"
          @popup-close="dialogEditDetailVisible = false"
        >
          <DetailedBudgetDetails
            ref="DecomposeEdit"
            slot="main"
            :pageType="pageType"
            :propDetail="propDetailprop"
          ></DetailedBudgetDetails>
        </dd-popup>
  </div>
</template>

<script>
import { getApi } from '@/api/request';
import DetailedBudgetDetails from '../../Details/components/DetailedBudgetDetails.vue'
export default {
    props: ['propDetail', 'type'],
    components: {
      DetailedBudgetDetails
    },
  data() {
    return {
      loading: false,
      dialogEditDetailVisible: false,
      EditDetailTitle: '',
      pageType: 'adjustment',
      propDetailprop: {
        type: 'detail',
        isTaskBook: true,
      },
      columnsList: [],
      tableColumns: [
        {
          title: "费用类型编号",
          key: "costTypeCode",
          align: "center",
        },
        {
          title: "费用类型名称",
          key: "costTypeName",
          align: "center",
          overflow: true,
        },
        {
            title: '',
            align: 'center',
            children: [
            {
                title: '项目合计',
                key: 'totalAmount',
                align: 'center',
                styleEvent: {
                  method: (row) => {
                    return this.handleStyleEvent(row, ["totalAmount"]);
                  },
                },
              },
            ]
        }
      ],
      tableData: [],
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    getTableData() {
      getApi(`/finance/finbudgetadjustable/kingdeebudgetview/${this.propDetail.id}`).then(res => {
        if(res.data.code == 0) {
          let { data } = res;
          this.tableColumns[2].title = data.data[0].prjName || '';
          data.data.forEach((element, index) => {
            if(element.finBudgetAdjusdetailVOList?.length) {
              element.finBudgetAdjusdetailVOList.forEach((item, i) => {
                if(index == 0) {
                  this.tableColumns[2].children.push({
                    key: 'value' + i,
                    title: item.assignName,
                    align: 'center'
                  })
                }
                
                element[`value${i}`] = item.budgetAmount
              })
              this.tableData = data.data;
              this.tableDataCopy = JSON.parse(JSON.stringify(data.data))
            }
          })
        }
      })
    },
    headerClick(row) {
      let id = null;
      for(let i = 0; i < this.tableDataCopy.length; i++) {
        let element = this.tableDataCopy[i].finBudgetAdjusdetailVOList;
        element.forEach(item => {
          if(item.assignName == row.label) {
            id = item.budgetCostDisassembleId
          }
        })
      };
      if(id) {
        this.dialogEditDetailVisible = true;
        this.EditDetailTitle = '详情';
        this.propDetailprop.id = id;
      }
      
    },
    SetheaderCellClassName({row, column, rowIndex, columnIndex}) {
      if(columnIndex >= 1 && rowIndex == 1) {
        return 'blueColor'
      }
    },
    handleStyleEvent(row, keys) {
      if(row) {
        return `color: red`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
  /deep/.blueColor {
  color: blue !important;
  cursor: pointer !important;
}

/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}
/deep/ .el-tabs__nav {
  width: 100%;
}
/deep/ .el-tabs__item {
  width: 25%;
  text-align: center;
}
</style>