<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>

    <dd-card title="" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        :isSelection="true"
        ref="contract_table"
        show-page
        :loading="loading"
        @select-change="handleSelect"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            icon="export"
            size="medium"
            @click="handleExport"
            >导出
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>

    <dd-popup
      v-if="associationPopVisible"
      width="90%"
      height="90%"
      showBtn
      :saveBtn="false"
      :submitBtn="false"
      :show="associationPopVisible"
      title-name="关联合同"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        size="medium"
        type="primary"
        @click="handleSubmit(1)"
        >提交</dd-button
      >
      <associationPop
        ref="associationPop"
        :pageProp="pageProp"
        slot="main"
      ></associationPop>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
import associationPop from "./associationPop.vue";
export default {
  name: "contractPendingContract",
  $dynamicRoute: {
    name: "合同待关联",
    path: "/contract/contractPending/contract",
  },
  components: { associationPop },
  data() {
    return {
      associationPopVisible: false,
      loading: false,
      formList: [
        {
          label: "合同编号",
          value: "fnumber",
          inputType: "text",
          placeholderText: "请输入合同编号进行搜索",
        },
        {
          label: "合同名称",
          value: "fname",
          inputType: "text",
          placeholderText: "请输入合同名称进行搜索",
        },
        {
          label: "关联状态",
          value: "status",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择关联状态",
        },
        {
          label: "合同乙方",
          value: "fpartb",
          inputType: "text",
          placeholderText: "请输入合同乙方进行搜索",
        },
        {
          label: "关联类型",
          value: "relationType",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择关联类型进行搜索",
        },
        {},
        {},
      ],
      pageProp: {},
      tableColumns: [
        {
          title: "合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
          width: 130,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
          width: 130,
        },
        {
          title: "合同类型",
          key: "procurementTypeName",
          align: "center",
          overflow: true,
          width: 100,
        },
        {
          title: "合同总金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: 96,
        },
        {
          title: "变更后合同总金额",
          key: "fchangeamount",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "合同乙方",
          key: "fpartb",
          align: "center",
          overflow: true,
        },
        {
          title: "合同甲方",
          key: "fparta",
          align: "center",
          overflow: true,
        },
        {
          title: "关联类型",
          key: "relationType",
          align: "center",
          overflow: true,
        },
        {
          title: "已关联的合同金额",
          key: "relatedAmount",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "未关联的合同金额",
          key: "unRelatedAmount",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "关联自建合同编号",
          key: "selfCodes",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "关联自建合同名称",
          key: "selfNames",
          align: "center",
          overflow: true,
          width: 140,
        },
        {
          title: "关联状态",
          key: "status",
          align: "center",
          overflow: true,
        },
        {
          title: "首次关联操作人",
          key: "applyName",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 120,
          event: [
            {
              state: "default",
              label: "关联自建合同",
              width: 120,
              type: "primary",
              method: (row) => {
                this.associationContarct(row);
              },
            },
          ],
        },
      ],
      drawerVisible: false,
      tableHeight: null,
      tableData: [],
      columnsList: [],
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
      searchData: {
        status: null,
      },
      selectData: [],
    };
  },
  created() {
    this.getDataType();
    this.getGLLXData();
    // this.getTableData();
  },
  mounted() {
    this.columnsList = [...this.tableColumns];
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
  },
  methods: {
    getTableData() {
      postApi(
        `/contract/contractpurchaseinfo/todo/relation/${this.current}/${this.size}`,
        this.searchData
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelect(e) {
      this.selectData = e;
    },
    handleSearchEvent(e) {
      this.current = 1;
      this.searchData = e;
      this.getTableData();
    },
    handleModify() {
      this.associationPopVisible = true;
    },
    associationContarct(row) {
      this.pageProp.id = row.id;
      this.pageProp.fchangeamount = row.fchangeamount;
      this.associationPopVisible = true;
    },
    handleSubmit() {
      if (
        this.$refs.associationPop.tableData &&
        this.$refs.associationPop.tableData.length
      ) {
        let flag = true;
        let arr = this.$refs.associationPop.tableData;
        for (let i = 0; i < arr.length; i++) {
          if (
            (arr[i].allotmentAmount || 0) >
            (arr[i].procurementFinallyAmount || 0) -
              (arr[i].allotmentTotalAmount || 0)
          ) {
            flag = false;
            break;
          }
        }
        let sums = 0;
        arr.map((item) => {
          sums += Number(item.allotmentAmount);
        });
        if (sums > Number(this.$refs.associationPop.topText[0].value)) {
          this.$message.warning("关联金额之和，不能大于变更后合同总金额");
          return false;
        }
        if (!flag) {
          this.$message.warning(
            "关联金额不能大于 自建合同变更后总金额-自建合同已关联金额"
          );
          return false;
        }
      }
      this.$refs.contract_table.$refs.baseTable.clearSelection();
      let params = {
        id: this.pageProp.id,
        relateType:
          this.$refs.associationPop.contractType == "关联合同"
            ? "PC-DB-GLLX-GLHT"
            : "PC-DB-GLLX-YBHT",
        relations: this.$refs.associationPop.tableData,
      };
      postApi(
        `/contract/contractpurchaseinfo/associate/selfcontract`,
        params
      ).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败");
        }
        this.associationPopVisible = false;
        this.getTableData();
      });
    },
    handleClose() {
      this.associationPopVisible = false;
      this.$refs.contract_table.$refs.baseTable.clearSelection();
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getTableData();
    },
    getDataType() {
      postApi(`/contract/dicttype/list/all`, { code: "PC-HTTODO-STATUS" }).then(
        (res) => {
          if (res.data.code == 0) {
            this.formList.forEach((item) => {
              if (item.value == "status") {
                item.optionsList = res.data.data;
              }
            });
            res.data.data.forEach((item) => {
              if (item.name == "待关联") {
                this.formList[2].defaultValue = item.code;
                this.searchData.status = item.code;
                this.$forceUpdate();
              }
            });
            this.getTableData();
          }
        }
      );
    },
    getGLLXData(){
        postApi(`/contract/dicttype/list/all`, { code: "PC-DB-GLLX" }).then(
        (res) => {
          if (res.data.code == 0) {
            this.formList.forEach((item) => {
              if (item.value == "relationType") {
                item.optionsList = res.data.data;
              }
            });
          }
        }
      );
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleExport() {
      let params = {
        ...this.searchData,
        ids: this.selectData.map((item) => {
          return item.id;
        }),
      };

      loadApi(`/contract/contractpurchaseinfo/export`, params).then((res) => {
        let { data } = res;
        this.$refs.contract_table.$refs.baseTable.clearSelection();
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
  },
};
</script>

<style lang="less" scoped></style>