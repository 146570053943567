<template>
  <div class="main-container">
    <dd-card title="条件搜索">
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
        labelWidth="180px"
      ></dd-search-form>
    </dd-card>
    <dd-card title="绩效核算汇总列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        border
        :isSelection="true"
        show-page
        :loading="loading"
        ref="TableRef"
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd">新建部门绩效汇总单</dd-button>
          <dd-button type="danger" size="medium" icon="back" @click="handleback">退回已生成汇总单</dd-button>
          <dd-button icon="export" type="success" size="medium" autoConfirm @click="handleFileExport">导出</dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="80%"
      height="80%"
      :showBtn="showBtn"
      :submitBtn="false"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="isSave ? handleSave('addPopForm', 'baseForm', 0) : ''"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :isEditf="isEditf"
        :rowData="rowData"
      ></add-pop>
      <template slot="button">
        <dd-button type="primary" size="medium"  @click="isSubmit ? handleSave('addPopForm', 'baseForm', 1) : ''">确认生成</dd-button>
      </template>
    </dd-popup>
    <!-- 详情 -->
    <dd-popup
      width="90%"
      height="90%"
      :showBtn="false"
      :submitBtn="submitBtn"
      :show="xqAddPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
    >
      <viewDetail
        v-if="xqAddPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></viewDetail>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { delApi, postApi, loadApi, getApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import viewDetail from "./components/ViewDetails.vue";
export default {
  name: "index",
  $dynamicRoute: {
    name: "绩效核算汇总管理",
    path: "/performanceAccounting/collectManage",
  },
  components: {
    addPop,
    viewDetail,
  },
  computed: {},
  data() {
    return {
      drawerVisible:false,
      tableHeight: null,
      loading: true,
      formData: {},
      multipleSelection: [],  // 表格选择数据集合
      submitBtn:true,
      showBtn:true,
      formList: [
        {
          label: "单据名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入单据名称",
          defaultValue:'',
        },
        {
          label: "分管部门",
          value: "fenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          defaultValue: '',
          placeholderText: "请选择分管部门",
          rules: [
            {
              required: true,
              message: "请选择分管部门",
              trigger: "blur"
            }
          ],
        },
        {
          label: "状态",
          value: "status",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "所属年月",
          value: "applyDate",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },{},{},{},
      ],
      tableColumns: [
        {
          title: "单据名称",
          key: "name",
          align: "center",
        },
        {
          title: "分管部门",
          key: "fenDeptName",
          align: "center",
        },
        {
          title: "所属年月",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "状态",
          key: "statusName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          fixed: "right",
          event: [
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "warning",
              rules: (row) => {
                let show = false;
                if (row.status == "ACCOUNTING-SUM-STATE-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看详情",
              icon: "el-icon-view",
              method: (row) => {
                this.handleViewDetails(row);
              },
              type: "info",
              rules: (row) => {
                let show = false;
                if (row.status == "ACCOUNTING-SUM-STATE-YSC" || row.status == "ACCOUNTING-SUM-STATE-YTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "提交",
              icon: "el-icon-submit",
              method: (row) => {
                this.handleSubmit(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.status == "ACCOUNTING-SUM-STATE-YSC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleDanger(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.status == "ACCOUNTING-SUM-STATE-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 10,
      total: null,
      addPopupVisible: false,
      xqAddPopupVisible:false,
      rowData: null,
      poptitleName: "新增",
      isSubmit:true,
      isSave:true,
      isEditf:false,
    };
  },
  created() {
    this.getFormList();
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
  },
  methods: {
    getFormList() {
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "ACCOUNTING-SUM-STATE",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "status") {
              item.optionsList = data.data;
            }
          });
        }
      });
      getApi(`/admin/sysfendept/getList`, {
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "fenDeptId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
      };
      this.loading = true;
      postApi(`/project/accountingsum/page/${current}/${size}`, params).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.loading = false;
            this.tableData = data.data.records;
            this.total = data.data.total;
          }
        }
      );
      
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
      this.isEditf = false;
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.xqAddPopupVisible = false;
    },
    // 查看详情
    handleViewDetails(row){
      this.xqAddPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "查看详情";
      this.submitBtn = false;
      this.showBtn = false
      this.isSubmit = false;
      this.isSave = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
      this.isEditf = true;
      this.showBtn = true;
      this.isSubmit = true;
      this.isSave = true;
    },
    // 表格行内查看事件
    handleRowDetail(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "详情";
    },
    // 提交
    handleSubmit(row){
      let that = this;
      that.$confirm(`确认提交该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        postApi(`/project/accountingsum/submit/${row.id}`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            that.$message.success("提交成功");
          }
          that.getTableData();
        });
      }); 
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/project/assign/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase, type) {
      let list = this.$refs.addPop.$refs['Table'].$attrs.data;
      if(list && list.length <= 0){
        return this.$message.warning('核算单不能为空');
      }
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      
      let params = {
        ...this.$refs.addPop.$refs[formName].formData,
        list:list,
      };
      if(this.rowData != null){
        params.id = this.rowData.id;
      }
      if(flag){
        postApi(`/project/accountingsum/save/${type}`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 导出
    handleFileExport() {
      let { multipleSelection } = this;
      if (multipleSelection.length == 0 || multipleSelection.length > 1) {
        this.$message.warning("请选择一条数据进行导出");
        return;
      }
      let params = {
        id: multipleSelection[0].id,
      };
      loadApi(`/project/accountingsum/export`, params).then((res) => {
        let { data } = res;
        this.$refs.TableRef.$refs.baseTable.clearSelection();
        this.multipleSelection = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    // 退回
    handleback(){
      let { multipleSelection } = this;
      if (multipleSelection.length == 0 || multipleSelection.length > 1) {
        this.$message.warning("请选择一条数据进行退回！");
        return;
      }
      if (multipleSelection[0].status == 'ACCOUNTING-SUM-STATE-YSC'){                
        postApi(`/project/accountingsum/back/${multipleSelection[0].id}`).then(res =>{
          if (res.data.code == 0) {
            this.$message.success('操作成功');
          }
          else {
            this.$message.warning('操作失败，请稍后重试');
          }
        }).finally(()=>{
          this.getTableData();
        })
      }else{
        this.$message.warning("只能退回状态为已生成状态的绩效核算汇总单！");
      }
      this.$refs.TableRef.$refs.baseTable.clearSelection();
      this.multipleSelection = [];
    },
    // 删除
    handleDanger(row){
      let that = this;
      that.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/project/accountingsum/${row.id}`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            that.$message.success("删除成功");
          }
          that.getTableData();
        });
      });
    }
  },
};
</script>

<style lang="less" scoped></style>
