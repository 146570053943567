<template>
    <div class="container">
        <div class="allocation_top">
            <div class="top_box">
                <div class="top_box_item" v-for="(item, index) in topText" :key="index">
                    <div class="label">{{ item.label }}：</div>
                    <div class="value">{{ item.value }}</div>
                </div>
            </div>
        </div>
        <div class="table_box">
            <dd-table ref="baseTable_1" :columns="tableColumns"  class="table_cell"
            :SetCellClassName="SetCellClassName" :summary-method="getSummaries" :data="tableData" show-summary border
                :isSelection="false" :loading="loading" max-height="640">
                <template #table-btn>
                    <dd-button
                        type="primary"
                        icon="export"
                        size="medium"
                        @click="handleExport"
                        >导出
                    </dd-button>
                    </template>
                <template #table-btn-right>
                    <dd-button type="primary" size="medium" @click="handleVerify">批量确认
                    </dd-button>
                </template>
            </dd-table>
        </div>

        <dd-popup v-if="popup_formVisible" width="30%" height="45%" inPopup showBtn :saveBtn="true" :submitBtn="false"
            :show="popup_formVisible" title-name="编辑" @popup-save="handleSave()" @popup-close="handleClose">
            <div slot="main">
                <dd-card title="" >
                        <dd-form :form-config="formList_form" :flex="1" :labelWidth="'220px'" 
                            ref="EditForm" formRef="EditForm"></dd-form>
                    </dd-card>
            </div>
        </dd-popup>
    </div>
</template>

<script>
import { getApi, postApi,loadApi } from '@/api/request';
import { downloadBinaryFile } from "@/utils/index";
export default {
    name: 'settlementAssociation',
    props: ['pageProp'],
    data() {
        return {
            popup_formVisible: false,
            topText: [
                {
                    label: "本合同变更后合同总金额",
                    value: "",
                },
            ],
            formList_form: [
                {
                    label: "金蝶结算单编号",
                    value: "settlefnumber",
                    inputType: "text",
                    disabled: true,
                    defaultValue: "",
                },
                {
                    label: "金蝶结算单名称",
                    value: "settlefname",
                    inputType: "text",
                    disabled: true,
                    defaultValue: "",
                },
                {
                    label: "关联结算金额",
                    value: "allotmentAmount",
                    inputType: "text",
                    disabled: false,
                    defaultValue: "",
                    rules: [
                        {
                        required: true,
                        message: "请输入关联结算金额",
                        trigger: "blur",
                        },
                        {
                            required: true,
                            pattern: /^\d*\.?\d{0,2}$/,
                            message: "请输入数字，小数最多两位",
                            trigger: "blur",
                        }
                    ],
                },
            ],
            tableColumns: [
                {
                    title: "金蝶结算单编号",
                    key: "settlefnumber",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "金蝶结算单名称",
                    key: "settlefname",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "合同关联金额",
                    key: "contractRelatedAmount",
                    align: "center",
                    overflow: true,
                    width: 110
                },
                {
                    title: "金蝶变更后合同总金额",
                    key: "ftotalamount",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "金蝶本次含税结算总金额",
                    key: "foftax",
                    align: "center",
                    overflow: true,
                    width: 150
                },{
                    title: "已关联其他自建合同的结算金额",
                    key: "linedOtherPurchase",
                    align: "center",
                    overflow: true,
                    width: 150
                },{
                    title: "未关联结算金额",
                    key: "unlinkAmount",
                    align: "center",
                    overflow: true,
                    width: 120
                }, 
                {
                    title: "数据状态",
                    key: "stauts",
                    align: "center",
                    overflow: true,
                    width: 100,
                }, {
                    title: "归属部门",
                    key: "deptName",
                    align: "center",
                    overflow: true,
                    width: 100
                }, {
                    title: "关联结算金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    width: 160
                },
                {
                    title: "期间",
                    key: "fperiod",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "金蝶合同编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                    width: 160
                },
                {
                    title: "金蝶合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                    width: 160
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    width: 100,
                    event: [
                        {
                            state: "default",
                            label: "编辑",
                            type: "warning",
                            width: 100,
                            method: (row) => {
                                this.handleEdit(row);
                            },
                        },
                    ],
                },
            ],
            rowData: {},
            tableData: [],
            loading: false,
        }
    },
    created() {
    },
    mounted() {
        // this.getPageData()
        this.topText[0].value = this.pageProp.procurementFinallyAmount || 0
    },
    methods: {
        handleEdit(row) {
            this.rowData = row;
            this.popup_formVisible = true;
            this.$nextTick(() => {
                this.formList_form[0].defaultValue = this.rowData.settlefnumber;
                this.formList_form[1].defaultValue = this.rowData.settlefname;
                this.formList_form[2].defaultValue = this.rowData.allotmentAmount == null ||  this.rowData.allotmentAmount === ''? this.rowData.unlinkAmount : this.rowData.allotmentAmount;
                this.$forceUpdate()
            })
        },
        getPageData() {
            getApi(`/contract/selfestablished/procurement/allocate`, { id: this.pageProp.id, type: 'settle' }).then(res => {
                if (res.data.code == 0) {
                    let { data } = res;
                    this.tableData = data.data;
                }
            })
        },
        handleExport() {
            loadApi(
                `/contract/selfestablished/procurement/relation/settle/export?id=${this.pageProp.id}&type=relation`
            ).then((res) => {
                let { data } = res;
                downloadBinaryFile(
                data,
                decodeURIComponent(
                    res.headers["content-disposition"].split(";")[1].split("=")[1]
                )
                );
                this.$message.success("导出成功");
            });
            },
        handleVerify(){
            if(this.tableData.length == 0){
                return false;
            }
            let param = {settleRelations : this.tableData};
            postApi(`/contract/selfestablished/procurement/batch/verify?type=settle`,param).then(res =>{
                    if (res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.getPageData();
            })
        },
        handleSave() {
            let flag = false;
                this.$refs.EditForm.$refs.EditForm.validate(
                    (valid) => {
                    if (valid) {
                        flag = true;
                    }
                    }
                );
                if(!flag) {
                    return false
                }
                if((this.rowData.foftax * 1 || 0 - this.rowData.linedOtherPurchase * 1 || 0) < this.$refs.EditForm.formData.allotmentAmount) {
                    this.$message.warning('关联金额不能大于 金蝶本次含税结算总金额-已关联其他自建合同的结算金额（即金蝶可关联金额）')
                    return false;
                }
                let sum = 0;
                this.tableData.forEach(item => {
                    if(item.id != this.rowData.id) {
                        sum += ((item.allotmentAmount * 1) || 0)
                    }
                    else {
                        sum += Number(this.$refs.EditForm.formData.allotmentAmount) || 0
                    }
                })
                if(sum.toFixed(2) > Number(this.pageProp.procurementFinallyAmount)){
                    this.$message.warning('关联金额之和，不能大于变更后合同总金额')
                    return false;
                }
                const params = {
                    id: this.pageProp.id,
                    relationId: this.rowData.id,
                    type: 'settle',
                    ...this.$refs.EditForm.formData
                }
                postApi(`/contract/selfestablished/procurement/update`, params).then(res => {
                    if(res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.success('操作失败，请稍后重试')
                    }
                    this.getPageData()
                })
                this.popup_formVisible = false;
        },
        handleClose() {
            this.popup_formVisible = false;
        },
        SetCellClassName({row, column, rowIndex, columnIndex}) {
            
            if(row.stauts == '未确认'){
                if(column.property == 'deptName' || column.property == 'allotmentAmount' || column.property == 'stauts') {
                return 'backgroundColor'
                }
            }
            
        },
        getSummaries(param) { // 自定义项表格合计方法
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = "合计";
                return;
                } else if (column.property == "allotmentAmount" || column.property == "contractRelatedAmount" 
                || column.property == "ftotalamount" || column.property == "linedOtherPurchase" 
                || column.property == "unlinkAmount" || column.property == "foftax"
                ) {
                //如果是经费（正常的加减法）
                const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    var sum = 0;
                    if (!isNaN(value)) {
                        sum = Number(Number(prev) + Number(curr)).toFixed(2);
                        return sum;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += " ";
                }
                }
            });
            return sums
            },
    }
}
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
.allocation_top {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.top_box {
    width: 80%;
    height: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
    justify-content: left;
    font-weight: bold;
    font-size: 15px;
    color: #606266;

    .top_box_item {
        display: flex;
        align-items: center;
    }
}

.table_box {
    margin-top: 30px;
}

/deep/ .table_cell {
  .backgroundColor {
    background-color: #a8d0fa !important;
  }
}


/deep/ .el-table__fixed-body-wrapper {
      top: 65px !important;
    }

</style>