<template>
  <div class="container">
    <div class="table_box">
      <dd-table
        ref="baseTable_1"
        :columns="tableColumns"
        class="table_cell"
        :summary-method="getSummaries"
        :data="tableData"
        show-summary
        border
        :isSelection="false"
        :loading="loading"
        max-height="460"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" @click="handleAdd"
            >新增
          </dd-button>
        </template>

        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>

      </dd-table>
    </div>

    <!-- 新增结算 -->
    <dd-popup
      v-if="addEditSettlementVisible"
      width="40%"
      height="60%"
      inPopup
      showBtn
      :saveBtn="false"
      :submitBtn="false"
      :show="addEditSettlementVisible"
      :title-name="addEditSettlementTitle"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        size="medium"
        type="primary"
        @click="handleSubmit"
        >提交</dd-button
      >
      <addEditSettlement
        ref="addEditSettlement"
        :pageSettleProp="pageSettleProp"
        slot="main"
      ></addEditSettlement>
    </dd-popup>
  </div>
</template>

<script>
import addEditSettlement from "./addEditSettlement.vue";
import { putApi, postApi, delApi } from "@/api/request";

export default {
  name: "settlementAssociation",
  props: ["pageProp"],
  components: { addEditSettlement },
  data() {
    return {
      loading: false,
      tableData: [],
      pageSettleProp: {},
      current: 1,
      total: 0,
      size: 10,
      addEditSettlementVisible: false,
      addEditSettlementTitle: "",
      tableColumns: [
        {
          title: "单据编号",
          key: "fnumber",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "单据名称",
          key: "fname",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "单据状态",
          key: "fbillsateName",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "期间",
          key: "fperiodidName",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "合同编号",
          key: "purchaseInfoNum",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "合同名称",
          key: "purchaseInfoName",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "业务日期",
          key: "fbizdate",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "本期金额(元)",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "制单人",
          key: "fcreatorName",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "制单日期",
          key: "updateTime",
          align: "center",
          overflow: true,
          width: 150,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 100,
          event: [
            {
              state: "default",
              label: "编辑",
              type: "warning",
              width: 100,
              method: (row) => {
                this.handleEdit(row);
              },
            },
            {
              state: "default",
              label: "删除",
              type: "danger",
              width: 100,
              method: (row) => {
                this.handleDelete(row);
              },
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getTableList();
  },
  mounted() {},
  methods: {
    getTableList(){
      let params = {purchaseInfoId:this.pageProp.purchaseInfoId}
      postApi(`/contract/contractpurchaseinfosettle/pagebase/${this.current}/${this.size}`,params).then((res)=>{
        if(res.data.code == 0){
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      })
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getTableList();
    },
    handleAdd() {
      this.addEditSettlementVisible = true;
      this.addEditSettlementTitle = "结算新增";
      this.pageSettleProp.pageType = "add";
    },
    handleEdit(row) {
      this.addEditSettlementVisible = true;
      this.pageSettleProp.pageType = "edit";
      this.pageSettleProp.data = row;
      this.addEditSettlementTitle = "结算编辑";
    },
    handleDelete(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/contract/contractpurchaseinfosettle/${row.id}`).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("删除成功");
          } else {
            this.$message.warning("删除失败，请稍后重试");
          }
          this.getTableList();
        });
      });
    },
    handleClose() {
      this.addEditSettlementVisible = false;
    },
    handleSubmit() {
      let flag = false;
      this.$refs.addEditSettlement.$refs.addPopForm.$refs.addPopForm.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }
      let params = {
        ...this.$refs.addEditSettlement.$refs.addPopForm.formData,
        purchaseInfoId:this.pageProp.purchaseInfoId
      };
      console.log("params",params);
      if (params.id && params.id != null && params.id != "") {
        putApi(`/contract/contractpurchaseinfosettle`,params).then((res)=>{
          if (res.data.code == 0) {
            this.$message.success("编辑成功");
          } else {
            this.$message.warning("编辑失败，请稍后重试");
          }
          this.addEditSettlementVisible = false;
          this.getTableList();
        })
      }else{
        postApi(`/contract/contractpurchaseinfosettle/save`,params).then((res)=>{
          if (res.data.code == 0) {
            this.$message.success("保存成功");
          } else {
            this.$message.warning("保存失败，请稍后重试");
          }
          this.addEditSettlementVisible = false;
          this.getTableList();
        })
      }
    },
    getSummaries(param) {
      // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "foriginalamount") {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
.table_box {
  margin-top: 40px;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
</style>