<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form :form-config="formList" ref="formRef" :labelWidth="'170px'"
        @search-click="handleSearchEvent"></dd-search-form>
    </dd-card>
    <dd-card title="预算申请列表" :height="tableHeight">
      <dd-table :columns="columnsList" :data="tableData" border ref="dTableRef" :loading="loading" show-page
        :isSelectable="true" @select-change="handleSelectionChange" :handleCheckDisable="handleCheckDisable">
        <template #table-btn-right>
          <el-button icon="el-icon-s-operation" circle type="info" size="small" @click="drawerVisible = true"
            title="列筛选"></el-button>
        </template>
        <template #table-btn>
          <dd-button v-if="$store.state.btnAuthorityList.includes('finance:finbudgetestablishment:addsc')" type="primary" size="medium" icon="add" @click="workOutProduction()">编制生产预算表</dd-button>
          <dd-button v-if="$store.state.btnAuthorityList.includes('finance:finbudgetestablishment:addxs')" type="warning" size="medium" icon="add" @click="workOutSales()">编制销售预算表</dd-button>
          <dd-button v-if="$store.state.btnAuthorityList.includes('finance:finbudgetestablishment:revise')" type="success" size="medium" icon="update" @click="handleUpdate()">修订</dd-button>
          <dd-button v-if="$store.state.btnAuthorityList.includes('finance:finbudgetestablishment:del')" type="danger" size="medium" icon="delete" @click="handleDelete()">删除</dd-button>
          <dd-button v-if="$store.state.btnAuthorityList.includes('finance:finbudgetestablishment:templateexport')" type="primary" size="medium" icon="export" @click="downloadImportTemplate()">导入模板下载</dd-button>
          <dd-button v-if="$store.state.btnAuthorityList.includes('finance:finbudgetestablishment:import')" type="success" size="medium" icon="import" @click="handleImport()">导入</dd-button>
          <dd-button v-if="$store.state.btnAuthorityList.includes('finance:finbudgetestablishment:export')" type="primary" size="medium" icon="export" @click="handleExport()">导出</dd-button>
          <dd-button v-if="$store.state.btnAuthorityList.includes('finance:finbudgetestablishment:batchexport')" type="info" size="medium" icon="export" @click="handleExportBatch()">批量导出</dd-button>
        </template>
        <template #table-pagination>
          <dd-pagination :total="total" :page-size="size" :page-number="current" @page-change="handleCurrentChange"
            align="right">
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 编制生产预算表 -->
    <dd-popup v-if="dialogAddEditProVisible" width="90%" height="90%" showBtn :saveBtn="saveBtn" :submitBtn="false"
      :show="dialogAddEditProVisible" :title-name="addEditTitlePro" @popup-save="handleSubmit(0, 'addEditPopPro')"
      @popup-close="handleClose">
      <dd-button v-if="isCheck && this.submitBtn" slot="button" size="medium" type="primary"
        @click="handleSubmit(1, 'addEditPopPro')">提交</dd-button>
      <dd-button v-if="!isCheck && this.submitBtn" slot="button" size="medium" type="primary"
        @click="saveCheck">校验保存</dd-button>
      <dd-button v-if="isCheck && this.submitBtn" slot="button" size="medium" type="primary"
        @click="backEdit">返回编辑</dd-button>
      <addEditPopPro ref="addEditPopPro" slot="main" :addEdit="addEdit" :type="type"></addEditPopPro>
    </dd-popup>
    <!-- 编制销售预算 -->
    <dd-popup v-if="dialogAddEditSalesVisible" width="90%" height="90%" showBtn :saveBtn="saveBtn"
      :submitBtn="submitBtn" :show="dialogAddEditSalesVisible" :title-name="addEditTitleSales"
      @popup-submit="handleSubmit(1, 'AddEditPopSales')" @popup-save="handleSubmit(0, 'AddEditPopSales')"
      @popup-close="handleClose">

      <addEditPopSale ref="AddEditPopSales" slot="main" :addEdit="addEdit" :type="type"></addEditPopSale>
    </dd-popup>
    <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
      :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange">
    </dd-filter-drawer>

    <dd-popup v-if="importVisible" width="30%" height="29%" showBtn :saveBtn="false" :submitBtn="false"
      :show="importVisible" title-name="导入"  @popup-close="handleClose">
      <dd-button slot="button" size="medium" type="primary" @click="handleconfirm(1, 'addEditPopPro')">确定</dd-button>
      <div slot="main">
        <dd-form :form-config="formList_import" :labelWidth="'170px'" :flex="1" ref="assignForm_import"
          formRef="assignForm_import"></dd-form>
          <div class="top_box">
            
            <span class="top_box_name"><span class="require" style="color: red">*</span>附件：</span>
        <div class="ibox-content" style="height: 100%">
          <el-upload
            class="upload-demo"
            action="#"
            :http-request="httpRequest"
            :on-remove="handleRemove"
            accept=".xlsx,.xls"
            :limit="1"
            :on-exceed="handleExceed"
            :on-success="handleFileSuccess"
            :file-list="fileList"
          >
            <el-button size="small" type="primary" 
              ><span
                class="iconfont icon-shangchuan"
                style="margin-right: 5px"
              ></span>
              点击上传
            </el-button>
          </el-upload>
        </div>
      </div>
      </div>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, loadApi, getApi, delApi } from "@/api/request";
import addEditPopSale from "./components/addEditPopSale.vue"; // 销售预算表
import addEditPopPro from "./components/addEditPopPro.vue"; // 生产预算表
import { downloadBinaryFile } from "@/utils/index";
import { formatNumber } from "@/utils/number";
import {mapState} from "vuex";
export default {
  name: "workout",
  $dynamicRoute: {
    name: "预算编制",
    path: "/finance/budget/workout",
    meta: {
      keepAlive: true,
    },
  },
  components: { addEditPopPro, addEditPopSale },
  // CW-YSBZ-YSLX-SC 生产预算
  // CW-YSBZ-YSLX-XS 销售预算
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  data() {
    return {
      headers: {},
      //附件列表
      fileList: [],
      file: null,
      formList_import: [
        {
          label: "生产任务书名称",
          value: "assignName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择生产任务书名称",
          optionsListConfig: {
            name: "assignName",
            code: "assignId",
          },
          disabled: false,
          rules: [
            {
              required: true,
              message: "请选择生产任务书名称",
              trigger: "blur",
            },
          ],
        },
      ],
      importVisible: false,
      searchObj: {},
      isCheck: false,
      tableHeight: null,
      checkId: null,
      checkXdId: null,
      loading: false,
      listType: 'sc', // 生产预算
      amountType: "",
      dialogAddEditProVisible: false,
      dialogAddEditSalesVisible: false,
      drawerVisible: false,
      addEdit: {
        id: null,
        type: '',
        pageType: 'workout',
        showStep: false,
      }, //传参子组件
      type: "workout",
      tableData: [],
      saveBtn: true,
      submitBtn: true,
      addEditTitlePro: "编制生产预算表",
      addEditTitleSales: '编制销售预算表',
      selectTable: [], // 表格勾选集合
      formList: [
        {
          label: "单据编码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单据编码",
        },
        {
          label: "分管部门",
          value: "businessFenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {
          label: "业务部门",
          value: "businessDeptId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索业务部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
        {
          label: "任务书名称或编码",
          value: "assignName",
          inputType: "text",
          defaultValue: "",
          placeholderText: "请输入任务书名称或编码",
        },
        {
          label: "详细预算类型",
          value: "budgetType",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择或搜索详细预算类型",
        },
        {
          label: "状态",
          value: "status",
          inputType: "select",
          filterable: "filterable",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择状态",
        },
        {
          // label: "审核节点",
          // value: "taskFlag",
          // inputType: "text",
          // placeholderText: "请输入审核节点",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "单据编码",
          key: "code",
          align: "center",
          overflow: true,
          width: 200,
        },
        {
          title: "任务书编码",
          key: "assignNum",
          align: "left",
          overflow: true,
          width: 180,
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "left",
          width: 400,
        },
        {
          title: "详细预算类型",
          key: "budgetTypeName",
          align: "center",
        },
        {
          title: "版本",
          key: "version",
          align: "center",
        },
        {
          title: "状态",
          key: "statusName",
          align: "center",
        },
        {
          title: "审核节点",
          key: "taskFlag",
          align: "center",
          width: 200,
        },
        {
          title: "编制人",
          key: "applierName",
          align: "center",
        },
        {
          title: "编制日期",
          key: "applyDate",
          align: "center",
          width: 100,
        },
        {
          title: "业务部门",
          key: "businessDeptName",
          align: "center",
          width: 120,
        },
        {
          title: "分管部门",
          key: "businessFenDeptName",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: 160,
          event: [
            {
              label: "编辑",
              type: "warning",
              state: "default",
              method: (row) => {
                this.handleRevise(row);
              },
              rules: (row) => {
                let show = false;
                if ((row.status == "CW-YSBZ-ZT-BZZ" || row.status == "CW-YSBZ-ZT-SPTH" || row.status == 'CW-YSBZ-ZT-XDBZZ')
                    && this.btnAuthorityList.includes('finance:finbudgetestablishment:edit')) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetail(row);
              },
            },
          ],
        },
      ],
      columnsList: [],
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
    };
  },
  created() {
    this.getStatusList(); // 获取预算编制状态
    this.getTypeList(); // 获取详细预算类型
    this.getFenDeptList(); // 获取分管部门数据
    this.getBusinessFenDeptList();
    // this.getAssignList();
  },
  mounted() {
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    this.headers = headers;
    this.columnsList = [...this.tableColumns];
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableList();
  },
  methods: {
    handleColumnsChange(val) { // 列筛选
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleSearchEvent(e) { // 查询
      this.searchObj = e;
      this.getTableList(e);
    },
    workOutProduction() { // 编制生产预算表
      this.dialogAddEditProVisible = true;
      this.addEditTitlePro = '编制生产预算';
      this.saveBtn = true;
      this.submitBtn = true;
      this.addEdit.type = 'add';
      this.addEdit.jbxxDisabled = false;
    },
    workOutSales() { // 编制销售预算表
      this.dialogAddEditSalesVisible = true;
      this.addEditTitleSales = '编制销售预算';
      this.addEdit.type = 'add';
      this.saveBtn = true;
      this.submitBtn = true;
      this.addEdit.showStep = false;
      this.addEdit.jbxxDisabled = false;
    },
    handleRevise(row) { // 修改操作
      this.addEdit.type = 'edit';
      this.isCheck = false;
      this.saveBtn = true;
      this.submitBtn = true;
      this.addEdit.id = row.id;
      this.addEdit.showStep = false;
      if (row.status == 'CW-YSBZ-ZT-BZZ') {
        this.addEdit.jbxxDisabled = false;
      }

      if (row.status == 'CW-YSBZ-ZT-SPTH') {
        this.addEdit.jbxxDisabled = true;
      }

      if (row.budgetType == 'CW-YSBZ-YSLX-SC') {
        this.dialogAddEditProVisible = true;
      }
      else {
        this.dialogAddEditSalesVisible = true;
      }
    },
    handleDetail(row) { // 查看详情
      this.addEdit.pageType = 'workout';
      this.addEdit.type = 'detail';
      this.addEdit.id = row.id;
      this.saveBtn = false;
      this.submitBtn = false;
      if (row.status == 'CW-YSBZ-ZT-BZZ' || row.status == 'CW-YSBZ-ZT-XDBZZ') {
        this.addEdit.showStep = false;
      }
      else {
        this.addEdit.showStep = true;
      }
      if (row.budgetType == 'CW-YSBZ-YSLX-SC') {
        this.addEditTitlePro = '生产预算详情';
        this.dialogAddEditProVisible = true;
      }
      else {
        this.addEditTitleSales = '销售预算详情';
        this.dialogAddEditSalesVisible = true;
      }
    },
    backEdit() {
      this.isCheck = false;
      this.addEdit.type = 'edit';
      this.addEdit.id = this.checkId;
    },
    saveCheck() {
      let ref = 'addEditPopPro';
      let flag = false;
      let jbxxData = { ...this.$refs[ref].$refs.assignForm.formData, ...this.$refs[ref].$refs.PersonForm.formData };
      this.$refs[ref].$refs.assignForm.$refs.assignForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return false;
      }
      if (!jbxxData.budgetTotalAmount || jbxxData.budgetTotalAmount == 0) {
        this.$message.warning('生产预算总额不能为0！')
        return false
      }
      let isExpenseSubtotal = false;
      let isExpenseSubtotalFixed = false;
      if (this.$refs[ref].tableDataSource && this.$refs[ref].tableDataSource.length) {
        this.$refs[ref].tableDataSource.forEach(item => {
          if (item.expenseSubtotal && Number(item.expenseSubtotal) > 0) {
            if (!item.jobContent || !item.measureUnit || !item.totalEffort || !item.workType || !item.efficiency) {
              isExpenseSubtotal = false;
            }
            else {
              isExpenseSubtotal = true;
            }
          }
        })
      }

      if (this.$refs[ref].tableDataFixed && this.$refs[ref].tableDataFixed.length) {
        this.$refs[ref].tableDataFixed.forEach(item => {
          if (item.expenseSubtotal && Number(item.expenseSubtotal) > 0) {
            if (!item.jobContent || !item.measureUnit || !item.totalEffort || !item.workType || !item.efficiency) {
              isExpenseSubtotalFixed = false;
            }
            else {
              isExpenseSubtotalFixed = true;
            }
          }
        })
      }
      if (!isExpenseSubtotal && !isExpenseSubtotalFixed) {
        this.$message.warning('请填写生产预算列表内容')
        return false
      }
      this.$refs[ref].tableDataSource.forEach(item => {
        delete item.id
      })
      let params = {
        id: jbxxData.id || '',
        version: jbxxData.version,
        status: jbxxData.status,
        assignId: jbxxData.assignId,
        assignName: jbxxData.assignName,
        assignNum: jbxxData.assignNum,
        assignAmount: jbxxData.assignAmount,
        contractId: jbxxData.contractId,
        contractCode: jbxxData.contractCode,
        contractName: jbxxData.contractName,
        applierId: jbxxData.applierId,
        applierName: jbxxData.applierName,
        applyDate: jbxxData.applyDate,
        budgetTotalAmount: jbxxData.budgetTotalAmount,
        businessDeptId: jbxxData.nowReceiveDeptId,
        businessDeptName: jbxxData.nowReceiveDept,
        businessFenDeptId: jbxxData.nowReceiveFenDeptId,
        businessFenDeptName: jbxxData.nowReceiveFenDept,
        finBudgetEstablishmentScZdyDTOS: this.$refs[ref].tableDataSource,
        finBudgetEstablishmentScGdDTOS: this.$refs[ref].tableDataFixed,
      }

      if (params.status == 'CW-YSBZ-ZT-QY') {

        postApi(`/finance/budget/establishment/revies/check/sc`, params).then(res => {
          if (res.data.code == 0) {
            this.$message.success('校验成功');
            this.isCheck = true;
            this.addEdit.type = 'detail';
            this.addEdit.showStep = false;
            this.checkXdId = res.data.data
            this.addEdit.id = res.data.data;
            this.$refs[ref].getDetailData(res.data.data);
            this.getTableList()
          }
        })
      }
      else {
        postApi(`/finance/budget/establishment/check/sc`, params).then(res => {
          if (res.data.code == 0) {
            this.$message.success('校验成功');
            this.isCheck = true;
            this.addEdit.type = 'detail';
            this.checkId = res.data.data
            this.addEdit.id = res.data.data;
            this.$refs[ref].getDetailData(res.data.data);
            this.getTableList()
          }
        })
      }


    },
    handleSubmit(type, ref) { // 弹框保存或提交
      let params = {}
      let flag = false;
      let popupType = '';
      if (ref == 'addEditPopPro') {
        popupType = 'sc';
        let jbxxData = { ...this.$refs[ref].$refs.assignForm.formData, ...this.$refs[ref].$refs.PersonForm.formData };
        this.$refs[ref].$refs.assignForm.$refs.assignForm.validate((valid) => {
          if (valid) {
            flag = true;
          }
        });
        if (!flag) {
          return false;
        }
        if (!jbxxData.budgetTotalAmount || jbxxData.budgetTotalAmount == 0) {
          this.$message.warning('生产预算总额不能为0！')
          return false
        }
        let isExpenseSubtotal = false;
        let isExpenseSubtotalFixed = false;
        if (this.$refs[ref].tableDataSource && this.$refs[ref].tableDataSource.length) {
          this.$refs[ref].tableDataSource.forEach(item => {
            if (item.expenseSubtotal && Number(item.expenseSubtotal) > 0) {
              if (!item.jobContent || !item.measureUnit || !item.totalEffort || !item.workType || !item.efficiency) {
                isExpenseSubtotal = false;
              }
              else {
                isExpenseSubtotal = true;
              }
            }
          })
        }

        if (this.$refs[ref].tableDataFixed && this.$refs[ref].tableDataFixed.length) {
          this.$refs[ref].tableDataFixed.forEach(item => {
            if (item.expenseSubtotal && Number(item.expenseSubtotal) > 0) {
              if (!item.jobContent || !item.measureUnit || !item.totalEffort || !item.workType || !item.efficiency) {
                isExpenseSubtotalFixed = false;
              }
              else {
                isExpenseSubtotalFixed = true;
              }
            }
          })
        }
        if (!isExpenseSubtotal && !isExpenseSubtotalFixed) {
          this.$message.warning('请填写生产预算列表内容')
          return false
        }
        this.$refs[ref].tableDataSource.forEach(item => {
          delete item.id
        })
        params = {
          id: jbxxData.id || '',
          version: jbxxData.version,
          status: jbxxData.status,
          assignId: jbxxData.assignId,
          assignName: jbxxData.assignName,
          assignNum: jbxxData.assignNum,
          assignAmount: jbxxData.assignAmount,
          contractId: jbxxData.contractId,
          contractCode: jbxxData.contractCode,
          contractName: jbxxData.contractName,
          applierId: jbxxData.applierId,
          applierName: jbxxData.applierName,
          applyDate: jbxxData.applyDate,
          budgetTotalAmount: jbxxData.budgetTotalAmount,
          businessDeptId: jbxxData.nowReceiveDeptId,
          businessDeptName: jbxxData.nowReceiveDept,
          businessFenDeptId: jbxxData.nowReceiveFenDeptId,
          businessFenDeptName: jbxxData.nowReceiveFenDept,
          finBudgetEstablishmentScZdyDTOS: this.$refs[ref].tableDataSource,
          finBudgetEstablishmentScGdDTOS: this.$refs[ref].tableDataFixed,
        }
      }
      else {
        popupType = 'xs';
        this.$refs[ref].$refs.assignFormSales.$refs.assignFormSales.validate((valid) => {
          if (valid) {
            flag = true;
          }
        });
        if (!flag) {
          return false;
        }
        let jbxxData = { ...this.$refs[ref].$refs.assignFormSales.formData, ...this.$refs[ref].$refs.personFormSales.formData };
        if (!jbxxData.budgetTotalAmount || jbxxData.budgetTotalAmount == 0) {
          this.$message.warning('生产预算总额不能为0！')
          return false
        }
        params = {
          id: jbxxData.id || '',
          version: jbxxData.version,
          status: jbxxData.status,
          assignId: jbxxData.assignId,
          assignName: jbxxData.assignName,
          assignNum: jbxxData.assignNum,
          assignAmount: jbxxData.assignAmount,
          contractId: jbxxData.contractId,
          contractCode: jbxxData.contractCode,
          contractName: jbxxData.contractName,
          applierId: jbxxData.applierId,
          applierName: jbxxData.applierName,
          applyDate: jbxxData.applyDate,
          budgetTotalAmount: jbxxData.budgetTotalAmount,
          businessDeptId: jbxxData.nowDownDeptId,
          businessDeptName: jbxxData.nowDownDept,
          businessFenDeptId: jbxxData.nowDownFenDeptId,
          businessFenDeptName: jbxxData.nowDownFenDept,
          finBudgetEstablishmentXsGdDTOS: this.$refs[ref].tableData
        }
      }
      if (params.status == 'CW-YSBZ-ZT-QY' || (params.status == 'CW-YSBZ-ZT-XDBZZ')) {
        if (ref == 'addEditPopPro') {
          if(type == 0){
            console.log("params",params);

            postApi(`/finance/budget/establishment/revies/save/sc`, params).then(res => {
              if (res.data.code == 0) {
                this.$message.success('修订成功');
                this.getTableList()
              }
              else {
                this.$message.error(res.data.msg);
              }
            }).finally(() => {
              this.dialogAddEditProVisible = false;
            })

          }else if(type == 1){
            let pid = this.checkXdId ? this.checkXdId : this.addEdit.id;            
            postApi(`/finance/budget/establishment/revies/submit/sc`, { id: pid }).then(res => {
              if (res.data.code == 0) {
                this.$message.success('修订成功');
                this.getTableList()
              }
              else {
                this.$message.error(res.data.msg);
              }
            }).finally(() => {
              this.dialogAddEditProVisible = false;
            })
          }
        }
        else {
          postApi('/finance/budget/establishment/revise', params).then(res => {
            if (res.data.code == 0) {
              this.$message.success('修订成功');
              this.getTableList()
            }
            else {
              this.$message.error(res.data.msg);
            }
          }).finally(() => {
            this.dialogAddEditSalesVisible = false;
          })
        }
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
      }
      else {
        if (type == 1 && ref == 'addEditPopPro') {
          postApi(`/finance/budget/establishment/submit/sc`, { id: this.checkId }).then(res => {
            if (res.data.code == 0) {
              this.$message.success('提交成功')
              this.dialogAddEditProVisible = false;
              this.isCheck = false;
              this.getTableList();
            }
          })
        }
        else {
          postApi(`/finance/budget/establishment/${popupType}/${type}`, params).then(res => {
            if (res.data.code == 0) {
              if (type == 0) {
                this.$message.success('保存成功');
              }
              else {
                this.$message.success('提交成功');
              }
              if (popupType == 'sc') {
                this.dialogAddEditProVisible = false;
              }
              else {
                this.dialogAddEditSalesVisible = false;
              }
              this.getTableList();
            }
          })
        }
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
      }
    },
    getTableList(obj) { // 获取预算申请列表
      let params = {};
      if (obj) {
        params = obj
      }
      postApi(`/finance/budget/establishment/page/${this.current}/${this.size}`, params).then(res => {
        if (res.data.code == 0) {
          this.total = res.data.data.total;
          this.tableData = res.data.data.records;
        }
      })
    },
    handleSelectionChange(e) { // 表格 select 状态
      this.selectTable = e;
    },
    handleCheckDisable(row) { // 表格 select 禁用
      return true
    },
    handleCurrentChange(size, number) { // 分页切换
      this.current = number;
      this.size = size;
      this.getTableList(this.searchObj);
    },
    handleExport() { // 导出列表
      if (this.selectTable.length != 1) {
        this.$message.warning('请选择一条数据');
        return false;
      }
      loadApi(`/finance/budget/establishment/export`, { id: this.selectTable[0].id }).then(res => {
        let { data } = res;
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
        this.selectTable = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success('导出成功')
      })
    },
    handleExportBatch() {
      if (this.selectTable.length < 1) {
        this.$message.warning('请至少选择一条数据');
        return false;
      }
      loadApi(`/finance/budget/establishment/batch/export`, { ids: this.selectTable.map((item) => {return item.id}) }).then(res => {
        let { data } = res;
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
        this.selectTable = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success('导出成功')
      })
    },
    getAssignList() { // 获取任务书列表
      getApi(`/project/assign/budget/assign/list/sc`).then(res => {
        if (res && res.data.code == 0) {
          let { data } = res;
          let options = {
            value: "assignName",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList_import, options)
        }
      })
    },
    handleDelete() { // 删除
      if (this.selectTable.length < 1) {
        this.$message.warning('请至少选择一条数据');
        return false;
      }
      let isDelete = false;
      this.selectTable.forEach(item => {
        if (item.status !== 'CW-YSBZ-ZT-BZZ') {
          isDelete = true;
        }
      })

      if (isDelete) {
        this.$message.warning('只可删除状态为编制中的数据！');
        return false;
      }

      let params = [];
      this.selectTable.forEach(item => {
        params.push(item.id);
      })

      postApi('/finance/budget/establishment/delete', { ids: params }).then(res => {
        if (res.data.code == 0) {
          this.$message.success('删除成功!')
          this.getTableList();
        }
        else {
          this.$message.warning(res.data.data.msg)
        }
      }).finally(() => {
        this.selectTable = [];
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
      })
    },
    handleUpdate() { // 修订
      if (this.selectTable.length != 1) {
        this.$message.warning('请选择一条数据');
        return false;
      }
      if (this.selectTable[0].status !== 'CW-YSBZ-ZT-QY') {
        this.$message.warning('非启用状态不可修订');
        return false;
      }
      getApi(`/finance/budget/establishment/revise/status/${this.selectTable[0].id}`).then(res => {
        if (res.data.code == 0) {
          this.saveBtn = false;
          this.submitBtn = true
          this.addEdit.showStep = true;
          this.addEdit.type = 'edit'
          this.addEdit.jbxxDisabled = true;
          this.addEdit.id = this.selectTable[0].id;
          if (this.selectTable[0].budgetType == 'CW-YSBZ-YSLX-SC') {
            this.dialogAddEditProVisible = true;
          }
          else {
            this.dialogAddEditSalesVisible = true;
          }
        }
      })
    },
    handleClose(e) {
      this.dialogAddEditProVisible = false;
      this.dialogAddEditSalesVisible = false;
      if (this.addEdit.type == 'edit' || this.addEdit.type == 'add') {
        this.getTableList();
      }
      this.importVisible = false;
      this.saveBtn = true;
      this.isCheck = false;
      this.submitBtn = true
    },
    getStatusList() { // 获取预算编制状态
      const params = {
        typeCode: "CW-YSBZ-ZT"
      }
      postApi('/finance/dict/list/all', params).then(res => {
        if (res.data.code == 0) {
          let { data } = res;
          let options = {
            value: "status",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options)
        }
      })
    },
    getTypeList() { // 获取预算编制类型
      const params = {
        typeCode: "BUDGET-TYPE-CW-YSBZ-YSLX"
      }
      postApi('/finance/dict/list/all', params).then(res => {
        if (res.data.code == 0) {
          let { data } = res;
          let options = {
            value: "budgetType",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options)
        }
      })
    },
    getFenDeptList() { // 获取分管部门数据
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "businessFenDeptId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    getBusinessFenDeptList() {
      // /admin/dept/getdeptlistbyorgid
      postApi(`/admin/dept/getdeptlistbyorgid`, { orgId: sessionStorage.getItem("orgId") }).then(res => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "businessDeptId",
            optionsList: data.data,
          };

          this.setOPtionsList(this.formList, options);
        }
      })
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleImport() {
      this.getAssignList();
      this.importVisible = true
    },
    downloadImportTemplate() {
      loadApi(`/finance/budget/establishment/export/template`).then(res => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success('下载成功')
      })
    },
    handleconfirm() {
      let flag = false
      this.$refs.assignForm_import.$refs.assignForm_import.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return false;
      }
      let fileData = new FormData();
      if(!this.file) {
        this.$message.warning('附件不能为空')
        return false;
      }
      fileData.append('file', this.file)
      postApi(`/finance/budget/establishment/import?id=${this.$refs.assignForm_import.formData.assignName}`, fileData).then(res => {
        if(res.data.code == 0) {
          this.$message.success('操作成功')
        }
        else {
          this.$message.warning('操作失败，请稍后重试')
        }
        this.getTableList()
      })
      
      this.importVisible = false;
    },
    httpRequest(e) {
      this.file = {};
      this.file = e.file;
    },
    //附件上传移除
    handleRemove(file, fileList) {
      this.file = {};
      this.fileList.map((item, index) => {
        if (item.fileName == file.fileName) {
          this.fileList.splice(index, 1);
        }
      });
    },
    //附件限制
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件`);
    },
    //附件上传成功
    handleFileSuccess(res, file) {
      if (res.code == 0) {
        this.fileList.push(res.data);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top_box {
  display: flex;
  align-items: center;


.top_box_name {
  padding: 0 15px;
  position: relative;
  box-sizing: border-box;
  height: 45px;
  line-height: 45px;
  font-weight: bold;
  font-size: 14px;
  color: #606266;
  .require{
      position: absolute;
      top: 0px;
      left: 5px;
    }
}

  .right_box {
    display: flex;
    align-items: center;

    .content {
      margin-right: 20px;
    }
  }
}
</style>