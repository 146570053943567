<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form :form-config="formList" :flex=2 :ref="formRef"></dd-form>
    </dd-card>
    <dd-card title="用车汇总列表">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        max-height="380"
        border
        ref="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
        :isSelection="isSelection"
      >
        <template #table-btn v-if="type == 'add' || type == 'update'">
          <dd-button
            type="primary"
            size="medium"
            icon="add"
            autoConfirm
            @click="handleAddEdit(0)"
            >新增</dd-button
          >
          <dd-button
            type="danger"
            icon="delete"
            size="medium"
            autoConfirm
            @click="handleDelete"
            >删除</dd-button
          >
        </template>
      </dd-table>
      <el-form :model="{}" ref="ruleForm" class="form_wrap">
        <div
          class="group-height group-center"
          style="position: fixed; bottom: 15px; right: 35px"
        >
          <div v-if="type == 'add' || type == 'update'">
            <dd-button slot="button" size="medium" @click="cancel"
              >取消
            </dd-button>
            <dd-button
              slot="button"
              type="primary"
              size="medium"
              @click="submitOrSave(0)"
              >保存
            </dd-button>
            <dd-button
              slot="button"
              type="primary"
              size="medium"
              @click="submitOrSave(1)"
              >提交
            </dd-button>
          </div>
        </div>
      </el-form>
      <dd-popup
        v-if="dialogVisible"
        width="90%"
        height="80%"
        showBtn
        :title-name="addEditTitle"
        :show="dialogVisible"
        @popup-save="handlePopupSave"
        @popup-close="dialogVisible = false"
        :inPopup="true"
      >
        <AddApplyList
          ref="addRef"
          :collectDepId="collectDepId"
          slot="main"
        ></AddApplyList>
      </dd-popup>
      <dd-popup
        v-if="dialogDetailVisible"
        width="90%"
        height="90%"
        title-name="详情"
        :show="dialogDetailVisible"
        @popup-close="dialogDetailVisible = false"
        :inPopup="true"
      >
        <AddUpdateDetailInfoPop
          slot="main"
          :type="applyType"
          :id="applyId"
          @diaChange="diaChange"
        ></AddUpdateDetailInfoPop>
      </dd-popup>
    </dd-card>
    <!-- 单图片预览 -->
    <el-image-viewer
      v-if="isspaImgePreview"
      :urlList="[spaImgePreviewUrl]"
      :on-close="closeViewer"
    ></el-image-viewer>
  </div>
</template>
  
<script>
import AddUpdateDetailInfoPop from "../../Apply/components/AddUpdateDetailInfoPop";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import AddApplyList from "../addApplyList/index.vue";
import qs from "qs";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    collectDepIdProp: {
      type: String,
      default: '',
    },
    collectDepName:{
      type: String,
      default: '',
    }
  },
  components: { AddApplyList, AddUpdateDetailInfoPop, ElImageViewer },
  data() {
    return {
      sumId: null,
      formRef: "baseForm",
      drawerVisible: false,
      dialogVisible: false,
      dialogDetailVisible: false,
      collectDepId: null,
      loading: false,
      ruleForm: {},
      applyId: null,
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      formList: [
        {
          label: "汇总单编号",
          value: "collectCode",
          inputType: "text",
          defaultValue: "",
          disabled: true,
          placeholderText: "自动生成",
        },
        {
          label: "上报月份",
          value: "reportMonth",
          placeholderText: "上报月份",
          inputType: "date",
          dateType: "month",
          format: "yyyy-MM",
          defaultValue: [],
          disabled: false,
          rules: [
            { required: true, message: "请选择上报月份", trigger: "blur" },
          ],
        },
        {
          label: "部门",
          value: "collectDepId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          change: this.handleChange,
          rules: [
            { required: true, message: "请选择或搜索部门", trigger: "blur" },
          ],
        },
      ],
      // 表格选择数据集合
      multipleSelection: [],
      deptObj:{},
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置

      // 表格头部配置数据
      tableColumns: [
        {
          title: "用车类型",
          key: "carSubsidyTypeName",
          align: "center",
          width: 80,
        },
        {
          title: "填报人",
          key: "applierName",
          align: "center",
        },
        {
          title: "收款人",
          key: "payeeName",
          align: "center",
        },
        {
          title: "申请时间",
          key: "applyTime",
          align: "center",
          width: 100,
        },
        {
          title: "用车事宜",
          key: "vehicleMatter",
          align: "left",
          width: 100,
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "left",
          width: 150,
          overflow: true,
        },
        {
          title: "项目编码",
          key: "projectCode",
          align: "left",
          width: 110,
          overflow: true,
        },
        {
          title: "起始日期",
          key: "vehicleStartDate",
          align: "center",
          width: 100,
        },
        {
          title: "终止日期",
          key: "vehicleEndDate",
          align: "center",
          width: 100,
        },
        {
          title: "起始公里",
          key: "startKilometer",
          align: "center",
          href: [
            {
              method: (row) => {
                this.handleImgPreview(row, "startKilometer");
              },
            },
          ],
        },
        {
          title: "结束公里",
          key: "endKilometer",
          align: "center",
          href: [
            {
              method: (row) => {
                this.handleImgPreview(row, "endKilometer");
              },
            },
          ],
        },
        {
          title: "核定公里",
          key: "approvedKilometer",
          align: "center",
          width: 100,
        },
        {
          title: "标准",
          key: "standardParameter",
          align: "center",
          width: 50,
        },
        {
          title: "用车核定金额(元)",
          key: "approvedAmount",
          align: "center",
        },
        {
          title: "过路费金额(元)",
          key: "tollAmount",
          align: "center",
        },
        {
          title: "过路费核定金额(元)",
          key: "tollVerifyAmount",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
      isspaImgePreview: false,
      spaImgePreviewUrl: "",
      isSelection: true,
    };
  },
  created() {},
  mounted() {
    if(this.type == "update") {
      this.formList[1].disabled = true;
      this.formList[2].disabled = true;
    }
    this.getDetailInfo();
    this.getConditionData(); //获取部门数据
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    //获取部门数据
    getConditionData() {
      getApi(`/admin/dept/getcollectdeptlist`).then((res) => {
        this.formList.forEach((item) => {
          if (item.value == "collectDepId") {
            item.optionsList = res.data.data;
          }
        });
      });
    },
    handleChange(e) {
      if(e) {
        let name = '';
        let deptId = ''
        this.formList[2].optionsList.forEach(item => {
          if(item.deptId == e) {
            name = item.name;
            deptId = item.deptId
          }
        })
        this.deptObj = {
          depName: name,
          deptId: deptId,
        }
      }
      if(this.tableDataSources.length > 0) {
        this.tableDataSources = [];
      }
    },
    // diaChange(e) {
    //   this.dialogAddEditVisible = e;
    //   this.dialogApplyBasicInfoVisible = e;
    //   this.dialogDetailVisible = e;
    //   console.log(e);
    //   this.id = null;
    //   this.state = null;
    //   this.$refs.dTableRef.$refs.dTableRef.clearSelection();
    //   this.getApplyList();
    // },
    handlePopupSave() {
      let list = this.$refs.addRef.multipleSelection
      this.dialogVisible = false;
      this.tableDataSources = list.filter((element, index, self) => {
        return self.findIndex((x) => x.id === element.id) === index;
      });
      this.$nextTick(() => {
        this.$refs.dTableRef.$refs.dTableRef.clearSelection();
      });
    },
    // 汇总详情
    getDetailInfo() {
      if (this.type == "update" || this.type == "detail") {
        this.sumId = this.id;
        this.loading = true;
        getApi(`/hr/collectsheet/detail/${this.id}`, {}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            data.data.list.forEach((item) => {
              item.standardParameter = formatNumber(item.standardParameter);
              item.approvedAmount = formatNumber(item.approvedAmount);
              item.tollAmount = formatNumber(item.tollAmount);
              item.tollVerifyAmount = formatNumber(item.tollVerifyAmount);
            });
            this.tableDataSources = data.data.list;
            this.formList[0].defaultValue = data.data.collectCode;
            this.formList[1].defaultValue = data.data.reportMonth;
            this.formList[2].defaultValue = data.data.collectDepName;
            if (this.type == "detail") {
              this.isSelection = false;
              this.formList[1].disabled = true;
            }
          }
        });
      }
    },
    // 申请单详情
    handleDetailInfo(row) {
      this.applyId = row.id;
      this.applyType = "detail";
      this.dialogDetailVisible = true;
    },
    //单图片预览
    handleImgPreview(row, type) {
      console.log(row, type);
      if (type == "startKilometer") {
        this.spaImgePreviewUrl = row.hrVehicleApplySheetFileList[0].url;
      } else {
        this.spaImgePreviewUrl = row.hrVehicleApplySheetFileList[1].url;
      }
      this.isspaImgePreview = true;
    },
    closeViewer() {
      this.isspaImgePreview = false;
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    handleAddEdit(type) {
      // type 0: 新建；1：编辑
      if (type == 0) {
        let { formData } = this.$refs.baseForm;
        if (!formData.reportMonth || !formData.reportMonth.length) {
          this.$message.warning("请选择上报月份");
          return;
        }
        if (!formData.collectDepId || !formData.collectDepId.length) {
          this.$message.warning("请选择部门");
          return;
        }
      
        this.id = null;
        this.addEditTitle = "添加申请单";
        this.type = "add";
        this.dialogVisible = true;
        this.collectDepId = this.collectDepIdProp ? this.collectDepIdProp : this.$refs.baseForm.formData.collectDepId;
        this.$nextTick(() => {
          this.$refs.addRef.feedbackMultipleSelection(this.tableDataSources);
        });
      }
    },
    handleDelete() {
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      let list = this.tableDataSources.filter((v) => {
        return this.multipleSelection.every((e) => e.id != v.id);
      });
      this.tableDataSources = list;
      this.$refs.dTableRef.$refs.dTableRef.clearSelection();
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "approvedAmount" ||
          column.property == "approvedKilometer"||
            column.property == "tollAmount" ||
            column.property == "tollVerifyAmount"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];

      list.map((item, index) => {
        if (index == sums.length - 1) {
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //提交1保存0
    submitOrSave(type) {
      let { formData } = this.$refs.baseForm;
      console.log(formData);

      //确认
      if (!formData.reportMonth || !formData.reportMonth.length) {
        this.$message.warning("请选择上报月份");
        return;
      }else if (!formData.collectDepId || !formData.collectDepId.length) {
          this.$message.warning("请选择部门");
          return;
        }
        else if (!this.tableDataSources.length) {
        this.$message.warning("请选择申请单");
        return;
      }
      let hrVehicleApplySheetList = [];
      this.tableDataSources.map((item) => {
        hrVehicleApplySheetList.push({
          id: item.id,
          payeeCode: item.payeeCode,
          payeeId: item.payeeId,
          payeeName: item.payeeName,
          projectName: item.projectName,
          projectCode: item.projectCode,
          projectId: item.projectId,
          approvedKilometer: item.approvedKilometer,
          standardParameter: item.standardParameter,
          approvedAmount: item.approvedAmount,
        });
      });
      let params = {
        id: this.sumId,
        // type,
        collectCode: formData.collectCode,
        reportMonth: formData.reportMonth,
        collectierName: sessionStorage.getItem("fullName"),
        collectierId: sessionStorage.getItem("userId"),
        collectOrgName: sessionStorage.getItem("orgName"),
        collectOrgId: sessionStorage.getItem("orgId"),
        collectDepName: this.deptObj.depName,
        collectDepId: this.deptObj.deptId,
        hrVehicleApplySheetList,
      };
      console.log("params", params);
      this.$confirm(
        `每个月只能申请一个汇总单,是否${type == 0 ? "保存" : "提交"}报账日期为${
          formData.reportMonth
        }的汇总单？`,
        "提示",
        {
          confirmButtonClass: "btnsearch",
          confirmButtonText: "确认",
          cancelButtonClass: "btnreset",
          cancelButtonText: "取消",
          showClose: true,
          type: "warning",
        }
      )
        .then(() => {
          postApi(`/hr/collectsheet/combine/${type}`, params)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message.success(
                  `${type == 0 ? "保存成功！" : "提交成功！"}`
                );
                this.$emit("diaChange", false);
              } else {
                this.$message.error(data.msg);
                this.$message({
                  message: data.msg /*  */,
                  type: "error",
                  duration: 6000,
                });
              }
            })
            .catch((data) => {
              if (
                data.response &&
                data.response.data &&
                data.response.data.msg
              ) {
                this.$message({
                  message: data.response.data.msg,
                  type: "error",
                  duration: 6000,
                });
              } else {
                this.$message({
                  message: data,
                  type: "error",
                  duration: 6000,
                });
              }
            });
        })
        .catch((err) => {
          this.$message.info(`${type == 0 ? "已取消保存" : "已取消确定"}`);
        });
    },
    //弹框取消回调事件
    cancel() {
      this.$emit("diaChange", false);
    },
  },
};
</script>
  
<style lang='less' scoped>
</style>