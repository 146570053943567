<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          :labelWidth="'190px'"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>

      <dd-card title="考勤审查" :height="tableHeight">
        <dd-table
          :columns="columnsList"
          :data="tableData"
          border
          :isSelection="true"
          show-page
          :loading="loading"
          @select-change="handleSelectionChange"
          ref="dTableRef"
        >
          <template #table-btn>
            <dd-button type="primary" size="medium" @click="handleBatchAudit"
              >批量审核
            </dd-button>
            <dd-button type="primary" size="medium" @click="handleReturnAudit"
              >反审核
            </dd-button>
          </template>
          <template #table-btn-right>
            <el-button
              icon="el-icon-s-operation"
              circle
              type="info"
              size="small"
              @click="drawerVisible = true"
              title="列筛选"
            ></el-button>
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handlePageChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>

      <dd-filter-drawer
        v-if="drawerVisible"
        width="30%"
        title-name="列筛选"
        :show="drawerVisible"
        :tableColumns="tableColumns"
        :columns="columnsList"
        @popup-close="drawerVisible = false"
        @handleColumnsChange="handleColumnsChange"
      >
      </dd-filter-drawer>
      <!--  -->
      <dd-popup
        v-if="dialogVisible"
        width="20%"
        height="20%"
        showBtn
        :cancelBtn="true"
        :saveBtn="false"
        :submitBtn="false"
        :show="dialogVisible"
        title-name="批量审核"
        @popup-close="handleClose"
      >
        <dd-button
          slot="button"
          size="medium"
          type="danger"
          @click="handleReject"
          >不通过</dd-button
        >
        <dd-button
          slot="button"
          size="medium"
          type="success"
          @click="handleAccept"
          >通过</dd-button
        >
        <div slot="main" class="dialog-content">
          <i
            class="el-icon-warning"
            style="color: orange; margin-right: 10px"
          ></i>
          <span>批量审核选择数据！</span>
        </div>
      </dd-popup>
    </div>
    <!-- 详情 -->
    <dd-popup
      width="40%"
      height="60%"
      :show="infoPopupVisible"
      title-name="签到地点"
      @popup-close="handleClose1"
    >
      <info-pop
        v-if="infoPopupVisible"
        slot="main"
        :pointInfo="pointInfo"
      ></info-pop>
    </dd-popup>
  </div>
</template>
    <script>
import { getApi, postApi } from "@/api/request";
import infoPop from "../attendQuery/components/InfoPop.vue";
export default {
  name: "attendAudit",
  $dynamicRoute: {
    name: "考勤审查",
    path: "/checkingIn/attendAudit/index",
  },
  components: {
    infoPop,
  },
  data() {
    return {
      infoPopupVisible: false,
      pointInfo: "",
      drawerVisible: false,
      dialogVisible: false,
      tableHeight: null,
      loading: false,
      formData: {
        startTimeStr: this.getlastMonth(),
        verifyStatus: "type-wqdksp-dsh"
      },
      tableData: [],
      formList: [
        {
          label: "签到人工号",
          value: "username",
          inputType: "text",
          placeholderText: "请输入签到人工号进行搜索",
        },
        {
          label: "签到人姓名",
          value: "userFullName",
          inputType: "text",
          placeholderText: "请输入签到人姓名进行搜索",
        },
        {
          label: "审核状态",
          value: "verifyStatus",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择审核状态进行搜索",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          defaultValue: "type-wqdksp-dsh"
        },
        {
          label: "所属考勤月",
          value: "attendMonth",
          inputType: "date",
          dateType: "month",
          format: "yyyy-MM",
          placeholderText: "请选择所属考勤月进行搜索",
        },
        {
          label: "开始时间",
          value: "startTimeStr",
          inputType: "date",
          placeholderText: "请选择开始时间进行搜索",
          defaultValue: this.getlastMonth(),
        },
        {
          label: "结束时间",
          value: "endTimeStr",
          inputType: "date",
          placeholderText: "请选择结束时间进行搜索",
          defaultValue: null,
        },
      ],
      columnsList: [
        {
          title: "签到人工号",
          key: "username",
          align: "center",
          overflow: true,
        },
        {
          title: "签到人",
          key: "userFullName",
          align: "center",
        },
        {
          title: "签到时间",
          key: "signTime",
          align: "center",
        },
        {
          title: "签到节点",
          key: "signTimeNode",
          align: "center",
        },
        {
          title: "签到状态",
          key: "statusName",
          align: "center",
        },
        {
          title: "签到地点",
          key: "signAddress",
          align: "center",
        },
        {
          title: "签到位置",
          align: "center",
          event: [
            {
              show: "true",
              label: "查看详情",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
          ],
        },
        {
          title: "所属部门",
          key: "deptName",
          align: "center",
          width: "150px",
        },
        {
          title: "审核状态",
          key: "verifyStatusName",
          align: "center",
          width: "150px",
        },
        {
          title: "审核人",
          key: "auditName",
          align: "center",
          width: "150px",
        },
        {
          title: "审核日期",
          key: "auditDate",
          align: "center",
          width: "150px",
        },
        {
          title: "操作",
          width: 200,
          align: "center",
          event: [
            {
              show: "true",
              label: "通过",
              method: (row) => {
                this.handleAgree(row);
              },
              type: "success",
              rules: (row) => {
                let show = false;
                if (row.verifyStatus == "type-wqdksp-dsh") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              show: "true",
              label: "不通过",
              method: (row) => {
                this.handleDisAgress(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.verifyStatus == "type-wqdksp-dsh") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableColumns: [],
      total: 0,
      current: 1,
      size: 10,
      deptId: "",
      selectTable: [],
    };
  },
  mounted() {
    this.tableColumns = [...this.columnsList];
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(1);
    });
    this.getElmentList();
    this.getStatusList();
  },
  methods: {
    handleInfo(row) {
      this.pointInfo = row.inlocation;
      this.infoPopupVisible = true;
    },
    handleClose1() {
      this.pointInfo = "";
      this.infoPopupVisible = false;
    },
    getlastMonth() {
      // 获取当前日期
      const currentDate = new Date();

      // 创建一个新的日期对象，设定为上个月
      const lastMonthDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );

      // 获取上个月的年和月
      const lastMonthYear = lastMonthDate.getFullYear();
      const lastMonthMonth = lastMonthDate.getMonth() + 1; // 月份从 0 开始，所以加 1
      return (
        lastMonthYear +
        "-" +
        lastMonthMonth.toString().padStart(2, "0") +
        "-" +
        "01"
      );
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    getStatusList() {
      postApi(`/hr/hrdict/list?typeCode=type-wqdksp-status`).then((res) => {
        this.formList.forEach((item) => {
          let { data } = res;
          if (item.value == "verifyStatus") {
            item.optionsList = data.data;
          }
        });
      });
    },
    handleDisAgress(row) {
      let type = 0;
      let param = {
        id: row.id,
      };
      postApi(`/hr/attend/monthinfo/audit/${type}`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("审批成功");
        } else {
          this.$message.warning("审批失败，请稍后重试");
        }
        this.getElmentList();
      });
    },
    handleAgree(row) {
      let type = 1;
      let param = {
        id: row.id,
      };
      postApi(`/hr/attend/monthinfo/audit/${type}`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("审批成功");
        } else {
          this.$message.warning("审批失败，请稍后重试");
        }
        this.getElmentList();
      });
    },
    handleAccept() {
      let type = 1;
      let param = {
        voList: this.selectTable,
      };
      postApi(`/hr/attend/monthinfo/batch/accept/${type}`, param).then(
        (res) => {
          if (res.data.code == 0) {
            this.$message.success("审批成功");
          } else {
            this.$message.warning("审批失败，请稍后重试");
          }
          this.dialogVisible = false;
          this.getElmentList();
          this.selectTable = [];
          this.$refs.dTableRef.$refs.baseTable.clearSelection();
        }
      );
    },
    handleReject() {
      let type = 0;
      let param = {
        voList: this.selectTable,
      };
      postApi(`/hr/attend/monthinfo/batch/accept/${type}`, param).then(
        (res) => {
          if (res.data.code == 0) {
            this.$message.success("审批成功");
          } else {
            this.$message.warning("审批失败，请稍后重试");
          }
          this.dialogVisible = false;
          this.getElmentList();
          this.selectTable = [];
          this.$refs.dTableRef.$refs.baseTable.clearSelection();
        }
      );
    },
    handleBatchAudit() {
      if (this.selectTable.length == 0) {
        this.$message.warning("请选择批量审核数据");
        return false;
      }
      let flag = false;
      this.selectTable.forEach((item) => {
        if (item.verifyStatus != "type-wqdksp-dsh") {
          flag = true;
        }
      });
      if (flag) {
        this.$message.warning("只能选择待审核状态的数据！");
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
        this.selectTable = [];
        return false;
      }
      this.dialogVisible = true;
    },
    handleReturnAudit() {
      if (this.selectTable.length == 0) {
        this.$message.warning("请选择批量反审核数据");
        return false;
      }
      let flag = false;
      this.selectTable.forEach((item) => {
        if (
          item.verifyStatus != "type-wqdksp-tg" &&
          item.verifyStatus != "type-wqdksp-btg"
        ) {
          flag = true;
        }
      });
      if (flag) {
        this.$message.warning("只能选择通过或不通过状态的数据！");
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
        this.selectTable = [];
        return false;
      }

      let type = -1;
      let param = {
        voList: this.selectTable,
      };
      postApi(`/hr/attend/monthinfo/batch/accept/${type}`, param).then(
        (res) => {
          if (res.data.code == 0) {
            this.$message.success("审批成功");
          } else {
            this.$message.warning("审批失败，请稍后重试");
          }
          this.dialogVisible = false;
          this.getElmentList();
          this.selectTable = [];
          this.$refs.dTableRef.$refs.baseTable.clearSelection();
        }
      );
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handlePageChange(size, number) {
      this.size = size;
      this.current = number;
      this.getElmentList();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getElmentList();
    },
    getElmentList() {
      let { formData } = this;
      let param = {
        ...formData,
        auditPage: "auditPage",
      };
      postApi(
        `/hr/attend/monthinfo/page2/${this.current}/${this.size}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.selectTable = e;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
    
  <style lang="less" scoped>
.dialog-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* 添加底部间距 */
}
</style>
    