<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :formRef="formRef"
        ref="baseFormDetail"
        :flex="3"
        labelWidth="130px"
      ></dd-form>
    </dd-card>
    <dd-card  title="过路费附件">
      <div  v-show="value1" class="ibox-content" style="height: 200px; overflow: auto">
        <el-upload
            :auto-upload="true"
            class="upload-demo"
            ref="upload"
            action="/admin/sys-file/upload"
            :on-preview="handlePictureCardPreview"
            multiple
            :file-list="fileList"
            list-type="picture"
        >
          <div slot="tip" class="el-upload__tip" ref="filesValid"></div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import qs from "qs";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    type: "",
    id: "",
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      detailiInfo: {},
      hrVehicleApplySheetFileList: [],
      formRef: "baseFormDetail",
      headers: {}, // 请求头信息
      ruleForm: {},
      id: null,
      value: "",
      fileList: [],
      fileListParam: [],
      dialogImageUrl: "",
      dialogVisible: false,
      value1: false,
      formList: [
        {
          label: "核定公里数",
          value: "approvedKilometer",
          inputType: "text",
          placeholderText: "请输入核定公里数",
          defaultValue: "",
          input: this.handleInput,
          rules: [
            { required: true, message: "请输入核定公里数", trigger: "blur" },
            {
              pattern: /^[1-9]\d{0,10}$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],

    },
        {
          label: "金额(元)",
          disabled: true,
          value: "approvedAmount",
          inputType: "text",
          placeholderText: "自动计算",
          defaultValue: "0.00",
        },
        {
          label: "过路费审核金额",
          value: "tollVerifyAmount",
          inputType: "text",
          placeholderText: "请输入过路费金额",
          show: false,
          disabled: true,
          defaultValue: "",
          rules: [
            { required: true, message: "请输入过路费金额", trigger: "blur" },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
      {
          label: "用车补助类型",
          value: "carSubsidyTypeName",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
          defaultValue: '项目用车',
          placeholderText: "请选择用车补助类型",
        },
        {
          label: "申请编号",
          value: "applyCode",
          inputType: "text",
          placeholderText: "保存后生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请人",
          value: "applierName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "费用承担组织",
          value: "expenseBearOrgName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "费用承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索费用承担部门",
        },
        {
          label: "项目名称",
          value: "projectName",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectName",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索项目名称",
        },
        {
          label: "项目编码",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectCode",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索项目编码",
        },
        {
          label: "收款人员工号",
          value: "payeeCode",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "username",
            name: "username",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索收款人员工号",
        },
        {
          label: "收款人",
          value: "payeeId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索收款人",
        },
        {
          label: "用车起始日期",
          value: "vehicleStartDate",
          placeholderText: "用车起始日期",
          inputType: "date",
          dateType: "date",
          defaultValue: "",
        },
        {
          label: "用车结束日期",
          value: "vehicleEndDate",
          placeholderText: "用车结束日期",
          inputType: "date",
          dateType: "date",
          defaultValue: "",
        },
        {
          label: "出发地",
          value: "departurePlace",
          inputType: "text",
          placeholderText: "请输入出发地",
          defaultValue: "",
        },
        {
          label: "目的地",
          value: "destinationPlace",
          inputType: "text",
          placeholderText: "请输入目的地",
          defaultValue: "",
        },
        {
          label: "起始公里数",
          value: "startKilometer",
          inputType: "text",
          placeholderText: "请输入起始公里数,例如1000.00或1000",
          defaultValue: "",
        },
        {
          label: "结束公里数",
          value: "endKilometer",
          inputType: "text",
          placeholderText: "请输入结束公里数,例如1000.00或1000",
          defaultValue: "",
        },
        {
          label: "用车公里数",
          disabled: true,
          value: "totalKm",
          inputType: "text",
          placeholderText: "自动计算",
          defaultValue: "",
        },

        {
          label: "标准(元/km)",
          disabled: true,
          value: "standardParameter",
          inputType: "text",
          defaultValue: "",
        },

        {
          label: "起始公里图片",
          value: "startImg",
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "startImg");
          },
          defaultValue: "",
        },
        {
          label: "结束公里图片",
          value: "endImg",
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "endImg");
          },
          defaultValue: "",
        },
        {
          label: "用车事宜",
          value: "vehicleMatter",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入用车事宜",
          defaultValue: "",
        },
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          defaultValue: "",
        },
        {
          label: "过路费选择",
          value: "tollSign",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          filterable: true,
          disabled: true,
          placeholderText: "请选择是否存在过路费",
          optionsListConfig: {
            code: "code",
            name: "name",
          },
        },
        {
          label: "过路费金额",
          value: "tollAmount",
          inputType: "text",
          placeholderText: "请输入过路费金额",
          show: false,
          disabled: true,
          defaultValue: "",
        },
      ],
    };
  },
  mounted() {
    let headers = {
      Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
    };
    this.formList.forEach((item) => {
      if (item.inputType == "upload") {
        item.headers = headers;
      }
    });
    this.getExpenseDepartmentList();
    this.getTollSignType();
    this.getAccountingStandards();
    this.getProjectList(); //项目列表
    this.getCollectionList(); //收款人列表
    this.getDetailInfo();

  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getTollSignType() {
      postApi(`/hr/hrdict/list?typeCode=type-sfxz`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "tollSign") {
              item.optionsList = data.data;
              item.defaultValue = 'type-sfxz-false';
            }
          });
        }
      });
    },
    //项目列表
    getProjectList() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "projectName" || item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //收款人列表
    getCollectionList() {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "payeeCode" || item.value == "payeeId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //费用承担部门
    getExpenseDepartmentList() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.options = {
          value: "expenseBearDepId",
          optionsList: res.data.data,
        };
        this.setOPtionsList(this.formList, this.options);
      });
    },
    // 获取标准数据
    getAccountingStandards() {
      postApi(`/hr/hrdict/list?typeCode=gcsyjsbz`, {}).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "standard") {
              item.defaultValue = data.data[0].name.split("/")[0];
            }
          });
        }
      });
    },
    //编辑页面信息回显
    getDetailInfo() {
      getApi(`/hr/applysheet/${this.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.detailiInfo = data.data;
          let startImg = "";
          let endImg = "";
          this.fileList = res.data.data.tollSignAttachFileList;
          this.fileListParam = res.data.data.tollSignAttachFileList;
          data.data.hrVehicleApplySheetFileList &&
            data.data.hrVehicleApplySheetFileList.length &&
            data.data.hrVehicleApplySheetFileList.map((val) => {
              if (val.sign == "begin") {
                startImg = val.url;
              } else if (val.sign == "end") {
                endImg = val.url;
              }
            });
          this.formList.forEach((item) => {
            item.defaultValue = data.data[item.value];
            item.disabled = true;
            if (item.value == "tollAmount") {

              if(res.data.data.tollSign=='type-sfxz-true'){
                this.value1 = true;
                item.show = true;
              }else if (res.data.data.tollSign=='type-sfxz-false'){
                this.value1 = false;
                item.show = false;
              }
            }
            if (item.value == "tollVerifyAmount") {
              item.disabled = false;
              if(res.data.data.tollSign=='type-sfxz-true'){
                this.value1 = true;
                item.show = true;
              }else if (res.data.data.tollSign=='type-sfxz-false'){
                this.value1 = false;
                item.show = false;
              }
            }
            if (item.value == "startImg") {
              item.defaultValue = startImg;
            } else if (item.value == "endImg") {
              item.defaultValue = endImg;
            } else if (item.value == "totalKm") {
              if (data.data.endKilometer && data.data.startKilometer) {
                item.defaultValue =
                  data.data.endKilometer - data.data.startKilometer;
              } else {
                item.defaultValue = 0;
              }
            } else if (item.value == "approvedKilometer") {
              item.disabled = this.type == "detail" ? true : false;
            }
          });
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true
      this.dialogImageUrl = file.url
    },
    handleInput() {
      let { formData } = this.$refs.baseFormDetail;
      this.formList.forEach((item) => {
        if (item.value == "approvedAmount") {
          item.defaultValue = formData.approvedKilometer *(formData.standardParameter*10)/10;
        } else {
          item.defaultValue = formData[item.value];
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>