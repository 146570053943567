<template>
  <div class="container">
    <dd-card title="条件搜索">
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>

    <dd-card title="付款单列表">
      <dd-table
        ref="baseTable_1"
        :columns="tableColumns"
        :data="tableData"
        show-summary
        :summary-method="getSummaries"
        border
        :isSelection="false"
        :loading="loading"
        max-height="560"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            size="medium"
            icon="export"
            @click="handleExport"
            >导出
          </dd-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 金额复核确认 -->
    <dd-popup
      v-if="checkEditPopupVisible"
      width="35%"
      height="60%"
      inPopup
      showBtn
      :cancelBtn="true"
      :saveBtn="true"
      :submitBtn="false"
      :show="checkEditPopupVisible"
      :title-name="checkEditPopupTitle"
      @popup-save="handleSave"
      @popup-close="handleClose"
    >
      <checkEditPopup
        ref="checkEditPopup"
        :checkProp="checkProp"
        slot="main"
      ></checkEditPopup>
    </dd-popup>

    <!--修改记录  -->
    <dd-popup
      v-if="recordPopupVisible"
      width="90%"
      height="90%"
      inPopup
      showBtn
      :cancelBtn="true"
      :saveBtn="false"
      :submitBtn="false"
      :show="recordPopupVisible"
      title-name="修改记录"
      @popup-close="handleClose"
    >
      <recordPopup ref="recordPopup" :recordProp="recordProp" slot="main">
      </recordPopup>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi, loadApi } from "@/api/request";
import checkEditPopup from "./checkEditPopup.vue";
import recordPopup from "./recordPopup.vue";
import { downloadBinaryFile } from "@/utils/index";
export default {
  name: "checkDetail",
  props: ["pageProp"],
  components: { checkEditPopup, recordPopup },
  data() {
    return {
      tableColumns: [
        {
          title: "金蝶付款单编号",
          key: "paymentfnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶付款单名称",
          key: "paymentfname",
          align: "center",
          overflow: true,
        },
        {
          title: "归属部门",
          key: "deptName",
          align: "center",
          overflow: true,
        },
        {
          title: "关联实付金额",
          key: "allotmentAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "复核金额",
          key: "checkAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "复核状态",
          key: "checkStatus",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "供应商",
          key: "fparta",
          align: "center",
          overflow: true,
        },
        {
          title: "付款期间",
          key: "fperiod",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 300,
          event: [
            {
              state: "default",
              label: "金额复核确认",
              type: "success",
              method: (row) => {
                this.handleRowCheck(row);
              },
              rules: (row) => {
                let show = false;
                if (row.checkStatus == "未复核") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              state: "default",
              label: "金额复核修改",
              type: "warning",
              method: (row) => {
                this.handleRowEdit(row);
              },
              rules: (row) => {
                let show = false;
                if (row.checkStatus == "已复核") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              state: "default",
              label: "查询修改记录",
              type: "primary",
              method: (row) => {
                this.handleRowRecord(row);
              },
              rules: (row) => {
                let show = false;
                if (row.checkStatus == "已复核") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      recordTableData: [],
      tableData: [],
      formList: [
        {
          label: "金蝶付款单编号",
          value: "paymentfnumber",
          inputType: "text",
          placeholderText: "请输入金蝶付款单编号进行搜索",
        },
        {
          label: "金蝶付款单名称",
          value: "paymentfname",
          inputType: "text",
          placeholderText: "请输入金蝶付款单名称进行搜索",
        },
        {
          label: "归属部门",
          value: "deptName",
          inputType: "text",
          placeholderText: "请输入归属部门进行搜索",
        },
        {
          label: "复核状态",
          value: "checkStatus",
          inputType: "select",
          filterable: true,
          optionsList: [
            { code: "未复核", name: "未复核" },
            { code: "已复核", name: "已复核" },
          ],
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择复核状态进行搜索",
        },
        {
          label: "付款期间",
          value: "fperiod",
          inputType: "text",
          placeholderText: "请选择付款期间进行搜索",
        },
      ],
      loading: false,
      current: 1,
      size: 10,
      seachData: {},
      total: 0,
      checkEditPopupTitle: "",
      checkEditPopupVisible: false,
      checkProp: {},
      recordPopupVisible: false,
      recordProp: {},
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {},
  methods: {
    handleExport() {
      let param = {
        ...this.seachData,
        id: this.pageProp.id,
      };
      loadApi(
        `/contract/selfestablished/procurement/check/payment/export`,param
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handleSave() {
      let flag = false;
      this.$refs.checkEditPopup.$refs.checkEditPopup.$refs.checkEditPopup.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }
      if (this.checkProp.pageType == "check") {
        let param = {
          id: this.checkProp.id,
          checkAmount:
            this.$refs.checkEditPopup.$refs.checkEditPopup.formData.checkAmount,
        };
        postApi(
          `/contract/contractselfpurchasepaymentrelation/check`,
          param
        ).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("修改成功");
          } else {
            this.$message.success("修改失败，请稍后重试");
          }
          this.checkEditPopupVisible = false;
          this.getTableData();
        });
      } else {
        let param = {
          id: this.checkProp.id,
          checkAmount:
            this.$refs.checkEditPopup.$refs.checkEditPopup.formData.checkAmount,
          remark:
            this.$refs.checkEditPopup.$refs.checkEditPopup.formData.remark,
        };
        postApi(
          `/contract/contractselfpurchasepaymentrelation/checkd`,
          param
        ).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("修改成功");
          } else {
            this.$message.success("修改失败，请稍后重试");
          }
          this.checkEditPopupVisible = false;
          this.getTableData();
        });
      }
    },
    handleClose() {
      this.checkEditPopupVisible = false;
      this.recordPopupVisible = false;
    },
    getTableData() {
      let param = {
        ...this.seachData,
        id: this.pageProp.id,
      };
      postApi(
        `/contract/selfestablished/procurement/check/payement/${this.current}/${this.size}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.total = res.data.data.total;
          this.tableData = res.data.data.records;
        }
      });
    },
    handleRowCheck(row) {
      this.checkEditPopupTitle = "金额复核确认";
      this.checkEditPopupVisible = true;
      this.checkProp = row;
      this.checkProp.pageType = "check";
    },
    handleRowEdit(row) {
      this.checkEditPopupTitle = "金额复核修改";
      this.checkEditPopupVisible = true;
      this.checkProp = row;
      this.checkProp.pageType = "edit";
    },
    handleRowRecord(row) {
      this.recordPopupVisible = true;
      this.recordProp = row;
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getTableData();
    },
    handleSearchEvent(e) {
      this.current = 1;
      this.seachData = e;
      this.getTableData();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "allotmentAmount" ||
          column.property == "checkAmount"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-table__fixed-body-wrapper {
  top: 40px !important;
  height: calc(100% - 48px) !important;
}
</style>