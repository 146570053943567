<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        labelWidth="180px"
      ></dd-search-form>
    </dd-card>
    <dd-card title="报账单比对列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        :isSelection="false"
        border
        ref="dTableRef"
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <el-button
            icon="el-icon-download"
            circle
            type="info"
            @click="handleFileExport"
            size="small"
            title="下载"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="currentPage"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      width="65%"
      showBtn
      :saveBtn="false"
      :cancelBtn="false"
      :show="ensurePopupVisible"
      title-name="报账单比对信息确认"
      @popup-close="ensurePopupVisible = false"
    >
      <dd-button slot="button" size="medium" @click="popupClose"
        >取消</dd-button
      >
      <dd-button slot="button" type="primary" size="medium" @click="handleSave"
        >保存</dd-button
      >
      <ensure-pop
        v-if="ensurePopupVisible"
        slot="main"
        ref="ensure"
        :kindeeCode="kindeeCode"
        :ensureRemark="ensureRemark"
      ></ensure-pop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils";
import { getObjValue } from "@/utils/index";
import EnsurePop from "./components/EnsurePop.vue";

export default {
  name: "reportCompare",
  $dynamicRoute: {
    name: "报账单比对",
    path: "/finance/reportBill/reportCompare",
    meta: {
      keepAlive: true,
    },
  },
  components: {
    EnsurePop,
  },
  data() {
    return {
      tableHeight: null,
      loading: false,
      // 页码
      currentPage: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      // 接收表单返回数据
      formData: {},
      dialogDetailVisible: false,
      detailCompareTitle: "",
      projectId: "",
      drawerVisible: false,
      multipleSelection: [],
      sshExpenseTypesOption: {},
      kindeeExpenseTypesOption: {},
      ensurePopupVisible: false,
      kindeeCode: null,
      ensureId: null,
      ensureRemark: null,
      // 条件查询表单配置配置
      formList: [
        {
          label: "实时化报账单号",
          value: "sshCode",
          inputType: "text",
          placeholderText: "请输入报账单号",
        },
        {
          label: "金蝶报账单号",
          value: "kindeeCode",
          inputType: "text",
          placeholderText: "请输入报账单号",
        },
        {
          label: "实时化报账人",
          value: "sshApplier",
          inputType: "text",
          placeholderText: "请输入报账人姓名",
        },
        {
          label: "金蝶报账人",
          value: "kindeeApplier",
          inputType: "text",
          placeholderText: "请输入报账人姓名",
        },
        {
          label: "实时化项目名称",
          value: "sshProjectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "实时化项目编码",
          value: "sshProjectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "金蝶项目名称",
          value: "kindeeProjectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "金蝶项目编码",
          value: "kindeeProjectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "实时化业务类别",
          value: "sshExpenseTypeId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择业务类别",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {
          label: "金蝶业务类别",
          value: "kindeeExpenseTypeId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择业务类别",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "实时化报账单号",
          key: "sshCode",
          align: "center",
          width: 145,
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, ["sshCode", "kindeeCode"]);
            },
          },
          event: [
            {
              type: "primary",
              href: (row) => {
                return row.sshCode;
              },
              method: (row) => {
                this.handleRowHref(row);
              },
            },
          ],
        },
        {
          title: "金蝶报账单号",
          key: "kindeeCode",
          align: "center",
          width: 135,
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, ["sshCode", "kindeeCode"]);
            },
          },
        },
        {
          title: "实时化报账人",
          key: "sshApplier",
          align: "center",
          width: 80,
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshApplier",
                "kindeeApplier",
              ]);
            },
          },
        },
        {
          title: "金蝶报账人",
          key: "kindeeApplier",
          align: "center",
          width: 80,
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshApplier",
                "kindeeApplier",
              ]);
            },
          },
        },
        {
          title: "实时化项目名称",
          key: "sshProjectName",
          align: "left",
          width: 230,
          overflow: true,
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshProjectName",
                "kindeeProjectName",
              ]);
            },
          },
        },
        {
          title: "金蝶项目名称",
          key: "kindeeProjectName",
          align: "left",
          width: 230,
          overflow: true,
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshProjectName",
                "kindeeProjectName",
              ]);
            },
          },
        },
        {
          title: "实时化项目编码",
          key: "sshProjectCode",
          align: "left",
          width: 130,
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshProjectCode",
                "kindeeProjectCode",
              ]);
            },
          },
        },
        {
          title: "金蝶项目编码",
          key: "kindeeProjectCode",
          align: "left",
          width: 130,
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshProjectCode",
                "kindeeProjectCode",
              ]);
            },
          },
        },
        // {
        //   title: "实时化单号",
        //   key: "sshCode",
        //   align: "center",
        //   width: 150,
        // },
        {
          title: "实时化业务类别",
          key: "sshExpenseTypeName",
          align: "center",
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshExpenseTypeName",
                "kindeeExpenseTypeName",
              ]);
            },
          },
        },
        {
          title: "金蝶业务类别",
          key: "kindeeExpenseTypeName",
          align: "center",
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshExpenseTypeName",
                "kindeeExpenseTypeName",
              ]);
            },
          },
        },
        {
          title: "实时化金额(元)",
          key: "sshReviewAmount",
          align: "center",
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshReviewAmount",
                "kindeeReviewAmount",
              ]);
            },
          },
        },
        // {
        //   title: "金蝶单号",
        //   key: "kindeeCode",
        //   align: "center",
        //   width: 150,
        // },
        {
          title: "金蝶金额(元)",
          key: "kindeeReviewAmount",
          align: "center",
          styleEvent: {
            method: (row) => {
              return this.handleStyleEvent(row, [
                "sshReviewAmount",
                "kindeeReviewAmount",
              ]);
            },
          },
        },
        {
          title: "备注",
          key: "ensureRemark",
          align: "center",
          width: 180,
          fixed: "right",
          overflow: true,
          contentEvent: {
            method: (row, key) => {
              return this.handleContentEvent(row, key);
            },
          },
        },
        {
          title: "错误类别",
          key: "errorTypeName",
          align: "center",
          width: 180,
          fixed: "right",
        },
        {
          title: "操作",
          align: "center",
          width: 80,
          fixed: "right",
          event: [
            {
              show: "true",
              label: "备注",
              type: "primary",
              method: (row) => {
                this.handleRowEnsure(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    setTimeout(() => {
      //获取项目信息
      this.getProject();
      // 获取业务类别
      this.getOneLevelExpenseTypes();
    }, 500);
    this.getDataList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    getDataList() {
      let { formData, currentPage, pageSize } = this;
      let params = {
        ...formData,
        curOrgId: sessionStorage.getItem("orgId"),
      };
      postApi(
        `/finance/claims/compare/list/${currentPage}/${pageSize}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.sshReviewAmount = formatNumber(item.sshReviewAmount);
            item.kindeeReviewAmount = formatNumber(item.kindeeReviewAmount);
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleSearchEvent(obj) {
      this.formData.sshCode = obj.sshCode;
      this.formData.kindeeCode = obj.kindeeCode;
      this.formData.sshApplier = obj.sshApplier;
      this.formData.kindeeApplier = obj.kindeeApplier;
      this.formData.sshProjectId = obj.sshProjectId;
      this.formData.kindeeProjectId = obj.kindeeProjectId;
      this.formData.sshExpenseTypeId = obj.sshExpenseTypeId;
      this.formData.kindeeExpenseTypeId = obj.kindeeExpenseTypeId;
      this.pageNumber = 1;
      this.getDataList();
    },
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.currentPage = pageNumber;
      this.getDataList();
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/get/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.options = {
            value: "sshProjectId",
            optionsList: data.data,
          };
          this.projectCodeOptions = {
            value: "kindeeProjectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, this.options);
          this.setOPtionsList(this.formList, this.projectCodeOptions);
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleFileExport() {
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });

      this.formData["curOrgId"] = sessionStorage.getItem("orgId");

      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };

      let params = {
        formData,
        ids,
      };

      loadApi(`/finance/claims/compare/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    //处理样式事件
    handleStyleEvent(row, keys) {
      if (row[keys[0]] != row[keys[1]]) {
        return `color:red`;
      }
    },
    handleRowHref(row, e) {
      if (row.sshCode != "-") {
        this.$router.push("/finance/reportBill/list");
      }
    },
    //获取业务类别
    getOneLevelExpenseTypes() {
      getApi(`/finance/expense/typeList`).then((res) => {
        let { data } = res;
        if (data) {
          this.sshExpenseTypesOption = {
            value: "sshExpenseTypeId",
            optionsList: data,
          };

          this.kindeeExpenseTypesOption = {
            value: "kindeeExpenseTypeId",
            optionsList: data,
          };

          this.setOPtionsList(this.formList, this.sshExpenseTypesOption);
          this.setOPtionsList(this.formList, this.kindeeExpenseTypesOption);
        }
      });
    },
    handleRowEnsure(row) {
      this.ensurePopupVisible = true;
      this.kindeeCode = row.kindeeCode;
      this.ensureId = row.finExpensesClaimsSheetCompareEnsure.id;
      this.ensureRemark = row.finExpensesClaimsSheetCompareEnsure.remark;
    },
    //关闭事件---审核弹窗
    popupClose() {
      this.ensurePopupVisible = false;
      this.kindeeCode = null;
      this.ensureId = null;
      this.ensureRemark = null;
    },
    handleSave() {
      this.formData["orgId"] = sessionStorage.getItem("orgId");
      this.formData["kindeeCode"] = this.kindeeCode;
      this.formData["id"] = this.ensureId;
      this.formData["remark"] =
        this.$refs.ensure.$refs.ensureRef.formData.remark;

      postApi(`/finance/claims/compare/ensure`, this.formData).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("操作成功");
          this.formData["kindeeCode"] = "";
          this.popupClose();
          this.getDataList();
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    handleContentEvent(row, key) {
      if (key.indexOf(".") != -1) {
        return getObjValue(row, key);
      } else {
        return row[key];
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>
