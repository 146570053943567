<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form :form-config="formList" :ref="formRef" :formRef="formRef" :flex="3" labelWidth="130px"></dd-form>
    </dd-card>
    <dd-card  title="过路费附件">
      <div  v-show="value1" class="ibox-content" style="height: 200px; overflow: auto">
        <el-upload
            :auto-upload="true"
            class="upload-demo"
            ref="upload"
            action="/admin/sys-file/upload"
            :headers="headers"
            :on-remove="handleRemove"
            :on-success="beforeSuccess"
            :on-preview="handlePictureCardPreview"
            multiple
            :limit="10"
            :on-exceed="handleExceed"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
            list-type="picture"
        >
          <el-button  size="small" type="primary"
          ><span class="iconfont" style="margin-right: 5px"></span>
            选择文件
          </el-button>
          <div slot="tip" class="el-upload__tip">
            <i>*</i> 只能上传图片文件,且不超过5M
          </div>
          <div slot="tip" class="el-upload__tip" ref="filesValid"></div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
    </dd-card>

  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { getNowDate } from "@/utils/date";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    type: "",
    id: "",
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      formRef: "baseForm",
      hrVehicleApplySheetFileList: [],
      tableData: [],
      fileList: [],
      fileListParam: [],
      dialogImageUrl: "",
      dialogVisible: false,
      value1: false,
      // 请求头信息
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
      },
      formList: [
        {
          label: "申请单编号",
          value: "applyCode",
          inputType: "text",
          placeholderText: "自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请人",
          value: "applierName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: sessionStorage.getItem("fullName"),
        },
        {
          label: "费用承担组织",
          value: "expenseBearOrgName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: sessionStorage.getItem("orgName"),
        },
        {
          label: "费用承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
          defaultValue: sessionStorage.getItem("depId"),
          placeholderText: "请选择或搜索费用承担部门",
          rules: [
            { required: true, message: "请选择费用承担部门", trigger: "blur" },
          ],
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectName",
          },
          defaultValue: "",
          filterable: true,
          change: this.handleSelectChange,
          clear: this.handleSelectClear,
          placeholderText: "请选择或搜索项目名称",
          rules: [
            { required: true, message: "请选择项目名称", trigger: "blur" },
          ],
        },
        {
          label: "项目编码",
          value: "projectId",
          filterable: true,
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectCode",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索项目编码",
          rules: [
            { required: true, message: "请选择项目编码", trigger: "blur" },
          ],
        },
        {
          label: "用车补助类型",
          value: "carSubsidyType",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "code",
            name: "name",
          },
          defaultValue: '',
          placeholderText: "请选择用车补助类型",
          rules: [
            { required: true, message: "请选择用车补助类型", trigger: "blur" },
          ],
        },
        {
          label: "收款人员工号",
          value: "payeeId",
          filterable: true,
          disabled: true,
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "username",
          },
          defaultValue: sessionStorage.getItem("username"),
          placeholderText: "请选择或搜索收款人员工号",
          rules: [
            { required: true, message: "请选择收款人员工号", trigger: "blur" },
          ],
        },
        {
          label: "收款人",
          value: "payeeId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          defaultValue: sessionStorage.getItem("userId"),
          placeholderText: "请选择或搜索收款人",
          rules: [{ required: true, message: "请选择收款人", trigger: "blur" }],
        },
        {
          label: "用车起始日期",
          value: "vehicleStartDate",
          placeholderText: "用车起始日期",
          inputType: "date",
          dateType: "date",
          change: this.handleChange,
          defaultValue: getNowDate(),
          format: "yyyy-MM-dd",
          rules: [
            { required: true, message: "请选择用车起始日期", trigger: "blur" },
          ],
        },
        {
          label: "用车结束日期",
          value: "vehicleEndDate",
          placeholderText: "用车结束日期",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          change: this.handleChange,
          defaultValue: getNowDate(),
          rules: [
            { required: true, message: "请选择用车结束日期", trigger: "blur" },
          ],
        },
        {},
        {
          label: "出发地",
          value: "departurePlace",
          inputType: "text",
          placeholderText: "请输入出发地",
          defaultValue: "",
          rules: [{ required: true, message: "请输入出发地", trigger: "blur" }],
        },
        {
          label: "目的地",
          style: "width:50%",
          value: "destinationPlace",
          inputType: "text",
          placeholderText: "请输入目的地",
          defaultValue: "",
          rules: [{ required: true, message: "请输入目的地", trigger: "blur" }],
        },
        {},
        {
          label: "起始公里数",
          value: "startKilometer",
          ref: "startKilometer",
          change: this.handleChange,
          inputType: "text",
          placeholderText: "请输入正整数,最大不超过8位数",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入起始公里数", trigger: "blur" },
            {
              pattern: /^[1-9]\d{0,10}$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "结束公里数",
          ref: "endKilometer",
          change: this.handleChange,
          value: "endKilometer",
          inputType: "text",
          placeholderText: "请输入正整数,最大不超过8位数",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入结束公里数", trigger: "blur" },
            {
              pattern: /^[1-9]\d{0,10}$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "用车公里数",
          disabled: true,
          value: "totalKm",
          inputType: "text",
          placeholderText: "自动计算",
          defaultValue: "",
        },
        {
          label: "起始公里图片",
          value: "begin",
          ref: "begin",
          icon: "icon-licheng",
          action: "/admin/sys-file/upload",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
          },
          accept: "image/*",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleFileRemove,
          placeholderText: "点击可替换之前上传图片",
          successEvent: (res) => {
            this.handleFileSuccess(res,'begin');
          },
          defaultValue: "",
          rules: [
            { required: true, message: "请选择起始公里图片", trigger: "blur" },
          ],
        },
        {
          label: "结束公里图片",
          value: "end",
          ref: "end",
          icon: "icon-licheng",
          action: "/admin/sys-file/upload",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
          },
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleFileRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res,'end');
          },
          defaultValue: "",
          rules: [
            { required: true, message: "请选择结束公里图片", trigger: "blur" },
          ],
        },
        {},
        {
          label: "用车事宜",
          value: "vehicleMatter",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入用车事宜",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入用车事宜", trigger: "blur" },
            {
              min: 1,
              max: 100,
              message: "长度在 1 到 100个字符",
              trigger: "blur",
            },
          ],
        },
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          defaultValue: "",
          rules: [
            {
              max: 100,
              message: "长度在 1 到 100个字符",
              trigger: "blur",
            },
          ],
        },
        {},
        {
          label: "过路费选择",
          value: "tollSign",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          filterable: true,
          placeholderText: "请选择是否存在过路费",
          optionsListConfig: {
            code: "code",
            name: "name",
          },
          rules: [
            {
              required: true,
              message: "请选择是否存在过路费",
              trigger: "blur",
            },
          ],
          change: this.tollSignSelect,
        },
        {
          label: "过路费金额",
          value: "tollAmount",
          inputType: "text",
          placeholderText: "请输入过路费金额",
          show: false,
          defaultValue: "",
          rules: [
            { required: true, message: "请输入过路费金额", trigger: "blur" },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getExpenseDepartmentList(); //承担部门
    this.getProjectList(); //项目列表
    this.getCollectionList(); //收款人列表
    this.getCarSubsidyType(); // 用车补助类型
    this.getTollSignType(); // 用车补助类型
    if (this.type == "update" || this.type == "detail") {
      this.getDetailInfo();
    }
  },
  methods: {
    // 用车补助类型
    getCarSubsidyType() {
      postApi(`/hr/hrdict/list?typeCode=ycbzlx`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "carSubsidyType") {
              item.optionsList = data.data;
              item.defaultValue = 'ycbzlx-scgy';
            }
          });
        }
      });
    },
    getTollSignType() {
      postApi(`/hr/hrdict/list?typeCode=type-sfxz`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "tollSign") {
              item.optionsList = data.data;
              item.defaultValue = 'type-sfxz-false';
            }
          });
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
     //项目列表
     getProjectList() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //收款人列表
    getCollectionList() {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "payeeId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //费用承担部门
    getExpenseDepartmentList() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.options = {
          value: "expenseBearDepId",
          optionsList: res.data.data,
        };
        this.setOPtionsList(this.formList, this.options);
      });
    },
    //编辑页面信息回显
    getDetailInfo() {
      getApi(`/hr/applysheet/${this.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.hrVehicleApplySheetFileList = data.data.hrVehicleApplySheetFileList;
          this.fileList = res.data.data.tollSignAttachFileList;
          this.fileListParam = res.data.data.tollSignAttachFileList;
          this.formList.forEach((item) => {
            if (this.type == "detail") {
              item.disabled = true;
            }
            if (item.value == "tollAmount") {

              if(res.data.data.tollSign=='type-sfxz-true'){
                this.value1 = true;
                item.show = true;
              }else if (res.data.data.tollSign=='type-sfxz-false'){
                this.value1 = false;
                item.show = false;
              }
            }
            item.defaultValue = data.data[item.value];
            item.rules = this.type == "update" ? item.rules : [];
            if (item.value == "totalKm") {
              if (data.data.endKilometer && data.data.startKilometer) {
                item.defaultValue =
                  data.data.endKilometer - data.data.startKilometer;
              } else {
                item.defaultValue = 0;
              }
            }
            data.data.hrVehicleApplySheetFileList.forEach((val) => {
              if(item.value == val.sign) {
                  item.defaultValue = val.url;
              }
            })
          });
        }
      });
    },
    handleChange(e, type) {
      let { startKilometer, endKilometer, vehicleStartDate, vehicleEndDate } = this.$refs.baseForm.formData
      if (type == "startKilometer" || type == "endKilometer") {
        startKilometer = Number(startKilometer);
        endKilometer = Number(endKilometer);
        if (startKilometer && endKilometer) {
          if (endKilometer > startKilometer) {
            this.$refs.baseForm.formData.totalKm = endKilometer - startKilometer;
          } else {
            this.$refs.baseForm.formData.totalKm = "";
            this.$message.warning("结束公里数需大于起始公里数");
          }
        }
      } else if (type == "vehicleStartDate" || type == "vehicleEndDate") {
        if (vehicleStartDate && vehicleEndDate) {
          vehicleStartDate = vehicleStartDate.replace(/-/g, "/");
          vehicleEndDate = vehicleEndDate.replace(/-/g, "/");
          let start = new Date(vehicleStartDate).getTime();
          let end = new Date(vehicleEndDate).getTime();
          if (end < start) {
            this.$message.warning("用车结束时间数需大于用车起始时间");
            this.$refs.baseForm.formData.vehicleStartDate = "";
            this.$refs.baseForm.formData.vehicleEndDate = "";
            return;
          }
        }
      }
    },
    tollSignSelect() {
      let { tollSign } = this.$refs.baseForm.formData;

      if (tollSign == "type-sfxz-true"){

        this.value1 = true;
        this.formList.forEach((item) => {
          item.defaultValue = this.$refs.baseForm.formData[item.value];
          if (item.value == "tollAmount") {
            item.show = true;
          }
        });

      }else if (tollSign == "type-sfxz-false"){
        this.value1 = false;
        this.formList.forEach((item) => {
          item.defaultValue = this.$refs.baseForm.formData[item.value];
          if (item.value == "tollAmount") {
            item.show = false;
          }

        });
      }
    },
    //成功上传文件
    handleFileSuccess(res, type) {
      let { url } = res.data;
      console.log("data",res.data);
      console.log("url",url)

      let obj = {};
      obj = res.data;
      obj.sign = type;
      console.log("obj",obj)
      this.$refs.baseForm.formData[type] = url;
      this.hrVehicleApplySheetFileList.push(obj);
    },
    //删除文件
    handleFileRemove(type) {
      this.hrVehicleApplySheetFileList.forEach((item,index) => {
        if (item.sign == type) {
          this.hrVehicleApplySheetFileList.splice(index,1)
        }
      });
      this.$refs.baseForm.formData[type] = "";
      this.$refs.baseForm.$refs[type][0].clearFiles()
    },
    //只能上传图片类型
    beforeAvatarUpload(file) {
      const isJPG =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传图片只能是 jpg/jpeg/png格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传附件图片大小不能超过 5MB!");
      }
      return isJPG && isLt5M;
    },
    //附件上传移除
    handleRemove(file, fileList) {
      this.fileList.map((item, index) => {
        if (item.name == file.name) {
          this.fileList.splice(index, 1);
        }
      });

      this.fileListParam = this.fileList;
    },
    //附件限制
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择10个文件，本次选择了 ${files.length} 个文件，共选择了 ${
              files.length + fileList.length
          } 个文件`
      );
    },
    // 附件上传成功回调
    beforeSuccess(response, file, fileList) {
      if (response.code == 0) {
        this.fileListParam = this.fileListParam.concat(response.data);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true
      this.dialogImageUrl = file.url
    },
  },
};
</script>

<style lang='less' scoped>
/deep/ .card-contant {
  overflow: hidden !important;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor !important;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>