<template>
  <div class="main-container">
    <dd-card title="用车汇总列表">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        height="550"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        :isSelection="false"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogDetailVisible"
      width="96%"
      height="90%"
      title-name="详情"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
      :inPopup="true"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        :type="applyType"
        :id="applyDetailId"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <!-- 单图片预览 -->
    <el-image-viewer
      v-if="isspaImgePreview"
      :urlList="[spaImgePreviewUrl]"
      :on-close="closeViewer"
    ></el-image-viewer>
  </div>
</template>
  
<script>
import { formatNumber } from "@/utils/number";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import AddUpdateDetailInfoPop from "../../../../../PersonalOffice/Hr/WithoutCar/components/AddUpdateDetailInfoPop";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import qs from "qs";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    payeeName: {
      type: String,
      default: "",
    },
  },
  components: { ElImageViewer, AddUpdateDetailInfoPop },
  data() {
    return {
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 表格头部配置数据
      tableColumns: [
        {
          title: "申请编号",
          key: "applyCode",
          align: "center",
          fixed: "left",
          width: 200,
        },
        {
          title: "填报人",
          key: "applierName",
          align: "center",
          width: 100,
        },
        {
          title: "收款人",
          key: "payeeName",
          align: "center",
          width: 100,
        },
        {
          title: "用车事宜",
          key: "rentalcarMatter",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          width: 150,
          overflow: true,
        },
        {
          title: "项目编码",
          key: "projectCode",
          align: "center",
          overflow: true,
          width: 130,
        },
        {
          title: "用车起始日期",
          key: "rentalcarStartDate",
          align: "center",
          width: 100,
        },
        {
          title: "出发地",
          key: "departurePlace",
          align: "center",
          overflow: true,
        },
        {
          title: "目的地",
          key: "destinationPlace",
          align: "center",
        },
        {
          title: "用车终止日期",
          key: "rentalcarEndDate",
          align: "center",
          width: 100,
        },
        {
          title: "起始公里数(Km)",
          key: "startKilometer",
          align: "center",
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.startKilometer
              },
              method: (row) => {
                this.handleImgPreview(row, "startKilometer");
              },
            },
          ],
        },
        {
          title: "结束公里数(Km)",
          key: "endKilometer",
          align: "center",
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.endKilometer
              },
              method: (row) => {
                this.handleImgPreview(row, "endKilometer");
              },
            },
          ],
        },
        {
          title: "申请时间",
          key: "applyTime",
          align: "center",
          width: 200,
        },
        {
          title: "核定公里数(Km)",
          key: "useKilometer",
          align: "center",
          width: 100,
        },
        {
          title: "租车金额(元)",
          key: "rentalcarAmount",
          align: "center",
        },
        {
          title: "核定金额(元)",
          key: "approvedAmount",
          align: "center",
        },
        {
          title: "基础租车费用",
          key: "basicFee",
          align: "center",
          width: 100,
        },
        {
          title: "超出公里数追加费用",
          key: "additionalFee",
          align: "center",
          width: 100,
        },
        {
          title: "租车计价说明",
          key: "pricingInstruction",
          align: "center",
        },
        {
          title: "付款证明",
          key: "payImg",
          align: "center",
          width: 115,
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.payImg
              },
              method: (row) => {
                this.handleImgPreview(row, "payImg");
              },
            },
          ],
        },
        {
          title: "车辆行驶证",
          key: "driverLicenseImg",
          align: "center",
          width: 115,
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.driverLicenseImg
              },
              method: (row) => {
                this.handleImgPreview(row, "driverLicenseImg");
              },
            },
          ],
        },
        {
          title: "驾驶证/身份证",
          key: "driversLicenseImg",
          align: "center",
          width: 115,
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.driversLicenseImg
              },
              method: (row) => {
                this.handleImgPreview(row, "driversLicenseImg");
              },
            },
          ],
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
      dialogDetailVisible: false,
      isspaImgePreview: false,
      spaImgePreviewUrl: "",
      applyDetailId: "",
      applyType: "",
    };
  },
  created() {},
  mounted() {
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    //单图片预览
    handleImgPreview(row, type) {
      if (type == "startKilometer") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[0].url;
      } else if (type == "endKilometer") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[1].url;
      } else if (type == "payImg") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[2].url;
      } else if (type == "driverLicenseImg") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[3].url;
      } else if (type == "driversLicenseImg") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[4].url;
      }
      this.isspaImgePreview = true;
    },
    closeViewer() {
      this.isspaImgePreview = false;
    },
    // 原始用车详情  查看详情
    handleDetailInfo(row) {
      this.applyDetailId = row.id;
      this.applyType = "detail";
      this.dialogDetailVisible = true;
    },
    // 原始用车详情列表
    getApplyList() {
      getApi(
        `/hr/rentalcar/applysheet/audit?current=` +
          this.pageNumber +
          `&size=` +
          this.pageSize +
          `&collectId=` +
          this.id +
          `&applierName=` +
          this.payeeName
      ).then((res) => {
        let { data } = res;
        console.log(res);
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.approvedAmount = formatNumber(item.approvedAmount);
            item.rentalcarAmount = formatNumber(item.rentalcarAmount);
            item.basicFee = formatNumber(item.basicFee);
            item.additionalFee = formatNumber(item.additionalFee);
          });
          this.tableDataSources = data.data.records;
          for (let i = 0; i < this.tableDataSources.length; i++) {
            this.tableDataSources[i].payeeName =
              data.data.records[i].applierName;
            this.tableDataSources[i].payImg = "点击查看图片";
            this.tableDataSources[i].driverLicenseImg = "点击查看图片";
            this.tableDataSources[i].driversLicenseImg = "点击查看图片";
          }
          this.total = data.data.total;
        }
      });
    },

    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "approvedAmount" ||
          column.property == "useKilometer" ||
          column.property == "rentalcarAmount" ||
          column.property == "basicFee" ||
          column.property == "additionalFee"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          console.log(values);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            if (
              column.property == "rentalcarAmount" ||
              column.property == "approvedAmount" ||
              column.property == "basicFee" ||
              column.property == "additionalFee"
            ) {
              sums[index] = formatNumber(sums[index]);
            }
          }
        }
      });
      return sums;
    },
    //分页
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getApplyList();
    },
  },
};
</script>
  
<style lang='less' scoped>
</style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>