<template>
  <div class="container">
    <el-tabs
      v-model="activeName"
      type="card"
      class="tabs_card3333"
      @tab-click="handleClick"
    >
      <el-tab-pane label="相关记录详情" name="相关记录详情">
        <dd-table
          :columns="tableColumns_record"
          :data="tableData_record"
          show-summary
          :summary-method="getRecordSummaries"
          border
          :isSelection="false"
          :loading="loading"
          max-height="650"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="export"
              @click="handleExport"
              >导出
            </dd-button>
          </template>
        </dd-table>
      </el-tab-pane>
      <el-tab-pane label="关联金蝶合同列表" name="关联金蝶合同列表">
        <dd-table
          :columns="tableColumns_kingDee"
          :data="tableData_kingDee"
          show-summary
          border
          :isSelection="false"
          :loading="loading"
          max-height="650"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              icon="export"
              size="medium"
              @click="handlekingExport"
              >导出
            </dd-button>
          </template>
        </dd-table>
      </el-tab-pane>
      <el-tab-pane label="合同结算信息" name="合同结算信息">
        <dd-table
          ref="baseTable_1"
          :columns="tableColumns_settlement"
          :data="tableData_settlement"
          show-summary
          :summary-method="getSettleSummaries"
          border
          :isSelection="false"
          :loading="loading"
          max-height="650"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              icon="export"
              size="medium"
              @click="handlesettleExport"
              >导出
            </dd-button>
          </template>
        </dd-table>
      </el-tab-pane>
      <el-tab-pane label="合同付款信息" name="合同付款信息">
        <dd-table
          :columns="tableColumns_payment"
          :data="tableData_payment"
          show-summary
          :summary-method="getPaymentSummaries"
          border
          :isSelection="false"
          :loading="loading"
          max-height="650"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              icon="export"
              size="medium"
              @click="handlepaymentExport"
              >导出
            </dd-button>
          </template>
        </dd-table>
      </el-tab-pane>
      <el-tab-pane label="开票信息" name="开票信息">
        <dd-table
          :columns="tableColumns_invoicing"
          :data="tableData_invoicing"
          show-summary
          :summary-method="getInvoicingSummaries"
          border
          :isSelection="false"
          :loading="loading"
          max-height="650"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              icon="export"
              size="medium"
              @click="handleinvoiceExport"
              >导出
            </dd-button>
          </template>
        </dd-table>
      </el-tab-pane>
    </el-tabs>

    <dd-popup
      v-if="addEditPopupVisible"
      width="65%"
      height="75%"
      inPopup
      showBtn
      :cancelBtn="true"
      :saveBtn="record.pageType == 'edit'"
      :submitBtn="false"
      :show="addEditPopupVisible"
      :title-name="addEditPopupTitle"
      @popup-save="handleSave"
      @popup-close="handleClose"
    >
      <addEditPopup
        ref="addEditPopup"
        :record="record"
        slot="main"
      ></addEditPopup>
    </dd-popup>

    <dd-popup
      v-if="detailVisible"
      width="90%"
      height="90%"
      showBtn
      :saveBtn="false"
      inPopup
      :submitBtn="false"
      :show="detailVisible"
      title-name="查看详情"
      @popup-close="handleClose"
    >
      <pageDetail
        ref="detailVisible"
        :pageProp="pageProp"
        slot="main"
      ></pageDetail>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, putApi, loadApi } from "@/api/request";
import pageDetail from "../../../procurementContract/components/pageDetail.vue";
import addEditPopup from "./addEditPopup.vue";
import { downloadBinaryFile } from "@/utils/index";
export default {
  name: "contractDetail",
  props: ["pageProp"],
  components: { addEditPopup, pageDetail },
  data() {
    return {
      activeName: "相关记录详情",
      detailVisible: false,
      pageProp: {},
      loading: false,
      tableColumns_record: [
        {
          title: "单据编号",
          key: "code",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同编号",
          key: "saleContractCode",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同名称",
          key: "saleContractName",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同甲方",
          key: "partAId",
          align: "center",
          overflow: true,
        },
        {
          title: "已用金额",
          key: "amount",
          align: "center",
          overflow: true,
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
          overflow: true,
        },
        {
          title: "自建采购合同编号",
          key: "selfContractCode",
          align: "center",
          overflow: true,
        },
        {
          title: "自建采购合同名称",
          key: "selfContractName",
          align: "center",
          overflow: true,
        },
        {
          title: "记录日期",
          key: "recordDate",
          align: "center",
          overflow: true,
        },
        {
          title: "最新修改日期",
          key: "modifyDate",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 200,
          event: [
            {
              state: "default",
              label: "修改",
              type: "warning",
              width: 100,
              method: (row) => {
                this.handleEdit(row);
              },
            },
            {
              state: "default",
              label: "查看",
              width: 100,
              method: (row) => {
                this.handleRowRecordDetail(row);
              },
            },
          ],
        },
      ],
      tableColumns_kingDee: [
        {
          title: "关联金蝶合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "关联金蝶合同编号",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同乙方",
          key: "fpartbname",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同总合同额",
          key: "ftotalamount",
          align: "center",
          overflow: true,
        },
        {
          title: "关联金额",
          key: "allotmentAmount",
          align: "center",
          overflow: true,
        },
        // {
        //   title: "操作",
        //   align: "center",
        //   overflow: true,
        //   width: 100,
        //   event: [
        //     {
        //       state: "default",
        //       label: "查看详情",
        //       width: 100,
        //       method: (row) => {
        //         this.handleRowDetail(row);
        //       },
        //     },
        //   ],
        // },
      ],
      tableColumns_settlement: [
        {
          title: "合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "结算单据编号",
          key: "settlefnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "结算单据名称",
          key: "settlefname",
          align: "center",
          overflow: true,
        },
        {
          title: "业务日期",
          key: "fbizdate",
          align: "center",
          overflow: true,
        },
        {
          title: "期间",
          key: "fperiod",
          align: "center",
          overflow: true,
        },
        {
          title: "结算总金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
        },
        {
          title: "甲方",
          key: "fparta",
          align: "center",
          overflow: true,
        },
        {
          title: "乙方",
          key: "fpartb",
          align: "center",
          overflow: true,
        },
        {
          title: "制单人",
          key: "documenter",
          align: "center",
          overflow: true,
        },
        {
          title: "制单日期",
          key: "fcreatetime",
          align: "center",
          overflow: true,
        },
        // {
        //   title: "说明",
        //   key: "fdescription",
        //   align: "center",
        //   overflow: true,
        // },
        {
          title: "关联结算金额",
          key: "allotmentAmount",
          align: "center",
          overflow: true,
        },
      ],
      tableColumns_payment: [
        {
          title: "单据编号",
          key: "paymentfnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "单据名称",
          key: "paymentfname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "供应商",
          key: "fparta",
          align: "center",
          overflow: true,
        },
        {
          title: "支付类型",
          key: "payType",
          align: "center",
          overflow: true,
        },
        {
          title: "本期实付金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "不含税金额",
          key: "fnotaxdataamount",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "税额",
          key: "ftax",
          align: "center",
          overflow: true,
        },
        {
          title: "付款期间",
          key: "fperiod",
          align: "center",
          overflow: true,
        },
        {
          title: "付款日期",
          key: "fauditdate",
          align: "center",
          overflow: true,
        },
        {
          title: "工程项目",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "制单人",
          key: "documenter",
          align: "center",
          overflow: true,
        },
        {
          title: "关联付款金额",
          key: "allotmentAmount",
          align: "center",
          overflow: true,
          width: 120,
        },
      ],
      tableColumns_invoicing: [
        {
          title: "合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "发票代码",
          key: "invoiceCode",
          align: "center",
          overflow: true,
        },
        {
          title: "发票号码",
          key: "invoiceNum",
          align: "center",
          overflow: true,
        },
        {
          title: "开票日期",
          key: "invoiceDate",
          align: "center",
          overflow: true,
        },
        {
          title: "金额",
          key: "totalAmount",
          align: "center",
          overflow: true,
        },
        // {
        //     title: "税率",
        //     key: "code",
        //     align: "center",
        //     overflow: true,
        // },
        // {
        //     title: "税额",
        //     key: "code",
        //     align: "center",
        //     overflow: true,
        // },
        {
          title: "价税合计金额",
          key: "amountInFiguers",
          align: "center",
          overflow: true,
        },
        {
          title: "发票类型",
          key: "invoiceType",
          align: "center",
          overflow: true,
        },
        {
          title: "有效发票金额",
          key: "validInvoiceAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "开票供应商名称",
          key: "sellerName",
          align: "center",
          overflow: true,
        },
        {
          title: "制单人",
          key: "noteDrawer",
          align: "center",
          overflow: true,
        },
        {
          title: "制单日期",
          key: "applyDate",
          align: "center",
          overflow: true,
        },
        {
          title: "附件",
          align: "center",
          overflow: true,
          width: 140,
          event: [
            {
              type: "primary",
              method: (row) => {
                this.handleView(row);
              },
              href: (row) => {
                return "发票预览";
              },
            },
          ],
        },
        // {
        //     title: "附件",
        //     align: "center",
        //     overflow: true,
        //     image: true,
        //     AttachFiles: "file",
        // },
        {
          title: "关联发票金额",
          key: "allotmentAmount",
          align: "center",
          overflow: true,
        },
      ],
      tableData_invoicing: [],
      tableData_payment: [],
      tableData_settlement: [],
      tableData_kingDee: [],
      tableData: [],
      tableData_record: [],
      addEditPopupTitle: "",
      addEditPopupVisible: false,
      record: {},
    };
  },
  created() {
    this.getPageData();
  },
  mounted() {},
  methods: {
    handleinvoiceExport() {
      loadApi(
        `/contract/selfestablished/procurement/relation/invoice/export?id=${this.pageProp.id}&type=detail`
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handlepaymentExport() {
      loadApi(
        `/contract/selfestablished/procurement/relation/payment/export?id=${this.pageProp.id}&type=detail`
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handlesettleExport() {
      loadApi(
        `/contract/selfestablished/procurement/relation/settle/export?id=${this.pageProp.id}&type=detail`
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handlekingExport() {
      loadApi(
        `/contract/selfestablished/procurement/relation/export?id=${this.pageProp.id}&type=detail`
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handleExport() {
      loadApi(
        `/contract/selfestablished/procurement/record/export?id=${this.pageProp.id}`
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handleSave() {
      let flag = false;
      this.$refs.addEditPopup.$refs.addPopForm.$refs.addPopForm.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }
      let param = {
        id: this.record.id,
        amount: this.$refs.addEditPopup.$refs.addPopForm.formData.amount,
      };

      putApi(`/contract/specialrecord`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("修改成功");
        } else {
          this.$message.success("修改失败，请稍后重试");
        }
        this.addEditPopupVisible = false;
        this.getRecordData();
      });
    },
    handleEdit(row) {
      this.addEditPopupTitle = "编辑";
      this.addEditPopupVisible = true;
      this.record = row;
      this.record.pageType = "edit";
    },
    handleClose() {
      this.detailVisible = false;
      this.addEditPopupVisible = false;
    },
    handleClick(e) {},
    handleRowRecordDetail(row) {
      this.addEditPopupTitle = "查看";
      this.addEditPopupVisible = true;
      this.record = row;
      this.record.pageType = "detail";
    },
    handleRowDetail(row) {
      this.detailVisible = true;
      this.pageProp.id = row.purchaseContractId;
    },
    handleView(row) {
      getApi(`/admin/sys-file/online/ssh/${row.file[0].fileName}`).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let url = data.data;
            window.open(url);
          }
        }
      );
    },
    getRecordData() {
      getApi(`/contract/selfestablished/procurement/self/purchase`, {
        id: this.pageProp.id,
        type: "record",
      }).then((res) => {
        if (res.data.code == 0) {
          this.tableData_record = res.data.data;
        }
      });
    },
    async getPageData() {
      let typeArr = ["record", "purchase", "settle", "payment", "invoice"];
      for (let item of typeArr) {
        let res = await getApi(
          `/contract/selfestablished/procurement/self/purchase`,
          { id: this.pageProp.id, type: item }
        );
        if (res.data.code == 0) {
          let { data } = res;
          if (item == "record") {
            this.tableData_record = data.data;
          } else if (item == "purchase") {
            this.tableData_kingDee = data.data;
          } else if (item == "settle") {
            this.tableData_settlement = data.data;
          } else if (item == "payment") {
            this.tableData_payment = data.data;
          } else {
            data.data.forEach((item) => {
              item.file = [item.file];
            });
            this.tableData_invoicing = data.data;
          }
        }
      }
    },
    getRecordSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "amount") {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
    getSettleSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "allotmentAmount" ||
          column.property == "foriginalamount"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
    getPaymentSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "allotmentAmount" ||
          column.property == "foriginalamount" ||
          column.property == "fnotaxdataamount" ||
          column.property == "ftax"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
    getInvoicingSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "allotmentAmount" ||
          column.property == "totalAmount" ||
          column.property == "amountInFiguers" ||
          column.property == "validInvoiceAmount"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
.tabs_card3333 {
  /deep/ .el-tabs__item {
    width: 20% !important;
  }
}
</style>