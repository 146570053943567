<template>
  <div class="container">
    <div class="allocation_top">
      <div class="top_box">
        <div class="top_box_item" v-for="(item, index) in topText" :key="index">
          <div class="label">{{ item.label }}：</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="table_box">
      <dd-table
        ref="baseTable_1"
        :columns="tableColumns"
        class="table_cell"
        :SetCellClassName="SetCellClassName"
        :data="tableData"
        show-summary
        :summary-method="getSummaries"
        border
        :isSelection="false"
        :loading="loading"
        max-height="640"
      >
        <template #table-btn>
          <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
            >添加金蝶合同
          </dd-button>
          <dd-button
            type="primary"
            icon="export"
            size="medium"
            @click="handleExport"
            >导出
          </dd-button>
        </template>
      </dd-table>
    </div>

    <dd-popup
      v-if="addKingDeeVisible"
      width="90%"
      height="90%"
      inPopup
      showBtn
      :cancelBtn="false"
      :saveBtn="saveBtn"
      :submitBtn="false"
      :show="addKingDeeVisible"
      title-name="添加金蝶合同"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        size="medium"
        type="warning"
        @click="handleReturn"
        >返回</dd-button
      >
      <dd-button
        slot="button"
        size="medium"
        type="primary"
        @click="handleSubmit(1)"
        >确定</dd-button
      >
      <addKingDeeFir
        ref="addKingDeeFir"
        :pageProp="pageProp"
        slot="main"
      ></addKingDeeFir>
    </dd-popup>

    <dd-popup
      v-if="popup_formVisible"
      width="40%"
      height="55%"
      inPopup
      showBtn
      :saveBtn="true"
      :submitBtn="false"
      :show="popup_formVisible"
      title-name="编辑"
      @popup-save="handleSave()"
      @popup-close="handleClose"
    >
      <div slot="main">
        <dd-card title="">
          <dd-form
            :form-config="formList_form"
            :flex="1"
            :labelWidth="'220px'"
            ref="EditForm"
            formRef="EditForm"
          ></dd-form>
        </dd-card>
      </div>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi, loadApi } from "@/api/request";
import addKingDeeFir from "./addKingDeeFir.vue";
import { downloadBinaryFile } from "@/utils/index";
export default {
  components: {
    addKingDeeFir,
  },
  props: ["pageProp"],
  name: "contractAssociation",
  data() {
    return {
      popup_formVisible: false,
      formList_form: [
        {
          label: "金蝶合同编号",
          value: "fnumber",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "金蝶合同名称",
          value: "fname",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "金蝶可关联本合同金额",
          value: "correlateThePurchase",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "合同关联金额",
          value: "allotmentAmount",
          inputType: "text",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入合同关联金额",
              trigger: "blur",
            },
            {
              required: true,
              pattern: /^\d*\.?\d{0,2}$/,
              message: "请输入数字，小数最多两位",
              trigger: "blur",
            },
          ],
        },
        {
          label: "归属部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择归属部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择归属部门",
              trigger: "blur",
            },
          ],
        },
      ],
      saveBtn: false,
      topText: [
        {
          label: "本合同变更后合同总金额",
          value: "",
        },
        {
          label: "已关联金额",
          value: "",
        },
        {
          label: "待分配金额",
          value: "",
        },
      ],
      addKingDeeVisible: false,
      tableColumns: [
        {
          title: "金蝶合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同类型",
          key: "fcontracttypename",
          align: "center",
          overflow: true,
        },

        {
          title: "金蝶合同乙方",
          key: "fpartbname",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同变更后合同总金额",
          key: "ftotalamount",
          align: "center",
          overflow: true,
        },

        {
          title: "金蝶合同已关联其他合同金额",
          key: "linedOtherPurchase",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶可关联本合同金额",
          key: "correlateThePurchase",
          align: "center",
          overflow: true,
        },
        {
          title: "归属部门",
          key: "deptName",
          align: "center",
          overflow: true,
        },
        {
          title: "合同关联金额",
          key: "allotmentAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          width: 100,
          event: [
            {
              state: "default",
              label: "编辑",
              type: "warning",
              width: 100,
              method: (row) => {
                this.handleEdit(row);
              },
            },
          ],
        },
      ],
      tableData: [],
      loading: false,
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {
    this.topText[0].value = this.pageProp.procurementFinallyAmount || 0;
    this.getPageData();
  },
  methods: {
    getDataType() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        if (res.data.code == 0) {
          this.formList_form.forEach((item) => {
            if (item.value == "deptId") {
              item.optionsList = res.data.data;
            }
          });
        }
      });
    },
    getPageData() {
      getApi(`/contract/selfestablished/procurement/allocate`, {
        id: this.pageProp.id,
        type: "purchase",
      }).then((res) => {
        if (res.data.code == 0) {
          let { data } = res;
          this.tableData = data.data;
          let sum = 0;
          if (this.tableData && this.tableData.length > 0) {
            this.tableData.map((item) => {
              sum += Number(item.allotmentAmount);
            });
            this.topText[1].value = sum.toFixed(2);
            this.topText[2].value = (
              (this.pageProp.procurementFinallyAmount || 0) - sum.toFixed(2)
            ).toFixed(2);
          }
        }
      });
    },
    handleAdd() {
      this.addKingDeeVisible = true;
    },
    handleExport() {
      loadApi(
        `/contract/selfestablished/procurement/relation/export?id=${this.pageProp.id}&type=relation`
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handleEdit(row) {
      this.rowData = row;
      this.popup_formVisible = true;
      this.$nextTick(() => {
        this.formList_form[0].defaultValue = this.rowData.fnumber;
        this.formList_form[1].defaultValue = this.rowData.fname;
        this.formList_form[2].defaultValue = this.rowData.correlateThePurchase;
        this.formList_form[4].defaultValue = this.rowData.deptId;
        this.formList_form[3].defaultValue =
          this.rowData.allotmentAmount == null ||
          this.rowData.allotmentAmount === ""
            ? this.rowData.correlateThePurchase
            : this.rowData.allotmentAmount;
        this.$forceUpdate();
      });
    },
    handleClose() {
      this.addKingDeeVisible = false;
      this.popup_formVisible = false;
    },
    handleReturn() {
      this.addKingDeeVisible = false;
    },
    handleSave() {
      let flag = false;
      this.$refs.EditForm.$refs.EditForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return false;
      }
      if (
        (this.rowData.ftotalamount * 1 ||
          0 - this.rowData.linedOtherPurchase * 1 ||
          0) < this.$refs.EditForm.formData.allotmentAmount
      ) {
        this.$message.warning(
          "关联金额不能大于 自建合同变更后总金额-自建合同已关联金额（即金蝶可关联金额）"
        );
        return false;
      }
      let sum = 0;
      this.tableData.forEach((item) => {
        if (item.id != this.rowData.id) {
          sum += item.allotmentAmount * 1 || 0;
        } else {
          sum += Number(this.$refs.EditForm.formData.allotmentAmount) || 0;
        }
      });
      if (sum.toFixed(2) > Number(this.pageProp.procurementFinallyAmount)) {
        this.$message.warning("关联金额之和，不能大于变更后合同总金额");
        return false;
      }
      const params = {
        id: this.pageProp.id,
        relationId: this.rowData.id,
        type: "purchase",
        ...this.$refs.EditForm.formData,
      };
      postApi(`/contract/selfestablished/procurement/update`, params).then(
        (res) => {
          if (res.data.code == 0) {
            this.$message.success("操作成功");
          } else {
            this.$message.success("操作失败，请稍后重试");
          }
          this.getPageData();
        }
      );
      this.popup_formVisible = false;
    },
    handleSubmit() {
      this.tableData = this.$refs.addKingDeeFir.tableData;
      console.log("this.tableData", this.tableData);
      const params = {
        id: this.pageProp.id,
        voList: this.tableData.map((item) => {
          return { id: item.purchaseContractId };
        }),
      };
      postApi(
        `/contract/selfestablished/procurement/allocate/purcharse`,
        params
      ).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败，请稍后重试");
        }
        this.getPageData();
      });
      this.addKingDeeVisible = false;
    },
    SetCellClassName({ row, column, rowIndex, columnIndex }) {
      if (
        column.property == "allotmentAmount" ||
        column.property == "deptName"
      ) {
        return "backgroundColor";
      }
    },
    getSummaries(param) {
      // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "allotmentAmount" ||
          column.property == "correlateThePurchase" ||
          column.property == "ftotalamount" ||
          column.property == "linedOtherPurchase"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
.allocation_top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top_box {
  width: 80%;
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: bold;
  font-size: 15px;
  color: #606266;

  .top_box_item {
    display: flex;
    align-items: center;
  }
}

.table_box {
  margin-top: 30px;
}

/deep/ .table_cell {
  .backgroundColor {
    background-color: #a8d0fa !important;
  }
}
</style>