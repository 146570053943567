<template>
  <div class="main-container">
    <dd-form
      :form-config="formBasicList"
      ref="addPopForm"
      formRef="addPopForm"
      :labelWidth="'200px'"
      :flex="1"
    >
    </dd-form>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
export default {
  name: "add-pop",
  props: ["pageProp"],
  data() {
    return {
      formBasicList: [
        {
          label: "付款计划申请单",
          value: "planApplyId",
          inputType: "select",
          defaultValue: "",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择付款计划申请单",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择付款计划申请单",
              trigger: "blur",
            },
          ],
        },
        {
          label: "执行周期开始日期",
          value: "startTime",
          placeholderText: "执行周期开始日期",
          inputType: "dateStr",
          dateType: "date",
          defaultValue: "",
          disabled: true,
          format: "yyyy-MM-dd",
          rules: [
            {
              required: true,
              message: "请选择执行周期开始日期",
              trigger: "blur",
            },
          ],
        },
        {
          label: "执行周期结束日期",
          change: this.handleChange,
          value: "endTime",
          placeholderText: "执行周期结束日期",
          inputType: "dateStr",
          dateType: "date",
          defaultValue: "",
          format: "yyyy-MM-dd",
          rules: [
            {
              required: true,
              message: "请选择执行周期结束日期",
              trigger: "blur",
            },
          ],
        },
      ],
      detailData: null,
    };
  },
  created() {},
  mounted() {
    this.getDate();
    this.getDataType();
  },
  methods: {
    handleChange(e,type){
      let start = new Date(this.$refs.addPopForm.formData.startTime).getTime();
      let end = new Date(this.$refs.addPopForm.formData.endTime).getTime();
      if(end < start){
        this.$message.warning("执行周期结束日期需大于执行周期开始日期！");
        this.$refs.addPopForm.formData.endTime = "";
      }
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getDataType() {
      getApi(`/contract/payment/schedule/list`).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            let options = {
              value: "planApplyId",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
    },
    getDate() {
      getApi(`/contract/execute/query/date`).then((res) => {
        if (res.data.code == 0) {
          this.formBasicList[1].defaultValue = res.data.data + " 00:00:00";
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>
