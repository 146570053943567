import { render, staticRenderFns } from "./applyBasicInfo.vue?vue&type=template&id=07c1170e&scoped=true"
import script from "./applyBasicInfo.vue?vue&type=script&lang=js"
export * from "./applyBasicInfo.vue?vue&type=script&lang=js"
import style0 from "./applyBasicInfo.vue?vue&type=style&index=0&id=07c1170e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c1170e",
  null
  
)

export default component.exports