<template>
  <div class="main-container">
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :labelWidth="'170px'"
        :flex="3"
        ref="assignForm"
        formRef="assignForm"
      ></dd-form>
    </dd-card>

    <dd-card title="生产预算自定义项">
      <dd-table
        :columns="tableColumns"
        ref="multipleTable"
        class="table_cell"
        :data="tableDataSource"
        :isSelectable="true"
        @select-change="handleSelectionChange"
        :handleCheckDisable="handleCheckDisable"
        :loading="loading"
        border
        max-height="460"
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, delApi } from "@/api/request";
export default {
  name: "BudgetEstablishPop",
  props: {
    rowassignId: {
      type: String,
      default: "",
    },
  },
  created() {},
  mounted() {
    this.getDetailData();
  },
  data() {
    return {
      tableDataSource: [],
      loading: false,
      selectTableSource: [],
      tableColumns: [
        {
          title: "工作内容",
          key: "jobContent",
          align: "center",
        },
        {
          title: "计量单位",
          key: "measureUnitName",
          align: "center",
        },
        {
          title: "总工作量",
          key: "totalEffort",
          align: "center",
        },
        {
          title: "工种",
          key: "workTypeName",
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
        },
        {
          title: "绩效工资单价",
          key: "meritPayUnivalence",
          align: "center",
        },
      ],
      formList: [
        // 基本信息表单配置项
        {
          label: "生产任务书名称",
          value: "assignName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "生产任务书编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "生产预算总额",
          value: "budgetTotalAmount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "销售合同名称",
          value: "contractName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "销售合同编号",
          value: "contractCode",
          inputType: "text",
          disabled: true,
        },
        {
          label: "暂定合同额",
          value: "assignAmount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "版本",
          value: "version",
          inputType: "text",
          disabled: true,
        },
        {
          label: "状态",
          value: "statusName",
          inputType: "text",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    handleCheckDisable(row) {
      if (row.disabledCheck == 1) {
        return false;
      }
      else {
        return true;
      }
    },
    handleSelectionChange(val) {
      this.selectTableSource = val;
    },
    getDetailData() {
      // 获取详情数据
      getApi(`/finance/budget/establishment/query/byAssignId?assignId=${this.rowassignId}`,).then(
        (res) => {
          if (res.data.code == 0) {
            let { data } = res;
            let { finBudgetEstablishmentScZdys, ...jbxxData } = data.data;
            this.$refs.assignForm.formData = JSON.parse(
              JSON.stringify(jbxxData)
            );
            if(finBudgetEstablishmentScZdys.length > 0){
                finBudgetEstablishmentScZdys.forEach(item => {
                    if(item.selectFlag == 1){
                        item.disabledCheck = 1;
                    }
                });
            }
            this.tableDataSource = finBudgetEstablishmentScZdys;
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
</style>