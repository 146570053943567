<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="汇总单统计" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
              v-if="$store.state.btnAuthorityList.includes('hr:vehiclecollectcollect:listexport')"
            icon="export"
            type="primary"
            size="medium"
            autoConfirm
            @click="handleExport(0)"
            >列表数据批量导出
          </dd-button>
          <dd-button
              v-if="$store.state.btnAuthorityList.includes('hr:vehiclecollectcollect:financeexport')"
            icon="export"
            type="warning"
            size="medium"
            autoConfirm
            @click="handleExport(1)"
            >财务核算表批量导出
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogVisible"
      width="90%"
      height="80%"
      :title-name="addEditTitle"
      :show="dialogVisible"
      @popup-close="dialogVisible = false"
    >
      <SummarySheet slot="main" :type="type" :id="id"></SummarySheet>
    </dd-popup>
    <!-- 详情 -->
    <dd-popup
      v-if="dialogDetailVisible"
      width="90%"
      height="90%"
      :showBtn="type == 'hz' ? false : true"
      :saveBtn="false"
      :title-name="addEditTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="submitOrSave(1)"
        >确认
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="submitOrSave(0)"
        >退回
      </dd-button>
      <SummaryDetail slot="main" :type="type" :id="id"></SummaryDetail>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import SummarySheet from "../Department/components/SummarySheet";
import SummaryDetail from "./components/SummaryDetail";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import {mapState} from "vuex";

export default {
  name: "summarySheet",
  $dynamicRoute: {
    name: "项目用车汇总单管理",
    path: "/personalCar/summarySheet/list",
  },
  components: { SummaryDetail, SummarySheet },
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  data() {
    return {
      tableHeight: null,
      addEditTitle: null,
      drawerVisible: false,
      dialogDetailVisible: false,
      processInstanceId: null,
      loading: false,
      id: null,
      type: null,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,

      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "汇总单编号",
          value: "collectCode",
          inputType: "text",
          placeholderText: "请输入汇总单编号",
        },
        {
          label: "部门名称",
          value: "collectDepId",
          inputType: "select",
          placeholderText: "请选择或搜索部门名称",
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
        },
        {
          label: "月份",
          value: "applyDate",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
        {
          label: "上报状态",
          value: "reportStatus",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索上报状态",
        },
        {},
        {},
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "汇总单编号",
          key: "collectCode",
          align: "center",
          event: [
            {
              type: "primary",
              href: (row) => {
                return row.collectCode;
              },
              method: (row) => {
                this.handleDetailInfo(row, 0);
              },
            },
          ],
        },
        {
          title: "单据名称",
          key: "collectName",
          align: "left",
          overflow: true,
          width: 400,
        },
        {
          title: "部门",
          key: "collectDepName",
          align: "center",
        },
        {
          title: "公司",
          key: "collectOrgName",
          align: "center",
        },
        {
          title: "月份",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "上报状态",
          key: "reportStatus",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "接收确认",
              type: "primary",
              method: (row) => {
                this.handleDetailInfo(row, 2);
              },
              rules: (row) => {
                let show = false;
                if (row.reportStatusCode == "dxzqr" && this.btnAuthorityList.includes('hr:vehiclecollectcollect:confirm')) {
                  show = true;
                }
                return show;
              },
            },
            {
              label: "汇总详情",
              method: (row) => {
                this.handleDetailInfo(row, 1);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    // 获取单据状态
    this.getStateList();
    this.getExpenseDepartmentList();
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    getExpenseDepartmentList() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        this.formList.forEach((item) => {
          if (item.value == "collectDepId") {
            item.optionsList = data.data;
          }
        });
      });
    },
    // 详情
    handleDetailInfo(row, type) {
      this.id = row.id;
      if (type == "0") {
        // 查看详情
        this.addEditTitle = "申请单详情";
        this.type = "detail";
        this.dialogVisible = true;
      } else if (type == "1") {
        //汇总详情
        this.addEditTitle = `${row.collectDepName}${row.reportMonth}车辆补贴汇总单`;
        this.type = "hz";
        this.dialogDetailVisible = true;
      } else {
        this.type = "sh";
        this.addEditTitle = `${row.collectDepName}${row.reportMonth}车辆补贴汇总单`;
        this.dialogDetailVisible = true;
        //审核
      }
    },
    // 总金额（预算明细)
    handleDetail(val) {
      this.applyDetailTitle = "预算明细";
      this.applyId = val.id;
      this.projectId = val.projectId;
      this.dialogDetailVisible = true;
      this.amountType = "totalAmount";
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleExport(type) {
      /*
      type   0：行政  1：财务 
       */
      let ids = [];
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请选择一条数据！");
        return;
      }
      let flag = 1;
      this.multipleSelection.length &&
        this.multipleSelection.map((item) => {
          if (
              item.reportStatus === "已退回"||
              item.reportStatus === "未上报"
          ) {
            flag = 0;
          }
          ids.push(item.id);
        });
      if (flag == 0) {
        this.$message.warning("未上报和已退回的汇总单无汇总信息！");
        return;
      }
      let params = {
        formData: {
          ids:ids,
        },
      };
      if (type == 0) {
        loadApi(`/hr/collectsheet/export/statistics/person`, params).then(
          (res) => {
            let { data } = res;
            this.$refs.dTableRef.$refs.baseTable.clearSelection();
            this.multipleSelection = [];
            downloadBinaryFile(
              data,
              decodeURIComponent(
                res.headers["content-disposition"].split(";")[1].split("=")[1]
              )
            );
          }
        );
      } else {
        loadApi(`/hr/collectsheet/export/statistics/prj`, params).then(
          (res) => {
            let { data } = res;
            this.$refs.dTableRef.$refs.baseTable.clearSelection();
            this.multipleSelection = [];
            downloadBinaryFile(
              data,
              decodeURIComponent(
                res.headers["content-disposition"].split(";")[1].split("=")[1]
              )
            );
          }
        );
      }
    },
    // 预算申请列表请求
    getApplyList() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
        type: "1",
      };
      postApi(`/hr/collectsheet/audit/page`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/hr/hrdict/list?typeCode=djsbzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "reportStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getApplyList();
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "totalAmount") {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (!isNaN(+item)) {
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getApplyList();
    },
    // 弹窗关闭事件
    handleClose() {
      this.drawerVisible = false;
      this.dialogDetailVisible = false;
      this.dialogVisible = false;
      this.id = null;
    },
    //汇总单审核
    submitOrSave(type) {
      let params = {
        collectId: this.id,
        reviewResult: type,
      };
      postApi(`/hr/collectsheet/review`, params)
        .then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success(`${type == 0 ? "退回成功！" : "提交成功！"}`);
            this.getApplyList();
            this.handleClose();
          } else {
            this.$message.error(data.msg);
            this.$message({
              message: data.msg,
              type: "error",
              duration: 6000,
            });
          }
        })
        .catch((data) => {
          if (data.response && data.response.data && data.response.data.msg) {
            this.$message({
              message: data.response.data.msg,
              type: "error",
              duration: 6000,
            });
          } else {
            this.$message({
              message: data,
              type: "error",
              duration: 6000,
            });
          }
        });
    },
  },
};
</script>

<style lang="less" scoped></style>
