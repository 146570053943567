<template>
  <div>
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="search"
      ></dd-search-form>
    </dd-card>
    <dd-card title="申请单列表">
      <dd-table
        :topBtn="false"
        :columns="tableColumns"
        :data="tableDataSources"
        max-height="400"
        border
        show-summary
        :summary-method="getSummaries"
        show-page
        @select-change="handleSelectionChange"
        ref="test"
        :tableRef="tableRef"
      >
      </dd-table>
    </dd-card>
    <!-- 申请单详情弹窗 -->
    <dd-popup
      inPopup
      width="90%"
      :show="diaexpenseDetail"
      title-name="申请单详情"
      @popup-close="diaexpenseDetail = false"
    >
      <detail-pop
        v-if="diaexpenseDetail"
        slot="main"
        ref="detailPop"
        :expenseApply="expenseApply"
      ></detail-pop>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { getNowMonth } from "@/utils/date";
import detailPop from "../../ExpenseApply/components/expenseDetail.vue";
export default {
  name: "expense-apply",
  components: {
    detailPop,
  },
  props: {
    selectDataObj: {
      type: Object,
      default: null
    },
    filterSelection: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      tableRef: "expleApplyPop",
      // 表格选择数据集合
      multipleSelection: [],
      formData: {
        endTime: getNowMonth()
      },
      formList: [],
      tableColumns: [
        {
          title: "申请单号",
          key: "code",
          width: 200,
          align: "center",
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
          overflow: true,
        },
        {
          title: "任务书编码",
          key: "assignNum",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
        },
        {
          title: "项目编码",
          key: "prjCode",
          align: "center",
          overflow: true,
        },
        {
          title: "业务类别",
          key: "firstExpense",
          align: "center",
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
        },
        {
          title: "金额(元)",
          key: "amount",
          align: "center",
        },
        {
          title: "核定金额(元)",
          key: "reviewAmount",
          align: "center",
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "费用承担部门",
          key: "expenseBearDeptName",
          align: "center",
        },
      ],
      tableDataSources: [],
      //多选集合
      multipleSelectionId: [],
      //默认选择回显
      toggleSelectionData: [],
      loading: true,
      //详情查看显隐
      diaexpenseDetail: false,
      //子传父值（详情id，id）
      expenseApply: {},
    };
  },
  mounted() {
    this.formList.push({
      label: "月份",
      value: "endTime",
      inputType: "date",
      dateType: "month",
      placeholderText: "请选择月份",
      defaultValue: getNowMonth()
    })
    this.getTableDataList();
  },
  methods: {
    //初始化数据
    getTableDataList() {
      let {formData} = this;
      let params = {
        ...formData,
        ...this.selectDataObj
      };
      postApi(
        `/finance/expenseApply/app/list/unselected`,params
      ).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          if (res.data.data.length > 0) {
            res.data.data.forEach((item) => {
              item.amount = parseFloat(item.amount).toFixed(2);
              item.reviewAmount = parseFloat(item.reviewAmount).toFixed(2);
            });
          }
          this.total = res.data.data.total;
          this.tableDataSources = res.data.data;
          this.getToggleSelection(this.filterSelection);
          this.loading = false;
        }
      });
    },
    //默认选择回显
    toggleSelection(rows) {
      let { expleApplyPop } = this.$refs.test.$refs;
      if (rows) {
        rows.forEach((row) => {
          this.$refs.test.$refs.baseTable.toggleRowSelection(row);
        });
      }
    },
    // 弹框关闭事件
    popupClose() {
      this.$emit("popup-close", false);
    },
    //查询
    handleSearchEvent(obj) {
      console.log(obj)
      this.formData = obj;
      this.getTableDataList();
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.multipleSelectionId = val.id;
    },
    //获取默认选择状态回显数据
    getToggleSelection(data) {
      if (data) {
        this.toggleSelectionData = this.tableDataSources.filter((item1) =>
          data.find((item2) => item2.id === item1.id)
        );
        this.toggleSelection(this.toggleSelectionData);
      }
    },
    // 计算表格合计行函数
    getSummaries(param) {
      this.$nextTick(() => {
        let { expleApplyPop } = this.$refs.test.$refs;
        if (expleApplyPop) {
          expleApplyPop.doLayout();
        }
      });
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        // 初始化合计行
        sums[index] = 0;
        // 递归求和
        this.getSum(data, column.property, sums, index);
        // 如果求和得到的结果是非数字,则显示--
        if (isNaN(sums[index])) {
          sums[index] = "";
        }
        if (index === 9) {
          sums[index] = parseFloat(sums[index]).toFixed(2);
        }
        if (index === 10) {
          sums[index] = parseFloat(sums[index]).toFixed(2);
        }
      });
      return sums;
    },
    getSum(tree, idx, sums, index) {
      if (tree && tree.length > 0) {
        tree.map((item) => {
          this.getSum(item.children, idx, sums, index);
          sums[index] += Number(item[idx]);
        });
      }
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} pageSize 每页显示条数
     * @param {*} pageNumber 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.loading = true;
      this.getTableDataList();
    },
  },
};
</script>