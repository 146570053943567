<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="预算申请列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        ref="dTableRef"
        :loading="loading"
        :summary-method="getSummaries"
        show-summary
        show-page
        :isSelectable="true"
        @select-change="handleSelectionChange"
        :handleCheckDisable="handleCheckDisable"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            size="medium"
            icon="add"
            @click="handleRevise()"
            >修订</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
          <el-button
            icon="el-icon-download"
            circle
            type="info"
            size="small"
            @click="handleFileExport"
            title="下载"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 修订弹窗 -->
    <dd-popup
      v-if="dialogAddEditVisible"
      width="60%"
      height="80%"
      showBtn
      :saveBtn="false"
      submitBtn
      :show="dialogAddEditVisible"
      :title-name="addEditTitle"
      @popup-submit="handleSubmit('budgetBasicForm')"
      @popup-close="dialogAddEditVisible = false"
    >
      <addEditPop
        ref="addEditPop"
        slot="main"
        @budgetChange="budgetChange"
        @diaChange="diaChange"
        :addEdit="addEdit"
        :type="type"
      ></addEditPop>
    </dd-popup>
    <!-- 预算明细 -->
    <dd-popup
      v-if="dialogDetailVisible"
      width="60%"
      height="80%"
      :title-name="applyDetailTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <applyDetail
        slot="main"
        :applyId="applyId"
        :projectId="projectId"
        :amountType="amountType"
      ></applyDetail>
    </dd-popup>
    <!-- 详情弹窗 -->
    <dd-popup
      v-if="dialogBudgetBasicInfoVisible"
      width="60%"
      height="80%"
      title-name="预算详情"
      :show="dialogBudgetBasicInfoVisible"
      @popup-close="dialogBudgetBasicInfoVisible = false"
    >
      <applyBasicInfo slot="main" :rowData="rowData"></applyBasicInfo>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { postApi, loadApi, getApi } from "@/api/request";
import addEditPop from "../BudgetApply/components/AddEditPop.vue";
import applyDetail from "../BudgetApply/components/applyDetail";
import applyBasicInfo from "../BudgetApply/components/applyBasicInfo";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
export default {
  name: "budgetList",
  $dynamicRoute: {
    name: "预算列表",
    path: "/finance/budget/list",
    meta: {
      keepAlive: true,
    },
  },
  components: { addEditPop, applyDetail, applyBasicInfo },
  data() {
    return {
      tableHeight: null,
      amountType: "",
      addEdit: {
        id: null,
        type: "",
      }, //传参子组件
      type: "revise",
      tableData: [],
      formList: [
        {
          label: "编号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入编号",
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编码",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编码",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "申请人",
          value: "applier",
          inputType: "text",
          placeholderText: "请输入申请人姓名",
        },
        {
          label: "部门",
          value: "applyDep",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          placeholderText: "请选择或搜索申请人部门",
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
        },
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "编号",
          key: "code",
          align: "center",
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
        },
        {
          title: "项目编码",
          key: "projectCode",
          align: "center",
        },
        {
          title: "预算总金额(元)",
          key: "totalAmount",
          align: "center",
          width: 220,
          event: [
            {
              type: "primary",
              href: (row) => {
                return (
                  row["totalAmount"] +
                  " (已用: " +
                  row["realTotalAmount"] +
                  " )"
                );
              },
              method: (row) => {
                this.handleRowHref(row);
              },
            },
          ],
        },
        {
          title: "版本",
          key: "versionCode",
          align: "center",
        },
        {
          title: "修订状态",
          key: "revisingName",
          align: "center",
        },
        {
          title: "启用状态",
          key: "isEnableName",
          align: "center",
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "部门",
          key: "applyDep",
          align: "center",
        },
        {
          title: "详情",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleBasic(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
      stateList: [],
      formData: {},
      dialogAddEditVisible: false,
      dialogDetailVisible: false,
      drawerVisible: false,
      type: "revise",
      applyId: null,
      stateName: null,
      projectCode: null,
      isEnableName: null,
      multipleSelection: [],
      budgetBasicInfoTitle: false,
      dialogBudgetBasicInfoVisible: false,
      realDetailTitle: false,
      dialogRealDetailVisible: false,
      prjCode: null,
      rowData: null,
      loading: false,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getBudgetList();
    setTimeout(() => {
      this.getConditionData();
    }, 500);
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getConditionData() {
      getApi(`/project/prj/get/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.depList = res.data.data;
        let options = {
          value: "applyDep",
          optionsList: this.depList,
        };
        this.setOPtionsList(this.formList, options);
      });
    },
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.currentPage = 1;
      this.getBudgetList();
    },
    handleCurrentChange(pageSize, currentPage) {
      this.size = pageSize;
      this.current = currentPage;
      this.getBudgetList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getState(row) {
      if (row.revising == 1) {
        return "修订中";
      } else {
        return "未修订";
      }
    },
    handleCheckDisable(row) {
      if (row.revising == 1) {
        return false;
      } else {
        return true;
      }
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };

      formData["curOrgId"] = sessionStorage.getItem("curOrgId");

      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/budget/front/audit/export`, params)
        .then((res) => {
          console.log(res);
          let { data } = res;
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === 500 && error.response.headers.msg) {
              this.$message.warning(
                decodeURIComponent(error.response.headers.msg)
              );
              return;
            }
          }

          this.$message.error("请求异常");
        });
    },
    diaChange(e) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.dialogAddEditVisible = e;
      this.getBudgetList();
    },
    budgetChange(e, flag) {
      this.dialogAddEditVisible = e;
      this.id = null;
      this.state = null;

      if (0 == flag) {
        window.parent.postMessage("openPrjBudgetApplyListPage", "*");
      } else if (1 == flag) {
        this.getBudgetList();
      }
    },
    closePop() {
      this.dialogAddEditVisible = false;
      this.id = null;
      this.state = null;
    },
    //查看明细
    // handleDetail(val) {
    //   this.applyDetailTitle = "预算明细";
    //   this.applyId = val.id;
    //   this.dialogDetailVisible = true;
    //   this.projectId = val.projectId;
    // },
    handleRowHref(row) {
      this.applyDetailTitle = "预算总金额";
      this.applyId = row.id;
      this.dialogDetailVisible = true;
      this.projectId = row.projectId;
      this.amountType = "totalAmount";
    },
    doFormatter(row, column) {
      return row["totalAmount"];
    },
    getBudgetList() {
      this.loading = true;
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      postApi(`/finance/apply/audit/budget/list`, params).then((res) => {
        let { data } = res;
        if (data.code === 0 && data.data && data.data.records) {
          this.tableData = data.data.records.map((item) => {
            item.totalAmount = formatNumber(item.totalAmount);
            item.realTotalAmount = formatNumber(item.realTotalAmount);
            return item;
          });
          this.total = data.data.total;
        }
        this.loading = false;
      });
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "totalAmount") {
          const values = data.map((item) => {
            if (1 == item.isEnable) {
              return Number(item[column.property]);
            } else {
              return 0.0;
            }
          });
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });

      let list = [...sums];
      list.map((item, index) => {
        if (!isNaN(+item)) {
          sums[index] = formatNumber(sums[index]);
        }
      });

      var realSums = 0.0;

      data.forEach((item) => {
        if (item.isEnable == 1 && !isNaN(item.realTotalAmount)) {
          realSums += Number(item.realTotalAmount);
        }
      });

      sums[4] = "启用:" + sums[4] + " 已用: " + formatNumber(realSums);

      return sums;
    },
    // 处理项目预算修订
    handleRevise() {
      // 修订
      if (this.multipleSelection.length !== 1) {
        this.$message.warning("请选择一条数据！");
        return;
      }
      // 0: 状态编码，项目预算状态为未修订
      if (this.multipleSelection[0].revising !== 0) {
        this.$message.warning("只能选择未修订状态数据修订！");
        return;
      }
      this.addEdit.type = "revise";
      this.addEditTitle = "修订项目预算单";
      this.addEdit.id = this.multipleSelection[0].id;
      this.dialogAddEditVisible = true;
    },
    handleBasic(row) {
      this.budgetBasicInfoTitle = "预算信息详情";
      this.rowData = row;
      this.dialogBudgetBasicInfoVisible = true;
    },
    handleSubmit(formName) {
      let flag = false;
      let { tabArr } = this.$refs.addEditPop;

      this.$refs.addEditPop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return;
      }
      let oneTypeVOList = [];
      let amount = 0;
      tabArr.forEach((item) => {
        item.children.forEach((val) => {
          amount += val.amount;
        });
        oneTypeVOList.push(item.children[0]);
      });
      if (amount == 0) {
        this.$message.warning("请填写预算费用明细");
        return;
      }
      if (!this.$refs.addEditPop.fileListParam.length) {
        this.$message.warning("请上传电子版的预算明细");
        return;
      }
      let formBasicData = this.$refs.addEditPop.$refs[formName].formData;
      let params = {
        ...formBasicData,
        applierId: sessionStorage.getItem("userId"),
        deptId: sessionStorage.getItem("depId"),
        makerId: sessionStorage.getItem("userId"),
        orgId: sessionStorage.getItem("orgId"),
        orgName: sessionStorage.getItem("orgName"),
        oneTypeVOList: oneTypeVOList,
        prjBudgetApplyAttachFileList: this.$refs.addEditPop.fileListParam,
        id: this.addEdit.id,
      };
      postApi(`/finance/apply/front/revise`, params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("提交成功！");
          this.dialogAddEditVisible = false;
          this.getBudgetList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
