<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>
  
  <script>
import { postApi, getApi, putApi } from "@/api/request";
import { getNowDate, getNowYear } from "@/utils/date";
export default {
  name: "add-pop",
  data() {
    return {
      formBasicList: [
        {
          label: "所属考勤月",
          value: "month",
          inputType: "date",
          dateType: "month",
          format: "yyyy-MM",
          disabled: false,
          defaultValue: null,
          placeholderText: "请选择所属考勤月",
          rules: [
            { required: true, message: "请选择所属考勤月", trigger: "blur" },
          ],
        },
      ],
      detailData: null,
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
  <style lang="less" scoped>
</style>
  