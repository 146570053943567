<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="付款计划执行情况列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        :isSelection="true"
        show-page
        :loading="loading"
        @select-change="handleSelectionChange"
        ref="dTableRef"
      >
        <template #table-btn>
          <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
            >新增付款计划执行单
          </dd-button>
          <dd-button
            type="info"
            icon="export"
            size="medium"
            @click="handleExport"
            >导出
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>

    <!-- 新增计划 -->
    <!-- 添加 -->
    <dd-popup
      width="35%"
      height="40%"
      :showBtn="true"
      :saveBtn="true"
      :submitBtn="true"
      :cancelBtn="true"
      :show="visiblePopAdd"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-submit="handleSave('addPopForm', 'baseForm', 1)"
      @popup-save="handleSave('addPopForm', 'baseForm', 0)"
    >
      <add-pop
        v-if="visiblePopAdd"
        slot="main"
        ref="addPop"
        :titleName="poptitleName"
        :pageProp="pageProp"
      ></add-pop>
    </dd-popup>

    <!-- 编辑计划 -->
    <dd-popup
      width="35%"
      height="40%"
      :showBtn="true"
      :saveBtn="true"
      :submitBtn="true"
      :cancelBtn="true"
      :show="visiblePopEdit"
      title-name="编辑付款计划执行单"
      @popup-close="handleClose"
      @popup-submit="handleEditSave('editPopForm', 'baseForm', 1)"
      @popup-save="handleEditSave('editPopForm', 'baseForm', 0)"
    >
      <edit-pop
        v-if="visiblePopEdit"
        slot="main"
        ref="editPop"
        titleName="编辑付款计划执行单"
        :editProp="editProp"
      ></edit-pop>
    </dd-popup>

    <dd-popup
      v-if="detailPopupVisible"
      width="100%"
      height="100%"
      showBtn
      :saveBtn="saveBtn"
      :submitBtn="false"
      :show="detailPopupVisible"
      :title-name="detailPopupTitle"
      @popup-close="handleClose"
    >
      <detailPopup
        ref="detailPopup"
        :pageProp="pageProp"
        slot="main"
      ></detailPopup>
    </dd-popup>
  </div>
</template>
  
  <script>
import { delApi, postApi, putApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
import detailPopup from "./components/detailPopup.vue";
import addPop from "./components/addPop.vue";
import editPop from "./components/editPop.vue";
export default {
  name: "contractExecute",
  $dynamicRoute: {
    name: "付款计划执行情况 ",
    path: "/contract/execute/index",
  },
  components: { addPop, detailPopup, editPop },
  data() {
    return {
      loading: false,
      detailPopupVisible: false,
      saveBtn: false,
      pageProp: {},
      editProp: {},
      detailPopupTitle: "",
      formList: [
        {
          label: "单据编码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单据编码进行搜索",
        },
        {
          label: "单据名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入单据名称进行搜索",
        },
        {
          label: "单据状态",
          value: "status",
          inputType: "select",
          optionsList: [],
          filterable: true,
          defaultValue: "",
          placeholderText: "请选择单据状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
      ],
      drawerVisible: false,
      tableColumns: [
        {
          title: "单据编码",
          key: "code",
          align: "center",
          overflow: true,
          width: 180,
        },
        {
          title: "单据名称",
          key: "name",
          align: "center",
          overflow: true,
        },
        {
          title: "年度",
          key: "year",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "月份",
          key: "month",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "本期计划付款金额",
          key: "planAmount",
          align: "center",
          overflow: true,
          width: 160,
        },
        {
          title: "本期实际付款金额",
          key: "actualAmount",
          align: "center",
          overflow: true,
          width: 160,
        },
        {
          title: "执行周期",
          key: "executeDuration",
          align: "center",
          overflow: true,
        },
        {
          title: "单据状态",
          key: "statusName",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 200,
          event: [
            {
              state: "default",
              label: "编辑",
              type: "warning",
              width: 100,
              method: (row) => {
                this.handleEdit(row);
              },
              rules: (row) => {
                let show = false;
                if (row.status == "PC-CGJHZX-STATUS-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              state: "default",
              label: "查看",
              width: 100,
              method: (row) => {
                this.handleRowDetail(row);
              },
            },
            {
              state: "default",
              type: "danger",
              label: "删除",
              width: 100,
              method: (row) => {
                this.handleDelete(row);
              },
              rules: (row) => {
                let show = false;
                if (row.status == "PC-CGJHZX-STATUS-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      columnsList: [],
      tableHeight: null,
      saveBtn: false,
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
      searchData: {},
      selectTable: [], // 表格勾选集合
      visiblePopAdd: false,
      visiblePopEdit: false,
      poptitleName: "",
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {
    this.columnsList = [...this.tableColumns];
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
  },
  methods: {
    handleEditSave(formName, formNameBase, type) {
      let flag = false;
      this.$refs.editPop.$refs[formName].$refs[formName].validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return false;
      }

      let params = {
        id: this.editProp.id,
        startTime: this.$refs.editPop.$refs[formName].formData.startTime,
        endTime: this.$refs.editPop.$refs[formName].formData.endTime,
      };
      postApi(`/contract/execute/edit/${type}`, params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败");
        }
        this.getTableData();
        this.visiblePopEdit = false;
      });
    },
    handleSave(formName, formNameBase, type) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formName].validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return false;
      }

      let params = {
        id: this.pageProp.id ? this.pageProp.id : null,
        startTime: this.$refs.addPop.$refs[formName].formData.startTime,
        endTime: this.$refs.addPop.$refs[formName].formData.endTime,
        planApplyId: this.$refs.addPop.$refs[formName].formData.planApplyId,
      };
      postApi(`/contract/execute/save/${type}`, params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败");
        }
        this.getTableData();
        this.visiblePopAdd = false;
      });
    },
    getTableData() {
      postApi(
        `/contract/execute/page/${this.current}/${this.size}`,
        this.searchData
      ).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      });
    },
    handleSearchEvent(e) {
      this.current = 1;
      this.searchData = e;
      this.getTableData();
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getTableData();
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleAdd() {
      this.poptitleName = "新增付款计划执行单";
      this.visiblePopAdd = true;
      this.pageProp.pageType = "add";
      this.pageProp.id = null;
    },
    handleSelectionChange(e) {
      this.selectTable = e;
    },
    handleExport() {
      if (this.selectTable.length != 1) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      loadApi(`/contract/execute/export`, {
        id: this.selectTable[0].id,
      }).then((res) => {
        let { data } = res;
        this.$refs.dTableRef.$refs.baseTable.clearSelection();
        this.selectTable = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
        this.$message.success("导出成功");
      });
    },
    handleSubmit(e) {
      let params = {};
      if (e == 0) {
        let flag = false;
        this.$refs.addEditPopup.$refs.form_addEditPopup.$refs.form_addEditPopup.validate(
          (valid) => {
            if (valid) {
              flag = true;
            }
          }
        );
        if (!flag) {
          return false;
        }
      }
      params = {
        ...this.$refs.addEditPopup.$refs.form_addEditPopup.formData,
        scheduleVOS: this.$refs.addEditPopup.tableData,
        remark: this.$refs.addEditPopup.$refs.plan_remark.formData.remark,
      };
      postApi(`/contract/payment/schedule/save/${e}`, params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("操作成功");
        } else {
          this.$message.warning("操作失败");
        }
        this.getTableData();
        this.addEditPopupVisible = false;
      });
    },
    handleEdit(row) {
      this.visiblePopEdit = true;
      this.editProp;
      this.editProp.id = row.id;
      this.editProp.startTime = row.startTime;
      this.editProp.endTime = row.endTime;
      this.editProp.planApplyId = row.planApplyId;
      this.editProp.name = row.name;
    },
    handleRowDetail(row) {
      this.detailPopupTitle = "查看";
      this.detailPopupVisible = true;
      this.pageProp.id = row.id;
      this.pageProp.pageType = "detail";
    },
    handleClose() {
      this.visiblePopAdd = false;
      this.detailPopupVisible = false;
      this.visiblePopEdit = false;
      this.getTableData();
    },
    handleDelete(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/contract/execute?id=${row.id}`).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("操作成功");
          } else {
            this.$message.warning("操作失败，请稍后重试");
          }
          this.getTableData();
        });
      });
    },
    getDataType() {
      postApi(`/contract/dicttype/list/all`, { code: "PC-CGJHZX-STATUS" }).then(
        (res) => {
          if (res.data.code == 0) {
            this.formList.forEach((item) => {
              if (item.value == "status") {
                item.optionsList = res.data.data;
              }
            });
          }
        }
      );
    },
  },
};
</script>
  
  <style lang="less" scoped></style>