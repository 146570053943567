<template>
    <div class="container">
        <dd-table ref="baseTable_1" :columns="tableColumns" :data="tableData" 
                @select-change="handleSelectionChange" border :isSelection="true" :loading="loading">
                <template #table-btn>
                    <dd-button type="primary" icon="add" size="medium" @click="handleAdd">添加金蝶合同
                    </dd-button>
                    <dd-button type="primary" icon="add" size="medium" @click="handleDelete">移除
                    </dd-button>
                </template>
            </dd-table>
            <dd-popup v-if="showKingDee" width="100%" height="100%" inPopup showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="showKingDee" title-name="添加金蝶合同"
            @popup-close="handleClose">
            <!-- <dd-button  slot="button"  size="medium" type="primary"
            @click="handleSubmit(1)">提交</dd-button> -->
            <addKingDee ref="addKingDee" :pageProp="pageProp" @addKindPurchase= "addKindPurchase" slot="main"></addKingDee>
        </dd-popup>
    </div>
</template>
 
<script>
import { getApi } from '@/api/request';
import addKingDee from '../../invoiceRegistration/components/addKingDee.vue'
    export default {
        props: ['pageProp'],
        name: 'addKingDeeFir',
        components: { addKingDee },
        data() {
            return {
                saveBtn: false,
                showKingDee: false,
                tableColumns: [
                    {
                        title: "合同编号",
                        key: "fnumber",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "合同名称",
                        key: "fname",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "合同乙方",
                        key: "fpartbname",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "项目名称",
                        key: "prjName",
                        align: "center",
                        overflow: true,
                    },
                ],
                tableData: [],
                selectTableSource: [],
                loading: false,
            }
        },
        created() {

        },
        mounted() {
            this.getPageData();
        },
        methods: {
            getPageData() {
                getApi(`/contract/selfestablished/procurement/allocate`, {id: this.pageProp.id, type: 'purchase'}).then(res => {
                    if(res.data.code == 0) {
                        let { data } = res;
                        this.tableData = data.data;
                    }
                })
            },
            handleSelectionChange(e) {
                this.selectTableSource = e
            },
            handleDelete() {
            if (this.selectTableSource.length == 0) {
                this.$message.warning("请先至少勾选一条数据");
                return false;
            }

            this.selectTableSource.forEach(item => {  
                const index = this.tableData.findIndex(row => row.purchaseContractId === item.purchaseContractId); // 假设我们以purchaseContractId作为唯一标识  
                if (index > -1) {  
                this.tableData.splice(index, 1);  
                }  
            });  
            // 清空已选择的项  
            this.selectTableSource = [];  
            this.$refs.baseTable_1.$refs.baseTable.clearSelection();
            },
            handleAdd() {
                this.pageProp.tableData = this.tableData;
                this.pageProp.key = 'purchaseContractId';
                this.showKingDee = true
            },
            handleClose() {
                this.showKingDee = false
            },
            handleSubmit() {
                // this.$refs.addKingDee.selection_kingDee
                this.tableData = this.tableData.concat(this.$refs.addKingDee.selection_kingDee)
                this.showKingDee = false
            },
            addKindPurchase(obj){
                this.tableData.push(obj);
            },
        }
    }
</script>

<style lang="less" scoped>

</style>