<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        node-key="purId"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
    <div class="right-table">
      <dd-card class="table-tab">
        <el-tabs
          v-model="workActiveName"
          type="card"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            v-for="val in workTabList"
            :key="val.value"
            :label="val.label"
            :name="val.value"
          ></el-tab-pane>
        </el-tabs>
      </dd-card>
      <dd-card v-if="workActiveName == 'wxgzlxz'" :height="tableHeight">
        <dd-table
          :columns="nodeTableColumns"
          :data="nodeTableData"
          border
          :isSelection="false"
          show-page
          :loading="loading"
        >
        <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >填报</dd-button
            >
            <dd-button
              type="primary"
              size="medium"
              icon="export"
              @click="handleWorkloadExport"
              >导出</dd-button
            >
          </template>

          <!-- <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template> -->
        </dd-table>
      </dd-card>
      <dd-card v-if="workActiveName == 'wxgzltbjl'" :height="tableHeight">
        <dd-table
          :columns="docTableColumns"
          :data="docTableData"
          :topBtn="false"
          border
          :isSelection="false"
          show-page
          :loading="loading"
        >
          <!-- <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >填报</dd-button
            >
            <dd-button
              type="primary"
              size="medium"
              icon="export"
              @click="handleAdd"
              >导出</dd-button
            >
          </template> -->
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
      <!-- 填报弹窗 -->
      <dd-popup
        width="90%"
        height="90%"
        showBtn
        :saveBtn="true"
        :cancelBtn="true"
        :submitBtn="true"
        :show="addPopupVisible"
        :title-name="poptitleName"
        @popup-close="handleClose"
        @popup-save="handleReportSave('addPopForm', 'baseForm', 0)"
        @popup-submit="handleReportSave('addPopForm', 'baseForm', 1)"
      >
        <reportPop
          v-if="addPopupVisible"
          :rowId="rowId"
          :resolveType="resolveType"
          :rowData="rowData"
          :assignId="assignId"
          slot="main"
          ref="addPop"
        >
        </reportPop>
      </dd-popup>
      <!-- 填报详情弹窗 -->
      <dd-popup
        width="90%"
        height="90%"
        :showBtn="false"
        :show="detailPopupVisible"
        title-name="详情"
        @popup-close="handleClose"
      >
        <detailPop
          v-if="detailPopupVisible"
          :rowId="rowId"
          :resolveType="resolveType"
          :rowData="rowData"
          slot="main"
          ref="detailPop"
        >
        </detailPop>
      </dd-popup>
    </div>
  </div>
</template>

<script>
import { getApi, postApi, delApi, downBlobApi,postDownBlobApi } from "@/api/request";
import reportPop from "./components/ReportPop.vue";
import detailPop from "./components/DetailPop.vue";
export default {
  props: {
    resolveType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
  },
  components: {
    reportPop,
    detailPop,
  },
  data() {
    return {
      tableHeight: null,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeNodeId: "",
      loading: true,
      workActiveName: "wxgzlxz",
      workTabList: [
        {
          label: "外协工作量现状",
          value: "wxgzlxz",
        },
        {
          label: "外协工作量填报记录",
          value: "wxgzltbjl",
        },
      ],
      nodeTableColumns: [
        {
          title: "供应商",
          key: "purName",
          align: "center",
        },
        {
          title: "节点序号",
          key: "nodeCode",
          align: "center",
          overflow: true,
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "生产负责部门",
          key: "nodeDeptName",
          align: "center",
        },
        {
          title: "计量单位",
          key: "nodeUnitName",
          align: "center",
        },
        {
          title: "分配总量",
          key: "planWorkload",
          align: "center",
        },
        {
          title: "预估标准工天",
          key: "planStandardWorkday",
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
        },
        {
          title: "已完成量",
          key: "realWorkload",
          align: "center",
        },
        {
          title: "已完成标准工天",
          key: "realStandardWorkday",
          align: "center",
          width:"120",
        },
        {
          title: "说明",
          key: "description",
          align: "center",
        },
        {
          title: "最近填报日期",
          key: "purEndTime",
          align: "center",
        },
      ],
      nodeTableData: [],
      docTableColumns: [
        {
          title: "填报单据名称",
          key: "name",
          align: "center",
        },
        {
          title: "外协名称",
          key: "purName",
          align: "center",
        },
        {
          title: "填报日期",
          key: "workTime",
          align: "center",
        },
        {
          title: "填报人",
          key: "userName",
          align: "center",
        },
        {
          title: "填报序号",
          key: "code",
          align: "center",
        },
        {
          title: "状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 240,
          event: [
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "warning",
              rules: (row) => {
                let show = false;
                if (row.state == "PRJ-GZLZT-DTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.state == "PRJ-GZLZT-DTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "导出",
              icon: "el-icon-download",
              method: (row) => {
                this.handleExport(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.state == "PRJ-GZLZT-YTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看详情",
              icon: "el-icon-view",
              method: (row) => {
                this.handleRowDetail(row);
              },
            },
          ],
        },
      ],
      docTableData: [],
      poptitleName: "新增",
      addPopupVisible: false,
      current: 1,
      size: 20,
      total: null,
      rowData: null,
      detailPopupVisible: false,
      treeAssignId: null,
      purId:'',
      assignId:'',
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.treeAssignId = this.rowId;
    this.getLeftTree();
    this.getTableData();
  },
  methods: {
    // 获取左侧树形数据
    getLeftTree() {
      getApi(
        `/project/prjassignpurworkload/getprjassignpurworkloadtree/${this.rowId}`
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0 && data.data) {
          this.TreeList = data.data;
          this.treeNodeId = data.data[0].purId;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
          });
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let params = {
        assignId: this.treeAssignId,
        purId: this.treeNodeId,
      };
      this.loading = true;
      getApi(
        `/project/prjassignpurworkload/getprjassignpurworkloadnowlist`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.assignId = data.data[0].assignId
          this.nodeTableData = data.data;
        }
      });
    },
    // 填报记录
    getReportRecord() {
      let params = {
        assignId: this.rowId,
        purId: this.treeNodeId,
      };
      getApi(`/project/prjassignpurworkload/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.docTableData = data.data.records;
          this.total = data.data.total;
          this.loading = false;
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.purId;
      this.treeAssignId = data.assignId;
      if (this.workActiveName == "wxgzltbjl") {
        this.getReportRecord();
      } else {
        this.getTableData();
      }
    },
    handleTabClick(tab, event) {
      this.$nextTick(() => {
        this.tableHeight = this.autoTableHeight(0);
      });
      this.workActiveName = tab.name;
      if (this.workActiveName == "wxgzltbjl") {
        this.getReportRecord();
      } else {
        this.getTableData();
      }
    },
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.detailPopupVisible = false;
    },
    handleAdd() {
      this.poptitleName = "新增";
      this.addPopupVisible = true;
    },
    handleRowEditor(row) {
      this.poptitleName = "编辑";
      this.rowData = row;
      this.addPopupVisible = true;
    },
    handleReportSave(formName, formNameBase, type) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return;
      }
      let params = this.$refs.addPop.$refs.addPopForm.formData;
      params.assignId = this.assignId;
      params.workloadNodeDetailDTOList = this.$refs.addPop.tableData;
      const cellEdit = params.workloadNodeDetailDTOList.every((obj) => {
        return Object.keys(obj).every((key) => {
          if (obj.workload != null && obj.workload != "") {
            return true;
          }
        });
      });
      if (!cellEdit) {
        this.$message.warning("请填写本次完成量");
        return;
      }
      if (this.rowData == null && this.poptitleName == "新增") {
        postApi(
          `/project/prjassignpurworkload/savenodeRead/${type}`,
          params
        ).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.handleClose();
            this.getReportRecord();
            this.getLeftTree();
          }
        });
      } else {
        params.id = this.rowData.id;
        postApi(
          `/project/prjassignpurworkload/updatenodeRead/${type}`,
          params
        ).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.handleClose();
            this.getReportRecord();
            this.getLeftTree();
          }
        });
      }
    },
    // 删除
    handleRowDel(row) {
      this.$confirm(`确认要删除此数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/project/prjassignpurworkload/${row.id}`).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.getReportRecord();
            this.getLeftTree();
          }
        });
      });
    },
    // 查看详情
    handleRowDetail(row) {
      this.rowData = row;
      this.detailPopupVisible = true;
    },
    // 导出
    handleExport(row) {
      let params = {
        assignId: row.assignId,
      };
      downBlobApi(
        `/project/prjassignpurworkload/export`,
        params,
        row.name
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.getReportRecord();
        }
      });
    },
    handleWorkloadExport(){
      let params = {
        assignId: this.treeAssignId,
      };
      postDownBlobApi(
        `/project/prjassignpurworkload/pur/export`,
        params,
        "外协管理"
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
        }
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
  }
}
</style>
