<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="工作量管理列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        show-page
        :isSelection="false"
      >
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 新增/修改/详情-->
    <dd-popup
      v-if="dialogVisible"
      width="90%"
      height="90%"
      :showBtn="showBtn"
      :saveBtn="true"
      :submitBtn="true"
      :cancelBtn="true"
      :title-name="popTitle"
      :show="dialogVisible"
      @popup-close="handleClose"
      @popup-submit="handleSave(1)"
      @popup-save="handleSave(0)"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        :type="type"
        :rowData="rowData"
        ref="addPop"
        :id="id"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="handleClose"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import AddUpdateDetailInfoPop from "./components/AddUpdateDetailInfoPop";
import { postApi, getApi, loadApi, delApi } from "@/api/request";
export default {
  name: "workload",
  $dynamicRoute: {
    name: "工作量管理",
    path: "/performance/workload/index",
  },
  components: { AddUpdateDetailInfoPop },
  data() {
    return {
      tableHeight: null,
      showBtn: true,
      type: "",
      id: "",
      popTitle: "",
      drawerVisible: false,
      dialogDetailVisible: false,
      processInstanceId: null,
      loading: false,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      rowData: {},
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        // {
        //   label: "分管部门",
        //   value: "fenDeptId",
        //   inputType: "select",
        //   optionsList: [],
        //   placeholderText: "请选择分管部门",
        //   optionsListConfig: {
        //     name: "name",
        //     code: "id",
        //   },
        //   change:this.getDeptList
        // },
        {
          label: "工作量编码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入工作量编码",
          title: true,
        },
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
        {
          label: "区域",
          value: "regionAreaId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          placeholderText: "请选择区域",
        },
        {
          label: "状态",
          value: "workloadState",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
          title: true,
        },
        {
          label: "任务书编码",
          value: "assignCode",
          inputType: "text",
          placeholderText: "请输入任务书编码",
          title: true,
        },
        {
          label: "姓名",
          value: "userName",
          inputType: "text",
          placeholderText: "请输入姓名",
          title: true,
        },
        {
          label: "填报日期",
          value: "applyTimeSearch",
          inputType: "date",
          dateType: "daterange",
          format: "yyyy-MM-dd",
        },{},{},{}
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "工作量编码",
          key: "code",
          align: "center",
          width: 200,
          overflow: true,
        },
        {
          title: "工作量节点部门",
          key: "deptName",
          align: "center",
          width: 200,
          overflow: true,
        },
        {
          title: "区域",
          key: "regionAreaName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "任务书编码",
          key: "assignCode",
          align: "center",
          width: 180,
        },
        {
          title: "任务书名称",
          key: "assignName",
          width: 180,
          align: "center",
        },
        {
          title: "任务书类型",
          key: "produceTypeName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "工作包名称",
          key: "nodeName",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "工号",
          key: "userCode",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "姓名",
          key: "userName",
          align: "center",
          width: "100px",
          overflow: true,
        },
        {
          title: "填报时间",
          key: "applyTime",
          align: "center",
          width: "180px",
          overflow: true,
        },
        {
          title: "工作量",
          key: "applyWorkload",
          align: "center",
        },
        {
          title: "工作量单位",
          key: "nodeUnitName",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
        },
        {
          title: "标准工天",
          key: "planStandardWorkday",
          align: "center",
        },
        {
          title: "所属主节点",
          key: "assignNodeNameParent",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "状态",
          key: "workloadStateName",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: 150,
          event: [
            {
              label: "作废",
              state: "default",
              icon: "el-icon-delete",
              type: "warning",
              method: (row) => {
                this.handleVoid(row);
              },
              rules: (row) => {
                let show = false;
                if (row.workloadState == "PRJ-GZLZT-YTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "退回",
              state: "default",
              type: "danger",
              icon: "el-icon-delete",
              rules: (row) => {
                let show = false;
                if (row.workloadState == "PRJ-GZLZT-YTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
              method: (row) => {
                this.handleDelete(row);
              },
            },
            {
              label: "查看详情",
              icon: "el-icon-view",
              state: "default",
              method: (row) => {
                this.handleAddEdit(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    // this.getFenDeptList();
    // 获取状态
    this.getStateList();
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  // computed: {
  //   ...mapState(["btnAuthorityList"]), //权限标识数组
  // },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 分管部门列表
    getFenDeptList(){
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "fenDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 查询部门
    getDeptList(fenDeptId){
      getApi(`/admin/dept/getbydepid/${fenDeptId}`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "fenDeptId") {
              item.defaultValue = fenDeptId;
            }
            if (item.value == "deptId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 下拉数据请求
    getStateList() {
      //状态
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZLZT" }).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formList.forEach((item) => {
              if (item.value == "workloadState") {
                item.optionsList = data.data;
              }
            });
          }
        }
      );
      //部门
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      //区域
      getApi(`/admin/syscompanyregion/list`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "regionAreaId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 列表请求
    getApplyList() {
      this.handleClose();
      let { formData, current, size } = this;
      let params = {
        current,
        size,
        ...formData,
      };
      getApi(`/project/real/workload/allpage`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },

    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        current: 1,
        size: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },

    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getApplyList();
    },
    // 新增弹框关闭事件
    handleClose() {
      this.dialogVisible = false;
      this.drawerVisible = false;
      this.dialogDetailVisible = false;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.multipleSelection = [];
    },
    handleAddEdit(row) {
      this.id = row.id;
      this.type = "detail";
      this.popTitle = "详情";
      this.dialogVisible = true;
      this.rowData = row;
      this.rowData.applyTime = row.applyTime.slice(0, 10);
      this.showBtn = false;
    },
    //作废
    handleVoid(row) {
      this.$confirm("是否确定作废此条工作量？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          getApi(`/project/real/workload/cancelbyid/${row.id}`)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "作废成功!",
                });
                this.getApplyList();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDelete(row) {
      this.$confirm("是否确定退回？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          getApi(`/project/real/workload/backbyid/${row.id}`)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "退回成功!",
                });
                this.getApplyList();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getApplyList();
    },
    // //校验输入框的值
    handleInputReg(val) {
      let value = val.applyWorkload;
      let regex = /^\d+(\.\d{1,2})?$/; // 匹配整数或最多两位小数的数字
      if (!regex.test(value)) {
        val.applyWorkload = "";
        this.$message.warning("请输入正确的格式!");
        return;
      }
    },
    // 新增确认事件
    handleSave(type) {
      let flag = false;
      this.$refs.addPop.$refs.baseForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return;
      }
      let regex = /^\d+(\.\d{1,2})?$/; // 匹配整数或最多两位小数的数字
      let { _data } = this.$refs.addPop;
      let { baseForm } = this.$refs.addPop.$refs.baseForm.$refs;
      let { workloadForm } = this.$refs.addPop.$refs.workloadForm.$refs;
      let { notes } = this.$refs.addPop.$refs.notes.$refs;
      let { assignId } = baseForm.model;
      let { description } = notes.model;
      let params = {
        nodeCode: _data.nodeCode,
        assignId,
        description,
      };
      if (_data.tableDataSources.length) {
        //子任务
        for (let i = 0; i < _data.tableDataSources.length; i++) {
          let value = _data.tableDataSources[i];
          if (!regex.test(value.applyWorkload)) {
            value.applyWorkload = "";
            this.$message.warning("请输入正确的格式!");
            return;
          }
        }
        for (let i = 0; i < _data.tableDataSources.length; i++) {
          delete _data.tableDataSources[i].options;
        }
        params.workloadDetailList = _data.tableDataSources;
      } else {
        //wbs
        let { applyWorkload } = workloadForm.model;
        if (!regex.test(applyWorkload)) {
          this.$message.warning("请输入正确的格式!");
          for (let i = 0; i < _data.baseFormList.length; i++) {
            let item = _data.baseFormList[i];
            if (item.value == "applyWorkload") {
              item.defaultValue = "";
              return;
            }
          }
        }
        params.applyWorkload = applyWorkload;
      }
      if (this.type == "update") {
        params.id = this.id;
      }
      if (this.type == "add") {
        postApi(`/project/real/workload/save/${type}`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success(`${type == 1 ? "提交成功" : "保存成功"}`);
            this.getApplyList();
          }
        });
      } else {
        postApi(`/project/real/workload/update/${type}`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success(`${type == 1 ? "提交成功" : "保存成功"}`);
            this.getApplyList();
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
