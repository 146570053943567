<template>
  <div class="container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="工号汇总列表">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        max-height="360"
        :topBtn="false"
        border
        ref="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        :isSelection="false"
        @select-change="handleSelectionChange"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogVisible"
      width="90%"
      height="90%"
      title-name="原始用车详情"
      :show="dialogVisible"
      :inPopup="true"
      @popup-close="dialogVisible = false"
    >
      <CarSummaryDetail
        slot="main"
        :id="applyId"
        :payeeId="payeeId"
      ></CarSummaryDetail>
    </dd-popup>
  </div>
</template>
  
<script>
import CarSummaryDetail from "../CarSummaryDetail";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import { mapState, mapActions } from "vuex";
import qs from "qs";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  components: { CarSummaryDetail },
  data() {
    return {
      drawerVisible: false,

      processInstanceId: null,
      loading: false,
      // id: null,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      applyId: "",
      payeeId: "",
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "收款人",
          value: "payeeId",
          inputType: "select",
          filterable: "filterable",
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          placeholderText: "请选择或搜索收款人",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "部门",
          key: "depName",
          align: "center",
        },
        {
          title: "工号",
          key: "payeeCode",
          align: "center",
          overflow: true,
        },
        {
          title: "收款人",
          key: "payeeName",
          align: "center",
        },
        {
          title: "总核定公里数",
          key: "approvedKilometerTotal",
          align: "center",
        },
        {
          title: "核定用车费用总计(元)",
          key: "approvedAmountTotal",
          align: "center",
        },
        {
          title: "核定过路费总计(元)",
          key: "tollVerifyAmountTotal",
          align: "center",
        },
        {
          title: "费用总计(元)",
          key: "totalAmount",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    this.getPaymentData();
    console.log(this.type);
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  methods: {
    //获取收款人数据
    getPaymentData() {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        this.formList.forEach((item) => {
          if (item.value == "payeeId") {
            item.optionsList = data.data;
          }
        });
      });
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    // 列表请求
    getApplyList() {
      this.loading = true;
      let { current, size } = this;
      let params = {
        current,
        size,
        collectId: this.id,
        payeeId: this.formData.payeeId,
      };
      postApi(`/hr/collectsheet/list/statistics/person`, params).then(
        (res) => {
          let { data } = res;
          console.log(data);
          if (data.code == 0) {
            data.data.records.forEach((item) => {
              item.approvedAmountTotal = formatNumber(item.approvedAmountTotal);
              item.approvedKilometerTotal = formatNumber(
                item.approvedKilometerTotal
              );
              item.tollVerifyAmountTotal = formatNumber(item.tollVerifyAmountTotal);
              item.totalAmount = formatNumber(item.totalAmount);

            });
            this.tableDataSources = data.data.records;
            this.total = data.data.total;
          }
        }
      );
    },
    handleDetailInfo(row) {
      this.applyId = row.id;
      this.payeeId = row.payeeId;
      this.dialogVisible = true;
    },
    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.current = 1;
      this.getApplyList();
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "approvedAmountTotal"||
            column.property == "approvedKilometerTotal"||
            column.property == "tollVerifyAmountTotal"||
            column.property == "totalAmount"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return (prev * 1000 + curr * 1000) / 1000;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (!isNaN(+item)) {
          if (index == sums.length - 1) {
            console.log(sums[index]);
            sums[index] = formatNumber(sums[index]);
          }
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getApplyList();
    },
  },
};
</script>
  
<style lang='less' scoped>
</style>